<template>
    <el-dialog
        custom-class="block-selection-dialog"
        title="Blok selectie"
        :before-close="this.dialogClosed"
        :destroy-on-close="true"
        :lock-scroll="false"
        :model-value="this.value"
        ref="dialog"
    >
        <table class="block-selection-table" cellspacing="0" v-if="blocks.length">
            <thead>
                <tr>
                    <th class="date">Datum</th>
                    <th class="time">Tijd</th>
                    <th class="block_reference">Blok</th>
                    <th class="channel">Zender</th>
                    <th class="program_before">Programma voor</th>
                    <th class="grp">GRP</th>
                    <th class="leads_per_grp" v-if="blocks[0].leads_per_grp !== -1">Leads/GRP</th>
                    <th class="score">Score</th>
                    <th class="should_book">Boeken</th>
                    <th class="is_randomly_selected">Beste</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <template v-for="block of blocks" :key="block">
                    <tr>
                        <td class="date">
                            <span>{{ block.date_string }}</span>
                        </td>
                        <td class="time">
                            <span>{{ block.time_string }}</span>
                        </td>
                        <td class="block_reference">
                            <span>{{ block.block_reference }}</span>
                        </td>
                        <td class="channel">
                            <span>{{ block.channel }}</span>
                        </td>
                        <td class="program_before">
                            <span>{{ block.program_before.toUpperCase() }}</span>
                        </td>
                        <td class="grp">
                            <span>{{ grpHelper.display(block.grp) }}</span>
                        </td>
                        <td class="leads_per_grp" v-if="block.leads_per_grp !== -1">
                            <span class="is--greyed-out">{{
                                numberHelper.display(block.leads_per_grp)
                            }}</span>
                        </td>
                        <td class="score">
                            <span>{{ numberHelper.display(block.score, false, true) }}</span>
                        </td>
                        <td class="should_book">
                            <span>{{ block.should_book ? "Ja" : "Nee" }}</span>
                        </td>
                        <td class="is_randomly_selected">
                            <span>{{ block.is_randomly_selected === false ? "Ja" : "Nee" }}</span>
                        </td>
                        <td>
                            <el-button
                                v-if="block.considered_spots?.length"
                                size="small"
                                :custom-class="openedBlock === block ? 'opened' : 'closed'"
                                :icon="
                                    openedBlock === block
                                        ? 'el-icon-arrow-up'
                                        : 'el-icon-arrow-down'
                                "
                                @click="openBlock(block)"
                            />
                        </td>
                    </tr>

                    <tr v-if="openedBlock && openedBlock === block">
                        <td colspan="11">
                            <table
                                class="block-selection-table considered-spots"
                                cellspacing="0"
                                v-if="openedBlock.considered_spots?.length"
                            >
                                <thead>
                                    <tr>
                                        <th class="date">Datum</th>
                                        <th class="time">Tijd</th>
                                        <th class="block_reference">Blok</th>
                                        <th class="channel">Zender</th>
                                        <th class="program_before">Programma voor</th>
                                        <th class="grp">GRP</th>
                                        <th
                                            class="leads_per_grp"
                                            v-if="blocks[0].leads_per_grp !== -1"
                                        >
                                            Leads/GRP
                                        </th>
                                        <th class="score">Score</th>
                                        <th class="should_book"></th>
                                        <th class="is_randomly_selected"></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template
                                        v-for="(spot, i) of openedBlock.considered_spots"
                                        :key="spot.spot.reference"
                                    >
                                        <tr :class="i === 0 ? 'selected' : null">
                                            <td class="date">
                                                <span>{{ spot.spot.date_string }}</span>
                                            </td>
                                            <td class="time">
                                                <span>{{ spot.spot.time_string }}</span>
                                            </td>
                                            <td class="block_reference">
                                                <span>{{ spot.spot.block_reference }}</span>
                                            </td>
                                            <td class="channel">
                                                <span>{{ spot.spot.channel }}</span>
                                            </td>
                                            <td class="program_before">
                                                <span>{{
                                                    spot.spot.program_before.toUpperCase()
                                                }}</span>
                                            </td>
                                            <td class="grp">
                                                <span>{{
                                                    grpHelper.display(spot.spot.achieved_grp)
                                                }}</span>
                                            </td>
                                            <td
                                                class="leads_per_grp"
                                                v-if="block.leads_per_grp !== -1"
                                            >
                                                <span>
                                                    {{
                                                        numberHelper.display(
                                                            spot.spot.achieved_leads_per_grp
                                                        )
                                                    }}
                                                </span>
                                            </td>
                                            <td class="score">
                                                <span>{{
                                                    numberHelper.display(spot.score, false, true)
                                                }}</span>
                                            </td>
                                            <td class="should_book"></td>
                                            <td class="is_randomly_selected"></td>
                                            <td>
                                                <el-button
                                                    v-if="spot.reasons?.length"
                                                    size="small"
                                                    :custom-class="
                                                        openedConsideredSpot === spot
                                                            ? 'opened'
                                                            : 'closed'
                                                    "
                                                    :icon="
                                                        openedConsideredSpot === spot
                                                            ? 'el-icon-arrow-up'
                                                            : 'el-icon-arrow-down'
                                                    "
                                                    @click="openConsideredSpot(spot)"
                                                />
                                            </td>
                                        </tr>

                                        <tr
                                            v-if="
                                                openedConsideredSpot &&
                                                openedConsideredSpot === spot
                                            "
                                        >
                                            <td colspan="11">
                                                <table
                                                    class="block-selection-table considered-spot"
                                                    cellspacing="0"
                                                    v-if="spot.reasons?.length"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th class="mutation">Mutatie</th>
                                                            <th class="reason">Reden</th>
                                                            <th class="score_after">Score erna</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            v-for="reason of spot.reasons"
                                                            :key="reason"
                                                        >
                                                            <td class="mutation">
                                                                {{
                                                                    (reason.mutation >= 0
                                                                        ? "+"
                                                                        : "") +
                                                                    numberHelper.display(
                                                                        reason.mutation,
                                                                        false,
                                                                        true
                                                                    )
                                                                }}
                                                            </td>
                                                            <td class="reason">
                                                                {{ reason.reason }}
                                                            </td>
                                                            <td class="score_after">
                                                                {{
                                                                    numberHelper.display(
                                                                        reason.score_after
                                                                    )
                                                                }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </el-dialog>
</template>
<script lang="ts">
import { defineComponent, PropType, reactive, Ref, ref, toRefs, watch } from "vue";

import DateHelper from "@/helper/date-helper";
import GrpHelper from "@/helper/grp-helper";
import NumberHelper from "@/helper/number-helper";
import BlockApiService from "@/service/api/block";
import { Block, BlockConsideredSpot } from "@/types/uzs/block";

const props = {
    value: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
    guid: {
        type: String as PropType<string>,
        required: true,
    },
};

export default defineComponent({
    name: "SelectedBlocksDialog",
    props,
    setup(props, { emit }) {
        const guid = ref(props.guid ?? null) as Ref<string | null>;
        const blockApi = new BlockApiService();
        const dateHelper = DateHelper;
        const grpHelper = GrpHelper;
        const numberHelper = NumberHelper;

        const data = reactive({
            blocks: [] as Array<Block>,
            openedBlock: undefined as Block | undefined,
            openedConsideredSpot: undefined as BlockConsideredSpot | undefined,
        });

        const close = (): void => {
            guid.value = null;
            emit("input", props.value);
        };

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        const init = (): void => {
            data.blocks = [];
            loadBlocks();
        };

        function loadBlocks(): void {
            if (!guid.value) {
                return;
            }

            blockApi.fetch(guid.value).then((response) => {
                data.blocks = response.data as Block[];
            });
        }

        function openBlock(block: Block): void {
            if (data.openedBlock === block) {
                data.openedBlock = undefined;
                return;
            }

            data.openedBlock = block;
            data.openedBlock.considered_spots?.sort((a, b) => {
                if (a.index === b.index) {
                    return 0;
                }

                return a.index > b.index ? -1 : 1;
            });
        }

        function openConsideredSpot(spot: BlockConsideredSpot): void {
            if (data.openedConsideredSpot === spot) {
                data.openedConsideredSpot = undefined;
                return;
            }

            data.openedConsideredSpot = spot;
        }

        watch(
            () => props.guid,
            (value, oldValue) => {
                if (value !== oldValue) {
                    guid.value = value ?? null;
                    init();
                }
            }
        );

        return {
            ...toRefs(data),
            close,
            dateHelper,
            dialogClosed,
            grpHelper,
            guid,
            numberHelper,
            openBlock,
            openConsideredSpot,
        };
    },
});
</script>

<style lang="scss">
.block-selection-dialog {
    min-width: 995px;
}

.block-selection-table tr.selected td {
    color: hsl(106deg, 61%, 51%);
    font-weight: bold;
}

.block-selection-dialog table tr:not(:last-child) td,
.block-selection-dialog table tr:not(:last-child) th {
    border-bottom: 1px solid #ccc;
}

.block-selection-dialog td,
.block-selection-dialog th {
    text-align: left;
    vertical-align: center;
}

.block-selection-dialog td.date,
.block-selection-dialog th.date {
    min-width: 160px;
    max-width: 160px;
}

.block-selection-dialog td.time,
.block-selection-dialog th.time {
    min-width: 60px;
    max-width: 60px;
}

.block-selection-dialog td.block_reference,
.block-selection-dialog th.block_reference {
    min-width: 70px;
    max-width: 70px;
}

.block-selection-dialog td.channel,
.block-selection-dialog th.channel {
    min-width: 70px;
    max-width: 70px;
}

.block-selection-dialog td.program_before,
.block-selection-dialog th.program_before {
    max-width: 180px;
    min-width: 180px;
}

.block-selection-dialog td.grp,
.block-selection-dialog th.grp {
    max-width: 70px;
    min-width: 70px;
}

.block-selection-dialog td.leads_per_grp,
.block-selection-dialog th.leads_per_grp {
    max-width: 90px;
    min-width: 90px;
    text-align: center;
}

.block-selection-dialog td.score,
.block-selection-dialog th.score {
    max-width: 70px;
    min-width: 70px;
}

.block-selection-dialog td.should_book,
.block-selection-dialog th.should_book {
    max-width: 70px;
    min-width: 70px;
}

.block-selection-dialog td.is_randomly_selected,
.block-selection-dialog th.is_randomly_selected {
    max-width: 70px;
    min-width: 70px;
}

.block-selection-dialog td.mutation,
.block-selection-dialog th.mutation {
    max-width: 80px;
    min-width: 80px;
}

.block-selection-dialog td.reason,
.block-selection-dialog th.reason {
    max-width: 620px;
    min-width: 620px;
}

.block-selection-dialog td.score_after,
.block-selection-dialog th.score_after {
    width: 100%;
}

.block-selection-table th,
.block-selection-table td {
    padding: 0.5rem 0;
}

.block-selection-table td span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
}

.block-selection-table td.program_before span {
    max-width: 90%;
}

.block-selection-table.considered-spots,
.block-selection-table.considered-spots {
    margin: 2rem 0 8rem 0;
}

.block-selection-table.considered-spot,
.block-selection-table.considered-spot {
    margin: 1rem 0 4rem 0;
}

.block-selection-table button[custom-class="opened"] {
    border: 1px solid #000;
}

.selected-blocks.note {
    display: block;
    margin-top: 1rem;
    font-style: italic;
}
</style>
