<template>
    <imported-blocks-dialog
        v-model="this.showImportedBlocksDialog"
        :imported-blocks="this.currentBlocks"
        @input="this.onDoneEditing"
    />

    <selected-blocks-dialog
        v-model="this.showSelectedBlocksDialog"
        :guid="selectedBlocksGuid"
        @input="onDoneDebugging"
    />

    <el-form class="table-actions">
        <el-button type="secondary" @click="onDownloadLeadSpreadsheet">Download template</el-button>
    </el-form>

    <div>
        <el-upload
            class="uploader"
            drag
            multiple
            :action="this.backendUrl + '/spot/block/upload-spreadsheet'"
            :headers="{ Authorization: 'Bearer ' + this.accessToken }"
            :show-file-list="false"
            @error="this.onDoneUploading"
            @progress="this.onBusyUploading"
            @success="this.onDoneUploading"
        >
            <el-icon name="upload"></el-icon>
            <div v-if="!this.isUploading" class="el-upload__text">
                Sleep hier bestanden naartoe om ze te uploaden, of klik dit veld aan om een bestand
                te selecteren...
            </div>
            <div v-if="this.isUploading">
                <el-icon name="loading" />
            </div>
        </el-upload>
    </div>

    <el-table
        v-loading="this.isLoading"
        :default-sort="{ prop: 'created_at', order: 'descending' }"
        :data="this.importedBlocks"
        width="100%"
    >
        <el-table-column
            class-name="column-advertiser"
            label="Adverteerder"
            min-width="100"
            prop="advertiser"
        >
            <template #default="scope">
                <span :title="scope.row.uzs_advertiser?.name">{{
                    scope.row.uzs_advertiser?.name
                }}</span>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-target"
            label="Doelstelling"
            min-width="100"
            prop="target"
        >
            <template #default="scope">
                <span :title="scope.row.target">{{ scope.row.target }}</span>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-number-of-blocks"
            label="Aantal blokken"
            prop="number_of_blocks"
        >
            <template #default="scope">
                <span :title="scope.row.number_of_blocks">{{ scope.row.number_of_blocks }}</span>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-requested-grps"
            label="Benodigde GRP's"
            prop="required_grps"
        >
            <template #default="scope">
                <span :title="grpHelper.display(scope.row.required_grps ?? 0, true)">{{
                    grpHelper.display(scope.row.required_grps ?? 0, true)
                }}</span>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-created-at"
            label="Geïmporteerd"
            min-width="100"
            prop="created_at"
        >
            <template #default="scope">
                <span :title="this.dateHelper.dateTime(scope.row.created_at)">{{
                    this.dateHelper.dateTime(scope.row.created_at)
                }}</span>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-import-end"
            label="Verwerkt"
            min-width="100"
            prop="import_end"
        >
            <template #default="scope">
                <span
                    v-if="scope.row.import_end"
                    :title="this.dateHelper.dateTime(scope.row.import_end)"
                    >{{ this.dateHelper.dateTime(scope.row.import_end) }}</span
                >
                <el-icon v-if="scope.row.import_start && !scope.row.import_end" name="loading" />
            </template>
        </el-table-column>
        <el-table-column class-name="column-actions keep-width" width="230" fixed="right">
            <template #default="scope">
                <el-button
                    v-if="scope.row.import_end"
                    icon="el-icon-download"
                    plain="plain"
                    size="mini"
                    title="Download"
                    @click="this.onDownloadResultSpreadsheet(scope.row)"
                ></el-button>
                <el-button
                    class="edit"
                    icon="el-icon-edit"
                    plain="plain"
                    size="mini"
                    title="Stel in"
                    @click="this.onEdit(scope.row)"
                ></el-button>
                <el-button
                    class="debug"
                    icon="el-icon-aim"
                    plain="plain"
                    size="mini"
                    title="Debug"
                    @click="this.onDebug(scope.row)"
                ></el-button>
                <el-button
                    class="delete"
                    :icon="'el-icon-' + (this.isDeleting === scope.row?.id ? 'loading' : 'delete')"
                    :disabled="this.isDeleting === scope.row?.id"
                    plain="plain"
                    size="mini"
                    title="Verwijder bestand en data"
                    type="danger"
                    @click="this.onDelete(scope.row)"
                ></el-button>
            </template>
        </el-table-column>
    </el-table>

    <el-pagination
        :background="false"
        :current-page="this.currentPage"
        :hide-on-single-page="false"
        :page-count="this.pageCount"
        :page-size="this.itemsPerPage"
        :page-sizes="this.pageSizes"
        :total="this.itemCount"
        layout="sizes, prev, pager, next, jumper, ->, total"
        @current-change="this.goToPage"
        @next-click="this.goToPage"
        @prev-click="this.goToPage"
        @size-change="this.onPageSizeChanged"
    />
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import { Store, useStore } from "vuex";

import DateHelper from "@/helper/date-helper";
import GrpHelper from "@/helper/grp-helper";
import BlockSpreadSheetApiService from "@/service/api/blockSpreadSheet";
import ImportedBlocksApiService from "@/service/api/importedBlocks";
import config from "@/service/config";
import { Authentication } from "@/types/credentials/authentication";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { RequestParameters } from "@/types/request/requestParameters";
import { RootState } from "@/types/state/root";
import { BlockSpreadsheetAccess } from "@/types/uzs/blockSpreadsheetAccess";
import { ImportedBlocks } from "@/types/uzs/importedBlocks";
import ImportedBlocksDialog from "@/views/export/blocks/dialogs/ImportedBlocks.vue";
import SelectedBlocksDialog from "@/views/export/blocks/dialogs/SelectedBlocks.vue";

export default defineComponent({
    name: "List",
    components: { ImportedBlocksDialog, SelectedBlocksDialog },
    setup() {
        const store = useStore() as Store<RootState>;
        const rootState = store.state as RootState;
        const auth = rootState.authentication as Authentication;
        const dateHelper = DateHelper;
        const grpHelper = GrpHelper;
        const blockSpreadsheetApi = new BlockSpreadSheetApiService();
        const importedBlocksApi = new ImportedBlocksApiService();

        const actions = {
            importedBlocks: {
                list: (params: RequestParameters) => importedBlocksApi.fetchAll(params),
                delete: (id: number) => importedBlocksApi.delete(id),
            },
        };

        /* eslint-disable  @typescript-eslint/no-explicit-any */
        const data = reactive({
            backendUrl: config.backend,
            isDeleting: -1,
            isLoading: false,
            isUploading: false,
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            order: {
                created_at: "desc",
            } as OrderRequest,
            currentPage: 1,
            currentBlocks: null as ImportedBlocks | null,
            pageCount: 0,
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            itemsPerPage: config.pagination.per_page,
            itemCount: 0,
            importedBlocks: [] as ImportedBlocks[],
            showTemplateDialog: false,
            showImportedBlocksDialog: false,
            showSelectedBlocksDialog: false,
            selectedBlocksGuid: null as string | null,
            updateTimeout: null as any | null,
        });

        const goToPage = (page: number) => {
            if (!data.pagination) {
                return;
            }

            data.currentPage = page;
            data.pagination.page = page;

            loadImportedBlocks();
        };

        const init = (): void => {
            loadImportedBlocks();
            updateOnInterval();
        };

        const loadImportedBlocks = (isLoading = true): void => {
            data.isLoading = isLoading;

            actions.importedBlocks
                .list({
                    pagination: data.pagination,
                    order: data.order,
                })
                .then((result) => {
                    data.currentPage = result.pagination?.page ?? 1;
                    data.pageCount = result.pagination?.total_pages ?? 0;
                    data.itemsPerPage = result.pagination?.per_page ?? config.pagination.per_page;
                    data.itemCount = result.pagination?.total_items ?? 0;

                    return (result.data ?? []) as ImportedBlocks[];
                })
                .then((response) => {
                    data.importedBlocks = response;
                    data.isLoading = false;
                });
        };

        const onBusyUploading = () => {
            data.isUploading = true;
        };

        const onDelete = (importedBlocks: ImportedBlocks): void => {
            data.isDeleting = importedBlocks.id;

            actions.importedBlocks
                .delete(importedBlocks.id)
                .then(() => (data.isDeleting = -1))
                .catch(() => (data.isDeleting = -1));
        };

        const onDoneDebugging = (): void => {
            data.selectedBlocksGuid = null;
            data.showSelectedBlocksDialog = false;
        };

        const onDoneEditing = (): void => {
            data.currentBlocks = null;
            data.showImportedBlocksDialog = false;
        };

        const onDoneUploading = () => {
            data.isUploading = false;

            loadImportedBlocks();
        };

        function onDownloadLeadSpreadsheet(): void {
            blockSpreadsheetApi.downloadTemplate().then((response: BlockSpreadsheetAccess) => {
                if (response?.url) {
                    location.href = response.url;
                }
            });
        }

        function onDownloadResultSpreadsheet(importedBlocks: ImportedBlocks): void {
            blockSpreadsheetApi
                .downloadSpreadsheet(importedBlocks.uuid)
                .then((response: BlockSpreadsheetAccess) => {
                    if (response?.url) {
                        location.href = response.url;
                    }
                });
        }

        const onDebug = (importedBlocks: ImportedBlocks): void => {
            data.selectedBlocksGuid = importedBlocks?.uuid ?? null;
            data.showSelectedBlocksDialog = true;
        };

        const onEdit = (importedBlocks: ImportedBlocks): void => {
            data.currentBlocks = importedBlocks;
            data.showImportedBlocksDialog = true;
        };

        const onPageSizeChanged = (pageSize: number) => {
            if (!data.pagination) {
                return;
            }

            data.pagination.per_page = pageSize;

            loadImportedBlocks();
        };

        const updateOnInterval = () => {
            if (data.updateTimeout) {
                clearInterval(data.updateTimeout);
            }

            if (!data.isLoading) {
                loadImportedBlocks(false);
            }

            data.updateTimeout = setTimeout(() => {
                updateOnInterval();
            }, 3000);
        };

        onBeforeRouteLeave(() => {
            if (data.updateTimeout) {
                clearTimeout(data.updateTimeout);
            }
        });

        onMounted(() => {
            init();
        });

        return {
            ...toRefs(data),
            accessToken: auth.token,
            dateHelper,
            goToPage,
            grpHelper,
            onBusyUploading,
            onDebug,
            onDelete,
            onDoneDebugging,
            onDoneEditing,
            onDoneUploading,
            onDownloadLeadSpreadsheet,
            onDownloadResultSpreadsheet,
            onEdit,
            onPageSizeChanged,
        };
    },
});
</script>

<style lang="scss">
@media screen and (max-width: 719px) {
    .column-actions button.edit,
    .column-actions button.delete {
        display: none;
    }
}
</style>
