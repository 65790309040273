import { addDays, isAfter, isBefore } from "date-fns";

import DateHelper from "@/helper/date-helper";
import { Flight } from "@/types/uzs/flight";

export default class FlightHelper {
    public static isFutureCampaign = (flight: Flight | null | undefined): boolean => {
        if (!flight?.start_date) {
            return false;
        }

        const currentDate = DateHelper.date();

        return isBefore(currentDate, DateHelper.date(flight.start_date));
    };

    public static isCampaignFulfilled = (flight: Flight | null | undefined): boolean => {
        if (!flight?.end_date) {
            return false;
        }

        const currentDate = DateHelper.date();
        const minimumDate = addDays(DateHelper.date(flight.end_date), 7);

        return isAfter(currentDate, minimumDate);
    };
}
