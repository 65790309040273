
import { ElMessage, ElMessageBox } from "element-plus";
import { range } from "lodash";
import { defineComponent, onMounted, reactive, toRefs } from "vue";

import { Country } from "@/enums/country";
import DateHelper from "@/helper/date-helper";
import HolidayApiService from "@/service/api/settings/holiday/holiday";
import { Copy } from "@/service/clipboard/copy";
import config from "@/service/config";
import { FilterRequest } from "@/types/request/filterRequest";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { Holiday } from "@/types/uzs/settings/holiday/holiday";
import HolidayDialog from "@/views/settings/holidays/dialogs/Holiday.vue";

type CurrentRow = {
    row: Holiday;
    rowIndex: number;
};

type SortEvent = {
    column: string;
    order: string;
    prop: string;
};

export default defineComponent({
    name: "List",
    components: { HolidayDialog },
    setup() {
        const dateHelper = DateHelper;
        const holidayService = new HolidayApiService();
        const textCopier = new Copy();
        const countries = Country;

        const actions = {
            holiday: {
                delete: (guid: string) => holidayService.delete(guid),
                list: (params: RequestParameters) => holidayService.fetchAll(params),
            },
        };

        const data = reactive({
            country: "",
            currentHoliday: {} as Holiday | null,
            currentPage: 1 as number,
            holidays: [] as Holiday[],
            year: undefined as number | undefined,
            years: range(2017, new Date().getFullYear() + 2).reverse(),
            loading: true,
            filter: {} as FilterRequest,
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            order: {
                date: "asc",
            } as OrderRequest,
            page: 1,
            pages: undefined as number | undefined,
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            perPage: 25,
            totalPages: 1,
            totalItems: 0,
            search: "",
            showHolidayDialog: false,
            showFilterModal: false,
            sortingEnabled: false,
        });

        function init() {
            data.loading = true;

            const params = {
                filter: data.filter,
                pagination: data.pagination,
                search: data.search,
                sort: data.order,
            };

            actions.holiday.list(params).then((response: DataResult<Holiday[] | null>) => {
                data.holidays = (response.data ?? []) as Holiday[];
                data.page = response.pagination?.page ?? 1;
                data.perPage = response.pagination?.per_page ?? 1;
                data.totalPages = response.pagination?.total_pages ?? 1;
                data.totalItems = response.pagination?.total_items ?? 0;
                data.loading = false;
            });
        }

        const holidayInput = (value: boolean) => {
            data.currentHoliday = null;
            data.showHolidayDialog = value;

            init();
        };

        const deleteHoliday = (value: CurrentRow) => {
            ElMessageBox.confirm(
                "Weet je zeker dat je " + value.row.name + " wilt verwijderen?",
                "Verwijderen?",
                {
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nee",
                    type: "warning",
                }
            ).then(() => {
                actions.holiday
                    .delete(value.row.guid)
                    .then((result) => {
                        init();

                        if (!result.success) {
                            throw new Error();
                        }

                        ElMessage({
                            message: value.row.name + " is verwijderd.",
                            type: "success",
                        });
                    })
                    .catch(() => {
                        ElMessage({
                            message: "Er is een onbekende fout opgetreden.",
                            type: "error",
                        });
                    });
            });
        };

        const editHoliday = (holiday?: Holiday | null) => {
            data.currentHoliday = holiday ?? null;
            data.showHolidayDialog = true;
        };

        const goToPage = (page: number) => {
            if (!data?.pagination) {
                return;
            }

            data.currentPage = page;
            data.pagination.page = page;

            init();
        };

        function onSearchChanged() {
            init();
        }

        function onSelectedCountryChanged() {
            if (data.country !== "") {
                Object.assign(data.filter, {
                    ...data.filter,
                    country: data.country,
                });
            }

            if (data.country === "" && data.filter?.country) {
                delete data.filter.country;
            }

            init();
        }

        function onYearChanged() {
            if (data.year) {
                Object.assign(data.filter, {
                    ...data.filter,
                    year: data.year,
                });
            }

            if (!data.year && data.filter?.year) {
                delete data.filter.year;
            }

            init();
        }

        const newHoliday = () => {
            data.currentHoliday = {} as Holiday;
            data.showHolidayDialog = true;
        };

        const pageSizeChange = (pageSize: number) => {
            if (!data?.pagination) {
                return;
            }

            data.pagination.per_page = pageSize;

            init();
        };

        const sortingChanged = ($event: SortEvent) => {
            const property = $event.prop?.length ? $event.prop : "start_time";
            const direction = $event.order === "descending" ? "desc" : "asc";

            if (!data.holidays || !data.order || !data.sortingEnabled) {
                return;
            }

            data.order = {
                [property]: direction,
            };

            init();
        };

        onMounted(() => {
            init();
        });

        return {
            ...toRefs(data),
            countries,
            dateHelper,
            holidayInput,
            deleteHoliday,
            editHoliday,
            goToPage,
            onSearchChanged,
            onSelectedCountryChanged,
            onYearChanged,
            newHoliday,
            pageSizeChange,
            sortingChanged,
            textCopier,
        };
    },
});
