export default class NumberHelper {
    static display(value: number | null | undefined, round = false, allowNegative = false): string {
        if (value === null || value === undefined) {
            return "? %";
        }

        if (!allowNegative && value < 0) {
            value = 0;
        }

        let parsedValue = Math.round(value * 10) / 10;

        if (round) {
            parsedValue = Math.round(parsedValue);
        }

        let number = parsedValue.toString().replace(".", ",");

        if (number.indexOf(",") === -1 && value !== 100 && !round) {
            number += ",0";
        }

        return number;
    }
}
