import axios from "axios";

import config from "@/service/config";
import { DataResult } from "@/types/result/dataResult";
import { Region } from "@/types/uzs/big-query-region/region";

export default class RegionApiService {
    public async list(country: string): Promise<DataResult<Array<Region>>> {
        const response = await axios.post(config.backend + "/api/integration/bigquery-regions", {
            country,
        });

        return response?.data as DataResult<Array<Region>>;
    }
}
