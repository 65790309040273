<template>
    <el-card>
        <el-form class="login" label-position="left" label-width="120px" @keypress="this.keyPress">
            <el-form-item label="E-mailadres">
                <el-input
                    v-model="this.credentials.email"
                    class="input-email"
                    size="medium"
                    :disabled="this.isLoading"
                >
                </el-input>
            </el-form-item>
            <el-form-item label="Wachtwoord">
                <el-input
                    v-model="this.credentials.password"
                    class="input-password"
                    size="medium"
                    :disabled="this.isLoading"
                    show-password
                >
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button
                    class="button-login"
                    icon="el-icon-unlock"
                    size="medium"
                    type="primary"
                    :disabled="this.isLoading"
                    @click="this.loginUser"
                >
                    Inloggen</el-button
                >
            </el-form-item>
        </el-form>
    </el-card>
</template>
<script lang="ts">
import { ElMessage } from "element-plus";
import { defineComponent, inject } from "vue";
import { mapActions, mapState, Store } from "vuex";

import { User } from "@/types/credentials/user";
import { AuthenticationRequest } from "@/types/google/authenticationRequest";
import { State } from "@/types/state";

export default defineComponent({
    name: "Login",
    setup() {
        const store = inject("store") as Store<State>;

        return {
            _store: store,
        };
    },
    computed: {
        ...mapState(["token"]),
    },
    data() {
        return {
            isLoading: false,
            credentials: {} as User,
        };
    },
    mounted() {
        this.rerouteUser();

        if (this.$route.query?.type === "sso") {
            this.isLoading = true;

            this.loginWithGoogle(this.$route.query as AuthenticationRequest)
                .then(this.rerouteUser)
                .catch(() => {
                    ElMessage({
                        message: "Kan niet inloggen. Controleer de ingevulde gegevens.",
                        type: "error",
                    });

                    this.isLoading = false;
                });
        }
    },
    methods: {
        ...mapActions("authentication", ["checkIfTokenExpired", "login", "loginWithGoogle"]),
        keyPress(event: KeyboardEvent) {
            if (event.key.toLowerCase() === "enter") {
                this.loginUser();
            }
        },
        loginUser() {
            this.isLoading = true;

            this.login(this.credentials)
                .then(this.rerouteUser)
                .catch(() => {
                    ElMessage({
                        message: "Kan niet inloggen. Controleer de ingevulde gegevens.",
                        type: "error",
                    });

                    this.isLoading = false;
                });
        },
        rerouteUser() {
            this.checkIfTokenExpired().then((response: boolean) => {
                if (!response) {
                    this.$router.push({
                        name: "campaigns",
                    });
                }
            });
        },
    },
});
</script>

<style lang="scss">
div.roi {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    font-size: 1.35rem;
    max-width: 1344px;
    margin: 75px auto 0 auto;
    padding: 1rem;
}

div.roi div.content {
    flex: 1 0 calc(50% - 1rem);
}

div.roi div.video {
    flex: 1 0 calc(50% - 1rem);
}

div.roi h1 {
    background: unset;
    color: #111;
    font-size: 2rem;
    margin-left: -5px;
}

div.roi ul {
    margin-bottom: 25px;
    padding-left: 25px;
}

div.roi ul li {
    line-height: 2.5rem;
}

div.roi p a {
    color: hsl(355deg, 100%, 61%);
    font-weight: bold;
}

@media screen and (max-width: 1200px) {
    div.roi div.content {
        flex: 1 0 100%;
    }

    div.roi div.video {
        flex: 1 0 100%;
    }
}
</style>
