<template>
    <import-template-dialog
        v-model="this.showImportTemplateDialog"
        :import-template="this.currentImportTemplate"
        @input="this.importTemplateInput"
    />

    <el-form class="table-actions">
        <el-button @click="this.newImportTemplate">Nieuw template toevoegen</el-button>
    </el-form>

    <el-table
        v-loading="this.loading"
        :default-sort="{ prop: 'name', order: 'ascending' }"
        :data="this.importTemplates?.data"
        @sort-change="this.sortingChanged($event)"
        width="100%"
    >
        <el-table-column class-name="column-name" label="Naam" min-width="175" prop="name" sortable>
            <template #default="scope">
                {{ scope.row.name }}
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-operator"
            label="Exploitant"
            min-width="125"
            prop="operator"
            sortable
        >
            <template #default="scope">
                {{ scope.row.operator }}
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-country"
            label="Land"
            min-width="175"
            prop="country"
            sortable
        >
            <template #default="scope">
                {{ scope.row.country }}
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-auto-import"
            label="Automatisch verwerken"
            min-width="175"
            prop="name"
            sortable
        >
            <template #default="scope">
                {{ scope.row.auto_import ? "Ja" : "Nee" }}
            </template>
        </el-table-column>
        <el-table-column class-name="column-actions" fixed="right" min-width="120">
            <template #default="scope">
                <el-button
                    icon="el-icon-edit"
                    plain="plain"
                    size="mini"
                    title="Bekijk details"
                    @click="this.editImportTemplate(scope.row)"
                ></el-button>
                <el-button
                    icon="el-icon-delete"
                    plain="plain"
                    size="mini"
                    title="Verwijder"
                    type="danger"
                    @click="this.deleteImportTemplate(scope)"
                ></el-button>
            </template>
        </el-table-column>
    </el-table>

    <el-pagination
        :background="false"
        :current-page="this.currentPage"
        :hide-on-single-page="false"
        :page-count="this.pages"
        :page-size="this.perPage"
        :page-sizes="this.pageSizes"
        :total="this.importTemplates?.pagination?.total_items"
        layout="sizes, prev, pager, next, jumper, ->, total"
        @current-change="this.goToPage"
        @next-click="this.goToPage"
        @prev-click="this.goToPage"
        @size-change="this.pageSizeChange"
    />
</template>

<script lang="ts">
import { ElMessage, ElMessageBox } from "element-plus";
import { computed, defineComponent, onMounted, reactive, toRefs } from "vue";
import { Store, useStore } from "vuex";

import { Copy } from "@/service/clipboard/copy";
import config from "@/service/config";
import { FilterRequest } from "@/types/request/filterRequest";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { RequestParameters } from "@/types/request/requestParameters";
import { ImportTemplateState } from "@/types/state/importTemplate";
import { RootState } from "@/types/state/root";
import { ImportTemplate } from "@/types/uzs/importTemplate";
import ImportTemplateDialog from "@/views/settings/import-templates/dialogs/ImportTemplate.vue";

type CurrentRow = {
    row: ImportTemplate;
    rowIndex: number;
};

type SortEvent = {
    column: string;
    order: string;
    prop: string;
};

export default defineComponent({
    name: "List",
    components: { ImportTemplateDialog },
    setup() {
        const store = useStore() as Store<RootState>;
        const state = store.state.importTemplate as ImportTemplateState;
        const textCopier = new Copy();

        const actions = {
            importTemplate: {
                delete: (id: number) => store.dispatch("importTemplate/_delete", id),
                details: (id: number) => store.dispatch("importTemplate/_details", id),
                list: (params: RequestParameters) => store.dispatch("importTemplate/_list", params),
            },
        };

        const data = reactive({
            currentImportTemplate: {} as ImportTemplate | null,
            currentPage: 1 as number,
            loading: true,
            filter: {} as FilterRequest,
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            order: {
                name: "asc",
            } as OrderRequest,
            page: computed(() => state.filter?.page as number | undefined),
            pages: computed(
                () => state.importTemplates?.pagination?.total_pages as number | undefined
            ),
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            perPage: computed(() => state.filter?.per_page as number | undefined),
            showImportTemplateDialog: false,
            showFilterModal: false,
            sortingEnabled: false,
        });

        const importTemplateInput = (value: boolean) => {
            data.currentImportTemplate = null;
            data.showImportTemplateDialog = value;
            update();
        };

        const deleteImportTemplate = (value: CurrentRow) => {
            ElMessageBox.confirm(
                "Weet je zeker dat je " + value.row.name + " wilt verwijderen?",
                "Verwijderen?",
                {
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nee",
                    type: "warning",
                }
            ).then(() => {
                actions.importTemplate
                    .delete(value.row.id)
                    .then((result) => {
                        update();

                        if (!result.success) {
                            throw new Error();
                        }

                        ElMessage({
                            message: value.row.name + " is verwijderd.",
                            type: "success",
                        });
                    })
                    .catch(() => {
                        ElMessage({
                            message: "Er is een onbekende fout opgetreden.",
                            type: "error",
                        });
                    });
            });
        };

        const editImportTemplate = (importTemplate?: ImportTemplate | null) => {
            data.currentImportTemplate = importTemplate ?? null;
            data.showImportTemplateDialog = true;
        };

        const goToPage = (page: number) => {
            if (!data.pagination) {
                return;
            }

            data.currentPage = page;
            data.pagination.page = page;

            update();
        };

        const newImportTemplate = () => {
            data.currentImportTemplate = {} as ImportTemplate;
            data.showImportTemplateDialog = true;
        };

        const pageSizeChange = (pageSize: number) => {
            if (!data.pagination) {
                return;
            }

            data.pagination.per_page = pageSize;

            update();
        };

        const sortingChanged = ($event: SortEvent) => {
            const property = $event.prop?.length ? $event.prop : "start_time";
            const direction = $event.order === "descending" ? "desc" : "asc";

            if (!state.importTemplates || !data.order || !data.sortingEnabled) {
                return;
            }

            data.order = {
                [property]: direction,
            };

            update();
        };

        const update = () => {
            data.loading = true;
            actions.importTemplate
                .list({
                    filter: data.filter,
                    order: data.order,
                    pagination: data.pagination,
                })
                .then(() => {
                    data.loading = false;
                    data.sortingEnabled = true;
                });
        };

        onMounted(() => {
            update();
        });

        return {
            ...toRefs(data),
            ...toRefs(state),
            importTemplateInput,
            deleteImportTemplate,
            editImportTemplate,
            goToPage,
            newImportTemplate,
            pageSizeChange,
            sortingChanged,
            textCopier,
            update,
        };
    },
});
</script>
