import axios from "axios";
import { ActionContext } from "vuex";

import config from "@/service/config";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { SuccessResult } from "@/types/result/successResult";
import { RootState } from "@/types/state/root";
import { SpreadsheetState } from "@/types/state/spreadsheetState";
import { GenerateRequest } from "@/types/uzs/request/spreadsheet";
import { Spreadsheet } from "@/types/uzs/spreadsheet";
import { SpreadsheetAccess } from "@/types/uzs/spreadsheetAccess";

const SpreadsheetModule = {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        _list(
            context: ActionContext<SpreadsheetState, RootState>,
            params: RequestParameters | null
        ): Promise<DataResult<Spreadsheet[]>> {
            return axios
                .get(config.backend + "/api/uzs/spreadsheet", {
                    params: {
                        columns: params?.columns ?? context.state.columns,
                        filter: params?.filter ?? context.state.filter,
                        order: params?.order ?? context.state.order,
                        pagination: params?.pagination ?? context.state.pagination,
                        search: params?.search ?? context.state.search,
                    },
                })
                .then((response) => response?.data as DataResult<Spreadsheet[]>)
                .then((response) => {
                    context.state.spreadsheets = response;
                    return response;
                });
        },
        _download(
            context: ActionContext<RootState, RootState>,
            id: number
        ): Promise<SpreadsheetAccess> {
            return axios
                .get(config.backend + "/api/campaign/export/download-spreadsheet/" + id.toString())
                .then((response) => response?.data as SpreadsheetAccess);
        },
        _generate(
            context: ActionContext<RootState, RootState>,
            data: GenerateRequest
        ): Promise<SuccessResult> {
            return axios
                .post(config.backend + "/api/uzs/spreadsheet/generate", data)
                .then((response) => response?.data as SuccessResult);
        },
    },
};

export default SpreadsheetModule;
