
import { AxiosError } from "axios";
import { ElMessage } from "element-plus";
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { Store, useStore } from "vuex";

import DateHelper from "@/helper/date-helper";
import DataSetApiService from "@/service/api/settings/bigquery/dataSet";
import { Copy } from "@/service/clipboard/copy";
import config from "@/service/config";
import { Authentication } from "@/types/credentials/authentication";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { DataResult } from "@/types/result/dataResult";
import { ErrorResult } from "@/types/result/errorResult";
import { RootState } from "@/types/state/root";
import { DataSet } from "@/types/uzs/settings/bigquery/dataSet";
import { ServiceAccount } from "@/types/uzs/settings/bigquery/serviceAccount";
import DataSetDialog from "@/views/settings/big-query/dataSet/dialogs/DataSet.vue";
import dataSet from "@/views/settings/big-query/dataSet/dialogs/DataSet.vue";

type CurrentRow = {
    row: DataSet;
    rowIndex: number;
};

type SortEvent = {
    column: string;
    order: string;
    prop: string;
};

export default defineComponent({
    name: "List",
    computed: {
        dataSet() {
            return dataSet;
        },
    },
    components: { DataSetDialog },
    setup() {
        const store = useStore() as Store<RootState>;
        const auth = store.state.authentication as Authentication;
        const router = useRouter();
        const textCopier = new Copy();
        const dataSetApi = new DataSetApiService();

        /* eslint-disable  @typescript-eslint/no-explicit-any */
        const data = reactive({
            backendUrl: config.backend,
            currentDataSet: undefined as DataSet | undefined,
            currentPage: 1,
            isDeleting: -1,
            loading: true,
            filter: {},
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            order: {
                created_at: "desc",
            } as OrderRequest,
            page: 1,
            pages: undefined as number | undefined,
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            perPage: 25,
            totalPages: 1,
            totalItems: 0,
            dataSets: [] as ServiceAccount[],
            sortingEnabled: false,
            updateTimeout: null,
        }) as any;

        function dataSetInput(): void {
            data.currentDataSet = undefined;
            init(true);
        }

        function editDataSet(value: CurrentRow): void {
            data.currentDataSet = value.row;
        }

        function getFormattedDate(date: string | null, full = true): string {
            if (!date) {
                return "";
            }

            const dateText = DateHelper.dateTime(date);

            return full ? dateText : dateText.substring(0, 10);
        }

        function goToPage(page: number) {
            if (!data?.pagination) {
                return;
            }

            data.currentPage = page;
            data.pagination.page = page;

            init();
        }

        function init(loading = true) {
            data.loading = loading;
            data.sortingEnabled = false;

            const params = {
                filter: data.filter,
                pagination: data.pagination,
                sort: data.order,
            };

            dataSetApi
                .list(params)
                .then((response: DataResult<DataSet[]>) => {
                    data.dataSets = response?.data as DataSet[];

                    data.page = response.pagination?.page ?? 1;
                    data.perPage = response.pagination?.per_page ?? 1;
                    data.totalPages = response.pagination?.total_pages ?? 1;
                    data.totalItems = response.pagination?.total_items ?? 0;

                    data.loading = false;
                    data.sortingEnabled = true;
                })
                .catch((error: AxiosError) => {
                    const response = error.response?.data as ErrorResult;

                    if (!response?.error) {
                        return;
                    }

                    ElMessage({
                        message: response.message,
                        type: "error",
                    });
                });
        }

        function updateOnInterval() {
            if (data.updateTimeout) {
                clearInterval(data.updateTimeout);
            }

            if (!data.loading) {
                init(false);
            }

            data.updateTimeout = setTimeout(() => {
                updateOnInterval();
            }, 3000);
        }

        function navigateToServiceAccounts(): void {
            router.push({ name: "settings.google-big-query.service-accounts" });
        }

        function pageSizeChange(pageSize: number) {
            if (!data?.pagination) {
                return;
            }

            data.pagination.per_page = pageSize;

            init();
        }

        function sortingChanged($event: SortEvent) {
            const property = $event.prop?.length ? $event.prop : "created_at";
            const direction = $event.order === "descending" ? "desc" : "asc";

            if (!data.dataSets || !data.order || !data.sortingEnabled) {
                return;
            }

            data.order = {
                [property]: direction,
            };

            init();
        }

        function updateActivation(scope: CurrentRow): void {
            data.loading = true;
            dataSetApi.update(scope.row).then(() => (data.loading = false));
        }

        onBeforeRouteLeave(() => {
            if (data.updateTimeout) {
                clearTimeout(data.updateTimeout);
            }
        });

        onMounted(() => {
            Object.assign(data.pagination, {
                per_page: config.pagination.per_page,
            });

            Object.assign(data.order, {
                created_at: "desc",
            });

            init();
            updateOnInterval();
        });

        onBeforeRouteLeave(() => {
            if (data.updateTimeout) {
                clearInterval(data.updateTimeout);
            }
        });

        return {
            ...toRefs(data),
            accessToken: auth.token,
            dataSetInput,
            editDataSet,
            getFormattedDate,
            goToPage,
            init,
            navigateToServiceAccounts,
            pageSizeChange,
            sortingChanged,
            textCopier,
            updateActivation,
        };
    },
});
