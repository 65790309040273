
import { defineComponent, onMounted, reactive, toRefs } from "vue";

import HolidayApiService from "@/service/api/settings/holiday/holiday";
import { DataResult } from "@/types/result/dataResult";

export default defineComponent({
    name: "HolidayMessage",
    setup() {
        const holidayApi = new HolidayApiService();

        const data = reactive({
            holidays: [] as Array<string>,
        });

        function init() {
            holidayApi
                .fetchToday()
                .then(
                    (response: DataResult<string[] | null>) => (data.holidays = response.data ?? [])
                );
        }

        function getValue(): string {
            return data.holidays.sort().join(", ");
        }

        onMounted(() => init());

        return {
            ...toRefs(data),
            getValue,
        };
    },
});
