<template>
    <el-container>
        <el-header height="">
            <div class="container">
                <div class="logo clickable" @click="this.goToCampaigns">
                    <img alt="UZS" src="./assets/images/logo.png" class="logo" />
                </div>
                <div v-if="this.isAuthenticated()" class="navigation">
                    <ul v-if="this.getRoutes()">
                        <li v-for="route in this.getRoutes()" :key="route.name">
                            <el-dropdown v-if="this.getChildRoutes(route.name.toString())?.length">
                                <router-link :to="{ name: route.name }"
                                    ><span>{{ route.displayName }}</span></router-link
                                >
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <template
                                            v-for="childRoute in this.getChildRoutes(
                                                route.name.toString()
                                            )"
                                            :key="childRoute.name"
                                        >
                                            <router-link :to="{ name: childRoute.name }">
                                                <el-dropdown-item>
                                                    <span>{{ childRoute.displayName }}</span>

                                                    <span
                                                        class="notification child"
                                                        v-if="
                                                            childRoute.name === 'import.dataset' &&
                                                            notification?.import &&
                                                            notification.import.dataset > 0
                                                        "
                                                        >{{ notification.import.dataset }}</span
                                                    >
                                                    <span
                                                        class="notification child"
                                                        v-if="
                                                            childRoute.name === 'import.leads' &&
                                                            notification?.import &&
                                                            notification.import.leads > 0
                                                        "
                                                        >{{ notification.import.leads }}</span
                                                    >
                                                    <span
                                                        class="notification child"
                                                        v-if="
                                                            childRoute.name ===
                                                                'settings.channels' &&
                                                            notification?.settings &&
                                                            notification.settings.channel > 0
                                                        "
                                                        >{{ notification.settings.channel }}</span
                                                    >
                                                    <span
                                                        class="notification child"
                                                        v-if="
                                                            childRoute.name ===
                                                                'settings.target-groups' &&
                                                            notification?.settings &&
                                                            notification.settings.target_group > 0
                                                        "
                                                        >{{
                                                            notification.settings.target_group
                                                        }}</span
                                                    >
                                                </el-dropdown-item>
                                            </router-link></template
                                        >
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                            <router-link
                                v-else
                                :to="{ name: route.name }"
                                :class="{ active: this.isActiveRoute(route.name.toString()) }"
                                >{{ route.displayName }}
                            </router-link>
                            <span
                                class="notification"
                                v-if="
                                    route.name === 'creatives' &&
                                    notification?.creatives &&
                                    notification.creatives > 0
                                "
                                >{{ notification.creatives }}</span
                            >
                            <span
                                class="notification"
                                v-if="
                                    route.name === 'import' &&
                                    notification?.import &&
                                    notification.import.total > 0
                                "
                                >{{ notification.import.total }}</span
                            >
                            <span
                                class="notification"
                                v-if="
                                    route.name === 'settings' &&
                                    notification?.settings &&
                                    notification.settings.total > 0
                                "
                                >{{ notification.settings.total }}</span
                            >
                        </li>
                    </ul>
                </div>
                <div v-if="this.isAuthenticated()" class="mobile-menu-button">
                    <label for="mobile_menu"><i class="el-icon-menu"></i></label>
                </div>
                <div v-if="this.isAuthenticated()" class="user">
                    <el-dropdown>
                        <el-avatar icon="el-icon-user"></el-avatar>
                        <template #dropdown>
                            <el-dropdown-menu class="user-menu">
                                <el-dropdown-item
                                    v-if="userAccount?.guid"
                                    class="has--account-information"
                                >
                                    <div class="account-information">
                                        <span class="name">{{ userAccount.name }}</span>
                                        <span class="organization">{{
                                            userAccount.organization.name
                                        }}</span>
                                    </div>
                                </el-dropdown-item>
                                <el-dropdown-item
                                    icon="el-icon-document-copy"
                                    :class="{
                                        ['menu-item']: true,
                                        ['event-log']: true,
                                        active: this.isActiveRoute('events'),
                                    }"
                                    @click="this.goToEventLog"
                                    >Event log</el-dropdown-item
                                >
                                <el-dropdown-item
                                    icon="el-icon-aim"
                                    :class="{
                                        ['menu-item']: true,
                                        active: this.isActiveRoute('debugger'),
                                    }"
                                    @click="this.goToDebugger"
                                    >Debugger</el-dropdown-item
                                >
                                <el-dropdown-item
                                    class="menu-item logout"
                                    icon="el-icon-lock"
                                    @click="this.logoutUser"
                                    >Uitloggen</el-dropdown-item
                                >
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
        </el-header>

        <el-main>
            <div class="wrapper">
                <div class="container">
                    <router-view />
                </div>
            </div>
        </el-main>
        <el-footer></el-footer>
    </el-container>

    <input type="checkbox" id="mobile_menu" />

    <aside class="mobile-menu">
        <ul v-if="this.getRoutes()" class="mobile-menu">
            <li v-for="route in this.getRoutes()" :key="route.name">
                <el-dropdown v-if="this.getChildRoutes(route.name.toString())?.length">
                    <router-link :to="{ name: route.name }" @click="this.hideMenu()">{{
                        route.displayName
                    }}</router-link>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <template
                                v-for="childRoute in this.getChildRoutes(route.name.toString())"
                                :key="childRoute.name"
                            >
                                <router-link
                                    :to="{ name: childRoute.name }"
                                    @click="this.hideMenu()"
                                >
                                    <el-dropdown-item>
                                        {{ childRoute.displayName }}
                                    </el-dropdown-item>
                                </router-link></template
                            >
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
                <router-link
                    v-else
                    :to="{ name: route.name }"
                    :class="{
                        active: this.isActiveRoute(route.name.toString()),
                    }"
                    @click="this.hideMenu()"
                    >{{ route.displayName }}</router-link
                >
            </li>
        </ul>
    </aside>
</template>

<script lang="ts">
import { ElMessage } from "element-plus";
import { defineComponent } from "vue";
import { NavigationGuardNext, RouteLocation, useRouter } from "vue-router";
import { mapActions, mapState, useStore } from "vuex";

import { routes } from "@/router";
import NotificationApiService from "@/service/api/notification";
import AccountApiService from "@/service/api/user/account";
import { RouteRecord } from "@/types/router/route-record";
import { Notification } from "@/types/uzs/notification";
import { Account } from "@/types/uzs/user/account";

export default defineComponent({
    name: "App",
    setup() {
        const router = useRouter();
        const store = useStore();

        router.beforeResolve(
            (to: RouteLocation, from: RouteLocation, next: NavigationGuardNext) => {
                if (to.name === "login") {
                    next();
                    return;
                }

                store.dispatch("authentication/checkIfTokenExpired").then((expired: boolean) => {
                    if (expired) {
                        next({ name: "login" });

                        ElMessage({
                            message: "Je bent niet ingelogd.",
                            type: "info",
                        });

                        return;
                    }

                    next();
                });
            }
        );

        return {
            store,
        };
    },
    computed: {
        ...mapState(["authentication"]),
    },
    data() {
        return {
            areFlightMessagesReloading: false,
            checkLoginInterval: null as ReturnType<typeof setInterval> | null,
            notification: null as Notification | null,
            userAccount: null as Account | null,
        };
    },
    mounted() {
        this.checkLogin();

        const notificationApi = new NotificationApiService();
        notificationApi
            .list()
            .then((notification) => (this.notification = notification.data as Notification));
    },
    updated() {
        const notificationApi = new NotificationApiService();

        notificationApi
            .list()
            .then((notification) => (this.notification = notification.data as Notification));

        if (this.authentication?.user) {
            this.userAccount = this.authentication.user;
        }
    },
    methods: {
        ...mapActions("authentication", ["logout"]),
        ...mapActions("user", ["ping"]),
        checkLogin() {
            const isLoginRoute =
                this.$router.currentRoute.value.path === "/" ||
                this.$router.currentRoute.value.name === "login";

            if (!isLoginRoute) {
                this.ping()
                    .then((result) => {
                        if (result.success) {
                            return;
                        }

                        this.logoutUser(!isLoginRoute);
                    })
                    .catch(() => this.logoutUser(!isLoginRoute));
            }

            if (this.checkLoginInterval) {
                clearTimeout(this.checkLoginInterval);
            }

            this.checkLoginInterval = setInterval(() => this.checkLogin(), 30000);
        },
        async getAccountInformation(): Promise<Account | null> {
            if (!this.authentication?.user_identifier) {
                return null;
            }

            const accountApi = new AccountApiService();
            const information = await accountApi.fetchInformation();

            return information?.data ?? null;
        },
        getRoutes(): RouteRecord[] {
            return routes.filter((value) => value.showInNavigation === true);
        },
        getChildRoutes(routeName: string): RouteRecord[] | undefined {
            const currentRoute = routes.filter(
                (value) => value.name === routeName && value.showInNavigation === true
            );

            if (currentRoute?.length) {
                return currentRoute[0].children;
            }
        },
        goToCampaigns() {
            this.$router.push({ name: "campaigns" });
        },
        goToEventLog() {
            this.$router.push({ name: "events" });
        },
        goToDebugger() {
            this.$router.push({ name: "debugger" });
        },
        goToImport() {
            this.$router.push({ name: "import" });
        },
        goToExport() {
            this.$router.push({ name: "export" });
        },
        hideMenu() {
            const menuCheckbox = document.querySelector("input#mobile_menu") as HTMLInputElement;

            if (!menuCheckbox) {
                return;
            }

            setTimeout(() => (menuCheckbox.checked = false), 500);
        },
        isActiveRoute(name: string): boolean {
            return this.$route.name === name;
        },
        isAuthenticated(): boolean {
            return this.authentication?.token?.length > 60;
        },
        reloadFlightMessages(): void {
            this.areFlightMessagesReloading = true;

            this.store.dispatch("flight/_reload_alerts").then(() => {
                this.areFlightMessagesReloading = false;
                window.location.reload();
            });
        },
        logoutUser(showMessage = true) {
            this.logout();

            this.$router.push({
                name: "login",
            });

            if (!showMessage) {
                return;
            }

            ElMessage({
                message: "Je bent uitgelogd.",
                type: "info",
            });
        },
    },
});
</script>

<style lang="scss">
@import "assets/scss/main";

.el-dropdown-menu__item.has--account-information:hover {
    background-color: #fff !important;
    cursor: initial;
}

div.account-information {
    border-bottom: 1px solid #eee;
    display: flex;
    flex-direction: column;
    padding-bottom: 0.5rem;
}

div.account-information span.name,
div.account-information span.organization {
    flex: 1 0 100%;
    line-height: 1.25rem;
}

div.account-information span.name {
    font-weight: bold;
}

div.account-information span.organization {
    font-style: italic;
}

span.notification {
    background-color: $color-notification;
    color: white;
    font-weight: bold;
    padding: 4px 8px;
}

span.notification.child {
    background-color: $color-grey;
    border-radius: 50px;
    margin-left: 0.5rem;
}
</style>
