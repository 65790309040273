import axios from "axios";
import { ActionContext } from "vuex";

import config from "@/service/config";
import { RootState } from "@/types/state/root";
import { LeadSpreadsheetAccess } from "@/types/uzs/leadSpreadsheetAccess";

const LeadSpreadsheetModule = {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        _download(
            context: ActionContext<RootState, RootState>,
            id: number
        ): Promise<LeadSpreadsheetAccess> {
            return axios
                .get(config.backend + "/api/campaign/lead/download-template/" + id.toString())
                .then((response) => response?.data as LeadSpreadsheetAccess);
        },
    },
};

export default LeadSpreadsheetModule;
