import axios from "axios";

import config from "@/service/config";
import { DataResult } from "@/types/result/dataResult";
import { Block } from "@/types/uzs/block";

export default class BlockApiService {
    public fetch(guid: string): Promise<DataResult<Block[]>> {
        return axios
            .get(config.backend + "/api/spot/blocks/" + guid)
            .then((response) => response?.data as DataResult<Block[]>);
    }
}
