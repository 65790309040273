
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs, watch } from "vue";

import GrpHelper from "@/helper/grp-helper";
import { Alert } from "@/types/uzs/alert";
import { Package } from "@/types/uzs/alert/package";

const props = {
    alert: {
        type: Object as PropType<Alert>,
        required: true,
    },
};

export default defineComponent({
    name: "AlertSubOrdersTable",
    props,
    setup(props) {
        const alert = ref(props.alert) as Ref<Alert>;
        const grpHelper = GrpHelper;

        const data = reactive({
            subOrders: [] as Array<Package>,
        });

        const init = (): void => {
            data.subOrders = alert.value?.data as Array<Package>;
            data.subOrders.sort();

            console.info(data.subOrders);
        };

        onMounted(() => {
            init();
        });

        watch(
            () => props.alert,
            (value, oldValue) => {
                if (value !== oldValue) {
                    alert.value = value ?? ({} as Alert);
                    init();
                }
            }
        );

        return {
            ...toRefs(data),
            close,
            grpHelper,
        };
    },
});
