
import { ElMessage, ElMessageBox } from "element-plus";
import { defineComponent, inject, Ref, ref } from "vue";
import { Store } from "vuex";

import { ChannelCluster } from "@/enums/channelCluster";
import { Country } from "@/enums/country";
import ChannelApiService from "@/service/api/channel";
import SubOrderApiService from "@/service/api/subOrder";
import { Copy } from "@/service/clipboard/copy";
import config from "@/service/config";
import { FilterRequest } from "@/types/request/filterRequest";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { DataResult } from "@/types/result/dataResult";
import { Channel } from "@/types/screen-force/channel";
import { ChannelState } from "@/types/state/channel";
import ChannelDialog from "@/views/settings/channels/dialogs/Channel.vue";

type CurrentRow = {
    row: Channel;
    rowIndex: number;
};

type SortEvent = {
    column: string;
    order: string;
    prop: string;
};

export default defineComponent({
    name: "List",
    components: { ChannelDialog },
    setup() {
        const channelApi = new ChannelApiService();
        const channelClusters = ChannelCluster;
        const countries = Country;
        const currentChannel = ref(null) as Ref<Channel | null>;
        const store = inject("store") as Store<ChannelState>;
        const textCopier = new Copy();

        return {
            channelApi,
            channelClusters,
            countries,
            currentChannel,
            store,
            textCopier,
        };
    },
    computed: {
        page(): number {
            return this.pagination.page;
        },
        pages(): number {
            return this.channels?.pagination?.total_pages as number;
        },
        perPage(): number {
            return this.pagination.per_page;
        },
    },
    data() {
        return {
            channels: {} as DataResult<Channel[]>,
            selectedChannelClusters: [] as Array<ChannelCluster>,
            selectedCountries: [] as Array<Country>,
            selectedOperators: [] as string[],
            filter: {} as FilterRequest,
            order: {
                country: "asc",
            } as OrderRequest,
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            currentPage: undefined as number | undefined,
            loading: true,
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            showChannelModal: false,
            showFilterModal: false,
            sortingEnabled: false,
            operators: [] as string[],
        };
    },
    mounted() {
        this.update();
        this.loadOperators();
    },
    methods: {
        channelInput(value: boolean) {
            this.currentChannel = null;
            this.showChannelModal = value;
            this.update();
        },
        delete(value: CurrentRow) {
            const name = value?.row?.name?.length ? value.row.name : value.row.description;

            ElMessageBox.confirm(
                "Weet je zeker dat je " + name + " wilt verwijderen?",
                "Verwijderen?",
                {
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nee",
                    type: "warning",
                }
            ).then(() => {
                this.channelApi
                    .delete(value.row.id)
                    .then((result) => {
                        this.update();

                        if (!result.success) {
                            throw new Error();
                        }

                        ElMessage({
                            message: name + " is verwijderd.",
                            type: "success",
                        });
                    })
                    .catch(() => {
                        ElMessage({
                            message: "Er is een onbekende fout opgetreden.",
                            type: "error",
                        });
                    });
            });
        },
        editChannel(channel: Channel) {
            this.currentChannel = channel;
            this.showChannelModal = true;
        },
        getChannelData(): Channel[] {
            return (
                this.channels.data?.filter((data) => data.name !== "" && data.name !== null) ?? []
            );
        },
        loadOperators() {
            const subOrderApi = new SubOrderApiService();

            subOrderApi.fetchOperators().then((data) => {
                this.operators = data.data ?? [];
            });
        },
        getRowClassName(value: CurrentRow) {
            return "row " + value.row.id;
        },
        goToPage(page: number) {
            this.currentPage = page;
            this.pagination.page = page;

            this.update();
        },
        newChannel() {
            this.showChannelModal = false;
            this.currentChannel = null;
            this.showChannelModal = true;
        },
        pageSizeChange(pageSize: number) {
            this.pagination.per_page = pageSize;

            this.update();
        },
        routeToDetails($event: Event, row: Channel) {
            $event.stopImmediatePropagation();

            this.$router.push({
                name: "channel-detail",
                params: {
                    id: row.id,
                },
            });
        },
        sortingChanged($event: SortEvent) {
            const property = $event.prop?.length ? $event.prop : null;
            const direction = $event.order === "descending" ? "desc" : "asc";

            if (!property || !this.channels || !this.sortingEnabled) {
                return;
            }

            this.order = {
                [property]: direction,
            };

            this.update();
        },
        update() {
            this.loading = true;

            Object.assign(this.filter, {
                cluster: {
                    operator: "IN",
                    value: this.selectedChannelClusters,
                },
            });

            Object.assign(this.filter, {
                country: {
                    operator: "IN",
                    value: this.selectedCountries,
                },
            });

            Object.assign(this.filter, {
                operator: {
                    operator: "IN",
                    value: this.selectedOperators,
                },
            });

            this.channelApi
                .fetchAll({
                    filter: this.filter,
                    order: this.order,
                    pagination: this.pagination,
                })
                .then((response) => {
                    this.loading = false;
                    this.sortingEnabled = true;
                    this.channels = response;
                });
        },
    },
});
