
import { addDays, format, startOfDay } from "date-fns";
import { ElMessage } from "element-plus";
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs } from "vue";

import GrpInput from "@/components/form/GrpInput.vue";
import DateHelper from "@/helper/date-helper";
import InputNumberHelper from "@/helper/input-number-helper";
import PriceHelper from "@/helper/price-helper";
import CampaignDataSetApiService from "@/service/api/campaignDataSet";
import SubOrderApiService from "@/service/api/subOrder";
import config from "@/service/config";
import { FilterRequest } from "@/types/request/filterRequest";
import { DataResult } from "@/types/result/dataResult";
import { Order } from "@/types/screen-force/order";
import { Budget } from "@/types/screen-force/sub-order/budget";
import { SubOrder } from "@/types/screen-force/subOrder";
import { CampaignDataSet } from "@/types/uzs/campain-data-set/campaignDataSet";
import { CampaignOperator } from "@/types/uzs/campain-data-set/campaignOperator";
import { CampaignPackage } from "@/types/uzs/campain-data-set/campaignPackage";
import { Flight } from "@/types/uzs/flight";
import { FlightBudgetPerOperator } from "@/types/uzs/flightBudgetPerOperator";

const props = {
    flight: {
        type: Object as PropType<Flight>,
        required: true,
    },
};

export default defineComponent({
    name: "SubOrderDialog",
    components: { GrpInput },
    props,
    setup(props, { emit }) {
        const flight = ref(props.flight ?? {}) as Ref<Flight | undefined>;
        const targets = ["BRAND UPLIFT", "WEBSITE TRAFFIC", "LEADS", "SALES"];
        const priceHelper = PriceHelper;
        const inputNumberHelper = InputNumberHelper;

        /* API services */
        const subOrderApi = new SubOrderApiService();
        const campaignDataSetApi = new CampaignDataSetApiService();

        /* Data */
        const data = reactive({
            campaignDataSet: null as CampaignDataSet | null,
            flightBudgets: [] as FlightBudgetPerOperator[],
            isLoadingOrders: false,
            isLoadingFlightBudgets: false,
            isModalVisible: true,
            orders: [] as Order[],
            orderIdentifiers: [] as string[],
            packageIdentifiers: [] as string[],
            selectedOrders: [] as Order[],
            selectedOrderIdentifiers: [] as string[],
            selectedSubOrders: [] as SubOrder[],
            shouldActivateSubOrders: true,
            shouldLockSubOrders: false,
            subOrders: [] as SubOrder[],
            subOrderIdentifiers: [] as string[],
            subTotal: flight.value?.budget as number | null,
        });

        /**
         * Resets the form and fetches new data.
         */
        function init(): void {
            reset();
            loadCampaignDataSet(loadOrders);
        }

        /**
         * Resets the form.
         */
        function reset(): void {
            data.orders = [];
            data.subOrders = [];
            data.selectedOrders = [];
            data.selectedSubOrders = [];
        }

        /////////////////////////////
        // Data loading functions. //
        /////////////////////////////

        /**
         * Fetches orders from the API.
         */
        function loadOrders(): void {
            data.isLoadingOrders = true;

            const filter = getBaseFilter();

            if (!filter?.start_date) {
                return;
            }

            subOrderApi
                .fetchAll({
                    columns: [
                        "id",
                        "active",
                        "operator",
                        "description",
                        "channels",
                        "package_identifier",
                        "order_identifier",
                        "sub_order_identifier",
                        "start_date",
                        "end_date",
                        "booked_grps_no",
                        "requested_grps_no",
                        "requested_budget",
                    ],
                    order: {
                        description: "asc",
                        start_date: "asc",
                    },
                    filter: filter,
                    pagination: {
                        page: 1,
                        per_page: 1000,
                    },
                })
                .then((response: DataResult<SubOrder[]>) => {
                    data.subOrders = (response.data ?? []) as SubOrder[];

                    collectOrders();
                    collectPackageIdentifiers();
                });
        }

        /**
         * Fetches the campaign dataset from the API.
         */
        function loadCampaignDataSet(callback: CallableFunction | undefined = undefined): void {
            if (!flight.value?.id) {
                return;
            }

            data.isLoadingFlightBudgets = true;

            campaignDataSetApi.fetch(flight.value.id).then((response) => {
                data.campaignDataSet = response.data;

                if (!data.selectedOrderIdentifiers.length) {
                    data.selectedOrderIdentifiers = [
                        ...new Set(
                            data.campaignDataSet?.packages.map((pkg) => pkg.order_reference) ?? []
                        ),
                    ];
                }

                data.isLoadingFlightBudgets = false;

                updateCampaignBudgets();

                if (callback) {
                    callback();
                }
            });
        }

        ///////////////////////
        // Collect functions //
        ///////////////////////

        /**
         * Collects the orders from the sub orders.
         */
        function collectOrders(): void {
            data.orders = [];

            for (const subOrder of data.subOrders) {
                if (!subOrder?.order) {
                    continue;
                }

                if (!data.orders.filter((order: Order) => order.id === subOrder.order?.id).length) {
                    data.orders.push(subOrder.order);
                }
            }

            onChangeSelectOrders();
        }

        /**
         * Collects the package identifiers from the sub orders.
         */
        function collectPackageIdentifiers(): void {
            data.packageIdentifiers = [];

            for (const subOrder of data.subOrders) {
                if (!data.packageIdentifiers.includes(subOrder.package_identifier)) {
                    data.packageIdentifiers.push(subOrder.package_identifier);
                }
            }
        }

        //////////////////////////////
        // Event handler functions. //
        //////////////////////////////

        /**
         * (De-)activates all sub orders when the switch has changed.
         */
        function onActivateSubOrdersSwitchChanged(): void {
            data.selectedSubOrders?.forEach((subOrder: SubOrder) => {
                subOrder.active = data.shouldActivateSubOrders;
            });
        }

        /**
         * Runs the callback function before closing this dialog.
         *
         * @param done
         */
        function onDialogBeforeClose(done: CallableFunction): void {
            done();
            closeDialog();
        }

        /**
         * Submits the form when Enter is pressed.
         *
         * @param event
         */
        function onFormEnterPressed(event: KeyboardEvent): void {
            const input = event.target as HTMLElement | null;

            if (
                event.key.toLowerCase() === "enter" &&
                input?.tagName?.toLowerCase() !== "textarea"
            ) {
                saveData();
            }
        }

        function onRequestedGrpsNoChanged(value: number, subOrder: SubOrder): void {
            subOrder.requested_grps_no = value;
        }

        /**
         * (Un)locks all sub orders when the switch has changed.
         */
        function onSubOrdersLockSwitchChanged(): void {
            data.selectedSubOrders?.forEach((subOrder: SubOrder) => {
                subOrder.locked = data.shouldLockSubOrders;
            });
        }

        /**
         *
         * @param subOrder
         */
        function onSubOrderLockClicked(subOrder: SubOrder): void {
            subOrder.locked = !subOrder.locked;

            data.shouldLockSubOrders =
                data.selectedSubOrders.filter((subOrder: SubOrder) => !subOrder.locked).length > 0;
        }

        /**
         * Updates selected orders.
         */
        const onChangeSelectOrders = (): void => {
            data.selectedOrders = [];

            if (data.orders.length) {
                data.selectedOrders =
                    data.orders.filter((order) =>
                        data.selectedOrderIdentifiers.includes(order.identifier)
                    ) ?? [];
            }

            updateSelectedSubOrders();
        };

        ///////////////////////
        // Update functions. //
        ///////////////////////

        /**
         * Updates campaign budgets.
         */
        function updateCampaignBudgets(): void {
            const operators: string[] = [];

            if (!data.campaignDataSet) {
                return;
            }

            for (const selectedSubOrder of data.selectedSubOrders) {
                if (selectedSubOrder.operator === "") {
                    continue;
                }

                if (!operators.includes(selectedSubOrder.operator)) {
                    operators.push(selectedSubOrder.operator);
                }

                const hasBudget = data.campaignDataSet.operators.filter(
                    (operator) => operator.name === selectedSubOrder.operator
                );

                if (hasBudget.length) {
                    continue;
                }

                data.campaignDataSet.operators.push({
                    name: selectedSubOrder.operator,
                    budget: 0,
                });
            }

            data.campaignDataSet.operators = data.campaignDataSet.operators.filter((operator) =>
                operators.includes(operator.name)
            );

            data.campaignDataSet.operators.forEach((operator) => calculateBudget(operator));
        }

        /**
         * Updates number input values.
         */
        function updateNumberInputValues(): void {
            const inputNumbers = document.querySelectorAll(
                'div.el-input-number input[type="text"]'
            );

            for (const inputNumber of inputNumbers) {
                inputNumberHelper.onUpdate(inputNumber as HTMLInputElement);
            }
        }

        /**
         * Updates selected sub orders.
         */
        function updateSelectedSubOrders(): void {
            data.selectedSubOrders = [];

            if (data.selectedOrders.length) {
                data.selectedSubOrders =
                    data.subOrders?.filter((subOrder) =>
                        data.selectedOrders.find(
                            (order) => order.identifier === subOrder.order_identifier
                        )
                    ) ?? [];
            }

            loadCampaignDataSet();
        }

        /////////////////////
        // Save functions. //
        /////////////////////

        /**
         * Stores the dataset when the save button is clicked.
         */
        function saveData(): void {
            if (!flight?.value?.id || !data.campaignDataSet) {
                return;
            }

            const nameOfCampaign = flight.value?.name ?? "";

            data.campaignDataSet.budget = data.subTotal ?? 0;

            data.campaignDataSet.packages = data.selectedSubOrders.map((subOrder) => {
                return {
                    operator: subOrder.operator,
                    reference: subOrder.package_identifier,
                    order_reference: subOrder.order_identifier,
                    sub_order_reference: subOrder.sub_order_identifier,
                    requested_grps: subOrder.requested_grps_no,
                } as CampaignPackage;
            });

            campaignDataSetApi
                .store(flight.value.id, data.campaignDataSet)
                .then(() => {
                    ElMessage({
                        message: "Campagne " + nameOfCampaign + " is opgeslagen.",
                        type: "success",
                    });

                    closeDialog(true);
                })
                .catch(() => {
                    ElMessage({
                        message:
                            "Er is een fout opgetreden bij het opslaan van de budgetten voor campagne " +
                            nameOfCampaign,
                        type: "error",
                    });
                });
        }

        ///////////////////////
        // Common functions. //
        ///////////////////////

        /**
         * Calculates the budget per sub order
         * based on the operator budget.
         *
         * @param operator
         */
        function calculateBudget(operator: CampaignOperator | null): void {
            if (!operator) {
                return;
            }

            const selectedSubOrders = data.selectedSubOrders?.filter(
                (subOrder: SubOrder) => subOrder.operator === operator.name
            );

            let totalGrp = 0;

            selectedSubOrders?.forEach((subOrder: SubOrder) => {
                const booked = subOrder?.booked_grps_no ?? 0;
                const requested = subOrder?.requested_grps_no ?? 0;

                if (booked > 0) {
                    totalGrp += booked;

                    if (!subOrder.active) {
                        totalGrp -= booked;
                    }
                } else if (requested > 0) {
                    totalGrp += requested;

                    if (!subOrder.active) {
                        totalGrp -= requested;
                    }
                }
            });

            selectedSubOrders?.forEach((subOrder: SubOrder) => {
                if (!operator.budget || subOrder.locked === true) {
                    return;
                }

                if (!subOrder.budget) {
                    subOrder.budget = {
                        budget: 0,
                    } as Budget;
                }

                subOrder.original_budget =
                    subOrder.original_budget ?? subOrder.budget.budget ?? subOrder.requested_budget;

                let GRPs = subOrder?.booked_grps_no;

                if (GRPs === 0 && subOrder?.requested_grps_no !== null) {
                    GRPs = subOrder.requested_grps_no ?? 0;
                }

                subOrder.budget.budget =
                    parseFloat(totalGrp.toString()) !== 0.0
                        ? (operator.budget / totalGrp) * (GRPs ?? 0)
                        : 0;

                if (subOrder.budget.budget === 0 && selectedSubOrders.length === 1) {
                    subOrder.budget.budget = operator.budget;
                }

                if (
                    totalGrp === 0 &&
                    subOrder.requested_budget === 0 &&
                    selectedSubOrders.length >= 1
                ) {
                    subOrder.budget.budget = operator.budget / selectedSubOrders.length;
                }
            });

            calculateSubTotal();
        }

        /**
         * Calculates the subtotal of all selected sub orders.
         */
        function calculateSubTotal(): void {
            let subTotal = 0;
            let allActivated = true;

            data.shouldActivateSubOrders = false;
            data.selectedSubOrders.forEach((subOrder: SubOrder) => {
                if (subOrder.active) {
                    subTotal += subOrder.budget?.budget ?? subOrder?.requested_budget ?? 0;
                }

                if (!subOrder.active) {
                    allActivated = false;
                }
            });

            data.subTotal = Math.round(subTotal);

            if (allActivated) {
                data.shouldActivateSubOrders = true;
            }
        }

        /**
         * Emits an event for this dialog to close.
         */
        function closeDialog(changed = false): void {
            data.isModalVisible = false;
            flight.value = undefined;
            emit("close", changed);
        }

        /**
         * Returns a base filter for filtering
         * sub orders in a date range.
         */
        function getBaseFilter(): FilterRequest {
            let filter: FilterRequest = {};

            if (flight.value?.start_date && flight.value?.end_date) {
                const startDate = format(
                    startOfDay(DateHelper.date(flight.value?.start_date)),
                    "yyyy-MM-dd HH:mm:ss"
                );

                const endDate = format(
                    addDays(startOfDay(DateHelper.date(flight.value?.end_date)), 1),
                    "yyyy-MM-dd HH:mm:ss"
                );

                filter = {
                    country: {
                        operator: "=",
                        value: flight.value.country,
                    },
                    start_date: {
                        operator: ">=",
                        value: startDate,
                    },
                    end_date: {
                        operator: "<=",
                        value: endDate,
                    },
                } as FilterRequest;
            }

            return filter;
        }

        /**
         * Returns the budget for an operator.
         *
         * @param operator
         */
        function getBudgetForOperator(operator: string): FlightBudgetPerOperator | null {
            const budgetsForOperator = data.flightBudgets.filter(
                (budget) => budget.operator === operator
            );

            if (budgetsForOperator.length > 0) {
                return budgetsForOperator[0];
            }

            return null;
        }

        /**
         * Returns the budget for an operator.
         *
         * @param operator
         */
        function getBudgetForCampaignOperator(operator: string): CampaignOperator | null {
            const budgetsForOperator = data.flightBudgets.filter(
                (budget) => budget.operator === operator
            );

            if (budgetsForOperator.length > 0) {
                const flightOperator = budgetsForOperator[0];

                return {
                    name: flightOperator.operator,
                    budget: flightOperator.budget,
                };
            }

            return null;
        }

        /**
         * Returns a formatted date.
         *
         * @param date
         * @param originalTimezone
         */
        function getFormattedDate(
            date: string | undefined,
            originalTimezone: string = config.timezone
        ): string {
            if (!date) {
                return "";
            }

            return DateHelper.display(date, false, false, false, originalTimezone);
        }

        /**
         * Returns a formatted string for the sub total.
         */
        function getFormattedSubTotal(): string {
            return "€ " + (data.subTotal ? data.subTotal.toLocaleString("nl") : "");
        }

        /**
         * Returns a formatted string for the spot lengths.
         */
        function getSpotLengths(lengths?: number[]): string {
            if (!lengths?.length) {
                return "";
            }

            return " " + lengths.join("+") + '"';
        }

        onMounted(() => {
            if (props.flight) {
                flight.value = props.flight as Flight;
                init();
            }
        });

        return {
            ...toRefs(data),

            /* Variables */
            flight,
            priceHelper,
            targets,

            /* Functions */
            calculateBudget,
            getBudgetForOperator,
            getBudgetForCampaignOperator,
            getFormattedDate,
            getFormattedSubTotal,
            getSpotLengths,
            onActivateSubOrdersSwitchChanged,
            onCountrySelectChanged: init,
            onDialogBeforeClose,
            onFormEnterPressed,
            onSubOrderLockClicked,
            onChangeSelectOrders,
            onRequestedGrpsNoChanged,
            onSaveButtonClicked: saveData,
            onSubOrdersLockSwitchChanged,
            updateNumberInputValues,
        };
    },
});
