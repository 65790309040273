import axios from "axios";
import { ActionContext } from "vuex";

import config from "@/service/config";
import { DataResult } from "@/types/result/dataResult";
import { EventState } from "@/types/state/event";
import { RootState } from "@/types/state/root";
import { Event } from "@/types/uzs/event";

import { RequestParameters } from "../../types/request/requestParameters";

const initialState: EventState = {
    columns: undefined,
    filter: {},
    order: {
        start_date: "desc",
    },
    pagination: {
        page: 1,
        per_page: config.pagination.per_page,
    },
    search: undefined,
    events: undefined,
};

const EventModule = {
    namespaced: true,
    state: initialState,
    getters: {},
    mutations: {},
    actions: {
        _list(
            context: ActionContext<EventState, RootState>,
            params: RequestParameters | null
        ): Promise<DataResult<Event[]>> {
            return axios
                .get(config.backend + "/api/uzs/events", {
                    params: {
                        columns: params?.columns ?? context.state.columns,
                        filter: params?.filter ?? context.state.filter,
                        order: params?.order ?? context.state.order,
                        pagination: params?.pagination ?? context.state.pagination,
                        search: params?.search ?? context.state.search,
                    },
                })
                .then((response) => response?.data as DataResult<Event[]>)
                .then((response) => {
                    context.state.events = response;
                    return response;
                });
        },
    },
};

export default EventModule;
