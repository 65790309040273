import axios from "axios";

import config from "@/service/config";
import { DataResult } from "@/types/result/dataResult";
import { SuccessResult } from "@/types/result/successResult";

export default class SessionApiService {
    public async dates(id: number): Promise<DataResult<string[]>> {
        const response = await axios.get(
            config.backend + "/api/integration/bigquery-session-dates/" + id
        );

        return response?.data as DataResult<string[]>;
    }

    public async export(
        id: number,
        startDate: string,
        endDate: string,
        exportFilteredData: boolean
    ): Promise<SuccessResult> {
        const response = await axios.post(
            config.backend + "/api/integration/bigquery-export",
            {
                campaign_id: id,
                start_date: startDate,
                end_date: endDate,
                export_filtered_data: exportFilteredData,
            }
        );

        return response.data as SuccessResult;
        // return window.URL.createObjectURL(new Blob([response.data]));
    }
}
