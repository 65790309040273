
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { Store, useStore } from "vuex";

import DateHelper from "@/helper/date-helper";
import config from "@/service/config";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { RootState } from "@/types/state/root";
import { Flight } from "@/types/uzs/flight";
import { Spreadsheet } from "@/types/uzs/spreadsheet";
import { SpreadsheetAccess } from "@/types/uzs/spreadsheetAccess";

export default defineComponent({
    name: "List",
    setup() {
        const dateHelper = DateHelper;
        const store = useStore() as Store<RootState>;

        const actions = {
            spreadsheet: {
                list: (params: RequestParameters) => store.dispatch("spreadsheet/_list", params),
                download: (id: number) => store.dispatch("spreadsheet/_download", id),
            },
        };

        /* eslint-disable  @typescript-eslint/no-explicit-any */
        const data = reactive({
            loading: true,
            filter: {},
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
                total_items: 0,
                total_pages: 1,
            } as PaginationRequest,
            order: {
                import_end: "desc",
            } as OrderRequest,
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            spreadsheets: undefined as Spreadsheet[] | undefined,
        }) as any;

        const getFormattedDate = (
            date: string | null,
            originalTimezone: string = config.timezone
        ): string => {
            if (!date) {
                return "";
            }

            return DateHelper.dateTime(date);
        };

        const goToPage = (page: number) => {
            if (!data.pagination) {
                return;
            }

            data.pagination.page = page;

            update();
        };

        const pageSizeChange = (pageSize: number) => {
            if (!data.pagination) {
                return;
            }

            data.pagination.per_page = pageSize;

            update();
        };

        const download = (id: number) => {
            actions.spreadsheet.download(id).then((result: SpreadsheetAccess) => {
                if (result?.url) {
                    location.href = result.url;
                }
            });
        };

        const update = (loading = true) => {
            data.loading = loading;
            actions.spreadsheet
                .list({
                    filter: data.filter,
                    pagination: data.pagination,
                    order: data.order,
                })
                .then((result: DataResult<Spreadsheet[]>) => {
                    data.spreadsheets = result.data;
                    data.pagination.total_items = result.pagination?.total_items;
                    data.pagination.total_pages = result.pagination?.total_pages;
                    data.loading = false;
                });
        };

        function getAdvertiserNames(campaigns: Flight[]): string {
            const names: string[] = [];

            for (const campaign of campaigns) {
                if (
                    campaign?.uzs_advertiser?.name &&
                    !names.includes(campaign.uzs_advertiser.name)
                ) {
                    names.push(campaign.uzs_advertiser.name);
                }
            }

            return names.join(", ");
        }

        function getCampaignNames(campaigns: Flight[]): string {
            const names: string[] = [];

            for (const campaign of campaigns) {
                if (campaign?.name && !names.includes(campaign.name)) {
                    names.push(campaign.name);
                }
            }

            return names.join(", ");
        }

        onMounted(() => {
            Object.assign(data.pagination, {
                per_page: config.pagination.per_page,
            });

            Object.assign(data.order, {
                created_at: "desc",
            });

            update();
        });
        return {
            ...toRefs(data),
            getFormattedDate,
            getAdvertiserNames,
            getCampaignNames,
            goToPage,
            pageSizeChange,
            download,
            update,
        };
    },
});
