import axios from "axios";

import config from "@/service/config";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { SuccessResult } from "@/types/result/successResult";
import { Holiday } from "@/types/uzs/settings/holiday/holiday";

export default class HolidayApiService {
    public async delete(guid: string): Promise<SuccessResult> {
        const response = await axios.delete(config.backend + "/api/settings/holiday/" + guid);

        return response?.data as SuccessResult;
    }

    public async fetchAll(params: RequestParameters): Promise<DataResult<Holiday[] | null>> {
        const response = await axios.get(config.backend + "/api/settings/holidays", {
            params,
        });

        return response?.data as DataResult<Holiday[] | null>;
    }

    public async fetchToday(): Promise<DataResult<string[] | null>> {
        const response = await axios.get(config.backend + "/api/settings/holidays/today");

        return response?.data as DataResult<string[] | null>;
    }

    public async store(data: Holiday): Promise<SuccessResult> {
        const response = await axios.post(config.backend + "/api/settings/holiday", data);

        return response?.data as SuccessResult;
    }
}
