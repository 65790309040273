<template>
    <span>{{ getValue() }}</span>
</template>
<script lang="ts">
import { defineComponent, PropType, Ref, ref, watch } from "vue";

import PriceHelper from "@/helper/price-helper";

const props = {
    value: {
        type: Number as PropType<number>,
        required: true,
    },
    digits: {
        type: Number as PropType<number>,
        default: 0,
    },
};

export default defineComponent({
    name: "CpmValue",
    props,
    setup(props) {
        const cpm = ref(props.value) as Ref<number>;
        const digits = ref(props.digits ?? 0) as Ref<number>;

        function getValue(): string {
            return PriceHelper.display(cpm.value, digits.value);
        }

        watch(
            () => props.value,
            (value) => {
                cpm.value = value as number;
            }
        );

        return {
            getValue,
        };
    },
});
</script>
