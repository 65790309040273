import axios from "axios";

import config from "@/service/config";
import { BlockSpreadsheetAccess } from "@/types/uzs/blockSpreadsheetAccess";

export default class BlockSpreadSheetApiService {
    public downloadTemplate(): Promise<BlockSpreadsheetAccess> {
        return axios
            .get(config.backend + "/api/spot/block/download-template")
            .then((response) => response?.data as BlockSpreadsheetAccess);
    }

    public downloadSpreadsheet(uuid: string): Promise<BlockSpreadsheetAccess> {
        return axios
            .get(config.backend + "/api/spot/block/download-spreadsheet/" + uuid)
            .then((response) => response?.data as BlockSpreadsheetAccess);
    }
}
