<template>
    <el-dialog
        :title="
            this.flight?.name && !this.isLoadingAlerts
                ? 'Alerts - Flight ' +
                  this.flight?.name +
                  ' - ' +
                  this.flight?.uzs_advertiser?.name +
                  ' - ' +
                  grpHelper.display(this.getTotalGrp())
                : 'Alerts'
        "
        :before-close="this.dialogClosed"
        :destroy-on-close="true"
        :lock-scroll="false"
        :model-value="isModalVisible"
        custom-class="wide"
        width="100%"
        ref="dialog"
    >
        <h4 class="subtitle" v-if="this.getLastUpdated()">
            {{ getLastUpdated() }}
        </h4>
        <div class="progress" @click="this.displayGrpAlert = !this.displayGrpAlert">
            <div class="grp">
                <div class="image"><img src="../../../assets/images/tv.png" /></div>
                <div class="content">
                    <span>GRP</span>
                    <GrpPercentage
                        :value="this.getGrpProgress()"
                        :is-future="this.isFutureCampaign()"
                    />
                </div>
            </div>
            <div class="duration">
                <div class="image">
                    <img src="../../../assets/images/tijdstip.png" />
                </div>
                <div class="content">
                    <span>Looptijd</span>
                    <DurationPercentage
                        :value="this.getDurationProgress()"
                        :is-future="this.isFutureCampaign()"
                    />
                </div>
            </div>
            <div class="target">
                <div class="image">
                    <img src="../../../assets/images/target.png" />
                </div>
                <div class="content">
                    <span>Target</span>
                    <TargetPercentage
                        :value="this.getTargetProgress()"
                        :is-fulfilled="this.isCampaignFulfilled()"
                    />
                </div>
            </div>
            <div class="price">
                <div class="image">
                    <img src="../../../assets/images/price.png" />
                </div>
                <div class="content">
                    <span>GRP-prijs</span>
                    <GrpPriceValue :value="this.getGrpPrice()" />
                </div>
            </div>
            <div v-if="!this.flightStatistics?.cpa" class="cpm">
                <div class="image">
                    <img src="../../../assets/images/cpm.png" />
                </div>
                <div class="content">
                    <span>CPM</span>
                    <CpmValue :value="this.getCpm()" :digits="2" />
                </div>
            </div>
            <div v-else class="cpa">
                <div class="image">
                    <img src="../../../assets/images/cpm.png" />
                </div>
                <div class="content">
                    <span>CPA</span>
                    <CpaValue :value="this.getCpa()" />
                </div>
            </div>
            <div class="optimization">
                <div class="image">
                    <img src="../../../assets/images/optimization.png" />
                </div>
                <div class="content">
                    <span>Optimalisatie</span>
                    <OptimizationScore :value="this.getOptimizationScore()" />
                </div>
            </div>
        </div>
        <i v-if="this.isLoadingAlerts" class="el-icon-loading" />
        <div v-if="!this.isLoadingAlerts" class="alerts">
            <div v-if="this.dailyGrpAlert && this.displayGrpAlert">
                <alert-daily-grp-table :alert="this.dailyGrpAlert" :alerts="this.alerts" />
            </div>

            <div v-if="this.displayGrpAlert">
                <package-spreading-table :campaignId="this.flight?.id" />
            </div>

            <div v-if="this.gcfAlert && this.displayGrpAlert">
                <alert-gcf-table :alert="this.gcfAlert" />
            </div>

            <alert-errors-table :alerts="this.alerts" />

            <div class="notices" v-for="alert of this.alerts" :key="alert.id">
                <div v-if="alert.message === alertClass.SuccessAirtimeToAwarenessChannels">
                    <h3 class="success" v-html="alertMessage.CorrectAirtimeToAwarenessChannels" />
                    <alert-channel-clusters-table :alert="alert" :show-max="true" />
                </div>
                <div v-if="alert.message === alertClass.SuccessCorrectAirtimeToPeak">
                    <h3 class="success" v-html="alertMessage.CorrectAirtimeToPeak" />
                    <alert-peak-clusters-table :alert="alert" :show-max="true" />
                </div>
                <div
                    v-if="
                        alert.message === alertClass.SuccessCorrectAirtimeToSmallBlocks &&
                        alert?.uzs_flight?.uzs_flight_statistics?.total_grp > 0
                    "
                >
                    <h3 class="success" v-html="alertMessage.CorrectAirtimeToSmallBlocks" />
                    <alert-grp-clusters-table :alert="alert" :show-max="true" />
                </div>
                <div
                    v-if="
                        alert.message === alertClass.SuccessCorrectAirtimeToLargeBlocks &&
                        alert?.uzs_flight?.uzs_flight_statistics?.total_grp > 0
                    "
                >
                    <h3 class="success" v-html="alertMessage.CorrectAirtimeToLargeBlocks" />
                    <alert-grp-clusters-table :alert="alert" :show-max="true" />
                </div>
                <div v-if="alert.message === alertClass.ErrorPackagesWithDuplicateSpots">
                    <h3 class="has--error">{{ alertMessage.ErrorPackagesWithDuplicateSpots }}</h3>
                    <p>De volgende pakketten bevatten overlappende spots:</p>
                    <alert-sub-orders-table :alert="alert" />
                </div>
                <div
                    v-if="
                        alert.message ===
                        alertClass.WarningTargetAudiencePackageAirtimeOutsideDaytime
                    "
                >
                    <h3
                        class="has--error"
                        v-html="
                            alertMessage.TargetAudiencePackageAirtimeOutsideDaytime.replace(
                                '[grp]',
                                this.grpHelper.display(alert.total)
                            ).replace(
                                '[percentage]',
                                this.percentageHelper.display(alert.percentage)
                            )
                        "
                    />
                    <alert-spots-table :alert="alert" :show-grp="true" />
                </div>
                <div
                    v-if="
                        alert.message ===
                        alertClass.WarningYourTimeFixedSturingPackageAirtimeOutsidePrimeTime
                    "
                >
                    <h3
                        class="has--error"
                        v-html="
                            alertMessage.YourTimeFixedSturingPackageAirtimeOutsidePrimeTime.replace(
                                '[grp]',
                                this.grpHelper.display(alert.total)
                            ).replace(
                                '[percentage]',
                                this.percentageHelper.display(alert.percentage)
                            )
                        "
                    />
                    <alert-spots-table :alert="alert" :show-grp="true" />
                </div>
                <div v-if="alert.message === alertClass.WarningDpgMediaAirtimeOutsideDaytime">
                    <h3
                        class="has--error"
                        v-html="
                            alertMessage.DpgMediaAirtimeOutsideDaytime.replace(
                                '[grp]',
                                this.grpHelper.display(alert.total)
                            ).replace(
                                '[percentage]',
                                this.percentageHelper.display(alert.percentage)
                            )
                        "
                    />
                    <alert-spots-table :alert="alert" :show-grp="true" />
                </div>
                <div v-if="alert.message === alertClass.WarningTooMuchAirtimeToAwarenessChannels">
                    <h3
                        class="has--error"
                        v-html="alertMessage.TooMuchAirtimeToAwarenessChannels"
                    />
                    <alert-channel-clusters-table
                        v-if="alert.message === alertClass.WarningTooMuchAirtimeToAwarenessChannels"
                        :alert="alert"
                        :show-max="true"
                    />
                </div>
                <div v-if="alert.message === alertClass.WarningTooMuchAirtimeToPeak">
                    <h3 class="has--error" v-html="alertMessage.TooMuchPeakAirtime" />
                    <alert-peak-clusters-table
                        v-if="alert.message === alertClass.WarningTooMuchAirtimeToPeak"
                        :alert="alert"
                        :show-max="true"
                    />
                </div>
                <div v-if="alert.message === alertClass.WarningTooLittleAirtimeToAwarenessChannels">
                    <h3
                        class="has--error"
                        v-html="alertMessage.TooLittleAirtimeToAwarenessChannels"
                    />
                    <alert-channel-clusters-table
                        v-if="
                            alert.message === alertClass.WarningTooLittleAirtimeToAwarenessChannels
                        "
                        :alert="alert"
                        :show-max="false"
                    />
                </div>
                <div v-if="alert.message === alertClass.WarningTooMuchAirtimeToSmallBlocks">
                    <h3 class="has--error" v-html="alertMessage.TooMuchAirtimeToSmallBlocks" />
                    <alert-grp-clusters-table
                        v-if="alert.message === alertClass.WarningTooMuchAirtimeToSmallBlocks"
                        :alert="alert"
                        :show-max="true"
                    />
                </div>
                <div v-if="alert.message === alertClass.WarningTooMuchAirtimeToLargeBlocks">
                    <h3 class="has--error" v-html="alertMessage.TooMuchAirtimeToLargeBlocks" />
                    <alert-grp-clusters-table
                        v-if="alert.message === alertClass.WarningTooMuchAirtimeToLargeBlocks"
                        :alert="alert"
                        :show-max="true"
                    />
                </div>
                <div
                    v-if="
                        alert.message ===
                        alertClass.WarningTooMuchGrpToLowPerformingTimeslotsAfternoon
                    "
                >
                    <h3
                        class="has--error"
                        v-if="!alert.message_shown"
                        v-html="alertMessage.TooMuchBudgetToLowPerformingTimeslotsAfternoon"
                    />
                    <div class="content">
                        <span>
                            Op dit moment gaat
                            <b class="red">{{
                                this.percentageHelper.display(alert?.data?.share)
                            }}</b>
                            van het budget naar tijdvak 17.00 tot 19.00 uur, daar waar
                            <b>maximaal 15%</b> is toegestaan in verband met slechte performance.
                            Dat is ongeveer {{ this.grpHelper.display(alert.total) }} teveel.
                        </span>
                    </div>
                </div>
                <div
                    v-if="
                        alert.message ===
                        alertClass.WarningTooMuchGrpToLowPerformingTimeslotsOutsideDaytime
                    "
                >
                    <h3
                        class="has--error"
                        v-if="!alert.message_shown"
                        v-html="alertMessage.TooMuchBudgetToLowPerformingTimeslotsAfternoon"
                    />
                    <div class="content">
                        <span>
                            Op dit moment gaat
                            <b class="red">{{
                                this.percentageHelper.display(alert?.data?.share)
                            }}</b>
                            van het budget <b>buiten</b> tijdvak 12.00 tot 23.00 uur, daar waar
                            <b>maximaal 15%</b> is toegestaan in verband met slechte performance.
                            Dat is ongeveer {{ this.grpHelper.display(alert.total) }} teveel.
                        </span>
                    </div>
                </div>
                <div v-if="alert.message === alertClass.WarningTooLittleSpreading">
                    <h3 class="has--error" v-html="alertMessage.TooLittleSpreading" />
                    <alert-spreading-table
                        :alert="alert"
                        message="Probeer meer spreiding aan te brengen, want op <b>[dates]</b> wijkt de inzet aanzienlijk af van de gemiddelde campagnedag."
                    />
                </div>
                <div v-if="alert.message === alertClass.WarningSpotWrongDay">
                    <alert-spots-table
                        :alert="alert"
                        :show-grp="true"
                        message="Spotinzet op [days], terwijl niet toegestaan."
                    />
                </div>
                <div v-if="alert.message === alertClass.WarningSpotGrpOverage">
                    <h3 class="has--error" v-html="alertMessage.SpotGrpOverage" />
                    <alert-spots-table :alert="alert" :show-grp="true" :show-grp-errors="false" />
                </div>
                <div v-if="alert.message === alertClass.WarningSubOrderWithoutBudget">
                    <h3 class="has--error" v-html="alertMessage.SubOrderWithoutBudget" />
                    <alert-sub-orders-table :alert="alert" />
                </div>
                <div v-if="alert.message === alertClass.WarningTooLittleGrpsBooked">
                    <h3 class="has--error" v-html="alertMessage.TooLittleGrpsBooked" />
                    <alert-requested-grps-table :alert="alert" />
                </div>
            </div>
        </div>
    </el-dialog>
</template>
<script lang="ts">
import { format } from "date-fns";
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs } from "vue";
import { Store, useStore } from "vuex";

import CpaValue from "@/components/campaigns/CpaValue.vue";
import CpmValue from "@/components/campaigns/CpmValue.vue";
import DurationPercentage from "@/components/campaigns/DurationPercentage.vue";
import GrpPercentage from "@/components/campaigns/GrpPercentage.vue";
import GrpPriceValue from "@/components/campaigns/GrpPriceValue.vue";
import OptimizationScore from "@/components/campaigns/OptimizationScore.vue";
import TargetPercentage from "@/components/campaigns/TargetPercentage.vue";
import { AlertClass } from "@/enums/alertClass";
import { AlertMessage } from "@/enums/alertMessage";
import { Goal } from "@/enums/goal";
import DateHelper from "@/helper/date-helper";
import FlightHelper from "@/helper/flight-helper";
import GrpHelper from "@/helper/grp-helper";
import PercentageHelper from "@/helper/percentage-helper";
import AlertApiService from "@/service/api/alert";
import { DataResult } from "@/types/result/dataResult";
import { FlightState } from "@/types/state/flight";
import { RootState } from "@/types/state/root";
import { Alert } from "@/types/uzs/alert";
import { Flight } from "@/types/uzs/flight";
import { FlightStatistics } from "@/types/uzs/flightStatistics";
import AlertChannelClustersTable from "@/views/campaigns/dialogs/alerts/ChannelClustersTable.vue";
import AlertDailyGrpTable from "@/views/campaigns/dialogs/alerts/DailyGrpTable.vue";
import AlertErrorsTable from "@/views/campaigns/dialogs/alerts/ErrorsTable.vue";
import AlertGcfTable from "@/views/campaigns/dialogs/alerts/GcfTable.vue";
import AlertGrpClustersTable from "@/views/campaigns/dialogs/alerts/GrpClustersTable.vue";
import PackageSpreadingTable from "@/views/campaigns/dialogs/alerts/PackageSpreadingTable.vue";
import AlertPeakClustersTable from "@/views/campaigns/dialogs/alerts/PeakClusterTable.vue";
import AlertRequestedGrpsTable from "@/views/campaigns/dialogs/alerts/RequestedGrpsTable.vue";
import AlertSpotsTable from "@/views/campaigns/dialogs/alerts/SpotsTable.vue";
import AlertSpreadingTable from "@/views/campaigns/dialogs/alerts/SpreadingTable.vue";
import AlertSubOrdersTable from "@/views/campaigns/dialogs/alerts/SubOrdersTable.vue";

const props = {
    flight: {
        type: Object as PropType<Flight>,
        required: true,
    },
};

export default defineComponent({
    name: "AlertsDialog",
    computed: {
        Goal() {
            return Goal;
        },
    },
    components: {
        PackageSpreadingTable,
        AlertPeakClustersTable,
        GrpPercentage,
        DurationPercentage,
        GrpPriceValue,
        CpmValue,
        CpaValue,
        OptimizationScore,
        TargetPercentage,
        AlertGcfTable,
        AlertDailyGrpTable,
        AlertSpreadingTable,
        AlertRequestedGrpsTable,
        AlertChannelClustersTable,
        AlertGrpClustersTable,
        AlertSubOrdersTable,
        AlertSpotsTable,
        AlertErrorsTable,
    },
    props,
    setup(props, { emit }) {
        const alertApi = new AlertApiService();
        const alertClass = AlertClass;
        const alertMessage = AlertMessage;
        const flight = ref(props.flight) as Ref<Flight | undefined>;
        const dateHelper = DateHelper;
        const grpHelper = GrpHelper;
        const percentageHelper = PercentageHelper;
        const store = useStore() as Store<RootState>;
        const state = store.state.flight as FlightState;

        const data = reactive({
            alert: null as Alert | null,
            alerts: [] as Alert[],
            flightStatistics: null as FlightStatistics | null,
            gcfAlert: null as Alert | null,
            dailyGrpAlert: null as Alert | null,
            displayGrpAlert: false,
            successes: [] as Alert[],
            isLoadingAlerts: false,
            isModalVisible: true,
        });

        function close(changed = false): void {
            flight.value = undefined;
            data.gcfAlert = null;
            data.dailyGrpAlert = null;
            data.displayGrpAlert = false;
            data.isModalVisible = false;

            emit("close", changed);
        }

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        function init(): void {
            loadAlerts();
        }

        function getTotalGrp(): number {
            if (!data.dailyGrpAlert?.uzs_flight?.uzs_flight_daily_grp) {
                return 0;
            }

            let totalGrp = 0;

            for (const d of data.dailyGrpAlert.uzs_flight.uzs_flight_daily_grp) {
                totalGrp +=
                    d?.holiday?.is_operator_closed &&
                    d?.holiday?.date === format(new Date(), "yyyy-MM-dd")
                        ? d.predicted_grp
                        : d.rating;
            }

            return totalGrp;
        }

        function getTotalRequestedGrp(): number {
            return data.flightStatistics?.total_requested_grp ?? 0;
        }

        function getGrpProgress(): number {
            return data.flightStatistics?.grp_progress ?? 0;
        }

        function getDurationProgress(): number {
            return data.flightStatistics?.duration_progress ?? 0;
        }

        const getTargetProgress = (): number => {
            return data.flightStatistics?.target_progress ?? 0;
        };

        function getCpa(): number {
            return data.flightStatistics?.cpa ?? 0;
        }

        function getCpm(): number {
            return data.flightStatistics?.cpm ?? 0;
        }

        function getGrpPrice(): number {
            return data.flightStatistics?.grp_price ?? 0;
        }

        function getOptimizationScore(): number {
            return data.flightStatistics?.optimization_score ?? 0;
        }

        function getLastUpdated(): string | null {
            if (!flight.value?.most_recent_package_imported_at) {
                return null;
            }

            const date = dateHelper.display(
                flight.value.most_recent_package_imported_at,
                true,
                true
            );

            return (
                "Laatste wijziging " +
                (date.indexOf("gisteren") > -1 || date.indexOf("vandaag") > -1 ? "" : " op ") +
                date
            );
        }

        function loadAlerts(): void {
            if (!flight.value?.id) {
                return;
            }

            let messageShown = false;

            data.isLoadingAlerts = true;

            alertApi
                .fetchAllForFlight({}, flight.value.id)
                .then((result: DataResult<Alert[]>) => {
                    data.alerts = (result.data ?? []).map((alert) => {
                        if (
                            alert.message !==
                                AlertClass.WarningTooMuchGrpToLowPerformingTimeslotsAfternoon &&
                            alert.message !==
                                AlertClass.WarningTooMuchGrpToLowPerformingTimeslotsOutsideDaytime
                        ) {
                            return alert;
                        }

                        alert.message_shown = messageShown;
                        messageShown = true;

                        return alert;
                    });

                    data.alert = data.alerts.length ? data.alerts[0] : null;

                    data.dailyGrpAlert =
                        data.alerts.filter(
                            (alert) => alert?.uzs_flight?.uzs_flight_daily_grp !== null
                        )[0] ?? null;

                    data.flightStatistics = data.alert?.uzs_flight?.uzs_flight_statistics ?? null;

                    data.gcfAlert =
                        data.alerts.filter(
                            (alert) => alert?.uzs_flight?.uzs_flight_gcf !== null
                        )[0] ?? null;

                    data.isLoadingAlerts = false;
                })
                .catch(() => (data.isLoadingAlerts = false));
        }

        function isCampaignFulfilled(): boolean {
            return data.alert ? FlightHelper.isCampaignFulfilled(flight.value) : false;
        }

        function isFutureCampaign(): boolean {
            return data.alert ? FlightHelper.isFutureCampaign(flight.value) : false;
        }

        onMounted(() => {
            if (props.flight) {
                flight.value = props.flight as Flight;
                init();
            }
        });

        return {
            ...toRefs(data),
            ...toRefs(state),
            alertClass,
            alertMessage,
            close,
            dialogClosed,
            flight,
            grpHelper,
            isCampaignFulfilled,
            isFutureCampaign,
            getTotalGrp,
            getGrpProgress,
            getDurationProgress,
            getTargetProgress,
            getCpa,
            getCpm,
            getGrpPrice,
            getOptimizationScore,
            getLastUpdated,
            percentageHelper,
        };
    },
});
</script>

<style>
h3 {
    color: red;
    font-weight: bold;
    margin-bottom: 15px;
    word-break: break-word;
}

h3.success {
    color: initial;
}

h4.subtitle {
    color: #aaa;
    display: block;
    font-style: italic;
    font-weight: normal;
    transform: translateY(-30px);
}

b.red {
    color: red;
}

div.el-table,
div.content {
    margin-bottom: 35px;
    word-break: break-word;
}

div.progress {
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

div.progress div.grp,
div.progress div.duration,
div.progress div.target,
div.progress div.cpm,
div.progress div.cpa,
div.progress div.price,
div.progress div.optimization {
    display: flex;
    flex: 1;
    min-width: 100px;
    max-width: 150px;
}

div.progress > div > div.image {
    flex: 1;
}

div.progress > div > div.image img {
    max-height: 32px;
}

div.progress > div > div.content {
    flex: 3;
}

div.progress > div > div.content span {
    display: block;
}

div.progress > div > div.content span:nth-child(1) {
    font-weight: bold;
}

div.progress > div > div.content span.green {
    color: hsl(106deg, 61%, 51%);
    font-weight: bold;
}

div.progress > div > div.content span.red {
    color: #ff0000;
    font-weight: bold;
}

div.progress > div > div.content span.greyed-out {
    color: #aaa;
}
</style>
