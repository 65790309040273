import axios from "axios";

import config from "@/service/config";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { SuccessResult } from "@/types/result/successResult";
import { ServiceAccount } from "@/types/uzs/settings/bigquery/serviceAccount";

export default class ServiceAccountApiService {
    public async delete(guid: string): Promise<SuccessResult> {
        const response = await axios.delete(
            config.backend + "/api/settings/bigquery/service-account/" + guid
        );

        return response?.data as SuccessResult;
    }

    public async list(params: RequestParameters): Promise<DataResult<ServiceAccount[]>> {
        const response = await axios.get(
            config.backend + "/api/settings/bigquery/service-accounts",
            {
                params,
            }
        );

        return response?.data as DataResult<ServiceAccount[]>;
    }
}
