import axios from "axios";

import config from "@/service/config";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { SuccessResult } from "@/types/result/successResult";
import { ImportedLeads } from "@/types/uzs/importedLeads";

export default class ImportedLeadsApiService {
    public fetchOne(id: number): Promise<DataResult<ImportedLeads>> {
        return axios
            .get(config.backend + "/api/uzs/imported-leads/" + id)
            .then((response) => response?.data as DataResult<ImportedLeads>);
    }

    public fetchAll(params: RequestParameters): Promise<DataResult<ImportedLeads[]>> {
        return axios
            .get(config.backend + "/api/uzs/imported-leads", {
                params,
            })
            .then((response) => response.data as DataResult<ImportedLeads[]>);
    }

    public delete(id: number): Promise<SuccessResult> {
        return axios
            .post(config.backend + "/api/uzs/imported-leads/" + id + "/delete")
            .then((response) => response?.data)
            .then((response) => response as SuccessResult);
    }

    public store(data: ImportedLeads): Promise<DataResult<ImportedLeads>> {
        return axios
            .post(config.backend + "/api/uzs/imported-leads", data)
            .then((response) => response?.data as DataResult<ImportedLeads>);
    }
}
