
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import { Store, useStore } from "vuex";

import DateHelper from "@/helper/date-helper";
import GrpHelper from "@/helper/grp-helper";
import BlockSpreadSheetApiService from "@/service/api/blockSpreadSheet";
import ImportedBlocksApiService from "@/service/api/importedBlocks";
import config from "@/service/config";
import { Authentication } from "@/types/credentials/authentication";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { RequestParameters } from "@/types/request/requestParameters";
import { RootState } from "@/types/state/root";
import { BlockSpreadsheetAccess } from "@/types/uzs/blockSpreadsheetAccess";
import { ImportedBlocks } from "@/types/uzs/importedBlocks";
import ImportedBlocksDialog from "@/views/export/blocks/dialogs/ImportedBlocks.vue";
import SelectedBlocksDialog from "@/views/export/blocks/dialogs/SelectedBlocks.vue";

export default defineComponent({
    name: "List",
    components: { ImportedBlocksDialog, SelectedBlocksDialog },
    setup() {
        const store = useStore() as Store<RootState>;
        const rootState = store.state as RootState;
        const auth = rootState.authentication as Authentication;
        const dateHelper = DateHelper;
        const grpHelper = GrpHelper;
        const blockSpreadsheetApi = new BlockSpreadSheetApiService();
        const importedBlocksApi = new ImportedBlocksApiService();

        const actions = {
            importedBlocks: {
                list: (params: RequestParameters) => importedBlocksApi.fetchAll(params),
                delete: (id: number) => importedBlocksApi.delete(id),
            },
        };

        /* eslint-disable  @typescript-eslint/no-explicit-any */
        const data = reactive({
            backendUrl: config.backend,
            isDeleting: -1,
            isLoading: false,
            isUploading: false,
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            order: {
                created_at: "desc",
            } as OrderRequest,
            currentPage: 1,
            currentBlocks: null as ImportedBlocks | null,
            pageCount: 0,
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            itemsPerPage: config.pagination.per_page,
            itemCount: 0,
            importedBlocks: [] as ImportedBlocks[],
            showTemplateDialog: false,
            showImportedBlocksDialog: false,
            showSelectedBlocksDialog: false,
            selectedBlocksGuid: null as string | null,
            updateTimeout: null as any | null,
        });

        const goToPage = (page: number) => {
            if (!data.pagination) {
                return;
            }

            data.currentPage = page;
            data.pagination.page = page;

            loadImportedBlocks();
        };

        const init = (): void => {
            loadImportedBlocks();
            updateOnInterval();
        };

        const loadImportedBlocks = (isLoading = true): void => {
            data.isLoading = isLoading;

            actions.importedBlocks
                .list({
                    pagination: data.pagination,
                    order: data.order,
                })
                .then((result) => {
                    data.currentPage = result.pagination?.page ?? 1;
                    data.pageCount = result.pagination?.total_pages ?? 0;
                    data.itemsPerPage = result.pagination?.per_page ?? config.pagination.per_page;
                    data.itemCount = result.pagination?.total_items ?? 0;

                    return (result.data ?? []) as ImportedBlocks[];
                })
                .then((response) => {
                    data.importedBlocks = response;
                    data.isLoading = false;
                });
        };

        const onBusyUploading = () => {
            data.isUploading = true;
        };

        const onDelete = (importedBlocks: ImportedBlocks): void => {
            data.isDeleting = importedBlocks.id;

            actions.importedBlocks
                .delete(importedBlocks.id)
                .then(() => (data.isDeleting = -1))
                .catch(() => (data.isDeleting = -1));
        };

        const onDoneDebugging = (): void => {
            data.selectedBlocksGuid = null;
            data.showSelectedBlocksDialog = false;
        };

        const onDoneEditing = (): void => {
            data.currentBlocks = null;
            data.showImportedBlocksDialog = false;
        };

        const onDoneUploading = () => {
            data.isUploading = false;

            loadImportedBlocks();
        };

        function onDownloadLeadSpreadsheet(): void {
            blockSpreadsheetApi.downloadTemplate().then((response: BlockSpreadsheetAccess) => {
                if (response?.url) {
                    location.href = response.url;
                }
            });
        }

        function onDownloadResultSpreadsheet(importedBlocks: ImportedBlocks): void {
            blockSpreadsheetApi
                .downloadSpreadsheet(importedBlocks.uuid)
                .then((response: BlockSpreadsheetAccess) => {
                    if (response?.url) {
                        location.href = response.url;
                    }
                });
        }

        const onDebug = (importedBlocks: ImportedBlocks): void => {
            data.selectedBlocksGuid = importedBlocks?.uuid ?? null;
            data.showSelectedBlocksDialog = true;
        };

        const onEdit = (importedBlocks: ImportedBlocks): void => {
            data.currentBlocks = importedBlocks;
            data.showImportedBlocksDialog = true;
        };

        const onPageSizeChanged = (pageSize: number) => {
            if (!data.pagination) {
                return;
            }

            data.pagination.per_page = pageSize;

            loadImportedBlocks();
        };

        const updateOnInterval = () => {
            if (data.updateTimeout) {
                clearInterval(data.updateTimeout);
            }

            if (!data.isLoading) {
                loadImportedBlocks(false);
            }

            data.updateTimeout = setTimeout(() => {
                updateOnInterval();
            }, 3000);
        };

        onBeforeRouteLeave(() => {
            if (data.updateTimeout) {
                clearTimeout(data.updateTimeout);
            }
        });

        onMounted(() => {
            init();
        });

        return {
            ...toRefs(data),
            accessToken: auth.token,
            dateHelper,
            goToPage,
            grpHelper,
            onBusyUploading,
            onDebug,
            onDelete,
            onDoneDebugging,
            onDoneEditing,
            onDoneUploading,
            onDownloadLeadSpreadsheet,
            onDownloadResultSpreadsheet,
            onEdit,
            onPageSizeChanged,
        };
    },
});
