<template>
    <span :class="getClassName()">{{ getValue() }}</span>
</template>
<script lang="ts">
import { defineComponent, PropType, Ref, ref, watch } from "vue";

import PercentageHelper from "@/helper/percentage-helper";

const props = {
    value: {
        type: Number as PropType<number>,
        required: true,
    },
    round: {
        type: Boolean as PropType<boolean>,
        default: false,
    },
};

export default defineComponent({
    name: "Percentage",
    props,
    setup(props) {
        const percentage = ref(props.value) as Ref<number>;
        const round = ref(props.round ?? false) as Ref<boolean>;

        function getClassName(): string {
            if (percentage.value <= 0) {
                return "is--greyed-out";
            }

            if (percentage.value > 85) {
                return "has--success is--bold";
            }

            if (percentage.value < 55) {
                return "has--error is--bold";
            }

            return "";
        }

        function getValue(): string {
            return percentage.value > 0
                ? PercentageHelper.display(percentage.value, round.value)
                : "n/a";
        }

        watch(
            () => props.value,
            (value) => {
                percentage.value = value as number;
            }
        );

        return {
            getClassName,
            getValue,
        };
    },
});
</script>

<style lang="scss" scoped>
@import "src/assets/scss/global/variables";

span.boundary {
    color: $color-greyed-out;
    font-weight: $font-weight-regular;
    margin-left: 8px;
}
</style>
