import axios from "axios";

import config from "@/service/config";
import { DataResult } from "@/types/result/dataResult";
import { SuccessResult } from "@/types/result/successResult";
import { CampaignDataSet } from "@/types/uzs/campain-data-set/campaignDataSet";

export default class CampaignDataSetApiService {
    public fetch(campaignId: number): Promise<DataResult<CampaignDataSet>> {
        return axios
            .get(config.backend + "/api/campaign/" + campaignId + "/dataset")
            .then((response) => response?.data as DataResult<CampaignDataSet>);
    }

    public store(campaignId: number, data: CampaignDataSet): Promise<SuccessResult> {
        return axios
            .post(config.backend + "/api/campaign/" + campaignId + "/dataset", data)
            .then((response) => response?.data as SuccessResult);
    }
}
