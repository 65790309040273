import axios from "axios";
import { ActionContext } from "vuex";

import config from "@/service/config";
import { DataResult } from "@/types/result/dataResult";
import { SuccessResult } from "@/types/result/successResult";
import { DayPartState } from "@/types/state/dayPart";
import { RootState } from "@/types/state/root";
import { DayPart } from "@/types/uzs/dayPart";

import { RequestParameters } from "../../types/request/requestParameters";

const initialState: DayPartState = {
    columns: undefined,
    filter: {},
    order: {
        start_time: "asc",
    },
    pagination: {
        page: 1,
        per_page: config.pagination.per_page,
    },
    search: undefined,
    dayPart: undefined,
    dayParts: undefined,
};

const DayPartModule = {
    namespaced: true,
    state: initialState,
    getters: {},
    mutations: {},
    actions: {
        _delete(
            context: ActionContext<DayPartState, RootState>,
            id: number
        ): Promise<SuccessResult> {
            return axios
                .delete(config.backend + "/api/uzs/day-part/" + id)
                .then((response) => response?.data)
                .then((response) => response as SuccessResult);
        },
        _list(
            context: ActionContext<DayPartState, RootState>,
            params: RequestParameters | null
        ): Promise<DataResult<DayPart[]>> {
            return axios
                .get(config.backend + "/api/uzs/day-parts", {
                    params: {
                        columns: params?.columns ?? context.state.columns,
                        filter: params?.filter ?? context.state.filter,
                        order: params?.order ?? context.state.order,
                        pagination: params?.pagination ?? context.state.pagination,
                        search: params?.search ?? context.state.search,
                    },
                })
                .then((response) => response?.data as DataResult<DayPart[]>)
                .then((response) => {
                    context.state.dayParts = response;
                    return response;
                });
        },
        _details(
            context: ActionContext<DayPartState, RootState>,
            id: number
        ): Promise<DataResult<DayPart>> {
            return axios
                .get(config.backend + "/api/uzs/day-part/" + id)
                .then((response) => response?.data as DataResult<DayPart>)
                .then((response) => {
                    context.state.dayPart = response;
                    return response;
                });
        },
        _store(
            context: ActionContext<DayPartState, RootState>,
            data: DayPart
        ): Promise<DataResult<DayPart>> {
            return axios
                .post(config.backend + "/api/uzs/day-part", data)
                .then((response) => response?.data as DataResult<DayPart>)
                .then((response) => {
                    context.state.dayPart = response;
                    return response;
                });
        },
    },
};

export default DayPartModule;
