<template>
    <el-dialog
        :title="'Zender - ' + (this.channel?.name ?? this.channel?.description ?? '')"
        :before-close="this.dialogClosed"
        :destroy-on-close="true"
        :lock-scroll="false"
        :model-value="this.value"
        width="100%"
        ref="dialog"
    >
        <el-form label-position="top" @keydown="this.submitOnEnter" @input="this.nullOnEmpty">
            <el-form-item label="Land">
                <el-select v-model="this.country" filterable @change="this.onCountrySelected">
                    <el-option v-for="item in this.countries" :key="item" :value="item"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Zender" v-if="!this.channel">
                <el-select v-if="!this.isLoadingChannels" v-model="this.channel">
                    <el-option
                        v-for="channel of this.channels"
                        :key="channel.id"
                        :value="channel"
                        :label="channel.description"
                        >{{ channel.description }}</el-option
                    >
                </el-select>
                <el-icon v-if="this.isLoadingChannels" name="loading" />
            </el-form-item>
            <template v-if="this.channel">
                <el-form-item label="Exploitant">
                    <el-select v-model="this.channel.operator" filterable>
                        <el-option
                            v-for="item in this.operators"
                            :key="item"
                            :value="item"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Naam">
                    <el-input
                        clearable
                        v-model="this.channel.name"
                        :model-value="this.getChannelName()"
                    />
                </el-form-item>
                <el-form-item :label="this.getChannelSizeLabel()">
                    <div>
                        <el-slider
                            max="50"
                            step="0.1"
                            v-model="this.channel.size"
                            :model-value="this.channel?.size"
                            :format-tooltip="(val) => this.getFormattedPercentage(val)"
                        />
                        <el-input
                            v-model="this.channelSizeText"
                            @keyup="this.onChannelSizeChanged"
                            @change="this.onChannelSizeChanged"
                        >
                            <template #append>%</template>
                        </el-input>
                    </div>
                </el-form-item>
                <el-form-item label="Regio">
                    <el-select
                        v-model="this.selectedRegions"
                        placeholder="Provincie"
                        clearable
                        filterable
                        multiple
                        @change="this.regionChanged"
                    >
                        <el-option
                            v-for="region in this.regions"
                            :key="region.region"
                            :label="region.region"
                            :value="region.region"
                            >{{ region.region }}</el-option
                        >
                    </el-select>
                    <el-select
                        v-if="this.selectedRegions.length"
                        v-model="this.selectedCities"
                        placeholder="Stad"
                        clearable
                        filterable
                        multiple
                    >
                        <el-option
                            v-for="city in this.cities"
                            :key="city"
                            :label="city"
                            :value="city"
                            >{{ city }}</el-option
                        >
                    </el-select>
                </el-form-item>
                <el-form-item label="Channel Cluster">
                    <el-select v-model="this.channel.cluster" filterable>
                        <el-option
                            v-for="cluster in this.clusters"
                            :key="cluster"
                            :value="cluster"
                            >{{ cluster }}</el-option
                        >
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="this.save()">Opslaan</el-button>
                </el-form-item>
            </template>
        </el-form>
    </el-dialog>
</template>
<script lang="ts">
import { ElInput, ElMessage } from "element-plus";
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs, watch } from "vue";
import { Store, useStore } from "vuex";

import { ChannelCluster } from "@/enums/channelCluster";
import { Country } from "@/enums/country";
import PercentageHelper from "@/helper/percentage-helper";
import ChannelApiService from "@/service/api/channel";
import RegionApiService from "@/service/api/integration/bigquery/region";
import SubOrderApiService from "@/service/api/subOrder";
import config from "@/service/config";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { Channel } from "@/types/screen-force/channel";
import { Region as ChannelRegion } from "@/types/screen-force/channel";
import { City as ChannelCity } from "@/types/screen-force/channel";
import { RootState } from "@/types/state/root";
import { Region } from "@/types/uzs/big-query-region/region";
import { TargetGroup } from "@/types/uzs/targetGroup";

const props = {
    value: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
    channel: {
        type: Object as PropType<Channel>,
        required: false,
    },
};

export default defineComponent({
    name: "ChannelDialog",
    props,
    setup(props, { emit }) {
        const channel = ref(props?.channel ?? null) as Ref<Channel | null>;
        const channelApi = new ChannelApiService();
        const channelSizeInput = ref<typeof ElInput | null>(null);
        const subOrderApi = new SubOrderApiService();
        const store = useStore() as Store<RootState>;
        const clusters = ChannelCluster;

        const actions = {
            channel: {
                store: (channel: Channel) => store.dispatch("channel/_store", channel),
            },
            targetGroup: {
                list: (params?: RequestParameters) => store.dispatch("targetGroup/_list", params),
            },
        };

        const data = reactive({
            country: channel.value?.country ?? Country.NETHERLANDS,
            countries: config.countries,
            clusters,
            channels: [] as Channel[],
            operator: null as string | null,
            mostImportantTargetGroup: null as TargetGroup | null,
            isLoadingChannels: false,
            channelSizeText: "0,0%",
            operators: [] as string[],
            regions: [] as Region[],
            regionNames: [] as string[],
            selectedRegions: [] as string[],
            cities: [] as string[],
            selectedCities: [] as string[],
        });

        const init = (): void => {
            loadChannels();
            loadMostImportantTargetGroup();
            loadOperators();

            data.selectedRegions = channel.value?.regions.map((region) => region.name) ?? [];
            data.selectedCities = channel.value?.cities.map((city) => city.name) ?? [];
        };

        const close = (): void => {
            emit("input", props.value);
        };

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        function getChannelName(): string {
            if (channel.value?.name?.length) {
                return channel.value.name;
            }

            return channel.value?.description ?? "";
        }

        const getChannelSizeLabel = (): string => {
            if (!data.mostImportantTargetGroup) {
                return "Marktaandeel";
            }

            return (
                "Marktaandeel " +
                data.mostImportantTargetGroup.country +
                " " +
                data.mostImportantTargetGroup.name
            );
        };

        const getFormattedPercentage = (value: number | undefined): string => {
            if (!value) {
                return "0";
            }

            return value.toString().replace(".", ",") + " %";
        };

        const loadChannels = (): void => {
            data.isLoadingChannels = true;

            let params = {
                filter: {
                    name: {
                        operator: "=",
                        value: "",
                    },
                    country: {
                        operator: "=",
                        value: data.country,
                    },
                },
                pagination: {
                    page: 1,
                    per_page: 1000,
                },
            } as RequestParameters;

            channelApi
                .fetchAll(params)
                .then((response) => {
                    data.channels = response.data ?? [];
                })
                .then(() => {
                    data.isLoadingChannels = false;
                })
                .catch(() => (data.isLoadingChannels = false));
        };

        const loadMostImportantTargetGroup = (): void => {
            if (!channel.value) {
                return;
            }

            data.mostImportantTargetGroup = null;

            actions.targetGroup
                .list({
                    columns: ["country", "name"],
                    filter: {
                        country: {
                            operator: "=",
                            value: data.country,
                        },
                        is_most_important: {
                            operator: "=",
                            value: true,
                        },
                    },
                })
                .then((result: DataResult<TargetGroup[]>) => {
                    data.mostImportantTargetGroup = result.data?.pop() ?? null;
                });
        };

        const loadOperators = (): void => {
            subOrderApi.fetchOperators().then((result) => {
                data.operators = result.data ?? [];
            });
        };

        const onCountrySelected = (): void => {
            init();
        };

        const onChannelSizeChanged = (event?: InputEvent | KeyboardEvent): void => {
            if (!channel.value) {
                return;
            }

            if (event instanceof KeyboardEvent && (event.key === "." || event.key === ",")) {
                return;
            }

            const size = parseFloat(
                data.channelSizeText.toString().replace(",", ".").replace("%", "")
            );

            channel.value.size = !isNaN(size) ? size : 0;
            data.channelSizeText = PercentageHelper.display(channel.value.size);

            if (channel.value?.size > 3) {
                channel.value.cluster = ChannelCluster.AWARENESS;
            }

            if (channel.value?.size >= 0.4 && channel.value?.size <= 3) {
                channel.value.cluster = ChannelCluster.VOLUME;
            }

            if (channel.value?.size < 0.4) {
                channel.value.cluster = ChannelCluster.FREQUENCY;
            }
        };

        const nullOnEmpty = (): void => {
            if (channel.value && !channel.value.name?.length) {
                channel.value.name = null;
            }
        };

        const save = (): void => {
            if (!channel.value) {
                return;
            }

            channel.value.cities = data.selectedCities.map((city) => {
                return { name: city } as ChannelCity;
            });

            channel.value.regions = data.selectedRegions.map((region) => {
                return { name: region } as ChannelRegion;
            });

            actions.channel
                .store(channel.value)
                .then(() => {
                    ElMessage({
                        message:
                            channel.value?.name ?? channel.value?.description + " is opgeslagen.",
                        type: "success",
                    });

                    close();
                })
                .catch(() => {
                    ElMessage({
                        message:
                            "Er is een fout opgetreden bij het opslaan van " +
                                channel.value?.name ?? channel.value?.description,
                        type: "error",
                    });
                });
        };

        const submitOnEnter = (event: KeyboardEvent): void => {
            const input = event.target as HTMLElement | null;

            if (
                event.key.toLowerCase() === "enter" &&
                input?.tagName?.toLowerCase() !== "textarea"
            ) {
                save();
            }
        };

        function findRegions(): void {
            const regionApi = new RegionApiService();

            regionApi.list(channel.value?.country ?? Country.NETHERLANDS).then((response) => {
                const regions = response.data as Array<Region>;

                data.regions = [];

                for (const region of regions) {
                    if (!data.regionNames.includes(region.region)) {
                        data.regionNames.push(region.region);
                        data.regions.push(region);
                    }
                }
            });
        }

        function regionChanged(): void {
            data.cities = [];

            if (!data.regions?.length) {
                return;
            }

            data.cities = data.regions
                .filter((region) => data.selectedRegions.includes(region.region))
                .map((region) => region.city);
        }

        onMounted(() => {
            init();
            findRegions();

            data.country = channel.value?.country ?? Country.NETHERLANDS;
            data.channelSizeText = (channel.value?.size ?? 0).toString().replace(".", ",") + "%";
        });

        watch(
            () => props.channel,
            (value) => {
                console.debug("CHANNEL", value);
                channel.value = value as Channel | null;
                data.country = channel.value?.country ?? Country.NETHERLANDS;
                data.channelSizeText =
                    (channel.value?.size ?? 0).toString().replace(".", ",") + "%";
            }
        );

        watch(
            () => channel.value,
            () => {
                if (!channel.value) {
                    return;
                }

                channel.value.name =
                    channel.value.name !== "" ? channel.value.name : channel.value.description;

                onChannelSizeChanged();
            }
        );

        watch(
            () => channel.value?.size,
            (value) => {
                data.channelSizeText = PercentageHelper.display(value);

                onChannelSizeChanged();
            }
        );

        return {
            ...toRefs(data),
            channel,
            close,
            channelSizeInput,
            dialogClosed,
            getChannelName,
            getChannelSizeLabel,
            getFormattedPercentage,
            onChannelSizeChanged,
            onCountrySelected,
            nullOnEmpty,
            save,
            submitOnEnter,
            regionChanged,
        };
    },
});
</script>
