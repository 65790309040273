<template>
    <el-dialog
        :title="'Delete Import - ' + (this.importedFile?.filename ?? '')"
        :before-close="this.dialogClosed"
        :destroy-on-close="true"
        :lock-scroll="false"
        :model-value="this.value"
        width="100%"
        ref="dialog"
    >
        <el-form
            :model="this.importedFile"
            v-if="this.importedFile?.id"
            label-position="left"
            label-width="150px"
        >
            <el-form-item label="Data">
                <el-select v-model="this.flags" multiple>
                    <el-option
                        :key="importedFileDeleteFlag.CHANNELS"
                        :value="importedFileDeleteFlag.CHANNELS"
                        label="Zenders"
                        >Zenders</el-option
                    >
                    <el-option
                        :key="importedFileDeleteFlag.COMMERCIALS"
                        :value="importedFileDeleteFlag.COMMERCIALS"
                        label="Commercials"
                        >Commercials</el-option
                    >
                    <el-option
                        :key="importedFileDeleteFlag.SPOTS"
                        :value="importedFileDeleteFlag.SPOTS"
                        label="Spots"
                        >Spots</el-option
                    >
                    <el-option
                        :key="importedFileDeleteFlag.SUB_ORDERS"
                        :value="importedFileDeleteFlag.SUB_ORDERS"
                        label="Suborders/Pakketten"
                        >Suborders/Pakketten</el-option
                    >
                    <el-option
                        :key="importedFileDeleteFlag.TARGET_GROUPS"
                        :value="importedFileDeleteFlag.TARGET_GROUPS"
                        label="Doelgroepen"
                        >Doelgroepen</el-option
                    >
                    <el-option
                        :key="importedFileDeleteFlag.FILE"
                        :value="importedFileDeleteFlag.FILE"
                        label="Bestand"
                        >Bestand</el-option
                    >
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button
                    type="danger"
                    :disabled="this.isDeleting"
                    @click="this.deleteImportedFile()"
                    ><i v-if="this.isDeleting" class="el-icon-loading" /> Verwijderen</el-button
                >
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script lang="ts">
import { ElMessage } from "element-plus";
import { defineComponent, PropType, reactive, Ref, ref, toRefs, watch } from "vue";
import { Store, useStore } from "vuex";

import { ImportedFileDeleteFlag } from "@/enums/importedFileDeleteFlag";
import { Request } from "@/types/request/request";
import { ImportedFileState } from "@/types/state/importedFile";
import { RootState } from "@/types/state/root";
import { ImportedFile } from "@/types/uzs/importedFile";
import { ImportMapping } from "@/types/uzs/importMapping";

const props = {
    value: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
    importedFile: {
        type: Object as PropType<ImportedFile>,
        required: true,
    },
};

export default defineComponent({
    name: "DeleteImportedFileDialog",
    props,
    setup(props, { emit }) {
        const importedFile = ref(props.importedFile ?? {}) as Ref<ImportedFile | undefined>;
        const store = useStore() as Store<RootState>;
        const state = store.state.importedFile as ImportedFileState;
        const importedFileDeleteFlag = ImportedFileDeleteFlag;

        const actions = {
            importedFile: {
                delete: (importedFile: ImportedFile, flags: Array<string> = []) =>
                    store.dispatch("importedFile/_delete", {
                        id: importedFile.id,
                        flags,
                    } as Request),
            },
        };

        const data = reactive({
            flags: [importedFileDeleteFlag.FILE, importedFileDeleteFlag.SPOTS] as Array<string>,
            isDeleting: false,
        });

        const close = (): void => {
            importedFile.value = {} as ImportedFile;
            data.isDeleting = false;

            emit("input", props.value);
        };

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        const deleteImportedFile = (): void => {
            if (!importedFile?.value) {
                return;
            }

            data.isDeleting = true;

            actions.importedFile
                .delete(importedFile.value, data.flags)
                .then(() => {
                    ElMessage({
                        message:
                            data.flags.indexOf(importedFileDeleteFlag.FILE) !== -1
                                ? importedFile?.value?.filename + " is verwijderd."
                                : "Data van " + importedFile?.value?.filename + " is verwijderd.",
                        type: "success",
                    });

                    close();
                })
                .catch(() => {
                    ElMessage({
                        message:
                            "Er is een fout opgetreden bij het verwijderen van " +
                            importedFile?.value?.filename,
                        type: "error",
                    });
                });
        };

        watch(
            () => props.importedFile,
            (value, oldValue) => {
                if (value !== oldValue) {
                    importedFile.value =
                        value ?? ({ mapping: {} as ImportMapping } as ImportedFile);
                }
            }
        );

        return {
            ...toRefs(data),
            ...toRefs(state),
            close,
            deleteImportedFile,
            dialogClosed,
            importedFile,
            importedFileDeleteFlag,
        };
    },
});
</script>
