
import { format } from "date-fns";
import { defineComponent, onMounted, PropType, reactive, toRefs } from "vue";
import { Store, useStore } from "vuex";

import { Goal } from "@/enums/goal";
import DateHelper from "@/helper/date-helper";
import config from "@/service/config";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { RootState } from "@/types/state/root";
import { Advertiser } from "@/types/uzs/advertiser";
import { Flight } from "@/types/uzs/flight";
import { LeadSpreadsheetAccess } from "@/types/uzs/leadSpreadsheetAccess";

const props = {
    value: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
};

export default defineComponent({
    name: "DownloadTemplateDialog",
    props,
    setup(props, { emit }) {
        const store = useStore() as Store<RootState>;
        const dateHelper = DateHelper;
        const actions = {
            flight: {
                list: (params: RequestParameters) => store.dispatch("flight/_list", params),
            },
            leadSpreadsheet: {
                download: (id: number) => store.dispatch("leadSpreadsheet/_download", id),
            },
        };

        const init = (): void => {
            loadFlights();
        };

        /* eslint-disable  @typescript-eslint/no-explicit-any */
        const data = reactive({
            advertisers: [] as Array<Advertiser>,
            advertiserId: null as number | null,
            advertiserIds: [] as Array<number>,
            backendUrl: config.backend,
            flights: [] as Array<Flight>,
            flightsForAdvertiser: [] as Array<Flight>,
            flightId: null as number | null,
        }) as any;

        const close = (): void => {
            emit("input", props.value);
        };

        const onAdvertiserSelected = (): void => {
            data.flightsForAdvertiser = data.flights.filter(
                (flight: Flight) => flight.uzs_advertiser_id === data.advertiserId
            );
        };

        const onDownloadLeadSpreadsheet = (): void => {
            actions.leadSpreadsheet
                .download(data.flightId)
                .then((response: LeadSpreadsheetAccess) => {
                    if (response?.url) {
                        data.flightId = null;
                        location.href = response.url;
                    }
                });
        };

        const loadFlights = (): void => {
            const startDate = DateHelper.date();
            startDate.setHours(0);
            startDate.setMinutes(0);
            startDate.setSeconds(0);

            actions.flight
                .list({
                    filter: {
                        goal: {
                            operator: "=",
                            value: Goal.LEADS,
                        },
                        start_date: {
                            operator: "<",
                            value: format(startDate, "yyyy-MM-dd"),
                        },
                    },
                    order: {
                        start_date: "desc",
                    },
                })
                .then((response: DataResult<Array<Flight>>) => {
                    data.flights = response.data as Array<Flight>;

                    for (const flight of data.flights) {
                        if (
                            !flight.uzs_advertiser ||
                            !flight.uzs_advertiser_id ||
                            data.advertiserIds.includes(flight.uzs_advertiser_id)
                        ) {
                            continue;
                        }

                        data.advertisers.push(flight.uzs_advertiser);
                        data.advertiserIds.push(flight.uzs_advertiser_id);
                    }
                });
        };

        onMounted(() => {
            init();
        });

        return {
            ...toRefs(data),
            close,
            dateHelper,
            onAdvertiserSelected,
            onDownloadLeadSpreadsheet,
        };
    },
});
