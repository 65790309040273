
import { endOfDay, format, parseISO, startOfDay } from "date-fns";
import { nl } from "date-fns/locale";
import { ElMessage, ElMessageBox } from "element-plus";
import { range } from "lodash";
import { defineComponent, inject, onMounted, Ref, ref } from "vue";
import { mapActions, Store } from "vuex";

import OptimizationScore from "@/components/campaigns/OptimizationScore.vue";
import HolidayMessage from "@/components/message/HolidayMessage.vue";
import DateHelper from "@/helper/date-helper";
import AdvertiserApiService from "@/service/api/advertiser";
import config from "@/service/config";
import { FilterRequest } from "@/types/request/filterRequest";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { DataResult } from "@/types/result/dataResult";
import { FlightState } from "@/types/state/flight";
import { Advertiser } from "@/types/uzs/advertiser";
import { Flight } from "@/types/uzs/flight";
import { Message } from "@/types/uzs/message";
import AdvertiserDialog from "@/views/campaigns/dialogs/Advertiser.vue";
import AlertsDialog from "@/views/campaigns/dialogs/Alerts.vue";
import ExportDialog from "@/views/campaigns/dialogs/Export.vue";
import FlightDialog from "@/views/campaigns/dialogs/Flight.vue";
import PropertyDialog from "@/views/campaigns/dialogs/link-data/Property.vue";
import QRCodeDialog from "@/views/campaigns/dialogs/link-data/QRCode.vue";
import SubOrderDialog from "@/views/campaigns/dialogs/link-data/SubOrder.vue";

type CurrentRow = {
    row: Flight;
    rowIndex: number;
};

type SortEvent = {
    column: string;
    order: string;
    prop: string;
};

export default defineComponent({
    name: "List",
    components: {
        HolidayMessage,
        AlertsDialog,
        SubOrderDialog,
        PropertyDialog,
        AdvertiserDialog,
        ExportDialog,
        FlightDialog,
        OptimizationScore,
        QRCodeDialog,
    },
    setup() {
        const store = inject("store") as Store<FlightState>;
        const advertisers = ref(null) as Ref<Advertiser[] | null>;
        const advertiserApi = new AdvertiserApiService();

        onMounted(() => {
            advertiserApi
                .fetchAll({
                    order: {
                        name: "asc",
                    },
                })
                .then((data) => {
                    advertisers.value = data.data;
                });
        });

        return {
            _store: store,
            advertisers,
            advertiserApi,
        };
    },
    computed: {
        page(): number {
            return this.pagination.page;
        },
        pages(): number {
            return this.flights?.pagination?.total_pages as number;
        },
        perPage(): number {
            return this.pagination.per_page;
        },
        totalBudget(): string {
            let total = 0;

            this.flights?.data?.forEach((flight) => {
                total += flight.budget ?? 0.0;
            });

            return (
                "€ " +
                total.toLocaleString("nl", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                })
            );
        },
    },
    data() {
        return {
            filter: {} as FilterRequest,
            order: {
                start_date: "desc",
                country: "asc",
                name: "asc",
            } as OrderRequest,
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            search: undefined as string | undefined,
            searchTimeout: undefined as undefined | unknown,
            flight: undefined as Flight | undefined,
            flights: undefined as DataResult<Flight[]> | undefined,
            advertiserIds: [] as number[],
            alertsDialogDisabled: false,
            showAdvertiserDialog: false,
            showAlertsDialog: false,
            showExportDialog: false,
            showFlightDialog: false,
            showLinkSubOrderDialog: false,
            showLinkPropertyDialog: false,
            showQRCodeDialog: false,
            currentAdvertiser: null as Advertiser | null | undefined,
            currentFlight: null as Flight | null,
            currentFlightId: null as number | null,
            currentPage: undefined as number | undefined,
            loading: true,
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            showFilterModal: false,
            sortingEnabled: false,
            flashMessages: [] as Message[],
            year: null as null | number,
            years: range(
                new Date().getMonth() > 10
                    ? new Date().getFullYear() + 1
                    : new Date().getFullYear(),
                2016
            ),
        };
    },
    mounted() {
        this.order = {
            start_date: "desc",
            country: "asc",
            name: "asc",
        };

        Object.assign(this.pagination, {
            per_page: 25,
        });

        this.update();
    },
    methods: {
        ...mapActions("flight", [
            "_activate",
            "_deactivate",
            "_delete",
            "_details",
            "_list",
            "_store",
        ]),
        getDatePeriod(value: CurrentRow) {
            const startDate = DateHelper.date(value.row.start_date ?? "now");
            const endDate = DateHelper.date(value.row.end_date);

            let startDay = format(startDate, "d");
            const endDay = format(endDate, "d");

            let startMonth =
                " " + format(startDate, "LLL", { locale: nl }).toLowerCase().replace(".", "");

            const endMonth =
                " " + format(endDate, "LLL", { locale: nl }).toLowerCase().replace(".", "");

            let startYear = " " + format(startDate, "yyyy");
            const endYear = " " + format(endDate, "yyyy");

            if (startDay === endDay && startMonth === endMonth && startYear === endYear) {
                startDay = "";
            }

            if (startMonth === endMonth) {
                startMonth = "";
            }

            if (startYear === endYear) {
                startYear = "";
            }

            return (
                startDay +
                startMonth +
                startYear +
                (startDay === "" && startMonth === "" && startYear === "" ? "" : " - ") +
                endDay +
                endMonth +
                endYear
            );
        },
        getBudget(value: CurrentRow): string {
            return (
                "€ " +
                (value.row.budget ?? 0).toLocaleString("nl", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                })
            );
        },
        getRowClassName(value: CurrentRow) {
            let className = "row " + value.row.id;

            if (this.flightIsActive(value)) {
                className += " active";
            }

            if (this.flightIsUpcoming(value)) {
                className += " upcoming";
            }

            return className;
        },
        goToPage(page: number) {
            this.currentPage = page;
            this.pagination.page = page;
            this.update();
        },
        pageSizeChange(pageSize: number) {
            this.pagination.per_page = pageSize;
            this.update();
        },
        routeToDetails($event: Event, row: Flight) {
            $event.stopImmediatePropagation();

            this.$router.push({
                name: "Details",
                params: {
                    id: row.id,
                },
            });
        },
        sortingChanged($event: SortEvent) {
            const property = $event.prop?.length ? $event.prop : null;
            const direction = $event?.order === "descending" ? "desc" : "asc";

            if (!property || !this.sortingEnabled || !this.flights || !this.order) {
                return;
            }

            this.order = {
                [property]: direction,
            };

            this.update();
        },
        newExport(value?: CurrentRow): void {
            this.currentFlight = {} as Flight;

            if (value) {
                this.currentFlight = value.row;
            }

            this.showExportDialog = true;
        },
        onExportDialogClosed(): void {
            this.currentFlight = {} as Flight;
            this.showExportDialog = false;
        },
        onFlightDialogClosed(changed: boolean): void {
            this.currentFlight = {} as Flight;
            this.showFlightDialog = false;

            if (changed) {
                this.update();
            }
        },
        onQRCodeDialogClosed() {
            this.currentFlight = {} as Flight;
            this.showQRCodeDialog = false;
        },
        deleteFlight(value: CurrentRow): void {
            ElMessageBox.confirm(
                "Weet je zeker dat je " + value.row.name + " wilt verwijderen?",
                "Verwijderen?",
                {
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nee",
                    type: "warning",
                }
            ).then(() => {
                this._delete(value.row.id)
                    .then((result) => {
                        this.update();

                        if (!result.success) {
                            throw new Error();
                        }

                        ElMessage({
                            message: value.row.name + " is verwijderd.",
                            type: "success",
                        });
                    })
                    .catch(() => {
                        ElMessage({
                            message: "Er is een onbekende fout opgetreden.",
                            type: "error",
                        });
                    });
            });
        },
        changeRowActivation(value: CurrentRow) {
            if (!value.row.active) {
                this._deactivate(value.row.id);
                return;
            }

            this._activate(value.row.id);
        },
        onAdvertiserDialogClosed(changed: boolean): void {
            this.currentAdvertiser = null;
            this.showAdvertiserDialog = false;

            if (changed) {
                this.update();
            }
        },
        newAdvertiser(): void {
            this.showAdvertiserDialog = true;
        },
        editAdvertiser(value: CurrentRow): void {
            this.currentAdvertiser = value.row.uzs_advertiser;
            this.showAdvertiserDialog = true;
        },
        onAlertsDialogClosed(): void {
            this.currentFlight = {} as Flight;
            this.showAlertsDialog = false;
            this.alertsDialogDisabled = false;
        },
        showAlerts(value: CurrentRow): void {
            this.alertsDialogDisabled = true;
            this.currentFlight = value.row as Flight;
            this.showAlertsDialog = true;
        },
        flightIsActive(value: CurrentRow): boolean {
            const currentDate = new Date();
            const startDate = startOfDay(parseISO(value.row.start_date));
            const endDate = endOfDay(parseISO(value.row.end_date));

            return currentDate >= startDate && currentDate <= endDate;
        },
        flightIsUpcoming(value: CurrentRow): boolean {
            const currentDate = new Date();
            const startDate = startOfDay(parseISO(value.row.start_date));

            return currentDate < startDate;
        },
        flightHasImportantErrors(value: CurrentRow): boolean {
            const important = ["importeer nieuwe uitzenddata"];

            for (const message of important) {
                if (value.row.alert_summary.toLowerCase().indexOf(message) !== -1) {
                    return true;
                }
            }

            return false;
        },
        onSubOrderDialogClosed(changed: boolean): void {
            this.currentFlight = {} as Flight;
            this.showLinkSubOrderDialog = false;

            if (changed) {
                this.update();
            }
        },
        linkSubOrders(value: CurrentRow): void {
            this.currentFlight = value.row;
            this.showLinkSubOrderDialog = true;
        },
        newFlight(): void {
            this.currentFlight = {} as Flight;
            this.showFlightDialog = true;
        },
        editFlight(value: CurrentRow): void {
            this.currentFlight = value.row;
            this.showFlightDialog = true;
        },
        onPropertyDialogClosed(changed: boolean): void {
            this.currentFlight = {} as Flight;
            this.showLinkPropertyDialog = false;

            if (changed) {
                this.update();
            }
        },
        linkProperty(value: CurrentRow): void {
            this.currentFlight = value.row;
            this.showLinkPropertyDialog = true;
        },
        editQRCode(value: CurrentRow): void {
            this.currentFlight = value.row;
            this.showQRCodeDialog = true;
        },
        onSearchTermChanged(): void {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout as number);
            }

            this.searchTimeout = setTimeout(() => this.update(), 500);
        },
        onSelectedAdvertiserChanged(): void {
            Object.assign(this.filter, {
                uzs_advertiser_id: {
                    operator: "IN",
                    value: this.advertiserIds,
                },
            });

            this.update();
        },
        onSelectedYearChanged() {
            if (!this.year) {
                Object.assign(this.filter, {
                    start_date: undefined,
                });

                this.update();
                return;
            }

            Object.assign(this.filter, {
                start_date: {
                    operator: "BETWEEN",
                    value: [this.year + "-01-01", this.year + "-12-31"],
                },
            });

            this.update();
        },
        update() {
            this.loading = true;

            this._list({
                filter: this.filter,
                order: this.order,
                pagination: this.pagination,
                search: this.search,
            }).then((response) => {
                this.loading = false;
                this.sortingEnabled = true;
                this.flights = response;
            });
        },
    },
});
