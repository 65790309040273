<template>
    <span :class="getClassName()">{{ getValue() }}</span>
</template>
<script lang="ts">
import { isAfter, startOfDay, subBusinessDays } from "date-fns";
import { defineComponent, PropType, Ref, ref, watch } from "vue";

import PriceHelper from "@/helper/price-helper";

const props = {
    value: {
        type: Number as PropType<number>,
        required: true,
    },
    lastUpdate: {
        type: Date as PropType<Date>,
    },
};

export default defineComponent({
    name: "CpaValue",
    props,
    setup(props) {
        const cpa = ref(props.value) as Ref<number>;
        const lastUpdate = ref(props.lastUpdate) as Ref<Date>;

        function getClassName(): string {
            if (!lastUpdate.value) {
                return "";
            }

            let lastWorkDay = startOfDay(subBusinessDays(new Date(), 1));

            if (isAfter(lastUpdate.value, lastWorkDay)) {
                return "";
            }

            return "has--warning is--bold";
        }

        function getValue(): string {
            return PriceHelper.display(cpa.value, 0);
        }

        watch(
            () => props.value,
            (value) => {
                cpa.value = value as number;
            }
        );

        watch(
            () => props.lastUpdate,
            (value) => {
                lastUpdate.value = value as Date;
            }
        );

        return {
            getClassName,
            getValue,
        };
    },
});
</script>
