<template>
    <span :class="getClassName()">{{ getValue() }}</span>
</template>
<script lang="ts">
import { defineComponent, PropType, Ref, ref, watch } from "vue";

import PercentageHelper from "@/helper/percentage-helper";

const props = {
    value: {
        type: Number as PropType<number>,
        required: true,
    },
    isFulfilled: {
        type: Boolean as PropType<boolean>,
        default: false,
    },
    round: {
        type: Boolean as PropType<boolean>,
        default: false,
    },
};

export default defineComponent({
    name: "TargetPercentage",
    props,
    setup(props) {
        const targetProgress = ref(props.value) as Ref<number>;
        const isFulfilled = ref(props.isFulfilled ?? false) as Ref<boolean>;
        const round = ref(props.round ?? false) as Ref<boolean>;

        function getClassName(): string {
            const value = Math.round(targetProgress.value);

            if (value >= 100 && isFulfilled.value) {
                return "has--success is--bold";
            }

            if (value < 100) {
                return "has--error is--bold";
            }

            return "";
        }

        function getValue(): string {
            return PercentageHelper.display(targetProgress.value, round.value);
        }

        watch(
            () => props.value,
            (value) => {
                targetProgress.value = value as number;
            }
        );

        watch(
            () => props.isFulfilled,
            (value) => {
                isFulfilled.value = value as boolean;
            }
        );

        watch(
            () => props.round,
            (value) => {
                round.value = value as boolean;
            }
        );

        return {
            getClassName,
            getValue,
        };
    },
});
</script>
