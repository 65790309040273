
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs, watch } from "vue";

import { AlertClass } from "@/enums/alertClass";
import { AlertMessage } from "@/enums/alertMessage";
import { AlertType } from "@/enums/alertType";
import { Alert } from "@/types/uzs/alert";

const props = {
    alerts: {
        type: Array as PropType<Array<Alert>>,
        required: true,
    },
};

export default defineComponent({
    name: "AlertErrorsTable",
    props,
    setup(props) {
        const alerts = ref(props.alerts) as Ref<Array<Alert>>;

        const data = reactive({
            errors: [] as Alert[],
            tableData: [] as Array<{ message: string }>,
        });

        const init = (): void => {
            data.errors = alerts.value?.filter((alert) => alert.type === AlertType.Error) ?? [];

            loadTableData();
        };

        const loadTableData = (): void => {
            data.tableData = [];

            for (const error of data.errors) {
                let message = "";

                if (error.message === AlertClass.WarningTipConnection) {
                    message = AlertMessage.TipConnection;
                }

                if (error.message === AlertClass.ErrorNoGrpData) {
                    message = AlertMessage.NoGrpData;
                }

                if (error.message === AlertClass.ErrorDataNotRefreshed) {
                    const operators = (error.data ?? []) as string[];

                    message = AlertMessage.DataNotRefreshed.replace(
                        "[operator]",
                        "<b>" + operators.join("</b>, <b>") + "</b>"
                    );

                    if (operators.length >= 2) {
                        message =
                            message.substr(0, message.lastIndexOf("</b>, <b>")) +
                            "</b> en <b>" +
                            message.substr(message.lastIndexOf("<b>") + "<b>".length);
                    }
                }

                if (error.message === AlertClass.ErrorCronTurnedOff) {
                    message = AlertMessage.CronTurnedOff;
                }

                if (error.message === AlertClass.ErrorFlightWithoutActiveGoogleAnalyticsProperty) {
                    message = AlertMessage.FlightWithoutActiveGoogleAnalyticsProperty;
                }

                if (error.message === AlertClass.ErrorFlightWithoutBudget) {
                    message = AlertMessage.FlightWithoutBudget;
                }

                if (message.length === 0) {
                    continue;
                }

                data.tableData.push({
                    message,
                });
            }
        };

        onMounted(() => {
            init();
        });

        watch(
            () => props.alerts,
            (value, oldValue) => {
                if (value !== oldValue) {
                    alerts.value = value ?? [];
                    init();
                }
            }
        );

        return {
            ...toRefs(data),
            close,
        };
    },
});
