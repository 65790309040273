
import { format } from "date-fns";
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs, watch } from "vue";

import GrpValue from "@/components/campaigns/GrpValue.vue";
import LeadsPerGrpValue from "@/components/campaigns/LeadsPerGrpValue.vue";
import LeadsValue from "@/components/campaigns/LeadsValue.vue";
import { AlertClass } from "@/enums/alertClass";
import { Goal } from "@/enums/goal";
import DateHelper from "@/helper/date-helper";
import GrpHelper from "@/helper/grp-helper";
import StringHelper from "@/helper/string-helper";
import { Alert } from "@/types/uzs/alert";
import { Spot } from "@/types/uzs/alert/spot";
import { FlightDailyGrp } from "@/types/uzs/flightDailyGrp";
import { Holiday } from "@/types/uzs/settings/holiday/holiday";

const props = {
    alert: {
        type: Object as PropType<Alert>,
        required: true,
    },
    alerts: {
        type: Array as PropType<Array<Alert>>,
        required: true,
    },
};

export default defineComponent({
    name: "AlertDailyGrpTable",
    components: { LeadsPerGrpValue, LeadsValue, GrpValue },
    computed: {
        Goal() {
            return Goal;
        },
    },
    props,
    setup(props) {
        const alert = ref(props.alert) as Ref<Alert>;
        const alerts = ref(props.alerts) as Ref<Array<Alert>>;
        const grpHelper = GrpHelper;
        const dateHelper = DateHelper;
        const stringHelper = StringHelper;

        const data = reactive({
            averageGrp: 0.0,
            averageLeads: 0.0,
            totalGrp: 0.0,
            totalLeads: 0,
            totalLeadsAllocated: 0,
            dates: [] as string[],
            datesWithError: [] as string[],
            datesWithWarning: [] as string[],
            datesWithHoliday: [] as string[],
            datesWithoutSpots: [] as string[],
            excludedDates: [] as string[],
            holidays: [] as Holiday[],
            operators: [] as string[],
        });

        const init = (): void => {
            data.datesWithWarning = [];
            data.datesWithError = [];

            collectGrp();

            if (data.totalGrp > 0.0) {
                collectDates();
                calculateGrpWarnings();
                calculateSpotWrongDayErrors();
                calculateSpotLittleSpreadingErrors();
                collectOperators();
            }
        };

        const calculateGrpWarnings = (): void => {
            let totalGrp = 0.0;
            let totalLeads = 0;
            let totalLeadsAllocated = 0;

            for (const dailyGrp of alert.value?.uzs_flight?.uzs_flight_daily_grp ?? []) {
                if (dailyGrp.is_date_excluded) {
                    continue;
                }

                totalGrp +=
                    dailyGrp?.holiday?.is_operator_closed &&
                    dailyGrp?.holiday?.date === format(new Date(), "yyyy-MM-dd")
                        ? dailyGrp.predicted_grp
                        : dailyGrp.rating;

                totalLeads += dailyGrp.leads;
                totalLeadsAllocated = totalLeads;
            }

            let progressedDays = null;
            let rawNumberOfDays = data.dates.length - data.excludedDates.length;
            let totalGrpUntilDate = null;

            if (alert?.value?.uzs_flight?.uzs_flight_statistics) {
                rawNumberOfDays = alert.value.uzs_flight.uzs_flight_statistics.number_of_days;
            }

            if (
                alert?.value?.uzs_flight?.goal === Goal.LEADS &&
                alert?.value?.uzs_flight?.uzs_flight_statistics
            ) {
                progressedDays = Math.floor(
                    (alert.value.uzs_flight.uzs_flight_statistics.number_of_days / 100) *
                        alert.value.uzs_flight.uzs_flight_statistics.duration_progress
                );

                totalGrpUntilDate =
                    (totalGrp / 100) * alert.value.uzs_flight.uzs_flight_statistics.grp_progress;
            }

            console.info(rawNumberOfDays);

            const numberOfDays = progressedDays ?? rawNumberOfDays;

            if (
                alert?.value?.uzs_flight?.uzs_flight_statistics?.total_leads &&
                alert.value.uzs_flight.uzs_flight_statistics.total_leads > 0
            ) {
                totalLeads = alert.value.uzs_flight.uzs_flight_statistics.total_leads;
            }

            data.averageGrp = rawNumberOfDays !== 0 ? totalGrp / rawNumberOfDays : 0;

            if (numberOfDays > 0) {
                data.averageLeads = Math.round(totalLeadsAllocated / numberOfDays);
            }

            data.totalLeads = totalLeads;
            data.totalLeadsAllocated = totalLeadsAllocated;

            for (const dailyGrp of alert.value?.uzs_flight?.uzs_flight_daily_grp ?? []) {
                if (
                    getGrpForDate(dailyGrp.date) > data.averageGrp / 2 &&
                    getGrpForDate(dailyGrp.date) < data.averageGrp * 2
                ) {
                    continue;
                }

                data.datesWithWarning.push(DateHelper.display(dailyGrp.date));
            }
        };

        const calculateSpotWrongDayErrors = (): void => {
            const filteredAlerts = alerts.value?.filter(
                (alert) => alert.message === AlertClass.WarningSpotWrongDay
            );

            if (filteredAlerts.length === 0) {
                return;
            }

            for (const spotData of filteredAlerts[0].data as Spot[]) {
                data.datesWithError.push(DateHelper.display(spotData.scheduled_date_time));
            }
        };

        const calculateSpotLittleSpreadingErrors = (): void => {
            const filteredAlerts = alerts.value?.filter(
                (alert) => alert.message === AlertClass.WarningTooLittleSpreading
            );

            if (filteredAlerts.length === 0) {
                return;
            }

            for (const spotData of filteredAlerts[0].data as Spot[]) {
                data.datesWithError.push(DateHelper.display(spotData.scheduled_date_time));
            }
        };

        const collectDates = (): void => {
            if (!alert.value?.uzs_flight?.uzs_flight_daily_grp) {
                return;
            }

            const dates: string[] = [];
            const datesWithoutSpots: string[] = [];
            const excludedDates: string[] = [];

            for (const dailyGrp of alert.value.uzs_flight.uzs_flight_daily_grp) {
                if (!dates.includes(dailyGrp.date)) {
                    dates.push(dailyGrp.date);
                }

                if (!datesWithoutSpots.includes(dailyGrp.date) && dailyGrp.number_of_spots === 0) {
                    datesWithoutSpots.push(dailyGrp.date);
                }

                if (!excludedDates.includes(dailyGrp.date) && dailyGrp.is_date_excluded) {
                    excludedDates.push(dailyGrp.date);
                }

                if (dailyGrp.holiday?.name && dailyGrp.holiday?.is_affecting_viewing_behavior) {
                    data.datesWithHoliday.push(dailyGrp.date);
                    data.holidays.push(dailyGrp.holiday);
                }
            }

            data.dates = dates.sort((a, b) => {
                if (a === b) {
                    return 0;
                }

                return a > b ? 1 : -1;
            });

            data.datesWithoutSpots = datesWithoutSpots;
            data.excludedDates = excludedDates;
        };

        const collectGrp = (): void => {
            if (!alert.value?.uzs_flight?.uzs_flight_daily_grp) {
                return;
            }

            let grps = 0.0;

            for (const dailyGrp of alert.value.uzs_flight.uzs_flight_daily_grp) {
                grps +=
                    dailyGrp?.holiday?.is_operator_closed &&
                    dailyGrp?.holiday?.date === format(new Date(), "yyyy-MM-dd")
                        ? dailyGrp.predicted_grp
                        : dailyGrp.rating;
            }

            data.totalGrp = grps;
        };

        const collectOperators = (): void => {
            if (!alert.value?.uzs_flight?.uzs_flight_daily_grp) {
                return;
            }

            const operators: string[] = [];

            for (const dailyGrp of alert.value.uzs_flight.uzs_flight_daily_grp) {
                if (!operators.includes(dailyGrp.operator)) {
                    operators.push(dailyGrp.operator);
                }
            }

            operators.sort();

            data.operators = operators;
        };

        const getClassNamesForDate = (
            date: string,
            grp: number | null = null,
            dateOnly = false
        ): string[] => {
            const classNames = [];

            if (DateHelper.isDateTodayOrLater(date)) {
                classNames.push("is--greyed-out");
            }

            if (
                data.excludedDates.includes(date) ||
                (data.datesWithError.includes(DateHelper.display(date)) &&
                    (grp === null || grp > 0.0))
            ) {
                classNames.push("has--error");
            }

            if (dateOnly && data.datesWithHoliday.includes(date)) {
                classNames.push("has--holiday");
            }

            return classNames;
        };

        const getAltTextForDate = (date: string): string => {
            let altText = "";

            if (data.datesWithHoliday.includes(date)) {
                for (const holiday of data.holidays) {
                    if (holiday.date === format(DateHelper.date(date), "yyyy-MM-dd")) {
                        altText = holiday.name;
                        break;
                    }
                }
            }

            return altText;
        };

        const getClassNamesForDailyTotalOnDate = (
            date: string,
            grp: number | null = null
        ): string[] => {
            const classNames = [];

            if (DateHelper.isDateTodayOrLater(date)) {
                classNames.push("is--greyed-out");
            }

            if (isDateWithWarnings(date) && (grp === null || grp > 0.0)) {
                classNames.push("has--warning");
            }

            if (isDateWithErrors(date) || grp === 0) {
                classNames.push("has--error");
            }

            return classNames;
        };

        const getGrpForOperator = (operator: string): number => {
            if (!alert.value?.uzs_flight?.uzs_flight_daily_grp) {
                return 0.0;
            }

            let grps = 0.0;

            const dailyGrps = alert.value.uzs_flight.uzs_flight_daily_grp.filter(
                (dailyGrp) => dailyGrp.operator === operator
            );

            for (const dailyGrp of dailyGrps) {
                grps +=
                    dailyGrp?.holiday?.is_operator_closed &&
                    dailyGrp?.holiday?.date === format(new Date(), "yyyy-MM-dd")
                        ? dailyGrp.predicted_grp
                        : dailyGrp.rating;
            }

            return grps;
        };

        const getGrpForDate = (date: string): number => {
            if (!alert.value?.uzs_flight?.uzs_flight_daily_grp) {
                return 0.0;
            }

            let grps = 0.0;

            const dailyGrps: FlightDailyGrp[] = alert.value?.uzs_flight?.uzs_flight_daily_grp.filter(
                (dailyGrp) => dailyGrp.date === date
            );

            for (const dailyGrp of dailyGrps) {
                grps +=
                    dailyGrp?.holiday?.is_operator_closed &&
                    dailyGrp?.holiday?.date === format(new Date(), "yyyy-MM-dd")
                        ? dailyGrp.predicted_grp
                        : dailyGrp.rating;
            }

            return grps;
        };

        const getLeadsForDate = (date: string): number => {
            if (!alert.value?.uzs_flight?.uzs_flight_daily_grp) {
                return 0;
            }

            let leads = 0;

            const dailyGrps: FlightDailyGrp[] = alert.value?.uzs_flight?.uzs_flight_daily_grp.filter(
                (dailyGrp) => dailyGrp.date === date
            );

            for (const dailyGrp of dailyGrps) {
                leads += dailyGrp.leads;
            }

            return leads;
        };

        const getLeadsPerGrpForDate = (date: string): number => {
            const leads = getLeadsForDate(date);
            const grp = getGrpForDate(date);

            return grp > 0 ? leads / grp : 0;
        };

        const getGrpForDateAndOperator = (date: string, operator: string): number => {
            if (!alert.value?.uzs_flight?.uzs_flight_daily_grp) {
                return 0.0;
            }

            let grps = 0.0;

            const dailyGrps: FlightDailyGrp[] = alert.value?.uzs_flight?.uzs_flight_daily_grp.filter(
                (dailyGrp) => dailyGrp.date === date && dailyGrp.operator === operator
            );

            for (const dailyGrp of dailyGrps) {
                grps +=
                    dailyGrp?.holiday?.is_operator_closed &&
                    dailyGrp?.holiday?.date === format(new Date(), "yyyy-MM-dd")
                        ? dailyGrp.predicted_grp
                        : dailyGrp.rating;
            }

            return grps;
        };

        function getTotalLeads(): number {
            return data.totalLeads;
        }

        function getTotalLeadsAllocated(): number {
            return data.totalLeadsAllocated;
        }

        function getTotalLeadsPerGrp(): number {
            return data.totalGrp !== 0.0 ? data.totalLeadsAllocated / data.totalGrp : 0;
        }

        function isDateWithWarnings(date: string): boolean {
            return data.datesWithWarning.includes(DateHelper.display(date));
        }

        function isDateWithErrors(date: string): boolean {
            return (
                data.excludedDates.includes(date) ||
                data.datesWithError.includes(DateHelper.display(date))
            );
        }

        onMounted(() => {
            init();
        });

        watch(
            () => props.alert,
            (value, oldValue) => {
                if (value !== oldValue) {
                    alert.value = value ?? ({} as Alert);
                    init();
                }
            }
        );

        watch(
            () => props.alerts,
            (value, oldValue) => {
                if (value !== oldValue) {
                    alerts.value = value ?? [];
                    init();
                }
            }
        );

        return {
            ...toRefs(data),
            alert,
            dateHelper,
            getAltTextForDate,
            getClassNamesForDate,
            getClassNamesForDailyTotalOnDate,
            getGrpForOperator,
            getGrpForDate,
            getLeadsForDate,
            getLeadsPerGrpForDate,
            getGrpForDateAndOperator,
            getTotalLeads,
            getTotalLeadsAllocated,
            getTotalLeadsPerGrp,
            grpHelper,
            isDateWithErrors,
            isDateWithWarnings,
            stringHelper,
        };
    },
});
