import axios from "axios";
import { ActionContext } from "vuex";

import config from "@/service/config";
import { DataResult } from "@/types/result/dataResult";
import { Channel } from "@/types/screen-force/channel";
import { ChannelState } from "@/types/state/channel";
import { RootState } from "@/types/state/root";

const initialState: ChannelState = {
    columns: undefined,
    filter: {},
    order: {},
    pagination: {
        page: 1,
        per_page: config.pagination.per_page,
    },
    search: undefined,
    channel: undefined,
    channels: undefined,
};

const ChannelModule = {
    namespaced: true,
    state: initialState,
    getters: {},
    mutations: {},
    actions: {
        _store(
            context: ActionContext<ChannelState, RootState>,
            data: Channel
        ): Promise<DataResult<Channel>> {
            return axios
                .post(config.backend + "/api/screen-force/channel", data)
                .then((response) => response?.data as DataResult<Channel>)
                .then((response) => {
                    context.state.channel = response;
                    return response;
                });
        },
    },
};

export default ChannelModule;
