
import { computed, defineComponent, onMounted, reactive, toRefs } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import { Store, useStore } from "vuex";

import HolidayMessage from "@/components/message/HolidayMessage.vue";
import DateHelper from "@/helper/date-helper";
import { Copy } from "@/service/clipboard/copy";
import config from "@/service/config";
import { Authentication } from "@/types/credentials/authentication";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { ImportedFileState } from "@/types/state/importedFile";
import { RootState } from "@/types/state/root";
import { ImportedFile } from "@/types/uzs/importedFile";
import DeleteImportedFileDialog from "@/views/import/dataset/dialogs/DeleteImportedFile.vue";
import ImportedFileMappingDialog from "@/views/import/dataset/dialogs/ImportedFileMapping.vue";

export default defineComponent({
    name: "List",
    components: { HolidayMessage, DeleteImportedFileDialog, ImportedFileMappingDialog },
    setup() {
        const store = useStore() as Store<RootState>;
        const auth = store.state.authentication as Authentication;
        const state = store.state.importedFile as ImportedFileState;
        const textCopier = new Copy();

        const actions = {
            importedFile: {
                list: (params: RequestParameters) => store.dispatch("importedFile/_list", params),
                delete: (id: number) => store.dispatch("importedFile/_delete", id),
            },
        };

        /* eslint-disable  @typescript-eslint/no-explicit-any */
        const data = reactive({
            backendUrl: config.backend,
            currentImportedFile: {} as ImportedFile,
            currentPage: 1,
            isDeleting: -1,
            isUploading: false,
            loading: true,
            filter: {},
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            order: {
                created_at: "desc",
            } as OrderRequest,
            page: computed(() => data.pagination?.page as number | undefined),
            pages: computed(
                () => state.importedFiles?.pagination?.total_pages as number | undefined
            ),
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            perPage: computed(() => state.pagination?.per_page as number | undefined),
            showDeleteImportedFileDialog: false,
            showImportedFileMappingDialog: false,
            updateTimeout: null,
        }) as any;

        const closeDeleteImportedFile = (): void => {
            data.isDeleting = -1;
            data.showDeleteImportedFileDialog = false;
            update();
        };

        const closeImportedFileMapping = (): void => {
            data.showImportedFileMappingDialog = false;
            update();
        };

        const editImportedFileMapping = (file: ImportedFile): void => {
            data.currentImportedFile = file;
            data.showImportedFileMappingDialog = true;
        };

        const getFormattedDate = (date: string | null): string => {
            if (!date) {
                return "";
            }

            return DateHelper.dateTime(date);
        };

        const goToPage = (page: number) => {
            if (!data.pagination) {
                return;
            }

            data.currentPage = page;
            data.pagination.page = page;

            update();
        };

        const pageSizeChange = (pageSize: number) => {
            if (!data.pagination) {
                return;
            }

            data.pagination.per_page = pageSize;

            update();
        };

        const update = (loading = true) => {
            data.loading = loading;
            actions.importedFile
                .list({
                    filter: data.filter,
                    pagination: data.pagination,
                    order: data.order,
                })
                .then(() => {
                    data.loading = false;
                });
        };

        const updateOnInterval = () => {
            if (data.updateTimeout) {
                clearInterval(data.updateTimeout);
            }

            if (!data.loading) {
                update(false);
            }

            data.updateTimeout = setTimeout(() => {
                updateOnInterval();
            }, 3000);
        };

        onBeforeRouteLeave(() => {
            if (data.updateTimeout) {
                clearTimeout(data.updateTimeout);
            }
        });

        const onBusyUploading = () => {
            data.isUploading = true;
        };

        const onDelete = (file: ImportedFile) => {
            data.currentImportedFile = file;
            data.isDeleting = file.id;
            data.showDeleteImportedFileDialog = true;
        };

        const onDoneUploading = () => {
            data.isUploading = false;

            update();
        };

        onMounted(() => {
            state.importedFiles = {} as DataResult<ImportedFile[]>;

            Object.assign(data.pagination, {
                per_page: config.pagination.per_page,
            });

            Object.assign(data.order, {
                created_at: "desc",
            });

            update();
            updateOnInterval();
        });

        onBeforeRouteLeave(() => {
            if (data.updateTimeout) {
                clearInterval(data.updateTimeout);
            }
        });

        return {
            ...toRefs(data),
            ...toRefs(state),
            accessToken: auth.token,
            closeDeleteImportedFile,
            closeImportedFileMapping,
            editImportedFileMapping,
            getFormattedDate,
            goToPage,
            pageSizeChange,
            textCopier,
            update,
            onBusyUploading,
            onDelete,
            onDoneUploading,
        };
    },
});
