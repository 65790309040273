import axios from "axios";
import { getUnixTime } from "date-fns";
import { Ref, ref } from "vue";
import { ActionContext } from "vuex";

import JWTHelper from "@/helper/jwt-helper";
import config from "@/service/config";
import { Authentication } from "@/types/credentials/authentication";
import { User } from "@/types/credentials/user";
import { RootState } from "@/types/state/root";

const AuthenticationModule = {
    namespaced: true,
    getters: {},
    mutations: {},
    actions: {
        checkIfTokenExpired(context: ActionContext<RootState, RootState>): Promise<boolean> {
            const authentication = ref(context.rootState.authentication) as Ref<Authentication>;
            const expired = ref(true) as Ref<boolean>;
            const jwt = JWTHelper.decode(authentication?.value?.token ?? "");
            const timestamp = getUnixTime(new Date());

            if (jwt?.expires_at && jwt.expires_at >= timestamp) {
                expired.value = false;
            }

            if (expired.value) {
                context.dispatch("logout");
            }

            return new Promise((resolve) => {
                resolve(expired.value);
            });
        },
        login(
            context: ActionContext<RootState, RootState>,
            credentials: User
        ): Promise<Authentication> {
            return axios
                .post(config.backend + "/api/access", {
                    username: credentials.email,
                    password: credentials.password,
                })
                .then((response) => response?.data as Authentication)
                .then((response) => {
                    const jwt = JWTHelper.decode(response?.token ?? "");

                    if (jwt?.user) {
                        response.user = jwt.user;
                    }

                    context.commit("updateAuthentication", response, {
                        root: true,
                    });

                    if (!response.token) {
                        throw new Error("not logged in");
                    }

                    return response;
                });
        },
        logout(context: ActionContext<RootState, RootState>): void {
            context.commit("updateAuthentication", undefined, {
                root: true,
            });
        },
    },
};

export default AuthenticationModule;
