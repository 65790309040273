
import { ElMessage } from "element-plus";
import { defineComponent } from "vue";
import { NavigationGuardNext, RouteLocation, useRouter } from "vue-router";
import { mapActions, mapState, useStore } from "vuex";

import { routes } from "@/router";
import NotificationApiService from "@/service/api/notification";
import AccountApiService from "@/service/api/user/account";
import { RouteRecord } from "@/types/router/route-record";
import { Notification } from "@/types/uzs/notification";
import { Account } from "@/types/uzs/user/account";

export default defineComponent({
    name: "App",
    setup() {
        const router = useRouter();
        const store = useStore();

        router.beforeResolve(
            (to: RouteLocation, from: RouteLocation, next: NavigationGuardNext) => {
                if (to.name === "login") {
                    next();
                    return;
                }

                store.dispatch("authentication/checkIfTokenExpired").then((expired: boolean) => {
                    if (expired) {
                        next({ name: "login" });

                        ElMessage({
                            message: "Je bent niet ingelogd.",
                            type: "info",
                        });

                        return;
                    }

                    next();
                });
            }
        );

        return {
            store,
        };
    },
    computed: {
        ...mapState(["authentication"]),
    },
    data() {
        return {
            areFlightMessagesReloading: false,
            checkLoginInterval: null as ReturnType<typeof setInterval> | null,
            notification: null as Notification | null,
            userAccount: null as Account | null,
        };
    },
    mounted() {
        this.checkLogin();

        const notificationApi = new NotificationApiService();
        notificationApi
            .list()
            .then((notification) => (this.notification = notification.data as Notification));
    },
    updated() {
        const notificationApi = new NotificationApiService();

        notificationApi
            .list()
            .then((notification) => (this.notification = notification.data as Notification));

        if (this.authentication?.user) {
            this.userAccount = this.authentication.user;
        }
    },
    methods: {
        ...mapActions("authentication", ["logout"]),
        ...mapActions("user", ["ping"]),
        checkLogin() {
            const isLoginRoute =
                this.$router.currentRoute.value.path === "/" ||
                this.$router.currentRoute.value.name === "login";

            if (!isLoginRoute) {
                this.ping()
                    .then((result) => {
                        if (result.success) {
                            return;
                        }

                        this.logoutUser(!isLoginRoute);
                    })
                    .catch(() => this.logoutUser(!isLoginRoute));
            }

            if (this.checkLoginInterval) {
                clearTimeout(this.checkLoginInterval);
            }

            this.checkLoginInterval = setInterval(() => this.checkLogin(), 30000);
        },
        async getAccountInformation(): Promise<Account | null> {
            if (!this.authentication?.user_identifier) {
                return null;
            }

            const accountApi = new AccountApiService();
            const information = await accountApi.fetchInformation();

            return information?.data ?? null;
        },
        getRoutes(): RouteRecord[] {
            return routes.filter((value) => value.showInNavigation === true);
        },
        getChildRoutes(routeName: string): RouteRecord[] | undefined {
            const currentRoute = routes.filter(
                (value) => value.name === routeName && value.showInNavigation === true
            );

            if (currentRoute?.length) {
                return currentRoute[0].children;
            }
        },
        goToCampaigns() {
            this.$router.push({ name: "campaigns" });
        },
        goToEventLog() {
            this.$router.push({ name: "events" });
        },
        goToDebugger() {
            this.$router.push({ name: "debugger" });
        },
        goToImport() {
            this.$router.push({ name: "import" });
        },
        goToExport() {
            this.$router.push({ name: "export" });
        },
        hideMenu() {
            const menuCheckbox = document.querySelector("input#mobile_menu") as HTMLInputElement;

            if (!menuCheckbox) {
                return;
            }

            setTimeout(() => (menuCheckbox.checked = false), 500);
        },
        isActiveRoute(name: string): boolean {
            return this.$route.name === name;
        },
        isAuthenticated(): boolean {
            return this.authentication?.token?.length > 60;
        },
        reloadFlightMessages(): void {
            this.areFlightMessagesReloading = true;

            this.store.dispatch("flight/_reload_alerts").then(() => {
                this.areFlightMessagesReloading = false;
                window.location.reload();
            });
        },
        logoutUser(showMessage = true) {
            this.logout();

            this.$router.push({
                name: "login",
            });

            if (!showMessage) {
                return;
            }

            ElMessage({
                message: "Je bent uitgelogd.",
                type: "info",
            });
        },
    },
});
