
import { format } from "date-fns";
import { nl } from "date-fns/locale";
import { ElMessage } from "element-plus";
import { range } from "lodash";
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { useStore } from "vuex";

import DateHelper from "@/helper/date-helper";
import SubOrderApiService from "@/service/api/subOrder";
import config from "@/service/config";
import { FilterRequest } from "@/types/request/filterRequest";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { SuccessResult } from "@/types/result/successResult";
import { Commercial } from "@/types/screen-force/commercial";
import { CommercialState } from "@/types/state/commercial";
import { Advertiser } from "@/types/uzs/advertiser";
import { CommercialDescriptionUpdate } from "@/types/uzs/commercialDescriptionUpdate";
import { Flight } from "@/types/uzs/flight";

type CurrentRow = {
    row: Commercial;
    rowIndex: number;
};

type SortEvent = {
    column: string;
    order: string;
    prop: string;
};

export default defineComponent({
    name: "List",
    setup() {
        const dateHelper = DateHelper;
        const store = useStore();
        const state = store.state.commercial as CommercialState;
        const subOrderApi = new SubOrderApiService();

        const actions = {
            commercial: {
                listScreenForce: (params?: RequestParameters) =>
                    store.dispatch("commercial/_list_screen_force", params),
                storeDescriptionBatch: (data: CommercialDescriptionUpdate[]) =>
                    store.dispatch("commercial/_store_description_batch", data),
            },
        };

        const data = reactive({
            advertiserId: null as number | null,
            advertisers: [] as Advertiser[],
            filter: {} as FilterRequest,
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            order: {
                created_at: "desc",
            } as OrderRequest,
            search: "",
            flightId: null as number | null,
            flights: [] as Flight[],
            commercials: [] as Commercial[],
            commercialUpdateData: [] as CommercialDescriptionUpdate[],
            currentPage: 1,
            loading: false,
            operator: "",
            operators: [] as string[],
            pages: 0,
            perPage: 0,
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            totalItems: 0,
            sortingEnabled: false,
            searchTimeout: undefined as ReturnType<typeof setTimeout> | undefined,
            year: null as null | number,
            years: range(
                new Date().getMonth() > 10
                    ? new Date().getFullYear() + 1
                    : new Date().getFullYear(),
                2016
            ),
        });

        const onInput = (scope: CurrentRow): void => {
            if (!scope.row.uzs_commercial) {
                return;
            }

            scope.row.uzs_commercial.description = scope.row.uzs_commercial?.description.toUpperCase();

            data.commercialUpdateData = data.commercialUpdateData.filter(
                (data: CommercialDescriptionUpdate) => data.id !== scope.row.id
            );

            if (scope.row.description) {
                data.commercialUpdateData.push({
                    id: scope.row.id,
                    length: scope.row.spot_length ?? 0,
                    description: scope.row.uzs_commercial.description,
                    sf_description: scope.row.description,
                });
            }
        };

        const onSelectedOperatorChanged = () => {
            if (!data.operator) {
                Object.assign(data.filter, {
                    operator: undefined,
                });

                init();
                return;
            }

            Object.assign(data.filter, {
                operator: {
                    operator: "=",
                    value: data.operator,
                },
            });

            init();
        };

        const onSelectedYearChanged = () => {
            if (!data.year) {
                Object.assign(data.filter, {
                    created_at: undefined,
                });

                init();
                return;
            }

            Object.assign(data.filter, {
                created_at: {
                    operator: "BETWEEN",
                    value: [data.year + "-01-01", data.year + "-12-31"],
                },
            });

            init();
        };

        const onSearch = () => {
            if (data.searchTimeout !== undefined) {
                clearTimeout(data.searchTimeout);
            }

            data.searchTimeout = setTimeout(() => {
                init();
            }, 500);
        };

        const init = (): void => {
            let finalFilter = { ...data.filter };

            if (data.search?.length > 2) {
                Object.assign(finalFilter, {
                    description: {
                        operator: "LIKE",
                        value: "%" + data.search + "%",
                    },
                });
            }

            data.loading = true;
            actions.commercial
                .listScreenForce({
                    filter: finalFilter,
                    order: data.order,
                    pagination: data.pagination,
                })
                .then((commercials: DataResult<Commercial[]>) => {
                    data.loading = false;
                    data.commercials =
                        commercials.data?.filter((c: Commercial) => {
                            if (!c?.uzs_commercial?.description) {
                                Object.assign(c, {
                                    uzs_commercial: {
                                        description: "",
                                    },
                                });
                            }

                            return c;
                        }) ?? [];

                    data.currentPage = (state.pagination?.page as number) ?? 1;
                    data.pages = state.screenForceCommercials?.pagination?.total_pages ?? 0;
                    data.perPage = (state.pagination?.per_page as number) ?? 0;
                    data.totalItems = state.screenForceCommercials?.pagination?.total_items ?? 0;
                    data.sortingEnabled = true;
                });

            if (!data.operators.length) {
                subOrderApi
                    .fetchOperators()
                    .then(
                        (response: DataResult<Array<string>>) =>
                            (data.operators = response.data ?? [])
                    );
            }
        };

        const getFormattedDate = (date: string | undefined): string => {
            const dateObject = date ? Date.parse(date) : null;

            if (dateObject) {
                const formatted = format(dateObject, "LLLL yyyy", { locale: nl });
                return formatted.substr(0, 1).toUpperCase() + formatted.substr(1);
            }

            return "";
        };

        const goToPage = (page: number): void => {
            Object.assign(data.pagination, {
                page: page,
            });

            init();
        };

        const pageSizeChange = (size: number) => {
            Object.assign(data.pagination, {
                per_page: size,
            });

            init();
        };

        const sortingChanged = ($event: SortEvent) => {
            const property = $event.prop.length ? $event.prop : null;
            const direction = $event?.order === "descending" ? "desc" : "asc";

            if (!property || !data.sortingEnabled || !data.commercials) {
                return;
            }

            data.order = {
                [property]: direction,
            };

            init();
        };

        const save = (): void => {
            actions.commercial
                .storeDescriptionBatch(data.commercialUpdateData)
                .then((data: SuccessResult) => {
                    if (data.success) {
                        ElMessage({
                            message: "De commercials zijn opgeslagen.",
                            type: "success",
                        });

                        init();
                    }
                });
        };

        onMounted(() => {
            init();
        });

        return {
            ...toRefs(data),
            init,
            getFormattedDate,
            goToPage,
            onInput,
            onSelectedOperatorChanged,
            onSelectedYearChanged,
            onSearch,
            pageSizeChange,
            sortingChanged,
            save,
        };
    },
});
