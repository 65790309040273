
import { defineComponent, PropType, Ref, ref, watch } from "vue";

const props = {
    modelValue: {
        type: Number as PropType<number>,
        required: true,
    },
};

export default defineComponent({
    name: "GrpInput",
    props,
    setup(props, { emit }) {
        const grp = ref(props.modelValue) as Ref<number>;

        function getValue(): number {
            return grp.value;
        }

        function getFormattedValue(): string {
            return grp.value.toString().replace(".", ",");
        }

        function onInput(event: Event): void {
            const target = event.target as HTMLInputElement;
            const value = parseFloat(target.value.replace(",", "."));

            if (isNaN(value)) {
                return;
            }

            grp.value = value;
            emit("input-event", value);
        }

        function onKeyDown(e: KeyboardEvent): void {
            const allowedKeys = [
                "ArrowLeft",
                "ArrowRight",
                "Backspace",
                "Delete",
                "End",
                "Enter",
                "Home",
            ];

            if (!e.ctrlKey && !allowedKeys.includes(e.key) && !e.key.match("^[0-9,]")) {
                e.preventDefault();
            }
        }

        watch(
            () => props.modelValue,
            (value) => {
                grp.value = value as number;
            }
        );

        return {
            getValue,
            getFormattedValue,
            onInput,
            onKeyDown,
        };
    },
});
