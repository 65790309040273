
import { defineComponent, PropType, Ref, ref, watch } from "vue";

import PriceHelper from "@/helper/price-helper";

const props = {
    value: {
        type: Number as PropType<number>,
        required: true,
    },
};

export default defineComponent({
    name: "GrpPriceValue",
    props,
    setup(props) {
        const grpPrice = ref(props.value) as Ref<number>;

        function getValue(): string {
            return PriceHelper.display(grpPrice.value, 0);
        }

        watch(
            () => props.value,
            (value) => {
                grpPrice.value = value as number;
            }
        );

        return {
            getValue,
        };
    },
});
