
import { ElMessage, ElMessageBox } from "element-plus";
import { computed, defineComponent, onMounted, reactive, toRefs } from "vue";
import { Store, useStore } from "vuex";

import { Copy } from "@/service/clipboard/copy";
import config from "@/service/config";
import { FilterRequest } from "@/types/request/filterRequest";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { RequestParameters } from "@/types/request/requestParameters";
import { DayPartState } from "@/types/state/dayPart";
import { RootState } from "@/types/state/root";
import { DayPart } from "@/types/uzs/dayPart";
import DayPartDialog from "@/views/settings/day-parts/dialogs/DayPart.vue";

type CurrentRow = {
    row: DayPart;
    rowIndex: number;
};

type SortEvent = {
    column: string;
    order: string;
    prop: string;
};

export default defineComponent({
    name: "List",
    components: { DayPartDialog },
    setup() {
        const store = useStore() as Store<RootState>;
        const state = store.state.dayPart as DayPartState;
        const textCopier = new Copy();

        const actions = {
            dayPart: {
                delete: (id: number) => store.dispatch("dayPart/_delete", id),
                details: (id: number) => store.dispatch("dayPart/_details", id),
                list: (params: RequestParameters) => store.dispatch("dayPart/_list", params),
            },
        };

        const data = reactive({
            currentDayPart: {} as DayPart | null,
            currentPage: 1 as number,
            loading: true,
            filter: {} as FilterRequest,
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            order: {
                start_time: "asc",
            } as OrderRequest,
            page: computed(() => state.filter?.page as number | undefined),
            pages: computed(() => state.dayParts?.pagination?.total_pages as number | undefined),
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            perPage: computed(() => state.filter?.per_page as number | undefined),
            showDayPartDialog: false,
            showFilterModal: false,
            sortingEnabled: false,
        });

        const dayPartInput = (value: boolean) => {
            data.currentDayPart = null;
            data.showDayPartDialog = value;
            update();
        };

        const deleteDayPart = (value: CurrentRow) => {
            ElMessageBox.confirm(
                "Weet je zeker dat je " + value.row.name + " wilt verwijderen?",
                "Verwijderen?",
                {
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nee",
                    type: "warning",
                }
            ).then(() => {
                actions.dayPart
                    .delete(value.row.id)
                    .then((result) => {
                        update();

                        if (!result.success) {
                            throw new Error();
                        }

                        ElMessage({
                            message: value.row.name + " is verwijderd.",
                            type: "success",
                        });
                    })
                    .catch(() => {
                        ElMessage({
                            message: "Er is een onbekende fout opgetreden.",
                            type: "error",
                        });
                    });
            });
        };

        const editDayPart = (dayPart?: DayPart | null) => {
            data.currentDayPart = dayPart ?? null;
            data.showDayPartDialog = true;
        };

        const goToPage = (page: number) => {
            if (!data?.pagination) {
                return;
            }

            data.currentPage = page;
            data.pagination.page = page;

            update();
        };

        const newDayPart = () => {
            data.currentDayPart = {} as DayPart;
            data.showDayPartDialog = true;
        };

        const pageSizeChange = (pageSize: number) => {
            if (!data?.pagination) {
                return;
            }

            data.pagination.per_page = pageSize;

            update();
        };

        const sortingChanged = ($event: SortEvent) => {
            const property = $event.prop?.length ? $event.prop : "start_time";
            const direction = $event.order === "descending" ? "desc" : "asc";

            if (!state.dayParts || !data.order || !data.sortingEnabled) {
                return;
            }

            data.order = {
                [property]: direction,
            };

            update();
        };

        const update = () => {
            data.loading = true;
            actions.dayPart
                .list({
                    filter: data.filter,
                    pagination: data.pagination,
                    order: data.order,
                })
                .then(() => {
                    data.loading = false;
                    data.sortingEnabled = true;
                });
        };

        onMounted(() => {
            update();
        });

        return {
            ...toRefs(data),
            ...toRefs(state),
            dayPartInput,
            deleteDayPart,
            editDayPart,
            goToPage,
            newDayPart,
            pageSizeChange,
            sortingChanged,
            textCopier,
            update,
        };
    },
});
