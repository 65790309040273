import { JWT } from "@/types/jwt";

export default class JWTHelper {
    public static decode(jwt: string): JWT | undefined {
        if (typeof window.btoa === "undefined") {
            return undefined;
        }

        const parts = jwt.split(".");

        if (parts.length !== 3) {
            return undefined;
        }

        try {
            const decoded = JSON.parse(window.atob(decodeURI(parts[1])));

            return {
                user: decoded["user"] ?? "",
                roles: decoded["roles"] ?? [],
                created_at: decoded["iat"] ?? "",
                expires_at: decoded["exp"] ?? "",
            };
        } catch {
            return undefined;
        }
    }
}
