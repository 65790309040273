import axios from "axios";

import config from "@/service/config";
import { DataResult } from "@/types/result/dataResult";
import { Account } from "@/types/uzs/user/account";

export default class AccountApiService {
    public async fetchInformation(): Promise<DataResult<Account | null>> {
        return axios
            .get(config.backend + "/api/uzs/user/account-information")
            .then((response) => response?.data as DataResult<Account | null>);
    }
}
