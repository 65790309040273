
import { computed, defineComponent, onMounted, reactive, toRefs } from "vue";
import { Store, useStore } from "vuex";

import DateHelper from "@/helper/date-helper";
import { Copy } from "@/service/clipboard/copy";
import config from "@/service/config";
import { FilterRequest } from "@/types/request/filterRequest";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { RequestParameters } from "@/types/request/requestParameters";
import { LogState } from "@/types/state/log";
import { RootState } from "@/types/state/root";

export default defineComponent({
    name: "List",
    setup() {
        const store = useStore() as Store<RootState>;
        const state = store.state.log as LogState;
        const textCopier = new Copy();

        const actions = {
            log: {
                list: (params: RequestParameters) => store.dispatch("log/_list", params),
            },
        };

        /* eslint-disable  @typescript-eslint/no-explicit-any */
        const data = reactive({
            currentPage: 1 as number,
            loading: true,
            filter: {} as FilterRequest,
            type: "",
            order: {
                date: "desc",
            } as OrderRequest,
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            page: computed(() => data.pagination?.page as number | undefined),
            pages: computed(() => state.logs?.pagination?.total_pages as number | undefined),
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            perPage: computed(() => data.pagination?.per_page as number | undefined),
        }) as any;

        const getFormattedDate = (date: string | null): string => {
            if (!date) {
                return "";
            }

            return DateHelper.dateTime(date);
        };

        const goToPage = (page: number) => {
            if (!data.pagination) {
                return;
            }

            data.currentPage = page;
            data.pagination.page = page;

            update();
        };

        const pageSizeChange = (pageSize: number) => {
            if (!data.pagination) {
                return;
            }

            data.pagination.per_page = pageSize;

            update();
        };

        const onSelectedTypeChanged = () => {
            if (!data.type.length) {
                data.filter = {};
                update();
                return;
            }

            data.filter = {
                type: {
                    operator: "LIKE",
                    value: data.type,
                },
            };

            update();
        };

        const update = () => {
            data.loading = true;
            actions.log
                .list({
                    filter: data.filter,
                    order: data.order,
                    pagination: data.pagination,
                })
                .then(() => {
                    data.loading = false;
                });
        };

        onMounted(() => {
            update();
        });

        return {
            ...toRefs(data),
            ...toRefs(state),
            getFormattedDate,
            goToPage,
            onSelectedTypeChanged,
            pageSizeChange,
            textCopier,
            update,
        };
    },
});
