<template>
    <span :class="getClassName()" :title="getTitle()"
        >{{ getValue() }} <span v-if="getSuffix()">{{ getSuffix() }}</span></span
    >
</template>
<script lang="ts">
import { startOfDay } from "date-fns";
import { defineComponent, PropType, Ref, ref, watch } from "vue";

import DateHelper from "@/helper/date-helper";
import GrpHelper from "@/helper/grp-helper";

const props = {
    value: {
        type: Number as PropType<number>,
        required: true,
    },
    average: {
        type: Number as PropType<number>,
        required: true,
    },
    date: {
        type: String as PropType<string>,
        default: false,
    },
    hasError: {
        type: Boolean as PropType<boolean>,
        default: false,
    },
    isGreyedOut: {
        type: Boolean as PropType<boolean>,
        default: false,
    },
    isHoliday: {
        type: Boolean as PropType<boolean>,
        default: false,
    },
    suffix: {
        type: String as PropType<string>,
        default: "",
    },
    title: {
        type: String as PropType<string>,
        default: "",
    },
};

export default defineComponent({
    name: "GrpValue",
    props,
    setup(props) {
        const grp = ref(props.value) as Ref<number>;
        const averageGrp = ref(props.average) as Ref<number>;
        const date = ref(props.date ?? false) as Ref<string>;
        const hasError = ref(props.hasError ?? false) as Ref<boolean>;
        const isGreyedOut = ref(props.isGreyedOut ?? false) as Ref<boolean>;
        const isHoliday = ref(props.isHoliday ?? false) as Ref<boolean>;
        const suffix = ref(props.suffix ?? "") as Ref<string>;
        const title = ref(props.title ?? "") as Ref<string>;

        function getClassName(): string {
            if (hasError.value) {
                return "has--error is--bold";
            }

            if (isGreyedOut.value) {
                return "is--greyed-out";
            }

            if (
                isHoliday.value &&
                grp.value >= averageGrp.value &&
                DateHelper.date(date.value) > startOfDay(new Date())
            ) {
                return "has--error";
            }

            if (isHoliday.value && grp.value >= averageGrp.value) {
                return "has--warning";
            }

            if (isHoliday.value) {
                return "is--holiday";
            }

            return "";
        }

        function getTitle(): string {
            return title.value;
        }

        function getSuffix(): string {
            return suffix.value;
        }

        function getValue(): string {
            return GrpHelper.display(grp.value);
        }

        watch(
            () => props.value,
            (value) => {
                grp.value = value as number;
            }
        );

        watch(
            () => props.average,
            (value) => {
                averageGrp.value = value as number;
            }
        );

        watch(
            () => props.date,
            (value) => {
                date.value = value as string;
            }
        );

        watch(
            () => props.hasError,
            (value) => {
                hasError.value = value as boolean;
            }
        );

        watch(
            () => props.isGreyedOut,
            (value) => {
                isGreyedOut.value = value as boolean;
            }
        );

        watch(
            () => props.isHoliday,
            (value) => {
                isHoliday.value = value as boolean;
            }
        );

        watch(
            () => props.suffix,
            (value) => {
                suffix.value = value as string;
            }
        );

        watch(
            () => props.title,
            (value) => {
                title.value = value as string;
            }
        );

        return {
            getClassName,
            getTitle,
            getSuffix,
            getValue,
        };
    },
});
</script>
