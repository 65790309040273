import axios from "axios";
import { ActionContext } from "vuex";

import config from "@/service/config";
import { DataResult } from "@/types/result/dataResult";
import { SuccessResult } from "@/types/result/successResult";
import { FlightState } from "@/types/state/flight";
import { RootState } from "@/types/state/root";
import { Flight } from "@/types/uzs/flight";
import { FlightStatistics } from "@/types/uzs/flightStatistics";

import { RequestParameters } from "../../types/request/requestParameters";

const initialState: FlightState = {
    columns: undefined,
    filter: {},
    order: {},
    pagination: {
        page: 1,
        per_page: config.pagination.per_page,
    },
    search: undefined,
    flights: undefined,
    flight: undefined,
    statistics: undefined,
};

const FlightModule = {
    namespaced: true,
    state: initialState,
    getters: {},
    mutations: {},
    actions: {
        _activate(
            context: ActionContext<FlightState, RootState>,
            id: number
        ): Promise<SuccessResult> {
            return axios
                .get(config.backend + "/api/uzs/flight/" + id + "/activate")
                .then((response) => response?.data)
                .then((response) => response as SuccessResult);
        },
        _all(): Promise<DataResult<Flight[]>> {
            return axios
                .get(config.backend + "/api/uzs/flights")
                .then((response) => response?.data as DataResult<Flight[]>)
                .then((response) => {
                    return response;
                });
        },
        _deactivate(
            context: ActionContext<FlightState, RootState>,
            id: number
        ): Promise<SuccessResult> {
            return axios
                .get(config.backend + "/api/uzs/flight/" + id + "/deactivate")
                .then((response) => response?.data)
                .then((response) => response as SuccessResult);
        },
        _delete(
            context: ActionContext<FlightState, RootState>,
            id: number
        ): Promise<SuccessResult> {
            return axios
                .delete(config.backend + "/api/uzs/flight/" + id)
                .then((response) => response?.data)
                .then((response) => response as SuccessResult);
        },
        _list(
            context: ActionContext<FlightState, RootState>,
            params: RequestParameters | null
        ): Promise<DataResult<Flight[]>> {
            return axios
                .get(config.backend + "/api/uzs/flights", {
                    params: {
                        columns: params?.columns ?? context.state.columns,
                        filter: params?.filter ?? context.state.filter,
                        order: params?.order ?? context.state.order,
                        pagination: params?.pagination ?? context.state.pagination,
                        search: params?.search ?? context.state.search,
                    },
                })
                .then((response) => response?.data as DataResult<Flight[]>)
                .then((response) => {
                    context.state.flights = response;
                    return response;
                });
        },
        _details(
            context: ActionContext<FlightState, RootState>,
            id: number
        ): Promise<DataResult<Flight>> {
            return axios
                .get(config.backend + "/api/uzs/flight/" + id)
                .then((response) => response?.data as DataResult<Flight>)
                .then((response) => {
                    context.state.flight = response;
                    return response;
                });
        },
        _statistics(
            context: ActionContext<FlightState, RootState>,
            params: RequestParameters | null
        ): Promise<DataResult<FlightStatistics[]>> {
            return axios
                .get(config.backend + "/api/uzs/flights/statistics", {
                    params: {
                        columns: params?.columns ?? context.state.columns,
                        filter: params?.filter ?? context.state.filter,
                        order: params?.order ?? context.state.order,
                        pagination: params?.pagination ?? context.state.pagination,
                        search: params?.search ?? context.state.search,
                    },
                })
                .then((response) => response?.data as DataResult<FlightStatistics[]>)
                .then((response) => {
                    context.state.statistics = response;
                    return response;
                });
        },
        _store(
            context: ActionContext<FlightState, RootState>,
            data: Flight
        ): Promise<DataResult<Flight>> {
            return axios
                .post(config.backend + "/api/uzs/flight", data)
                .then((response) => response?.data as DataResult<Flight>)
                .then((response) => {
                    context.state.flight = response;
                    return response;
                });
        },
        _update(
            context: ActionContext<FlightState, RootState>,
            data: Flight
        ): Promise<DataResult<Flight>> {
            return axios
                .put(config.backend + "/api/uzs/flight", data)
                .then((response) => response?.data as DataResult<Flight>)
                .then((response) => {
                    context.state.flight = response;
                    return response;
                });
        },
        _sync_sub_orders(
            context: ActionContext<FlightState, RootState>,
            data: Flight
        ): Promise<SuccessResult> {
            return axios
                .post(config.backend + "/api/uzs/flight/" + data.id + "/sub-orders", data)
                .then((response) => response?.data as SuccessResult);
        },
        _reload_alerts(): Promise<SuccessResult> {
            return axios
                .get(config.backend + "/api/uzs/flight/alerts/reload")
                .then((response) => response?.data as SuccessResult);
        },
    },
};

export default FlightModule;
