import {
    addDays,
    format,
    isAfter,
    isToday,
    isWeekend,
    isYesterday,
    nextMonday,
    parseISO,
} from "date-fns";
import { nl } from "date-fns/locale";
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { isDate } from "lodash";

import config from "@/service/config";
import { Flight } from "@/types/uzs/flight";

export default class DateHelper {
    private static daysOfWeek = [
        "maandag",
        "dinsdag",
        "woensdag",
        "donderdag",
        "vrijdag",
        "zaterdag",
        "zondag",
    ];

    private static months = [
        "januari",
        "februari",
        "maart",
        "april",
        "mei",
        "juni",
        "juli",
        "augustus",
        "september",
        "oktober",
        "november",
        "december",
    ];

    static utc(value: string | number | Date | null = null): Date {
        if (value === null) {
            return new Date();
        }

        return zonedTimeToUtc(new Date(value), config.timezone);
    }

    static date(value: string | Date | number | null = null, originalTimezone = "UTC"): Date {
        if (value === null) {
            return new Date();
        }

        let date = value;

        if (originalTimezone !== "UTC") {
            date = zonedTimeToUtc(value, "UTC");
        }

        return utcToZonedTime(date, config.timezone);
    }

    static parse(value: string): Date {
        return parseISO(value.substr(0, 19));
    }

    static dateTime(value: Date | string | null | undefined, originalTimezone = "UTC"): string {
        if (!value) {
            return "";
        }

        const date = isDate(value) ? value : this.date(value, originalTimezone);

        return (
            formatInTimeZone(date, config.timezone, "dd-MM-yyyy") +
            " om " +
            formatInTimeZone(date, config.timezone, "HH.mm") +
            " uur"
        );
    }

    static display(
        value: Date | string | null | undefined,
        displayTime = false,
        displayAsTodayOrYesterday = false,
        shortMonth = false,
        originalTimezone = "UTC"
    ): string {
        if (value === null || value === undefined) {
            return "?";
        }

        const date = this.date(value, originalTimezone);
        const isDateToday = isToday(date);
        const isDateYesterday = isYesterday(date);

        const day = date ? this.daysOfWeek[parseInt(format(date, "i")) - 1] ?? "" : "";

        let month = this.months[parseInt(format(date, "M")) - 1] ?? "";

        if (shortMonth) {
            month = month.substring(0, 3);

            if (month === "maa") {
                month = "mrt";
            }
        }

        if (!displayAsTodayOrYesterday || (!isDateToday && !isDateYesterday)) {
            return (
                day +
                " " +
                formatInTimeZone(date, config.timezone, "d") +
                " " +
                month +
                " " +
                formatInTimeZone(date, config.timezone, "yyyy") +
                (displayTime
                    ? " om " + formatInTimeZone(date, config.timezone, "HH:mm") + " uur"
                    : "")
            );
        }

        return (
            (isDateToday ? "vandaag" : "gisteren") +
            (displayTime ? " om " + formatInTimeZone(date, config.timezone, "HH:mm") + " uur" : "")
        );
    }

    static displayPeriod(startDate: Date | string, endDate: Date | string): string {
        if (typeof startDate === "string") {
            startDate = this.parse(startDate);
        }

        if (typeof endDate === "string") {
            endDate = this.parse(endDate);
        }

        let startDay = format(startDate, "d");
        const endDay = format(endDate, "d");

        let startMonth =
            " " + format(startDate, "LLL", { locale: nl }).toLowerCase().replace(".", "");

        const endMonth =
            " " + format(endDate, "LLL", { locale: nl }).toLowerCase().replace(".", "");

        let startYear = " " + format(startDate, "yyyy");

        const endYear = " " + format(endDate, "yyyy");

        if (startDay === endDay && startMonth === endMonth && startYear === endYear) {
            startDay = "";
        }

        if (startMonth === endMonth) {
            startMonth = "";
        }

        if (startYear === endYear) {
            startYear = "";
        }

        return (
            startDay +
            startMonth +
            startYear +
            (startDay === "" && startMonth === "" && startYear === "" ? "" : " - ") +
            endDay +
            endMonth +
            endYear
        );
    }

    static displayPeriodOfFlight(flight: Flight): string {
        const startDate = DateHelper.date(flight.start_date ?? "now");
        const endDate = DateHelper.date(flight.end_date);

        return this.displayPeriod(startDate, endDate);
    }

    static isDateTodayOrLater(date: string): boolean {
        const currentDate = DateHelper.date();
        let parsedDate = addDays(DateHelper.date(date), 1);

        if (isWeekend(parsedDate)) {
            parsedDate = nextMonday(parsedDate);
        }

        parsedDate.setHours(9);
        parsedDate.setMinutes(0);

        return isAfter(parsedDate, currentDate);
    }
}
