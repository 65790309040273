const config = {
    backend: process.env.VUE_APP_BACKEND_URL?.length
        ? process.env.VUE_APP_BACKEND_URL
        : "http://127.0.0.1:8000",
    countries: ["Netherlands", "Belgium North", "Belgium South", "France"],
    pagination: {
        per_page: 25,
    },
    timezone: "Europe/Amsterdam",
};

export default config;
