<template>
    <el-dialog
        :title="'Dagdeel - ' + (this.dayPart?.name ?? '')"
        :before-close="this.dialogClosed"
        :destroy-on-close="true"
        :lock-scroll="false"
        :model-value="this.value"
        width="100%"
        ref="dialog"
    >
        <el-form :model="this.dayPart" label-position="top" @keydown="this.submitOnEnter">
            <el-form-item label="Naam">
                <el-input clearable="clearable" v-model="this.dayPart.name" />
            </el-form-item>
            <el-form-item label="Begintijd">
                <el-select v-model="this.dayPart.start_time" filterable>
                    <template #prefix><i class="el-icon-time" /></template>
                    <el-option
                        v-for="time in this.times"
                        :key="time"
                        :value="time"
                        :label="time.toString().substr(0, 5)"
                        >{{ time.toString().substr(0, 5) }}</el-option
                    >
                </el-select>
            </el-form-item>
            <el-form-item label="Eindtijd">
                <el-select v-model="this.dayPart.end_time" filterable>
                    <template #prefix><i class="el-icon-time" /></template>
                    <el-option
                        v-for="time in this.times"
                        :key="time"
                        :value="time"
                        :label="time.toString().substr(0, 5)"
                        >{{ time.toString().substr(0, 5) }}</el-option
                    >
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="this.save()">Opslaan</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script lang="ts">
import { ElMessage } from "element-plus";
import { defineComponent, PropType, reactive, Ref, ref, toRefs, watch } from "vue";
import { Store, useStore } from "vuex";

import { DayPartState } from "@/types/state/dayPart";
import { RootState } from "@/types/state/root";
import { DayPart } from "@/types/uzs/dayPart";

const props = {
    value: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
    dayPart: {
        type: Object as PropType<DayPart>,
        required: true,
    },
};

export default defineComponent({
    name: "DayPartDialog",
    props,
    setup(props, { emit }) {
        const dayPart = ref(props.dayPart ?? {}) as Ref<DayPart | undefined>;
        const store = useStore() as Store<RootState>;
        const state = store.state.dayPart as DayPartState;
        const times = [];

        for (let i = 0; i <= 23; i++) {
            const hour = i < 10 ? "0" + i.toString() : i.toString();

            times.push(hour + ":00:00");
            times.push(hour + ":30:00");
        }

        const actions = {
            dayPart: {
                store: (dayPart: DayPart) => store.dispatch("dayPart/_store", dayPart),
            },
        };

        const data = reactive({
            times,
        });

        const close = (): void => {
            dayPart.value = {} as DayPart;

            emit("input", props.value);
        };

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        const save = (): void => {
            if (!dayPart?.value) {
                return;
            }

            actions.dayPart
                .store(dayPart.value)
                .then(() => {
                    ElMessage({
                        message: dayPart?.value?.name + " is opgeslagen.",
                        type: "success",
                    });

                    close();
                })
                .catch(() => {
                    ElMessage({
                        message:
                            "Er is een fout opgetreden bij het opslaan van " + dayPart?.value?.name,
                        type: "error",
                    });
                });
        };

        const submitOnEnter = (event: KeyboardEvent): void => {
            const input = event.target as HTMLElement | null;

            if (
                event.key.toLowerCase() === "enter" &&
                input?.tagName?.toLowerCase() !== "textarea"
            ) {
                save();
            }
        };

        watch(
            () => props.dayPart,
            (value, oldValue) => {
                if (value !== oldValue) {
                    dayPart.value = value ?? ({} as DayPart);
                }
            }
        );

        return {
            ...toRefs(data),
            ...toRefs(state),
            close,
            dayPart,
            dialogClosed,
            save,
            submitOnEnter,
        };
    },
});
</script>
