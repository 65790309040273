<template>
    <holiday-dialog
        v-if="this.showHolidayDialog"
        v-model="this.showHolidayDialog"
        :holiday="this.currentHoliday"
        @input="this.holidayInput"
    />

    <el-form class="table-actions">
        <el-button @click="this.newHoliday">Nieuwe feestdag toevoegen</el-button>
        <el-input
            v-model="search"
            class="search-bar"
            clearable="clearable"
            placeholder="Zoeken..."
            suffix-icon="el-icon-search"
            @input="onSearchChanged"
            @change="onSearchChanged"
        />
        <el-select
            v-model="year"
            class="year-select"
            placeholder="Jaartal"
            clearable
            :multiple="false"
            @change="onYearChanged"
        >
            <el-option v-for="year of years" :key="year" :value="year" :label="year">{{
                year
            }}</el-option>
        </el-select>
        <el-select
            v-model="country"
            class="country-select"
            placeholder="Land"
            clearable
            :multiple="false"
            @change="onSelectedCountryChanged"
        >
            <el-option
                v-for="country of countries"
                :key="country"
                :value="country"
                :label="country"
                >{{ country }}</el-option
            >
        </el-select>
    </el-form>

    <el-table
        v-loading="this.loading"
        :default-sort="{ prop: 'date', order: 'ascending' }"
        :data="this.holidays"
        @sort-change="this.sortingChanged($event)"
        width="100%"
    >
        <el-table-column class-name="column-name" label="Naam" min-width="125" prop="name" sortable>
            <template #default="scope">
                {{ scope.row.name }}
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-country"
            label="Land"
            prop="country"
            min-width="70"
            sortable
        >
            <template #default="scope">
                {{ scope.row.country }}
            </template>
        </el-table-column>
        <el-table-column class-name="column-date" label="Datum" prop="date" min-width="90" sortable>
            <template #default="scope">
                {{ dateHelper.display(scope.row.date) }}
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-is-operator-closed"
            label="Exploitant gesloten"
            min-width="100"
            prop="is_operator_closed"
            sortable
        >
            <template #default="scope">
                {{ scope.row.is_operator_closed ? "Ja" : "Nee" }}
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-is-affecting-viewing-behavior"
            label="Invloed op kijkgedrag"
            min-width="100"
            prop="is_affecting_viewing_behavior"
            sortable
        >
            <template #default="scope">
                {{ scope.row.is_affecting_viewing_behavior ? "Ja" : "Nee" }}
            </template>
        </el-table-column>
        <el-table-column class-name="column-actions" fixed="right" min-width="120">
            <template #default="scope">
                <el-button
                    icon="el-icon-edit"
                    plain="plain"
                    size="mini"
                    title="Bekijk details"
                    @click="this.editHoliday(scope.row)"
                ></el-button>
                <el-button
                    icon="el-icon-delete"
                    plain="plain"
                    size="mini"
                    title="Verwijder"
                    type="danger"
                    @click="this.deleteHoliday(scope)"
                ></el-button>
            </template>
        </el-table-column>
    </el-table>

    <el-pagination
        :background="false"
        :current-page="this.currentPage"
        :hide-on-single-page="false"
        :page-count="this.pages"
        :page-size="this.perPage"
        :page-sizes="this.pageSizes"
        :total="this.totalItems"
        layout="sizes, prev, pager, next, jumper, ->, total"
        @current-change="this.goToPage"
        @next-click="this.goToPage"
        @prev-click="this.goToPage"
        @size-change="this.pageSizeChange"
    />
</template>

<script lang="ts">
import { ElMessage, ElMessageBox } from "element-plus";
import { range } from "lodash";
import { defineComponent, onMounted, reactive, toRefs } from "vue";

import { Country } from "@/enums/country";
import DateHelper from "@/helper/date-helper";
import HolidayApiService from "@/service/api/settings/holiday/holiday";
import { Copy } from "@/service/clipboard/copy";
import config from "@/service/config";
import { FilterRequest } from "@/types/request/filterRequest";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { Holiday } from "@/types/uzs/settings/holiday/holiday";
import HolidayDialog from "@/views/settings/holidays/dialogs/Holiday.vue";

type CurrentRow = {
    row: Holiday;
    rowIndex: number;
};

type SortEvent = {
    column: string;
    order: string;
    prop: string;
};

export default defineComponent({
    name: "List",
    components: { HolidayDialog },
    setup() {
        const dateHelper = DateHelper;
        const holidayService = new HolidayApiService();
        const textCopier = new Copy();
        const countries = Country;

        const actions = {
            holiday: {
                delete: (guid: string) => holidayService.delete(guid),
                list: (params: RequestParameters) => holidayService.fetchAll(params),
            },
        };

        const data = reactive({
            country: "",
            currentHoliday: {} as Holiday | null,
            currentPage: 1 as number,
            holidays: [] as Holiday[],
            year: undefined as number | undefined,
            years: range(2017, new Date().getFullYear() + 2).reverse(),
            loading: true,
            filter: {} as FilterRequest,
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            order: {
                date: "asc",
            } as OrderRequest,
            page: 1,
            pages: undefined as number | undefined,
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            perPage: 25,
            totalPages: 1,
            totalItems: 0,
            search: "",
            showHolidayDialog: false,
            showFilterModal: false,
            sortingEnabled: false,
        });

        function init() {
            data.loading = true;

            const params = {
                filter: data.filter,
                pagination: data.pagination,
                search: data.search,
                sort: data.order,
            };

            actions.holiday.list(params).then((response: DataResult<Holiday[] | null>) => {
                data.holidays = (response.data ?? []) as Holiday[];
                data.page = response.pagination?.page ?? 1;
                data.perPage = response.pagination?.per_page ?? 1;
                data.totalPages = response.pagination?.total_pages ?? 1;
                data.totalItems = response.pagination?.total_items ?? 0;
                data.loading = false;
            });
        }

        const holidayInput = (value: boolean) => {
            data.currentHoliday = null;
            data.showHolidayDialog = value;

            init();
        };

        const deleteHoliday = (value: CurrentRow) => {
            ElMessageBox.confirm(
                "Weet je zeker dat je " + value.row.name + " wilt verwijderen?",
                "Verwijderen?",
                {
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nee",
                    type: "warning",
                }
            ).then(() => {
                actions.holiday
                    .delete(value.row.guid)
                    .then((result) => {
                        init();

                        if (!result.success) {
                            throw new Error();
                        }

                        ElMessage({
                            message: value.row.name + " is verwijderd.",
                            type: "success",
                        });
                    })
                    .catch(() => {
                        ElMessage({
                            message: "Er is een onbekende fout opgetreden.",
                            type: "error",
                        });
                    });
            });
        };

        const editHoliday = (holiday?: Holiday | null) => {
            data.currentHoliday = holiday ?? null;
            data.showHolidayDialog = true;
        };

        const goToPage = (page: number) => {
            if (!data?.pagination) {
                return;
            }

            data.currentPage = page;
            data.pagination.page = page;

            init();
        };

        function onSearchChanged() {
            init();
        }

        function onSelectedCountryChanged() {
            if (data.country !== "") {
                Object.assign(data.filter, {
                    ...data.filter,
                    country: data.country,
                });
            }

            if (data.country === "" && data.filter?.country) {
                delete data.filter.country;
            }

            init();
        }

        function onYearChanged() {
            if (data.year) {
                Object.assign(data.filter, {
                    ...data.filter,
                    year: data.year,
                });
            }

            if (!data.year && data.filter?.year) {
                delete data.filter.year;
            }

            init();
        }

        const newHoliday = () => {
            data.currentHoliday = {} as Holiday;
            data.showHolidayDialog = true;
        };

        const pageSizeChange = (pageSize: number) => {
            if (!data?.pagination) {
                return;
            }

            data.pagination.per_page = pageSize;

            init();
        };

        const sortingChanged = ($event: SortEvent) => {
            const property = $event.prop?.length ? $event.prop : "start_time";
            const direction = $event.order === "descending" ? "desc" : "asc";

            if (!data.holidays || !data.order || !data.sortingEnabled) {
                return;
            }

            data.order = {
                [property]: direction,
            };

            init();
        };

        onMounted(() => {
            init();
        });

        return {
            ...toRefs(data),
            countries,
            dateHelper,
            holidayInput,
            deleteHoliday,
            editHoliday,
            goToPage,
            onSearchChanged,
            onSelectedCountryChanged,
            onYearChanged,
            newHoliday,
            pageSizeChange,
            sortingChanged,
            textCopier,
        };
    },
});
</script>
