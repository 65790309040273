import axios from "axios";

import config from "@/service/config";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { Alert } from "@/types/uzs/alert";

export default class AlertApiService {
    public fetchAllForFlight(params: RequestParameters, id: number): Promise<DataResult<Alert[]>> {
        return axios
            .get(config.backend + "/api/uzs/alerts/" + id, {
                params,
            })
            .then((response) => response.data as DataResult<Alert[]>);
    }
}
