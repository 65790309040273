import axios from "axios";

import config from "@/service/config";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { SuccessResult } from "@/types/result/successResult";
import { Advertiser as ScreenForceAdvertiser } from "@/types/screen-force/advertiser";
import { Advertiser } from "@/types/uzs/advertiser";

export default class AdvertiserApiService {
    public async delete(id: number): Promise<SuccessResult> {
        const response = await axios.delete(config.backend + "/api/uzs/advertiser/" + id);

        return response?.data as SuccessResult;
    }

    public async fetchOne(id: number): Promise<DataResult<Advertiser>> {
        const response = await axios.get(config.backend + "/api/uzs/advertiser/" + id);

        return response?.data as DataResult<Advertiser>;
    }

    public async fetchAll(params: RequestParameters): Promise<DataResult<Advertiser[]>> {
        const response = await axios.get(config.backend + "/api/uzs/advertisers", {
            params,
        });

        return response.data as DataResult<Advertiser[]>;
    }

    public async fetchAllFromScreenForce(
        params: RequestParameters
    ): Promise<DataResult<ScreenForceAdvertiser[]>> {
        const response = await axios.get(config.backend + "/api/uzs/advertisers/screen-force", {
            params,
        });

        return response.data as DataResult<ScreenForceAdvertiser[]>;
    }

    public async store(data: Advertiser): Promise<DataResult<Advertiser>> {
        const response = await axios.post(config.backend + "/api/uzs/advertiser", data);

        return response?.data as DataResult<Advertiser>;
    }
}
