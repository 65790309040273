<template>
    <el-dialog
        :title="this.flight?.name ? 'Flight - ' + this.flight?.name : 'Nieuwe flight'"
        :before-close="this.dialogClosed"
        :destroy-on-close="true"
        :lock-scroll="false"
        :model-value="isModalVisible"
        width="100%"
        ref="dialog"
    >
        <el-form
            v-if="this.flight"
            :model="this.flight"
            label-position="top"
            @keydown="this.submitOnEnter"
        >
            <el-form-item label="Adverteerder">
                <el-select v-model="this.flight.uzs_advertiser_id" filterable>
                    <el-option
                        v-for="advertiser in this.allAdvertisers"
                        :key="advertiser.id"
                        :label="advertiser.name"
                        :value="advertiser.id"
                        >{{ advertiser.name }}</el-option
                    >
                </el-select>
            </el-form-item>
            <el-form-item label="Naam">
                <el-input v-model="this.flight.name"></el-input>
            </el-form-item>
            <el-form-item label="Land">
                <el-select
                    v-model="this.flight.country"
                    filterable
                    allow-create
                    @change="this.onCountryChanged"
                >
                    <el-option v-for="item in this.countries" :key="item" :value="item"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Doelstelling">
                <el-select v-model="this.flight.goal" filterable>
                    <el-option
                        v-for="target in this.targets"
                        :key="target"
                        :label="target"
                        :value="target"
                        >{{ target }}</el-option
                    >
                </el-select>
            </el-form-item>
            <el-form-item label="Startdatum">
                <el-date-picker
                    v-model="this.startDate"
                    format="DD-MM-YYYY"
                    @change="this.dateChanged"
                ></el-date-picker>
            </el-form-item>
            <el-form-item label="Einddatum">
                <el-date-picker
                    v-model="this.endDate"
                    format="DD-MM-YYYY"
                    @change="this.dateChanged"
                ></el-date-picker>
            </el-form-item>
            <el-form-item label="Uitzenddagen">
                <el-checkbox-group v-model="this.flight.days_of_week">
                    <el-checkbox-button
                        v-for="day in this.daysOfWeek"
                        :key="day.number"
                        :label="day.number"
                        :model-value="day.number"
                        @change="this.isChecked"
                        >{{ day.label }}</el-checkbox-button
                    >
                </el-checkbox-group>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="this.save()">Opslaan</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script lang="ts">
import { addMonths, endOfDay, endOfMonth, formatISO, startOfDay, startOfMonth } from "date-fns";
import { ElMessage } from "element-plus";
import { defineComponent, onMounted, onUpdated, PropType, reactive, Ref, ref, toRefs } from "vue";
import { Store, useStore } from "vuex";

import { Country } from "@/enums/country";
import { Goal } from "@/enums/goal";
import AdvertiserApiService from "@/service/api/advertiser";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { FlightState } from "@/types/state/flight";
import { RootState } from "@/types/state/root";
import { Advertiser } from "@/types/uzs/advertiser";
import { Flight } from "@/types/uzs/flight";
import { Property } from "@/types/uzs/property";

const props = {
    flight: {
        type: Object as PropType<Flight>,
        required: true,
    },
};

const daysOfWeek = [
    {
        number: 1,
        label: "Ma",
    },
    {
        number: 2,
        label: "Di",
    },
    {
        number: 3,
        label: "Wo",
    },
    {
        number: 4,
        label: "Do",
    },
    {
        number: 5,
        label: "Vr",
    },
    {
        number: 6,
        label: "Za",
    },
    {
        number: 7,
        label: "Zo",
    },
];

export default defineComponent({
    name: "FlightDialog",
    props,
    setup(props, { emit }) {
        const advertiserApi = new AdvertiserApiService();
        const flight = ref(props.flight ?? {}) as Ref<Flight | undefined>;
        const store = useStore() as Store<RootState>;
        const state = store.state.flight as FlightState;
        const targets = Goal;
        const countries = Country;

        const actions = {
            flight: {
                store: (flight: Flight) => store.dispatch("flight/_store", flight),
            },
            property: {
                list: (params?: RequestParameters) => store.dispatch("property/_list", params),
            },
        };

        const data = reactive({
            isModalVisible: true,
            startDate: flight.value?.start_date
                ? Date.parse(flight.value.start_date)
                : startOfDay(startOfMonth(addMonths(new Date(), 1))).getTime(),
            endDate: flight.value?.end_date
                ? Date.parse(flight.value.end_date)
                : endOfDay(endOfMonth(addMonths(new Date(), 1))).getTime(),
            allAdvertisers: [] as Advertiser[],
            activeProperties: {} as DataResult<Property[]>,
            daysOfWeek: daysOfWeek,
            isLoadingProperties: false,
        });

        const init = (): void => {
            if (flight.value && !flight.value.days_of_week) {
                flight.value.days_of_week = [1, 2, 3, 4, 5, 6, 7];
            }

            prefillCountry();

            if (flight.value?.start_date) {
                data.startDate = Date.parse(flight.value.start_date);
            }

            if (flight.value?.end_date) {
                data.endDate = Date.parse(flight.value.end_date);
            }
        };

        const close = (changed = false): void => {
            flight.value = {} as Flight;

            data.startDate = 0;
            data.endDate = 0;
            data.isModalVisible = false;

            emit("close", changed);
        };

        const isChecked = () => {
            return;
        };

        const dateChanged = (): void => {
            if (!flight.value) {
                return;
            }
        };

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        const prefillCountry = (): void => {
            if (!flight.value) {
                return;
            }

            flight.value.country = flight.value.country?.length
                ? flight.value.country
                : flight.value?.uzs_property?.country ?? Country.NETHERLANDS;
        };

        const loadAdvertisers = (): void => {
            advertiserApi
                .fetchAll({
                    order: {
                        name: "asc",
                    },
                    pagination: {
                        page: 1,
                        per_page: 1000,
                    },
                })
                .then((advertisers) => {
                    data.allAdvertisers = advertisers.data ?? [];
                });
        };

        const onCountryChanged = (): void => {
            return;
        };

        const nullOnEmpty = (): void => {
            if (flight.value && !flight.value?.uzs_property_id?.toString().length) {
                flight.value.uzs_property_id = null;
            }
        };

        const save = (): void => {
            if (!flight?.value) {
                return;
            }

            flight.value.start_date = formatISO(data.startDate);
            flight.value.end_date = formatISO(data.endDate);

            actions.flight
                .store(flight.value)
                .then(() => {
                    ElMessage({
                        message: "Property voor " + flight?.value?.name + " is opgeslagen.",
                        type: "success",
                    });

                    close(true);
                })
                .catch(() => {
                    ElMessage({
                        message:
                            "Er is een fout opgetreden bij het opslaan van de " +
                            flight?.value?.name,
                        type: "error",
                    });
                });
        };

        const submitOnEnter = (event: KeyboardEvent): void => {
            const input = event.target as HTMLElement | null;

            if (
                event.key.toLowerCase() === "enter" &&
                input?.tagName?.toLowerCase() !== "textarea"
            ) {
                save();
            }
        };

        onMounted(() => {
            if (props.flight) {
                flight.value = props.flight as Flight;
                init();
            }
        });

        onUpdated(() => {
            loadAdvertisers();
        });

        return {
            ...toRefs(data),
            ...toRefs(state),
            close,
            countries,
            dateChanged,
            dialogClosed,
            flight,
            isChecked,
            onCountryChanged,
            nullOnEmpty,
            save,
            submitOnEnter,
            targets,
        };
    },
});
</script>
