
import { defineComponent, PropType, Ref, ref, watch } from "vue";

import NumberHelper from "@/helper/number-helper";

const props = {
    value: {
        type: Number as PropType<number>,
        required: true,
    },
    hasError: {
        type: Boolean as PropType<boolean>,
        default: false,
    },
    shouldDisplayZero: {
        type: Boolean as PropType<boolean>,
        default: false,
    },
    shouldDisplayBoldIfAboveZero: {
        type: Boolean as PropType<boolean>,
        default: false,
    },
};

export default defineComponent({
    name: "LeadsValue",
    props,
    setup(props) {
        const leads = ref(props.value) as Ref<number>;
        const hasError = ref(props.hasError ?? false) as Ref<boolean>;
        const shouldDisplayZero = ref(props.shouldDisplayZero ?? false) as Ref<boolean>;
        const shouldDisplayBoldIfAboveZero = ref(
            props.shouldDisplayBoldIfAboveZero ?? false
        ) as Ref<boolean>;

        function getClassName(): string {
            if (hasError.value) {
                return "has--error is--bold";
            }

            if (getValue() === "0" || getValue() === "n/a") {
                return "is--greyed-out";
            }

            if (shouldDisplayBoldIfAboveZero.value) {
                return "is--bold";
            }

            return "";
        }

        function getValue(): string {
            const value = Math.round(leads.value);

            if (value > 0) {
                return NumberHelper.display(value, true);
            }

            if (value === 0 && shouldDisplayZero.value) {
                return "0";
            }

            return "n/a";
        }

        watch(
            () => props.value,
            (value) => {
                leads.value = value as number;
            }
        );

        watch(
            () => props.hasError,
            (value) => {
                hasError.value = value as boolean;
            }
        );

        watch(
            () => props.shouldDisplayZero,
            (value) => {
                shouldDisplayZero.value = value as boolean;
            }
        );

        watch(
            () => props.shouldDisplayBoldIfAboveZero,
            (value) => {
                shouldDisplayBoldIfAboveZero.value = value as boolean;
            }
        );

        return {
            getClassName,
            getValue,
        };
    },
});
