import axios from "axios";
import { ActionContext } from "vuex";

import config from "@/service/config";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { SuccessResult } from "@/types/result/successResult";
import { Commercial as ScreenForceCommercial } from "@/types/screen-force/commercial";
import { CommercialState } from "@/types/state/commercial";
import { RootState } from "@/types/state/root";
import { Commercial } from "@/types/uzs/commercial";
import { CommercialDescriptionUpdate } from "@/types/uzs/commercialDescriptionUpdate";

const initialState: CommercialState = {
    columns: undefined,
    filter: {},
    order: {
        country: "asc",
    },
    pagination: {
        page: 1,
        per_page: config.pagination.per_page,
    },
    search: undefined,
    screenForceCommercial: undefined,
    screenForceCommercials: undefined,
    commercial: undefined,
    commercials: undefined,
};

const CommercialModule = {
    namespaced: true,
    state: initialState,
    getters: {},
    mutations: {},
    actions: {
        _all(): Promise<DataResult<Commercial[]>> {
            return axios
                .get(config.backend + "/api/uzs/commercials")
                .then((response) => response?.data as DataResult<Commercial[]>)
                .then((response) => {
                    return response;
                });
        },
        _delete(
            context: ActionContext<CommercialState, RootState>,
            id: number
        ): Promise<SuccessResult> {
            return axios
                .delete(config.backend + "/api/uzs/commercial/" + id)
                .then((response) => response?.data)
                .then((response) => response as SuccessResult);
        },
        _list(
            context: ActionContext<CommercialState, RootState>,
            params: RequestParameters | null
        ): Promise<DataResult<Commercial[]>> {
            return axios
                .get(config.backend + "/api/uzs/commercials", {
                    params: {
                        columns: params?.columns ?? context.state.columns,
                        filter: params?.filter ?? context.state.filter,
                        order: params?.order ?? context.state.order,
                        pagination: params?.pagination ?? context.state.pagination,
                        search: params?.search ?? context.state.search,
                    },
                })
                .then((response) => response?.data as DataResult<Commercial[]>)
                .then((response) => {
                    context.state.commercials = response;
                    return response;
                });
        },
        _list_screen_force(
            context: ActionContext<CommercialState, RootState>,
            params: RequestParameters | null
        ): Promise<DataResult<ScreenForceCommercial[]>> {
            return axios
                .get(config.backend + "/api/uzs/commercials/screen-force", {
                    params,
                })
                .then((response) => response?.data as DataResult<ScreenForceCommercial[]>)
                .then((response) => {
                    context.state.screenForceCommercials = response;
                    return response;
                });
        },
        _details(
            context: ActionContext<CommercialState, RootState>,
            id: number
        ): Promise<DataResult<Commercial>> {
            return axios
                .get(config.backend + "/api/uzs/commercial/" + id)
                .then((response) => response?.data as DataResult<Commercial>)
                .then((response) => {
                    context.state.commercial = response;
                    return response;
                });
        },
        _store(
            context: ActionContext<CommercialState, RootState>,
            data: Commercial
        ): Promise<DataResult<Commercial>> {
            return axios
                .post(config.backend + "/api/uzs/commercial", data)
                .then((response) => response?.data as DataResult<Commercial>)
                .then((response) => {
                    context.state.commercial = response;
                    return response;
                });
        },
        _store_description(
            context: ActionContext<CommercialState, RootState>,
            data: Commercial
        ): Promise<DataResult<Commercial>> {
            return axios
                .post(config.backend + "/api/uzs/commercial/description", data)
                .then((response) => response?.data as DataResult<Commercial>)
                .then((response) => {
                    context.state.commercial = response;
                    return response;
                });
        },
        _store_description_batch(
            context: ActionContext<CommercialState, RootState>,
            data: CommercialDescriptionUpdate[]
        ): Promise<SuccessResult> {
            return axios
                .post(config.backend + "/api/uzs/commercial/descriptions", data)
                .then((response) => response?.data as SuccessResult);
        },
        _store_batch(
            context: ActionContext<CommercialState, RootState>,
            data: Commercial[]
        ): Promise<DataResult<Commercial[]>> {
            return axios
                .post(config.backend + "/api/uzs/commercials", data)
                .then((response) => response?.data as DataResult<Commercial[]>)
                .then((response) => {
                    return response;
                });
        },
    },
};

export default CommercialModule;
