import axios from "axios";

import config from "@/service/config";
import { DataResult } from "@/types/result/dataResult";
import { CampaignPackageSpreading } from "@/types/uzs/campaign-insight/campaignPackageSpreading";

export default class CampaignInsightApiService {
    public fetchPackageSpreading(
        campaignId: number
    ): Promise<DataResult<CampaignPackageSpreading>> {
        return axios
            .get(config.backend + "/api/campaign/" + campaignId + "/insight/package-spreading")
            .then((response) => response?.data as DataResult<CampaignPackageSpreading>);
    }
}
