
import { defineComponent, PropType, reactive, Ref, ref, toRefs, watch } from "vue";

import DateHelper from "@/helper/date-helper";
import GrpHelper from "@/helper/grp-helper";
import NumberHelper from "@/helper/number-helper";
import BlockApiService from "@/service/api/block";
import { Block, BlockConsideredSpot } from "@/types/uzs/block";

const props = {
    value: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
    guid: {
        type: String as PropType<string>,
        required: true,
    },
};

export default defineComponent({
    name: "SelectedBlocksDialog",
    props,
    setup(props, { emit }) {
        const guid = ref(props.guid ?? null) as Ref<string | null>;
        const blockApi = new BlockApiService();
        const dateHelper = DateHelper;
        const grpHelper = GrpHelper;
        const numberHelper = NumberHelper;

        const data = reactive({
            blocks: [] as Array<Block>,
            openedBlock: undefined as Block | undefined,
            openedConsideredSpot: undefined as BlockConsideredSpot | undefined,
        });

        const close = (): void => {
            guid.value = null;
            emit("input", props.value);
        };

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        const init = (): void => {
            data.blocks = [];
            loadBlocks();
        };

        function loadBlocks(): void {
            if (!guid.value) {
                return;
            }

            blockApi.fetch(guid.value).then((response) => {
                data.blocks = response.data as Block[];
            });
        }

        function openBlock(block: Block): void {
            if (data.openedBlock === block) {
                data.openedBlock = undefined;
                return;
            }

            data.openedBlock = block;
            data.openedBlock.considered_spots?.sort((a, b) => {
                if (a.index === b.index) {
                    return 0;
                }

                return a.index > b.index ? -1 : 1;
            });
        }

        function openConsideredSpot(spot: BlockConsideredSpot): void {
            if (data.openedConsideredSpot === spot) {
                data.openedConsideredSpot = undefined;
                return;
            }

            data.openedConsideredSpot = spot;
        }

        watch(
            () => props.guid,
            (value, oldValue) => {
                if (value !== oldValue) {
                    guid.value = value ?? null;
                    init();
                }
            }
        );

        return {
            ...toRefs(data),
            close,
            dateHelper,
            dialogClosed,
            grpHelper,
            guid,
            numberHelper,
            openBlock,
            openConsideredSpot,
        };
    },
});
