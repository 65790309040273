import axios from "axios";

import config from "@/service/config";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { SuccessResult } from "@/types/result/successResult";
import { Strip } from "@/types/uzs/settings/strip/strip";

export default class StripApiService {
    public async delete(guid: string): Promise<SuccessResult> {
        const response = await axios.delete(config.backend + "/api/settings/strip/" + guid);

        return response?.data as SuccessResult;
    }

    public async fetchAll(params: RequestParameters): Promise<DataResult<Strip[] | null>> {
        const response = await axios.get(config.backend + "/api/settings/strips", {
            params,
        });

        return response?.data as DataResult<Strip[] | null>;
    }

    public async store(data: Strip): Promise<SuccessResult> {
        const response = await axios.post(config.backend + "/api/settings/strip", data);

        return response?.data as SuccessResult;
    }
}
