<template>
    <el-form class="table-actions">
        <el-select
            v-model="this.selectedCountries"
            class="country-select"
            placeholder="Land"
            :multiple="true"
            @change="this.onSelectedCountryChanged"
        >
            <el-option
                v-for="country of this.countries"
                :key="country"
                :value="country"
                :label="country"
                >{{ country }}</el-option
            >
        </el-select>
        <el-select
            v-model="this.advertiserIds"
            class="advertiser-select"
            placeholder="Adverteerders"
            :multiple="true"
            @change="this.onSelectedAdvertiserChanged"
        >
            <el-option
                v-for="advertiser of this.advertisers"
                :key="advertiser.id"
                :value="advertiser.id"
                :label="advertiser.name"
                >{{ advertiser.name }}</el-option
            >
        </el-select>
    </el-form>

    <el-table
        v-loading="this.loading"
        class="flights-statistics-table"
        :data="this.statistics?.data"
        @sort-change="this.sortingChanged($event)"
        width="100%"
    >
        <el-table-column class-name="column-advertiser" label="Adverteerder" width="170">
            <template #default="scope">{{ scope.row.uzs_flight?.uzs_advertiser?.name }} </template>
        </el-table-column>
        <el-table-column class-name="column-flight" label="Flight" width="300">
            <template #default="scope">
                <span :title="scope.row.name">{{ scope.row.name }}</span>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-period"
            label="Looptijd"
            width="175"
            prop="start_date"
            sortable
        >
            <template #default="scope">{{ this.getDatePeriod(scope.row) }} </template>
        </el-table-column>
        <el-table-column
            class-name="column-country"
            label="Land"
            width="150"
            prop="country"
            sortable
        >
            <template #default="scope">{{ scope.row.country }} </template>
        </el-table-column>
        <el-table-column class-name="column-goal" label="Doel" width="150">
            <template #default="scope">{{ scope.row.uzs_flight?.goal }} </template>
        </el-table-column>
        <el-table-column class-name="column-budget" label="Budget" width="120">
            <template #default="scope"
                ><span class="block text-align-right">{{
                    this.getBudget(scope.row.uzs_flight)
                }}</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="grp_price"
            class-name="column-grp-price"
            label="GRP-prijs"
            width="120"
            sortable
        >
            <template #default="scope"
                ><span class="block text-align-right">
                    <GrpPriceValue :value="scope.row.grp_price" />
                </span>
            </template>
        </el-table-column>
        <el-table-column prop="cpm" class-name="column-cpm" label="CPM" width="100" sortable>
            <template #default="scope"
                ><span class="block text-align-right">
                    <CpmValue :value="scope.row.cpm" :digits="2" />
                </span>
            </template>
        </el-table-column>
        <el-table-column
            prop="awareness_percentage"
            class-name="column-awareness"
            label="Awareness (%)"
            width="150"
            sortable
        >
            <template #default="scope">
                <GrpPercentage :value="scope.row.awareness_percentage" />
            </template>
        </el-table-column>
        <el-table-column
            prop="volume_percentage"
            class-name="column-volume"
            label="Volume (%)"
            width="130"
            sortable
        >
            <template #default="scope">
                <GrpPercentage :value="scope.row.volume_percentage" />
            </template>
        </el-table-column>
        <el-table-column
            prop="frequency_percentage"
            class-name="column-frequency"
            label="Frequency (%)"
            width="150"
            sortable
        >
            <template #default="scope">
                <GrpPercentage :value="scope.row.frequency_percentage" />
            </template> </el-table-column
        ><el-table-column
            prop="small_blocks_percentage"
            class-name="column-small-blocks"
            label="< 2 GRP (%)"
            width="130"
            sortable
        >
            <template #default="scope">
                <GrpPercentage :value="scope.row.small_blocks_percentage" />
            </template>
        </el-table-column>
        <el-table-column
            prop="medium_blocks_percentage"
            class-name="column-medium-blocks"
            label="2-4 GRP (%)"
            width="130"
            sortable
        >
            <template #default="scope">
                <GrpPercentage :value="scope.row.medium_blocks_percentage" />
            </template>
        </el-table-column>
        <el-table-column
            prop="large_blocks_percentage"
            class-name="column-large-blocks"
            label="> 4 GRP (%)"
            width="130"
            sortable
        >
            <template #default="scope">
                <GrpPercentage :value="scope.row.large_blocks_percentage" />
            </template>
        </el-table-column>
        <el-table-column
            prop="grp_overage_percentage"
            class-name="column-grp-overage"
            label="12 GRP+ (%)"
            width="135"
            sortable
        >
            <template #default="scope">
                <GrpPercentage :value="scope.row.grp_overage_percentage" />
            </template>
        </el-table-column>
        <el-table-column
            prop="excluded_days_percentage"
            class-name="column-excluded-days"
            label="Uitgesloten dag (%)"
            width="190"
            sortable
        >
            <template #default="scope">
                <DurationPercentage :value="scope.row.excluded_days_percentage" />
            </template>
        </el-table-column>
        <el-table-column
            prop="in_afternoon_percentage"
            class-name="column-outside-afternoon"
            label="Binnen 17-19 (%)"
            width="180"
            sortable
        >
            <template #default="scope">
                <GrpPercentage :value="scope.row.in_afternoon_percentage" />
            </template>
        </el-table-column>
        <el-table-column
            prop="outside_daytime_percentage"
            class-name="column-outside-daytime"
            label="Buiten 11-23 (%)"
            width="180"
            sortable
        >
            <template #default="scope">
                <GrpPercentage :value="scope.row.outside_daytime_percentage" />
            </template>
        </el-table-column>
        <el-table-column
            prop="outside_prime_time_percentage"
            class-name="column-outside-prime-time"
            label="Buiten 18.30-23.00 (%)"
            width="180"
            sortable
        >
            <template #default="scope">
                <GrpPercentage :value="scope.row.outside_prime_time_percentage" />
            </template>
        </el-table-column>
        <el-table-column prop="peak" class-name="column-peak" label="Peak" width="120" sortable>
            <template #default="scope">
                <GrpPercentage :value="scope.row.peak" :round="true" />
            </template>
        </el-table-column>
        <el-table-column
            prop="off_peak"
            class-name="column-off-peak"
            label="Off-peak"
            width="120"
            sortable
        >
            <template #default="scope">
                <GrpPercentage :value="scope.row.off_peak" :round="true" />
            </template>
        </el-table-column>
        <el-table-column
            prop="number_of_days"
            class-name="column-number-of-days"
            label="Campagnedagen"
            width="170"
            sortable
        >
            <template #default="scope">
                {{ scope.row.number_of_days }}
            </template>
        </el-table-column>
        <el-table-column
            prop="total_grp"
            class-name="column-total-grp"
            label="GRP"
            width="130"
            sortable
        >
            <template #default="scope">
                <GrpValue :value="scope.row.total_grp" />
            </template>
        </el-table-column>
        <el-table-column
            prop="grp_per_day"
            class-name="column-grp-per-day"
            label="GRP/dag"
            width="210"
            sortable
        >
            <template #default="scope">
                <GrpValue :value="scope.row.grp_per_day" />
            </template>
        </el-table-column>
        <el-table-column
            prop="total_leads"
            class-name="column-total-leads"
            label="Leads"
            width="130"
            sortable
        >
            <template #default="scope">
                {{ scope.row.total_leads }}
            </template>
        </el-table-column>
        <el-table-column
            prop="leads_per_day"
            class-name="column-leads-per-day"
            label="Leads/dag"
            width="210"
            sortable
        >
            <template #default="scope">
                {{ Math.round(scope.row.leads_per_day).toString() }}
            </template>
        </el-table-column>
        <el-table-column prop="cpa" class-name="column-cpa" label="CPA" width="90">
            <template #default="scope">
                <CpaValue v-if="scope.row.cpa > 0" :value="scope.row.cpa" />
            </template>
        </el-table-column>
        <el-table-column
            prop="target_progress"
            class-name="column-target-progress"
            label="Target"
            width="120"
            sortable
        >
            <template #default="scope">
                <TargetPercentage :value="scope.row.target_progress" :round="true" />
            </template>
        </el-table-column>
        <el-table-column
            prop="optimization_score"
            class-name="column-optimization-score"
            label="Optimalisatie"
            width="120"
            sortable
        >
            <template #default="scope">
                <OptimizationScore :value="scope.row.optimization_score" />
            </template>
        </el-table-column>
    </el-table>
    <el-pagination
        :background="false"
        :current-page="this.currentPage"
        :hide-on-single-page="false"
        :page-count="this.pages"
        :page-size="this.perPage"
        :page-sizes="this.pageSizes"
        :total="this.totalItems"
        layout="sizes, prev, pager, next, jumper, ->, total"
        @current-change="this.goToPage"
        @next-click="this.goToPage"
        @prev-click="this.goToPage"
        @size-change="this.pageSizeChange"
    />
</template>

<script lang="ts">
import { format } from "date-fns";
import { nl } from "date-fns/locale";
import { defineComponent, onMounted, Ref, ref } from "vue";
import { mapActions } from "vuex";

import CpaValue from "@/components/campaigns/CpaValue.vue";
import CpmValue from "@/components/campaigns/CpmValue.vue";
import DurationPercentage from "@/components/campaigns/DurationPercentage.vue";
import GrpPercentage from "@/components/campaigns/GrpPercentage.vue";
import GrpPriceValue from "@/components/campaigns/GrpPriceValue.vue";
import GrpValue from "@/components/campaigns/GrpValue.vue";
import OptimizationScore from "@/components/campaigns/OptimizationScore.vue";
import TargetPercentage from "@/components/campaigns/TargetPercentage.vue";
import { Country } from "@/enums/country";
import GrpHelper from "@/helper/grp-helper";
import PercentageHelper from "@/helper/percentage-helper";
import PriceHelper from "@/helper/price-helper";
import AdvertiserApiService from "@/service/api/advertiser";
import config from "@/service/config";
import { FilterRequest } from "@/types/request/filterRequest";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { DataResult } from "@/types/result/dataResult";
import { Advertiser } from "@/types/uzs/advertiser";
import { Flight } from "@/types/uzs/flight";
import { FlightStatistics } from "@/types/uzs/flightStatistics";

type SortEvent = {
    column: string;
    order: string;
    prop: string;
};

export default defineComponent({
    name: "Debug",
    components: {
        GrpValue,
        DurationPercentage,
        CpmValue,
        GrpPriceValue,
        GrpPercentage,
        CpaValue,
        TargetPercentage,
        OptimizationScore,
    },
    setup() {
        const advertisers = ref(null) as Ref<Advertiser[] | null>;
        const advertiserApi = new AdvertiserApiService();
        const countries = Country;
        const grpHelper = GrpHelper;
        const percentageHelper = PercentageHelper;
        const priceHelper = PriceHelper;

        onMounted(() => {
            advertiserApi
                .fetchAll({
                    order: {
                        start_date: "desc",
                        country: "asc",
                        name: "asc",
                    },
                })
                .then((data) => {
                    advertisers.value = data.data;
                });
        });

        return {
            advertisers,
            advertiserApi,
            countries,
            grpHelper,
            percentageHelper,
            priceHelper,
        };
    },
    computed: {
        page(): number {
            return this.pagination.page;
        },
        pages(): number {
            return this.statistics?.pagination?.total_pages as number;
        },
        perPage(): number {
            return this.pagination.per_page;
        },
    },
    data() {
        return {
            filter: {} as FilterRequest,
            order: {} as OrderRequest,
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            search: undefined as null | number | string | undefined,
            selectedCountries: [] as string[],
            advertiserIds: [] as number[],
            statistics: undefined as DataResult<FlightStatistics[]> | undefined,
            currentPage: undefined as number | undefined,
            loading: true,
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            sortingEnabled: false,
            totalItems: 0,
        };
    },
    mounted() {
        this.order = {
            start_date: "desc",
            country: "asc",
            name: "asc",
        };

        Object.assign(this.pagination, {
            per_page: 25,
        });

        this.update();
    },
    methods: {
        ...mapActions("flight", ["_statistics"]),
        getYear(date: string) {
            return format(Date.parse(date), "yyyy");
        },
        getDatePeriod(value: Flight | null) {
            if (!value) {
                return "";
            }

            const startDate = Date.parse(value.start_date ?? "now");
            const endDate = Date.parse(value.end_date);

            let startDay = format(startDate, "d");
            const endDay = format(endDate, "d");
            let startMonth =
                " " + format(startDate, "LLL", { locale: nl }).toLowerCase().replace(".", "");
            const endMonth =
                " " + format(endDate, "LLL", { locale: nl }).toLowerCase().replace(".", "");
            let startYear = " " + format(startDate, "yyyy");
            const endYear = " " + format(endDate, "yyyy");

            if (startDay === endDay && startMonth === endMonth && startYear === endYear) {
                startDay = "";
            }

            if (startMonth === endMonth) {
                startMonth = "";
            }

            if (startYear === endYear) {
                startYear = "";
            }

            return (
                startDay +
                startMonth +
                startYear +
                (startDay === "" && startMonth === "" && startYear === "" ? "" : " - ") +
                endDay +
                endMonth +
                endYear
            );
        },
        getBudget(value: Flight | null): string {
            if (!value) {
                return "";
            }

            return (
                "€ " +
                (value.budget ?? 0).toLocaleString("nl", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                })
            );
        },
        goToPage(page: number) {
            this.currentPage = page;
            this.pagination.page = page;
            this.update();
        },
        pageSizeChange(pageSize: number) {
            this.pagination.per_page = pageSize;
            this.update();
        },
        sortingChanged($event: SortEvent) {
            const property = $event.prop.length ? $event.prop : null;
            const direction = $event?.order === "descending" ? "desc" : "asc";

            if (!property || !this.sortingEnabled || !this.advertisers || !this.order) {
                return;
            }

            this.order = {
                [property]: direction,
            };

            this.update();
        },
        onSelectedAdvertiserChanged(): void {
            Object.assign(this.filter, {
                "uzs_flight_statistics.uzs_advertiser_id": {
                    operator: "IN",
                    value: this.advertiserIds,
                },
            });

            this.update();
        },
        onSelectedCountryChanged(): void {
            Object.assign(this.filter, {
                "uzs_flight_statistics.country": {
                    operator: "IN",
                    value: this.selectedCountries,
                },
            });

            this.update();
        },
        update() {
            this.loading = true;

            this._statistics({
                filter: this.filter,
                order: this.order,
                pagination: this.pagination,
                search: this.search,
            }).then((response) => {
                this.loading = false;
                this.sortingEnabled = true;
                this.statistics = response;
                this.totalItems = response.pagination?.total_items ?? 0;
            });
        },
    },
});
</script>

<style>
td.column-message > div.cell > i {
    cursor: pointer;
    margin: -4px;
    padding: 4px;
}

.country-select {
    margin-right: 5px;
}
</style>
