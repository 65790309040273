<template>
    <el-dialog
        :title="'Leads - ' + (this.importedLeads?.filename ?? '')"
        :before-close="this.dialogClosed"
        :destroy-on-close="true"
        :lock-scroll="false"
        :model-value="this.value"
        width="100%"
        ref="dialog"
    >
        <el-form
            :model="this.importedLeads"
            v-if="this.importedLeads"
            label-position="left"
            label-width="150px"
            @keydown="this.submitOnEnter"
        >
            <el-form-item label="Adverteerder">
                <i v-if="!this.advertisers.length" class="el-icon-loading"></i>
                <el-select
                    v-if="this.advertisers.length"
                    v-model="this.advertiserId"
                    @change="onAdvertiserSelected"
                    filterable
                >
                    <el-option
                        v-for="advertiser in this.advertisers"
                        :key="advertiser.id"
                        :label="advertiser.name"
                        :value="advertiser.id"
                        >{{ advertiser.name }}</el-option
                    >
                </el-select>
            </el-form-item>
            <el-form-item label="Flight">
                <i v-if="!this.flightsForAdvertiser.length" class="el-icon-loading"></i>
                <el-select
                    v-if="this.flightsForAdvertiser.length"
                    v-model="this.importedLeads.uzs_flight_id"
                    @change="onFlightSelected"
                    filterable
                >
                    <el-option
                        v-for="flight in this.flightsForAdvertiser"
                        :key="flight.id"
                        :label="flight.name"
                        :value="flight.id"
                        >{{ flight.name }} ({{
                            this.dateHelper.displayPeriodOfFlight(flight)
                        }})</el-option
                    >
                </el-select>
            </el-form-item>
            <el-form-item label="Startdatum">
                <el-date-picker
                    v-model="importedLeads.start_date"
                    format="DD-MM-YYYY"
                ></el-date-picker>
            </el-form-item>
            <el-form-item label="Einddatum">
                <el-date-picker
                    v-model="importedLeads.end_date"
                    format="DD-MM-YYYY"
                ></el-date-picker>
            </el-form-item>
            <el-form-item label="Totaal aantal leads">
                <el-input-number v-model="importedLeads.number_of_leads" :min="0" :max="99999">
                </el-input-number>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="this.save()">Opslaan</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script lang="ts">
import { format, isAfter, subDays } from "date-fns";
import { ElMessage } from "element-plus";
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs, watch } from "vue";
import { Store, useStore } from "vuex";

import { Goal } from "@/enums/goal";
import DateHelper from "@/helper/date-helper";
import ImportedLeadsApiService from "@/service/api/importedLeads";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { RootState } from "@/types/state/root";
import { Advertiser } from "@/types/uzs/advertiser";
import { Flight } from "@/types/uzs/flight";
import { ImportedLeads } from "@/types/uzs/importedLeads";

const props = {
    value: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
    importedLeads: {
        type: Object as PropType<ImportedLeads>,
        required: true,
    },
};

export default defineComponent({
    name: "ImportedLeadsDialog",
    props,
    setup(props, { emit }) {
        const importedLeads = ref(props.importedLeads ?? {}) as Ref<ImportedLeads | undefined>;
        const importedLeadsApi = new ImportedLeadsApiService();
        const store = useStore() as Store<RootState>;
        const dateHelper = DateHelper;

        const actions = {
            flight: {
                list: (params: RequestParameters) => store.dispatch("flight/_list", params),
            },
            importedLeads: {
                store: (importedLeads: ImportedLeads) => importedLeadsApi.store(importedLeads),
            },
        };

        const data = reactive({
            advertisers: [] as Array<Advertiser>,
            advertiserId: null as number | null,
            advertiserIds: [] as Array<number>,
            flights: [] as Array<Flight>,
            flightsForAdvertiser: [] as Array<Flight>,
        });

        const close = (): void => {
            importedLeads.value = {} as ImportedLeads;

            emit("input", props.value);
        };

        const onAdvertiserSelected = (): void => {
            data.flightsForAdvertiser = data.flights.filter(
                (flight: Flight) => flight.uzs_advertiser_id === data.advertiserId
            );
        };

        const onFlightSelected = (id: number): void => {
            if (!importedLeads.value) {
                return;
            }

            const flight = data.flights.find((flight) => flight.id === id);

            if (!flight) {
                return;
            }

            let endDate = DateHelper.date(flight.end_date);

            const yesterday = subDays(new Date(), 1);

            if (isAfter(endDate, yesterday)) {
                endDate = yesterday;
            }

            importedLeads.value.start_date = flight.start_date;
            importedLeads.value.end_date = format(endDate, "yyyy-MM-dd");
        };

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        const init = (): void => {
            loadFlights();

            const flight = data.flights.filter(
                (flight) => flight.id === importedLeads.value?.uzs_flight_id
            );

            if (flight.length) {
                data.advertiserId = flight[0].uzs_advertiser_id;

                onAdvertiserSelected();
            }
        };

        const loadFlights = (): void => {
            actions.flight
                .list({
                    filter: {
                        goal: {
                            operator: "=",
                            value: Goal.LEADS,
                        },
                    },
                    order: {
                        start_date: "desc",
                    },
                })
                .then((response: DataResult<Array<Flight>>) => {
                    data.flights = response.data as Array<Flight>;

                    for (const flight of data.flights) {
                        if (
                            !flight.uzs_advertiser ||
                            !flight.uzs_advertiser_id ||
                            data.advertiserIds.includes(flight.uzs_advertiser_id)
                        ) {
                            continue;
                        }

                        data.advertisers.push(flight.uzs_advertiser);
                        data.advertiserIds.push(flight.uzs_advertiser_id);
                    }
                });
        };

        const save = (): void => {
            if (!importedLeads?.value) {
                return;
            }

            actions.importedLeads
                .store(importedLeads.value)
                .then(() => {
                    ElMessage({
                        message: importedLeads?.value?.filename + " is opgeslagen.",
                        type: "success",
                    });

                    close();
                })
                .catch(() => {
                    ElMessage({
                        message:
                            "Er is een fout opgetreden bij het opslaan van " +
                            importedLeads?.value?.filename,
                        type: "error",
                    });
                });
        };

        const submitOnEnter = (event: KeyboardEvent): void => {
            const input = event.target as HTMLElement | null;

            if (
                event.key.toLowerCase() === "enter" &&
                input?.tagName?.toLowerCase() !== "textarea"
            ) {
                save();
            }
        };

        onMounted(() => {
            init();
        });

        watch(
            () => props.importedLeads,
            (value, oldValue) => {
                if (value !== oldValue) {
                    importedLeads.value = value ?? ({} as ImportedLeads);
                    init();
                }
            }
        );

        return {
            ...toRefs(data),
            close,
            onAdvertiserSelected,
            onFlightSelected,
            dateHelper,
            dialogClosed,
            importedLeads,
            save,
            submitOnEnter,
        };
    },
});
</script>
