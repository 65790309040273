export enum AlertClass {
    ErrorNoGrpData = "ERROR_NO_GRP_DATA",
    ErrorDataNotRefreshed = "ERROR_DATA_NOT_REFRESHED",
    ErrorCronTurnedOff = "ERROR_CRON_TURNED_OFF",
    ErrorFlightWithoutActiveGoogleAnalyticsProperty = "ERROR_FLIGHT_WITHOUT_ACTIVE_GOOGLE_ANALYTICS_PROPERTY",
    ErrorFlightWithoutBudget = "ERROR_FLIGHT_WITHOUT_BUDGET",
    ErrorFlightWithUnlinkedSubOrders = "ERROR_FLIGHT_WITH_UNLINKED_SUB_ORDERS",
    ErrorPackagesWithDuplicateSpots = "ERROR_PACKAGES_WITH_DUPLICATE_SPOTS",

    WarningSpotGrpOverage = "WARNING_SPOT_GRP_OVERAGE",
    WarningSpotWrongDay = "WARNING_SPOT_WRONG_DAY",
    WarningSubOrderWithoutBudget = "WARNING_SUB_ORDER_WITHOUT_BUDGET",
    WarningTargetAudiencePackageAirtimeOutsideDaytime = "WARNING_TARGET_AUDIENCE_PACKAGE_AIRTIME_OUTSIDE_DAYTIME",
    WarningDpgMediaAirtimeOutsideDaytime = "WARNING_DPG_MEDIA_AIRTIME_OUTSIDE_DAYTIME",
    WarningYourTimeFixedSturingPackageAirtimeOutsidePrimeTime = "WARNING_YOUR_TIME_FIXED_STURING_PACKAGE_AIRTIME_OUTSIDE_PRIME_TIME",
    WarningTipConnection = "WARNING_TIP_CONNECTION",
    WarningTooLittleAirtimeToAwarenessChannels = "WARNING_TOO_LITTLE_AIRTIME_TO_AWARENESS_CHANNELS",
    WarningTooMuchAirtimeToAwarenessChannels = "WARNING_TOO_MUCH_AIRTIME_TO_AWARENESS_CHANNELS",
    WarningTooMuchAirtimeToSmallBlocks = "WARNING_TOO_MUCH_AIRTIME_TO_SMALL_BLOCKS",
    WarningTooMuchAirtimeToLargeBlocks = "WARNING_TOO_MUCH_AIRTIME_TO_LARGE_BLOCKS",
    WarningTooMuchGrpToLowPerformingTimeslotsAfternoon = "WARNING_TOO_MUCH_GRP_TO_LOW_PERFORMING_TIMESLOTS_AFTERNOON",
    WarningTooMuchGrpToLowPerformingTimeslotsOutsideDaytime = "WARNING_TOO_MUCH_BUDGET_TO_LOW_PERFORMING_TIMESLOTS_OUTSIDE_DAYTIME",
    WarningTooLittleGrpsBooked = "WARNING_TOO_LITTLE_GRPS_BOOKED",
    WarningTooLittleSpreading = "WARNING_TOO_LITTLE_SPREADING",
    WarningTooMuchAirtimeToPeak = "WARNING_TOO_MUCH_AIRTIME_TO_PEAK",

    SuccessAirtimeToAwarenessChannels = "SUCCESS_CORRECT_AIRTIME_TO_AWARENESS_CHANNELS",
    SuccessCorrectAirtimeToSmallBlocks = "SUCCESS_CORRECT_AIRTIME_TO_SMALL_BLOCKS",
    SuccessCorrectAirtimeToLargeBlocks = "SUCCESS_CORRECT_AIRTIME_TO_LARGE_BLOCKS",
    SuccessCorrectAirtimeToPeak = "SUCCESS_AIRTIME_TO_PEAK",
}
