<template>
    <el-form class="table-actions">
        <el-button type="secondary" @click="this.navigateToDataSets()"
            >Beheer BigQuery datasets</el-button
        >
    </el-form>

    <div>
        <el-upload
            class="uploader"
            drag
            multiple
            :action="this.backendUrl + '/api/settings/bigquery/service-accounts'"
            :headers="{ Authorization: 'Bearer ' + this.accessToken }"
            :show-file-list="false"
            @error="this.onDoneUploading"
            @progress="this.onBusyUploading"
            @success="this.onDoneUploading"
        >
            <el-icon name="upload"></el-icon>
            <div v-if="!this.isUploading" class="el-upload__text">
                Sleep hier bestanden naartoe om ze te uploaden, of klik dit veld aan om een bestand
                te selecteren...
            </div>
            <div v-if="this.isUploading">
                <el-icon name="loading" />
            </div>
        </el-upload>
    </div>

    <el-table
        v-loading="this.loading"
        :default-sort="{ prop: 'created_at', order: 'descending' }"
        :data="this.serviceAccounts"
        width="100%"
        @sort-change="this.sortingChanged($event)"
    >
        <el-table-column
            class-name="column-project-reference"
            label="Project"
            min-width="150"
            prop="project_reference"
            sortable
        >
            <template #default="scope">
                <span :title="scope.row.project_reference">{{ scope.row.project_reference }}</span>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-created-at"
            label="Aangemaakt"
            min-width="100"
            prop="created_at"
            sortable
        >
            <template #default="scope">
                <span :title="this.getFormattedDate(scope.row.created_at)">{{
                    this.getFormattedDate(scope.row.created_at)
                }}</span>
            </template>
        </el-table-column>
        <el-table-column class-name="column-actions" fixed="right">
            <template #default="scope">
                <el-button
                    :icon="'el-icon-' + (this.isDeleting === scope.row?.id ? 'loading' : 'delete')"
                    :disabled="this.isDeleting === scope.row?.id"
                    plain="plain"
                    size="mini"
                    title="Verwijder"
                    type="danger"
                    @click="this.onDelete(scope)"
                ></el-button>
            </template>
        </el-table-column>
    </el-table>

    <el-pagination
        :background="false"
        :current-page="this.currentPage"
        :hide-on-single-page="false"
        :page-count="this.pages"
        :page-size="this.perPage"
        :page-sizes="this.pageSizes"
        :total="this.totalItems"
        layout="sizes, prev, pager, next, jumper, ->, total"
        @current-change="this.goToPage"
        @next-click="this.goToPage"
        @prev-click="this.goToPage"
        @size-change="this.pageSizeChange"
    />
</template>

<script lang="ts">
import { AxiosError } from "axios";
import { ElMessage, ElMessageBox } from "element-plus";
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { Store, useStore } from "vuex";

import DateHelper from "@/helper/date-helper";
import ServiceAccountApiService from "@/service/api/settings/bigquery/serviceAccount";
import { Copy } from "@/service/clipboard/copy";
import config from "@/service/config";
import { Authentication } from "@/types/credentials/authentication";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { DataResult } from "@/types/result/dataResult";
import { ErrorResult } from "@/types/result/errorResult";
import { RootState } from "@/types/state/root";
import { ServiceAccount } from "@/types/uzs/settings/bigquery/serviceAccount";

type CurrentRow = {
    row: ServiceAccount;
    rowIndex: number;
};

type SortEvent = {
    column: string;
    order: string;
    prop: string;
};

export default defineComponent({
    name: "List",
    setup() {
        const store = useStore() as Store<RootState>;
        const auth = store.state.authentication as Authentication;
        const router = useRouter();
        const textCopier = new Copy();
        const serviceAccountApi = new ServiceAccountApiService();

        /* eslint-disable  @typescript-eslint/no-explicit-any */
        const data = reactive({
            backendUrl: config.backend,
            currentDataSet: {} as ServiceAccount,
            currentPage: 1,
            isDeleting: -1,
            isUploading: false,
            loading: true,
            filter: {},
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            order: {
                created_at: "desc",
            } as OrderRequest,
            page: 1,
            pages: undefined as number | undefined,
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            perPage: 25,
            totalPages: 1,
            totalItems: 0,
            serviceAccounts: [] as ServiceAccount[],
            sortingEnabled: false,
            updateTimeout: null,
        }) as any;

        function getFormattedDate(date: string | null): string {
            if (!date) {
                return "";
            }

            return DateHelper.dateTime(date);
        }

        function goToPage(page: number) {
            if (!data?.pagination) {
                return;
            }

            data.currentPage = page;
            data.pagination.page = page;

            init();
        }

        function init(loading = true) {
            data.loading = loading;
            data.sortingEnabled = false;

            const params = {
                filter: data.filter,
                pagination: data.pagination,
                sort: data.order,
            };

            serviceAccountApi
                .list(params)
                .then((response: DataResult<ServiceAccount[]>) => {
                    data.serviceAccounts = response?.data as ServiceAccount[];

                    data.page = response.pagination?.page ?? 1;
                    data.perPage = response.pagination?.per_page ?? 1;
                    data.totalPages = response.pagination?.total_pages ?? 1;
                    data.totalItems = response.pagination?.total_items ?? 0;

                    data.loading = false;
                    data.sortingEnabled = true;
                })
                .catch((error: AxiosError) => {
                    const response = error.response?.data as ErrorResult;

                    if (!response?.error) {
                        return;
                    }

                    ElMessage({
                        message: response.message,
                        type: "error",
                    });
                });
        }

        function updateOnInterval() {
            if (data.updateTimeout) {
                clearInterval(data.updateTimeout);
            }

            if (!data.loading) {
                init(false);
            }

            data.updateTimeout = setTimeout(() => {
                updateOnInterval();
            }, 3000);
        }

        function onBusyUploading() {
            data.isUploading = true;
        }

        function onDelete(value: CurrentRow): void {
            ElMessageBox.confirm(
                "Weet je zeker dat je het service account " +
                    value.row.project_reference +
                    " wilt verwijderen?",
                "Verwijderen?",
                {
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nee",
                    type: "warning",
                }
            ).then(() => {
                data.isDeleting = true;
                serviceAccountApi.delete(value.row.guid).then(() => {
                    data.isDeleting = false;

                    init(true);
                });
            });
        }

        function onDoneUploading(error: Error | undefined): void {
            data.isUploading = false;

            init(true);

            if (!error) {
                return;
            }

            const errorData: ErrorResult = JSON.parse(error.message);

            ElMessage({
                message: errorData.message,
                type: "error",
            });
        }

        function navigateToDataSets(): void {
            router.push({ name: "settings.google-big-query.datasets" });
        }

        function pageSizeChange(pageSize: number) {
            if (!data?.pagination) {
                return;
            }

            data.pagination.per_page = pageSize;

            init();
        }

        function sortingChanged($event: SortEvent) {
            const property = $event.prop?.length ? $event.prop : "created_at";
            const direction = $event.order === "descending" ? "desc" : "asc";

            if (!data.serviceAccounts || !data.order || !data.sortingEnabled) {
                return;
            }

            data.order = {
                [property]: direction,
            };

            init();
        }

        onBeforeRouteLeave(() => {
            if (data.updateTimeout) {
                clearTimeout(data.updateTimeout);
            }
        });

        onMounted(() => {
            Object.assign(data.pagination, {
                per_page: config.pagination.per_page,
            });

            Object.assign(data.order, {
                created_at: "desc",
            });

            init();
            updateOnInterval();
        });

        onBeforeRouteLeave(() => {
            if (data.updateTimeout) {
                clearInterval(data.updateTimeout);
            }
        });

        return {
            ...toRefs(data),
            accessToken: auth.token,
            getFormattedDate,
            goToPage,
            init,
            navigateToDataSets,
            pageSizeChange,
            sortingChanged,
            textCopier,
            onBusyUploading,
            onDelete,
            onDoneUploading,
        };
    },
});
</script>

<style lang="scss">
div.el-upload,
div.el-upload-dragger {
    width: 100%;
}
</style>
