export enum AlertMessage {
    NoGrpData = "Deze campagne bevat geen GRP-data en is dus niet te optimaliseren.",
    DataNotRefreshed = "De data van [operator] is te lang niet ververst. Importeer nieuwe uitzenddata.",
    CorrectAirtimeToAwarenessChannels = "Voor deze campagnedoelstelling is de juiste hoeveelheid zendtijd op Awareness Channels aanwezig in het mediaplan.",
    CorrectAirtimeToSmallBlocks = "Voor deze campagnedoelstelling is de juiste hoeveelheid zendtijd geboekt in kleine blokken.",
    CorrectAirtimeToLargeBlocks = "Voor deze campagnedoelstelling is de juiste hoeveelheid zendtijd geboekt in grote blokken.",
    CorrectAirtimeToPeak = "Voor deze campagnedoelstelling is de juiste hoeveelheid zendtijd geboekt in peak blokken.",
    CronTurnedOff = "Voor deze campagne staat de crontaak uit.",
    FlightWithoutActiveGoogleAnalyticsProperty = "Aan deze campagne is geen actieve Google Analytics property gekoppeld.",
    FlightWithoutBudget = "Voor deze campagne is nog geen budget ingesteld.",
    FlightWithUnlinkedSubOrders = "",
    ErrorPackagesWithDuplicateSpots = "Binnen deze campagne zijn er spots die in meerdere pakketten voorkomen.",
    SpotGrpOverage = "Deze campagne bevat spots met meer dan <b class='red'>12 GRP</b>",
    SpotWrongDay = "Spotinzet op dagen die niet zijn toegestaan",
    SpotOutsideCampaign = "Spotinzet buiten de campagne",
    SubOrderWithoutBudget = "Binnen deze campagne is niet voor elk pakket een budget ingesteld.",
    TargetAudiencePackageAirtimeOutsideDaytime = "De campagne bevat [grp] ([percentage]) zendtijd <b>buiten</b> het tijdvak 11.00-23.00u (Pakket: doelgroep, thematics of ratecard).",
    DpgMediaAirtimeOutsideDaytime = "De campagne bevat [grp] ([percentage]) zendtijd <b>buiten</b> het tijdvak 11.00-23.00u (Exploitant: DPG Media).",
    YourTimeFixedSturingPackageAirtimeOutsidePrimeTime = "De campagne bevat [grp] ([percentage]) zendtijd <b>buiten</b> het tijdvak 18.30-23.00u (Pakketten: your time, fixed, sturing).",
    TipConnection = "Voor deze campagne lijkt de koppeling met TIP niet goed te werken.",
    TooLittleAirtimeToAwarenessChannels = "Voor deze campagnedoelstelling is er te weinig zendtijd op Awareness Channels aanwezig in het mediaplan.",
    TooMuchAirtimeToAwarenessChannels = "Voor deze campagnedoelstelling is er teveel zendtijd op Awareness Channels aanwezig in het mediaplan.",
    TooMuchAirtimeToSmallBlocks = "Voor deze campagnedoelstelling is er teveel zendtijd geboekt in kleine blokken.",
    TooMuchAirtimeToLargeBlocks = "Voor deze campagnedoelstelling is er teveel zendtijd geboekt in grote blokken.",
    TooMuchBudgetToLowPerformingTimeslotsAfternoon = "Voor deze campagnedoelstelling gaat er teveel budget naar slecht presterende tijdvakken.",
    TooMuchBudgetToLowPerformingTimeslotsOutsideDaytime = "Voor deze campagnedoelstelling gaat er teveel budget naar slecht presterende tijdvakken.",
    TooLittleGrpsBooked = "Deze campagne bevat pakketten waar aanzienlijk minder GRP's zijn ingedeeld dan aangevraagd.",
    TooLittleSpreading = "Spreiding van het budget is niet optimaal over de complete campagneperiode.",
    TooMuchPeakAirtime = "Voor deze campagnedoelstelling is er teveel zendtijd geboekt in peak blokken.",
}
