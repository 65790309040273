
import { ElMessage } from "element-plus";
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs, watch } from "vue";
import { Store, useStore } from "vuex";

import { DataResult } from "@/types/result/dataResult";
import { ImportedFileState } from "@/types/state/importedFile";
import { RootState } from "@/types/state/root";
import { ImportedFile } from "@/types/uzs/importedFile";
import { ImportMapping } from "@/types/uzs/importMapping";
import { ImportTemplate } from "@/types/uzs/importTemplate";

const props = {
    value: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
    importedFile: {
        type: Object as PropType<ImportedFile>,
        required: true,
    },
};

export default defineComponent({
    name: "ImportedFileMappingDialog",
    props,
    setup(props, { emit }) {
        const importedFile = ref(props.importedFile ?? {}) as Ref<ImportedFile | undefined>;
        const store = useStore() as Store<RootState>;
        const state = store.state.importedFile as ImportedFileState;

        const actions = {
            importTemplate: {
                all: () =>
                    store.dispatch("importTemplate/_list", {
                        pagination: {
                            per_page: 1000,
                        },
                        order: {
                            name: "asc",
                        },
                    }),
            },
            importedFile: {
                store: (importedFile: ImportedFile) =>
                    store.dispatch("importedFile/_store", importedFile),
            },
        };

        const data = reactive({
            selectedTemplateId: null as number | null,
            allTemplates: [] as ImportTemplate[],
            customizeTemplate: false,
        });

        const close = (): void => {
            importedFile.value = {} as ImportedFile;
            data.selectedTemplateId = null;

            emit("input", props.value);
        };

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        const loadAllTemplates = (): void => {
            actions.importTemplate.all().then((result: DataResult<ImportTemplate[]>) => {
                data.allTemplates = result.data ?? [];
            });
        };

        const onSelectedTemplateChanged = () => {
            const selectedTemplate = data.allTemplates.find(
                (template: ImportTemplate) => template.id === data.selectedTemplateId
            );

            if (!importedFile.value || !selectedTemplate) {
                return;
            }

            importedFile.value.country = selectedTemplate.country;
            importedFile.value.operator = selectedTemplate.operator;
            importedFile.value.mapping = selectedTemplate.mapping;
        };

        const save = (): void => {
            if (!importedFile?.value) {
                return;
            }

            actions.importedFile
                .store(importedFile.value)
                .then(() => {
                    ElMessage({
                        message: importedFile?.value?.filename + " is opgeslagen.",
                        type: "success",
                    });

                    close();
                })
                .catch(() => {
                    ElMessage({
                        message:
                            "Er is een fout opgetreden bij het opslaan van " +
                            importedFile?.value?.filename,
                        type: "error",
                    });
                });
        };

        const submitOnEnter = (event: KeyboardEvent): void => {
            const input = event.target as HTMLElement | null;

            if (
                event.key.toLowerCase() === "enter" &&
                input?.tagName?.toLowerCase() !== "textarea"
            ) {
                save();
            }
        };

        onMounted(() => {
            loadAllTemplates();
        });

        watch(
            () => props.importedFile,
            (value, oldValue) => {
                if (value !== oldValue) {
                    importedFile.value =
                        value ?? ({ mapping: {} as ImportMapping } as ImportedFile);

                    if (!importedFile.value?.mapping) {
                        importedFile.value.mapping = {} as ImportMapping;
                    }

                    loadAllTemplates();
                }
            }
        );

        return {
            ...toRefs(data),
            ...toRefs(state),
            close,
            importedFile,
            dialogClosed,
            onSelectedTemplateChanged,
            save,
            submitOnEnter,
        };
    },
});
