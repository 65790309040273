import axios from "axios";
import { ActionContext } from "vuex";

import config from "@/service/config";
import { DataResult } from "@/types/result/dataResult";
import { SuccessResult } from "@/types/result/successResult";
import { ImportTemplateState } from "@/types/state/importTemplate";
import { RootState } from "@/types/state/root";
import { ImportTemplate } from "@/types/uzs/importTemplate";

import { RequestParameters } from "../../types/request/requestParameters";

const initialState: ImportTemplateState = {
    columns: undefined,
    filter: {},
    order: {},
    pagination: {
        page: 1,
        per_page: config.pagination.per_page,
    },
    search: undefined,
    importTemplate: undefined,
    importTemplates: undefined,
};

const ImportTemplateModule = {
    namespaced: true,
    state: initialState,
    getters: {},
    mutations: {},
    actions: {
        _delete(
            context: ActionContext<ImportTemplateState, RootState>,
            id: number
        ): Promise<SuccessResult> {
            return axios
                .delete(config.backend + "/api/uzs/import-template/" + id)
                .then((response) => response?.data)
                .then((response) => response as SuccessResult);
        },
        _list(
            context: ActionContext<ImportTemplateState, RootState>,
            params: RequestParameters | null
        ): Promise<DataResult<ImportTemplate[]>> {
            return axios
                .get(config.backend + "/api/uzs/import-templates", {
                    params: {
                        columns: params?.columns ?? context.state.columns,
                        filter: params?.filter ?? context.state.filter,
                        order: params?.order ?? context.state.order,
                        pagination: params?.pagination ?? context.state.pagination,
                        search: params?.search ?? context.state.search,
                    },
                })
                .then((response) => response?.data as DataResult<ImportTemplate[]>)
                .then((response) => {
                    context.state.importTemplates = response;
                    return response;
                });
        },
        _details(
            context: ActionContext<ImportTemplateState, RootState>,
            id: number
        ): Promise<DataResult<ImportTemplate>> {
            return axios
                .get(config.backend + "/api/uzs/import-template/" + id)
                .then((response) => response?.data as DataResult<ImportTemplate>)
                .then((response) => {
                    context.state.importTemplate = response;
                    return response;
                });
        },
        _store(
            context: ActionContext<ImportTemplateState, RootState>,
            importTemplate: ImportTemplate
        ): Promise<DataResult<ImportTemplate>> {
            return axios
                .post(config.backend + "/api/uzs/import-template", importTemplate)
                .then((response) => response?.data as DataResult<ImportTemplate>)
                .then((response) => {
                    context.state.importTemplate = response;
                    return response;
                });
        },
    },
};

export default ImportTemplateModule;
