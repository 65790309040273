<template>
    <el-form class="table-actions">
        <el-select
            v-model="this.type"
            class="type"
            placeholder="Type"
            :multiple="true"
            @change="this.onSelectedTypeChanged"
        >
            <el-option
                v-for="type of ['INFO', 'WARNING', 'ERROR']"
                :key="type"
                :value="type"
                :label="type"
                >{{ type }}</el-option
            >
        </el-select>
    </el-form>

    <el-table
        v-loading="this.loading"
        :default-sort="{ prop: 'start_date', order: 'descending' }"
        :data="this.logs?.data"
        :row-class-name="this.getRowClassName"
        width="100%"
    >
        <el-table-column class-name="column-type" label="Type" min-width="150" prop="type">
            <template #default="scope">
                <span :title="scope.row.type">{{ scope.row.type }}</span>
            </template>
        </el-table-column>
        <el-table-column class-name="column-message" label="Message" min-width="350" prop="message">
            <template #default="scope">
                <span :title="scope.row.message">{{ scope.row.message }}</span>
            </template>
        </el-table-column>
        <el-table-column class-name="column-date" label="Begintijd" min-width="150" prop="date">
            <template #default="scope">
                {{ this.getFormattedDate(scope.row.date ?? "") }}
            </template>
        </el-table-column>
        <el-table-column class-name="column-channel" label="Kanaal" min-width="125" prop="channel">
            <template #default="scope">
                {{ scope.row.channel }}
            </template>
        </el-table-column>
    </el-table>

    <el-pagination
        :background="false"
        :current-page="this.currentPage"
        :hide-on-single-page="false"
        :page-count="this.pages"
        :page-size="this.perPage"
        :page-sizes="this.pageSizes"
        :total="this.logs?.pagination?.total_items"
        layout="sizes, prev, pager, next, jumper, ->, total"
        @current-change="this.goToPage"
        @next-click="this.goToPage"
        @prev-click="this.goToPage"
        @size-change="this.pageSizeChange"
    />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, toRefs } from "vue";
import { Store, useStore } from "vuex";

import DateHelper from "@/helper/date-helper";
import { Copy } from "@/service/clipboard/copy";
import config from "@/service/config";
import { FilterRequest } from "@/types/request/filterRequest";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { RequestParameters } from "@/types/request/requestParameters";
import { LogState } from "@/types/state/log";
import { RootState } from "@/types/state/root";

export default defineComponent({
    name: "List",
    setup() {
        const store = useStore() as Store<RootState>;
        const state = store.state.log as LogState;
        const textCopier = new Copy();

        const actions = {
            log: {
                list: (params: RequestParameters) => store.dispatch("log/_list", params),
            },
        };

        /* eslint-disable  @typescript-eslint/no-explicit-any */
        const data = reactive({
            currentPage: 1 as number,
            loading: true,
            filter: {} as FilterRequest,
            type: "",
            order: {
                date: "desc",
            } as OrderRequest,
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            page: computed(() => data.pagination?.page as number | undefined),
            pages: computed(() => state.logs?.pagination?.total_pages as number | undefined),
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            perPage: computed(() => data.pagination?.per_page as number | undefined),
        }) as any;

        const getFormattedDate = (date: string | null): string => {
            if (!date) {
                return "";
            }

            return DateHelper.dateTime(date);
        };

        const goToPage = (page: number) => {
            if (!data.pagination) {
                return;
            }

            data.currentPage = page;
            data.pagination.page = page;

            update();
        };

        const pageSizeChange = (pageSize: number) => {
            if (!data.pagination) {
                return;
            }

            data.pagination.per_page = pageSize;

            update();
        };

        const onSelectedTypeChanged = () => {
            if (!data.type.length) {
                data.filter = {};
                update();
                return;
            }

            data.filter = {
                type: {
                    operator: "LIKE",
                    value: data.type,
                },
            };

            update();
        };

        const update = () => {
            data.loading = true;
            actions.log
                .list({
                    filter: data.filter,
                    order: data.order,
                    pagination: data.pagination,
                })
                .then(() => {
                    data.loading = false;
                });
        };

        onMounted(() => {
            update();
        });

        return {
            ...toRefs(data),
            ...toRefs(state),
            getFormattedDate,
            goToPage,
            onSelectedTypeChanged,
            pageSizeChange,
            textCopier,
            update,
        };
    },
});
</script>
