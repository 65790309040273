import axios from "axios";
import { ActionContext } from "vuex";

import config from "@/service/config";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { SuccessResult } from "@/types/result/successResult";
import { TargetGroup as ScreenForceTargetGroup } from "@/types/screen-force/targetGroup";
import { RootState } from "@/types/state/root";
import { TargetGroupState } from "@/types/state/targetGroup";
import { TargetGroup } from "@/types/uzs/targetGroup";

const initialState: TargetGroupState = {
    columns: undefined,
    filter: {},
    order: {
        name: "asc",
    },
    pagination: {
        page: 1,
        per_page: config.pagination.per_page,
    },
    search: undefined,
    screenForceTargetGroups: undefined,
    screenForceTargetGroup: undefined,
    targetGroups: undefined,
    targetGroup: undefined,
};

const TargetGroupModule = {
    namespaced: true,
    state: initialState,
    getters: {},
    mutations: {},
    actions: {
        _all_screen_force(): Promise<DataResult<ScreenForceTargetGroup[]>> {
            return axios
                .get(config.backend + "/api/uzs/target-groups/all/screen-force")
                .then((response) => response?.data as DataResult<ScreenForceTargetGroup[]>)
                .then((response) => {
                    return response;
                });
        },
        _delete(
            context: ActionContext<TargetGroupState, RootState>,
            id: number
        ): Promise<SuccessResult> {
            return axios
                .delete(config.backend + "/api/uzs/target-group/" + id)
                .then((response) => response?.data)
                .then((response) => response as SuccessResult);
        },
        _list(
            context: ActionContext<TargetGroupState, RootState>,
            params: RequestParameters | null
        ): Promise<DataResult<TargetGroup[]>> {
            return axios
                .get(config.backend + "/api/uzs/target-groups", {
                    params: {
                        columns: params?.columns ?? context.state.columns,
                        filter: params?.filter ?? context.state.filter,
                        order: params?.order ?? context.state.order,
                        pagination: params?.pagination ?? context.state.pagination,
                        search: params?.search ?? context.state.search,
                    },
                })
                .then((response) => response?.data as DataResult<TargetGroup[]>)
                .then((response) => {
                    context.state.targetGroups = response;
                    return response;
                });
        },
        _list_screen_force(
            context: ActionContext<TargetGroupState, RootState>,
            params: RequestParameters | null
        ): Promise<DataResult<ScreenForceTargetGroup[]>> {
            return axios
                .get(config.backend + "/api/uzs/target-groups/screen-force", {
                    params: {
                        columns: params?.columns ?? context.state.columns,
                        filter: params?.filter ?? context.state.filter,
                        order: params?.order ?? context.state.order,
                        pagination: params?.pagination ?? context.state.pagination,
                        search: params?.search ?? context.state.search,
                    },
                })
                .then((response) => response?.data as DataResult<ScreenForceTargetGroup[]>)
                .then((response) => {
                    context.state.screenForceTargetGroups = response;
                    return response;
                });
        },
        _details(
            context: ActionContext<TargetGroupState, RootState>,
            id: number
        ): Promise<DataResult<TargetGroup>> {
            return axios
                .get(config.backend + "/api/uzs/target-group/" + id)
                .then((response) => response?.data as DataResult<TargetGroup>)
                .then((response) => {
                    context.state.targetGroup = response;
                    return response;
                });
        },
        _store(
            context: ActionContext<TargetGroupState, RootState>,
            data: TargetGroup
        ): Promise<DataResult<TargetGroup>> {
            return axios
                .post(config.backend + "/api/uzs/target-group", data)
                .then((response) => response?.data as DataResult<TargetGroup>)
                .then((response) => {
                    context.state.targetGroup = response;
                    return response;
                });
        },
    },
};

export default TargetGroupModule;
