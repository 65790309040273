import copy from "copy-to-clipboard";
import { ElMessage } from "element-plus";

export class Copy {
    private readonly successMessage: string;
    private readonly failureMessage: string;

    public constructor(successMessage?: string, failureMessage?: string) {
        this.successMessage = successMessage ?? "De link is gekopiëerd naar het klembord";
        this.failureMessage =
            failureMessage ?? "De link kon helaas niet gekopiëerd worden naar het klembord";
    }

    public toClipboard(text: string): void {
        const copied = copy(text);

        if (copied) {
            ElMessage({
                duration: 2000,
                message: this.successMessage,
                type: "success",
            });
        }

        if (!copied) {
            ElMessage({
                duration: 2000,
                message: this.failureMessage,
                type: "warning",
            });
        }
    }
}
