<template>
    <div v-if="this.operators.length">
        <h3>Spreiding van pakketten</h3>
        <div class="operators" v-for="operator of operators" :key="operator.name">
            <div class="operator" @click="() => onSelectOperator(operator.name)">
                <span>{{ operator.name }}</span>
                <div class="updated_at">{{ this.getLastUpdateForOperator(operator.name) }}</div>
                <div class="collapse">
                    <i
                        class="expand-icon"
                        :class="{
                            ['el-icon-minus']: this.selectedOperators.includes(operator.name),
                            ['el-icon-plus']: !this.selectedOperators.includes(operator.name),
                        }"
                    ></i>
                </div>
            </div>
            <div class="spreading-table" v-if="this.selectedOperators.includes(operator.name)">
                <table cellpadding="0" cellspacing="0" style="width: 100%">
                    <thead>
                        <tr>
                            <th class="head order" style="padding-left: 10px; width: 90px">
                                Order
                            </th>
                            <th class="head period">Looptijd</th>
                            <th class="head package">Pakket</th>
                            <th class="head requested">Aangevraagd</th>
                            <th class="head booked">Geboekt</th>
                            <th class="head target">Target</th>
                            <th class="head budget" style="padding-right: 10px; width: auto">
                                Budget
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="item of this.getSpreadingForOperator(operator.name)"
                            :key="item.reference"
                        >
                            <td class="cell order" style="padding-left: 10px; width: 100px">
                                <span
                                    class="ellipsis"
                                    :class="
                                        item.order_reference.toLowerCase().indexOf('n/a') !== -1
                                            ? 'is--greyed-out'
                                            : ''
                                    "
                                    :title="
                                        item.order_reference.toLowerCase().indexOf('n/a') !== -1
                                            ? 'n/a'
                                            : item.order_reference
                                    "
                                >
                                    {{
                                        item.order_reference.toLowerCase().indexOf("n/a") !== -1
                                            ? "n/a"
                                            : item.order_reference
                                    }}
                                </span>
                            </td>
                            <td class="cell period" style="width: 150px">
                                <span
                                    :title="
                                        dateHelper.displayPeriod(item.start_date, item.end_date)
                                    "
                                >
                                    {{
                                        dateHelper.displayPeriod(item.start_date, item.end_date)
                                    }}</span
                                >
                            </td>
                            <td class="cell package" style="width: 300px">
                                <span class="ellipsis" :title="item.description">{{
                                    item.description
                                }}</span>
                            </td>
                            <td class="cell requested" style="width: 130px">
                                <span class="ellipsis">{{
                                    this.grpHelper.display(item.requested_grps)
                                }}</span>
                            </td>
                            <td class="cell booked" style="width: 80px">
                                <span class="ellipsis">{{
                                    this.grpHelper.display(item.booked_grps)
                                }}</span>
                            </td>
                            <td class="cell target" style="width: 80px">
                                <span
                                    class="ellipsis"
                                    :class="getTargetClassName(item.target)"
                                    style="text-align: right"
                                    >{{
                                        item.target > 0.0
                                            ? this.percentageHelper.display(item.target, true, true)
                                            : "n/a"
                                    }}</span
                                >
                            </td>
                            <td class="cell budget" style="padding-right: 10px; width: auto">
                                <span class="ellipsis" style="text-align: right">{{
                                    this.priceHelper.display(item.budget, 0)
                                }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="cell order" style="padding-left: 10px; width: 100px">
                                <span class="is--bold">Totaal</span>
                            </td>
                            <td class="cell period" style="width: 150px"></td>
                            <td class="cell package" style="width: 300px"></td>
                            <td class="cell requested" style="width: 130px">
                                <span class="ellipsis is--bold">{{
                                    this.grpHelper.display(
                                        this.getSummaryForOperator(operator.name).requested_grps
                                    )
                                }}</span>
                            </td>
                            <td class="cell booked" style="width: 80px">
                                <span class="ellipsis is--bold">{{
                                    this.grpHelper.display(
                                        this.getSummaryForOperator(operator.name).booked_grps
                                    )
                                }}</span>
                            </td>
                            <td class="cell target" style="width: 80px">
                                <span
                                    class="ellipsis is--bold"
                                    :class="
                                        getTargetClassName(
                                            this.getSummaryForOperator(operator.name).target
                                        )
                                    "
                                    style="text-align: right"
                                    >{{
                                        this.getSummaryForOperator(operator.name).target > 0.0
                                            ? this.percentageHelper.display(
                                                  this.getSummaryForOperator(operator.name).target,
                                                  true,
                                                  true
                                              )
                                            : "n/a"
                                    }}</span
                                >
                            </td>
                            <td class="cell budget" style="padding-right: 10px; width: auto">
                                <span class="ellipsis is--bold" style="text-align: right">{{
                                    this.priceHelper.display(
                                        this.getSummaryForOperator(operator.name).budget,
                                        0
                                    )
                                }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs, watch } from "vue";

import DateHelper from "@/helper/date-helper";
import GrpHelper from "@/helper/grp-helper";
import NumberHelper from "@/helper/number-helper";
import PercentageHelper from "@/helper/percentage-helper";
import PriceHelper from "@/helper/price-helper";
import CampaignInsightApiService from "@/service/api/campaignInsight";
import { OperatorData, SpreadingData } from "@/types/uzs/campaign-insight/campaignPackageSpreading";

const props = {
    campaignId: {
        type: Number as PropType<number>,
        required: true,
    },
};

export default defineComponent({
    name: "PackageSpreadingTable",
    props,
    setup(props) {
        const campaignId = ref(props.campaignId) as Ref<number>;
        const insightApiService = new CampaignInsightApiService();
        const dateHelper = DateHelper;
        const grpHelper = GrpHelper;
        const numberHelper = NumberHelper;
        const percentageHelper = PercentageHelper;
        const priceHelper = PriceHelper;

        const data = reactive({
            operators: [] as OperatorData[],
            selectedOperators: [] as string[],
            spreading: [] as SpreadingData[],
            totalRequestedGrps: 0,
            totalBookedGrps: 0,
            totalBudget: 0,
        });

        function init(): void {
            data.spreading = [];

            insightApiService.fetchPackageSpreading(campaignId.value).then((response) => {
                data.operators =
                    response.data?.operators.filter((o) => o.requested_grps >= 0.0) ?? [];

                data.spreading =
                    response.data?.spreading.filter((s) => s.requested_grps >= 0.0) ?? [];
            });
        }

        function getLastUpdateForOperator(operator: string): string {
            const summary = getSummaryForOperator(operator);

            if (!summary?.last_update) {
                return "";
            }

            return "Laatste wijziging " + DateHelper.display(summary.last_update, true, true, true);
        }

        function getTargetClassName(target: number): string {
            if (target === 0.0) {
                return "is--greyed-out";
            }

            if (target < 95.0) {
                return "is--bold has--error";
            }

            if (target > 105) {
                return "is--bold has--warning";
            }

            return "";
        }

        function getSummaryForOperator(operator: string) {
            const foundOperators = data.operators.filter((s) => s.name === operator);
            return foundOperators[0] ?? null;
        }

        function getSpreadingForOperator(operator: string) {
            const summary = getSummaryForOperator(operator);
            let spreading = data.spreading.filter(
                (s) => s.operator === operator && s.requested_grps >= 0
            );

            if (summary.requested_grps > 0) {
                spreading = spreading.filter((s) => s.requested_grps > 0);
            }

            if (spreading.length === 1 && spreading[0].budget === 0.0) {
                spreading[0].budget = summary.budget;
            }

            return spreading;
        }

        function onSelectOperator(operator: string) {
            if (data.selectedOperators.includes(operator)) {
                data.selectedOperators = data.selectedOperators.filter((o) => o !== operator);
                return;
            }

            data.selectedOperators.push(operator);
        }

        onMounted(() => {
            init();
        });

        watch(
            () => props.campaignId,
            (value, oldValue) => {
                if (value !== oldValue) {
                    campaignId.value = value as number;
                    init();
                }
            }
        );

        return {
            ...toRefs(data),
            close,
            dateHelper,
            getLastUpdateForOperator,
            getTargetClassName,
            getSpreadingForOperator,
            getSummaryForOperator,
            grpHelper,
            numberHelper,
            percentageHelper,
            priceHelper,
            onSelectOperator,
        };
    },
});
</script>

<style>
h3 {
    color: initial !important;
    margin-bottom: 5px;
}

p {
    margin-bottom: 15px;
    word-break: break-word;
}

.red {
    color: red;
    font-weight: bold;
}

div.operators {
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 5px;
}

div.operators:last-of-type {
    margin-bottom: 35px;
}

div.operators div.operator {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
}

div.operators div.operator span {
    flex: 1 0 calc(28% - 38px);
    padding: 10px;
}

div.operators div.operator div.updated_at {
    flex: 1 0 calc(72% - 24px);
    font-style: italic;
    padding: 10px;
}

div.operators div.operator div.collapse {
    flex: 1 0 100%;
    max-width: 22px;
}

div.operators .expand-icon {
    float: none;
}

div.operators div.spreading-table {
    border-top: 1px solid #ddd;
}

div.operators div.spreading-table .head {
    border-bottom: 1px solid #ddd;
    color: #333;
    font-size: 1rem;
    font-style: italic;
    font-weight: bold;
    text-align: left;
    line-height: 23px;
    padding: 0.5rem 0;
}

div.operators div.spreading-table .head.target,
div.operators div.spreading-table .head.budget {
    text-align: right;
}

div.operators div.spreading-table .cell {
    border-bottom: 1px solid #ddd;
    font-size: 0.875rem;
    line-height: 23px;
    padding: 0.75rem 0;
}

div.operators div.spreading-table .cell.order span {
    max-width: 95px;
}

div.operators div.spreading-table .cell.package span {
    max-width: 295px;
}

div.operators div.spreading-table tr:hover .cell {
    background-color: whitesmoke;
}

div.operators div.spreading-table .cell.target,
div.operators div.spreading-table .cell.budget {
    text-align: right;
}

div.operators div.el-table.package-spreading {
    border-top: 1px solid #ddd;
    box-sizing: border-box;
    margin-bottom: 0;
}

div.operators div.el-table__footer-wrapper:not(:hover) td {
    background-color: transparent !important;
}

div.operators div.el-table__footer-wrapper td {
    font-weight: bold;
}

div.operators div.el-table__header-wrapper thead tr th:nth-child(6),
div.operators div.el-table__header-wrapper thead tr th:nth-child(7),
div.operators div.el-table__footer-wrapper tbody tr td:nth-child(6),
div.operators div.el-table__footer-wrapper tbody tr td:nth-child(7) {
    text-align: right;
}

div.operators span.ellipsis {
    display: block;
    width: 100%;
}
</style>
