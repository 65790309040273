<template>
    <h3>Spreiding van zenders</h3>
    <div
        v-if="items"
        class="el-table--fit el-table--enable-row-hover el-table--enable-row-transition el-table gcf"
        style="overflow-x: auto"
    >
        <div class="el-table__header-wrapper" style="overflow: visible">
            <table class="el-table__header" style="width: 100%" cellpadding="0" cellspacing="0">
                <thead>
                    <tr>
                        <th class="is-leaf" style="width: 150px">
                            <div class="cell ellipsis">Exploitant</div>
                        </th>
                        <th class="is-leaf" style="width: 110px">
                            <div class="cell ellipsis">Zender</div>
                        </th>
                        <th class="is-leaf" style="width: 110px">
                            <div class="cell ellipsis">GRP</div>
                        </th>
                        <th v-if="flight?.goal === 'LEADS'" class="is-leaf" style="width: 110px">
                            <div class="cell ellipsis">
                                {{ flightStatistics?.total_grp > 0 ? "Leads/GRP" : "Leads" }}
                            </div>
                        </th>
                        <th v-if="flight?.goal === 'LEADS'" class="is-leaf" style="width: 110px">
                            <div class="cell ellipsis">CPA</div>
                        </th>
                        <th class="is-leaf" style="width: 200px">
                            <div class="cell ellipsis">OTS</div>
                        </th>
                        <th v-if="flight?.goal !== goal.LEADS" class="is-leaf" style="width: 200px">
                            <div class="cell ellipsis">Optimalisatie</div>
                        </th>
                        <th
                            class="is-leaf"
                            :style="flight?.goal !== goal.LEADS ? 'width: 200px' : 'width: 400px'"
                        >
                            <div class="cell ellipsis">Pakket</div>
                        </th>
                    </tr>
                </thead>
            </table>
        </div>
        <div class="el-table__body-wrapper" style="overflow: visible">
            <table class="el-table__body" style="width: 100%" cellpadding="0" cellspacing="0">
                <tbody>
                    <tr class="el-table__row" v-for="item of items" :key="item">
                        <td style="width: 150px">
                            <div class="cell ellipsis" :title="item.operator">
                                {{ item.operator }}
                            </div>
                        </td>
                        <td style="width: 110px">
                            <div class="cell ellipsis" :title="item.channel_name">
                                {{ item.channel_name }}
                            </div>
                        </td>
                        <td style="width: 110px">
                            <div class="cell ellipsis" :title="grpHelper.display(item.grp)">
                                <GrpValue :value="item.grp" :is-greyed-out="!isCampaignFulfilled" />
                            </div>
                        </td>
                        <td v-if="flight?.goal === 'LEADS'" style="width: 110px">
                            <div class="cell ellipsis">
                                <LeadsPerGrpValue
                                    v-if="flightStatistics?.total_grp > -1"
                                    :value="getLeadsPerGrp(item)"
                                />
                                <LeadsValue v-else :value="item.leads" />
                            </div>
                        </td>
                        <td v-if="flight?.goal === 'LEADS'" style="width: 110px">
                            <div class="cell ellipsis" :class="getCpaClassName(item)">
                                <PriceValue :value="getCpa(item)" :should-display-zero="false" />
                            </div>
                        </td>
                        <td style="width: 200px">
                            <div
                                class="cell gcf"
                                :title="getFrequencyLabel(item)"
                                :class="getFrequencyClassName(item)"
                            >
                                {{ getFrequencyLabel(item) }}
                            </div>
                        </td>
                        <td v-if="flight?.goal !== goal.LEADS" style="width: 200px">
                            <div class="cell ellipsis" :title="getOptimizationLabel(item)">
                                {{ getOptimizationLabel(item) }}
                            </div>
                        </td>
                        <td :style="flight?.goal !== goal.LEADS ? 'width: 200px' : 'width: 400px'">
                            <div
                                class="cell ellipsis"
                                :title="item.packages_descriptions.join(', ')"
                            >
                                {{ item.packages_descriptions.join(", ") }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs, watch } from "vue";

import GrpValue from "@/components/campaigns/GrpValue.vue";
import LeadsPerGrpValue from "@/components/campaigns/LeadsPerGrpValue.vue";
import LeadsValue from "@/components/campaigns/LeadsValue.vue";
import PriceValue from "@/components/campaigns/PriceValue.vue";
import { Goal } from "@/enums/goal";
import FlightHelper from "@/helper/flight-helper";
import GrpHelper from "@/helper/grp-helper";
import PriceHelper from "@/helper/price-helper";
import StringHelper from "@/helper/string-helper";
import { Alert } from "@/types/uzs/alert";
import { FlightGcf } from "@/types/uzs/flightGcf";

const props = {
    alert: {
        type: Object as PropType<Alert>,
        required: true,
    },
};

export default defineComponent({
    name: "AlertGcfTable",
    components: { PriceValue, GrpValue, LeadsValue, LeadsPerGrpValue },
    props,
    setup(props) {
        const alert = ref(props.alert) as Ref<Alert>;
        const flightHelper = FlightHelper;
        const grpHelper = GrpHelper;
        const priceHelper = PriceHelper;
        const stringHelper = StringHelper;
        const goal = Goal;

        const data = reactive({
            flight: alert.value?.uzs_flight ?? null,
            flightStatistics: alert.value?.uzs_flight?.uzs_flight_statistics ?? null,
            items: [] as FlightGcf[],
            isCampaignFulfilled: false,
        });

        const init = (): void => {
            data.isCampaignFulfilled = flightHelper.isCampaignFulfilled(alert.value?.uzs_flight);

            collectItems();
        };

        const collectItems = (): void => {
            if (!alert.value?.uzs_flight?.uzs_flight_gcf) {
                return;
            }

            const items: FlightGcf[] = [];

            for (const gcf of alert.value.uzs_flight.uzs_flight_gcf) {
                if (!items.includes(gcf)) {
                    items.push(gcf);
                }
            }

            data.items = items;
        };

        function getCpa(item: FlightGcf): number {
            return item.grp > 0.5 ? item.cpa : 0;
        }

        function getCpaClassName(item: FlightGcf): string {
            const cpa = data.flightStatistics?.cpa ?? 0;

            if (cpa > 0 && item.cpa / 3 > cpa) {
                return "has--error is--bold";
            }

            return "";
        }

        function getFrequencyClassName(item: FlightGcf): string[] {
            if (item.channel_market_share < 0.2) {
                return ["is--greyed-out"];
            }

            if (item.frequency.toLowerCase() === "high") {
                return ["has--warning", "is--bold"];
            }

            if (item.frequency.toLowerCase() === "low") {
                return ["has--error", "is--bold"];
            }

            return [];
        }

        const getFrequencyLabel = (item: FlightGcf): string => {
            if (item.channel_market_share < 0.2) {
                return "n/a";
            }

            if (item.frequency === "HIGH") {
                return "GCF te hoog";
            }

            if (item.frequency === "LOW") {
                return "GCF te laag";
            }

            return "GCF is gunstig";
        };

        const getLeadsPerGrp = (item: FlightGcf): number => {
            return item.allocated_leads_grp > 0.5 ? item.leads / item.allocated_leads_grp : 0;
        };

        const getOptimizationLabel = (item: FlightGcf): string => {
            if (item.optimization > 0.0) {
                return "ca. " + grpHelper.display(item.optimization) + " erbij";
            }

            if (item.optimization < 0.0) {
                return "ca. " + grpHelper.display(Math.abs(item.optimization)) + " eraf";
            }

            return "";
        };

        onMounted(() => {
            init();
        });

        watch(
            () => props.alert,
            (value, oldValue) => {
                if (value !== oldValue) {
                    alert.value = value ?? ({} as Alert);
                    init();
                }
            }
        );

        return {
            ...toRefs(data),
            alert,
            getCpa,
            getCpaClassName,
            getFrequencyClassName,
            getFrequencyLabel,
            getLeadsPerGrp,
            getOptimizationLabel,
            goal,
            grpHelper,
            priceHelper,
            stringHelper,
        };
    },
});
</script>

<style scoped>
h3 {
    color: initial !important;
}

div.el-table.gcf {
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
}

div.cell.gcf.high {
    color: #ff9900 !important;
    font-weight: bold;
}

div.cell.gcf.low {
    color: #ff0000 !important;
    font-weight: bold;
}
</style>
