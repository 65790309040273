
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs, watch } from "vue";

import GrpHelper from "@/helper/grp-helper";
import { Alert } from "@/types/uzs/alert";
import { GrpSpreadingType } from "@/types/uzs/alert/grp-spreading-data";
import { SpotSpreading } from "@/types/uzs/alert/spot";

const props = {
    alert: {
        type: Object as PropType<Alert>,
        required: true,
    },
    message: {
        type: String as PropType<string>,
    },
};

export default defineComponent({
    name: "AlertSpreadingTable",
    props,
    setup(props) {
        const alert = ref(props.alert) as Ref<Alert>;
        const message = ref(props.message) as Ref<string>;
        const grpHelper = GrpHelper;
        const spreadingType = GrpSpreadingType;

        const data = reactive({
            dates: [] as string[],
            spreading: [] as Array<SpotSpreading>,
        });

        const init = (): void => {
            data.spreading = alert.value.data as SpotSpreading[];
            data.spreading.forEach((spreading) => {
                if (data.dates.includes(spreading.date_string)) {
                    return;
                }

                data.dates.push(spreading.date_string);
            });
        };

        const getDates = (): string => {
            let dates = "";

            for (const date of data.dates) {
                dates +=
                    (data.dates.length > 1 && data.dates.indexOf(date) === data.dates.length - 1
                        ? "</b> en <b>"
                        : data.dates.indexOf(date) > 0
                        ? "</b>, <b>"
                        : "") + date.toLowerCase();
            }

            return dates;
        };

        onMounted(() => {
            init();
        });

        watch(
            () => props.alert,
            (value, oldValue) => {
                if (value !== oldValue) {
                    alert.value = value ?? ({} as Alert);
                    init();
                }
            }
        );

        watch(
            () => props.message,
            (value, oldValue) => {
                if (value !== oldValue) {
                    message.value = value ?? "";
                    init();
                }
            }
        );

        return {
            ...toRefs(data),
            close,
            getDates,
            grpHelper,
            spreadingType,
        };
    },
});
