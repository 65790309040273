<template>
    <span :class="getClassName()">{{ getValue() }}</span>
</template>
<script lang="ts">
import { defineComponent, PropType, Ref, ref, watch } from "vue";

import PriceHelper from "@/helper/price-helper";

const props = {
    value: {
        type: Number as PropType<number>,
        required: true,
    },
    digits: {
        type: Number as PropType<number>,
        default: 0,
    },
    shouldDisplayZero: {
        type: Boolean as PropType<boolean>,
        default: true,
    },
};

export default defineComponent({
    name: "PriceValue",
    props,
    setup(props) {
        const price = ref(props.value) as Ref<number>;
        const digits = ref(props.digits ?? 0) as Ref<number>;
        const shouldDisplayZero = ref(props.shouldDisplayZero ?? true) as Ref<boolean>;

        function getClassName(): string {
            if (price.value === 0) {
                return "is--greyed-out";
            }

            return "";
        }

        function getValue(): string {
            if (price.value === 0 && !shouldDisplayZero.value) {
                return "n/a";
            }

            return PriceHelper.display(price.value, digits.value);
        }

        watch(
            () => props.value,
            (value) => {
                price.value = value as number;
            }
        );

        watch(
            () => props.digits,
            (value) => {
                digits.value = value as number;
            }
        );

        watch(
            () => props.shouldDisplayZero,
            (value) => {
                shouldDisplayZero.value = value as boolean;
            }
        );

        return {
            getClassName,
            getValue,
        };
    },
});
</script>
