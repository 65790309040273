import "date-fns/locale/nl";

import axios from "axios";
import ElementPlus, { locale } from "element-plus";
import lang from "element-plus/lib/locale/lang/nl";
import { createApp } from "vue";

import { Authentication } from "@/types/credentials/authentication";

import App from "./App.vue";
import router from "./router";
import store from "./store";

locale(lang);

axios.interceptors.request.use((config) => {
    const authentication = store?.state?.authentication as Authentication;

    config.headers["Content-Type"] = "application/json";

    if (authentication) {
        config.headers["Authorization"] = `Bearer ${authentication.token}`;
    }

    return config;
});

createApp(App).use(ElementPlus).use(store).use(router).mount("#app");
