
import { ElMessage } from "element-plus";
import { defineComponent, PropType, reactive, Ref, ref, toRefs, watch } from "vue";
import { Store, useStore } from "vuex";

import { ImportedFileDeleteFlag } from "@/enums/importedFileDeleteFlag";
import { Request } from "@/types/request/request";
import { ImportedFileState } from "@/types/state/importedFile";
import { RootState } from "@/types/state/root";
import { ImportedFile } from "@/types/uzs/importedFile";
import { ImportMapping } from "@/types/uzs/importMapping";

const props = {
    value: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
    importedFile: {
        type: Object as PropType<ImportedFile>,
        required: true,
    },
};

export default defineComponent({
    name: "DeleteImportedFileDialog",
    props,
    setup(props, { emit }) {
        const importedFile = ref(props.importedFile ?? {}) as Ref<ImportedFile | undefined>;
        const store = useStore() as Store<RootState>;
        const state = store.state.importedFile as ImportedFileState;
        const importedFileDeleteFlag = ImportedFileDeleteFlag;

        const actions = {
            importedFile: {
                delete: (importedFile: ImportedFile, flags: Array<string> = []) =>
                    store.dispatch("importedFile/_delete", {
                        id: importedFile.id,
                        flags,
                    } as Request),
            },
        };

        const data = reactive({
            flags: [importedFileDeleteFlag.FILE, importedFileDeleteFlag.SPOTS] as Array<string>,
            isDeleting: false,
        });

        const close = (): void => {
            importedFile.value = {} as ImportedFile;
            data.isDeleting = false;

            emit("input", props.value);
        };

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        const deleteImportedFile = (): void => {
            if (!importedFile?.value) {
                return;
            }

            data.isDeleting = true;

            actions.importedFile
                .delete(importedFile.value, data.flags)
                .then(() => {
                    ElMessage({
                        message:
                            data.flags.indexOf(importedFileDeleteFlag.FILE) !== -1
                                ? importedFile?.value?.filename + " is verwijderd."
                                : "Data van " + importedFile?.value?.filename + " is verwijderd.",
                        type: "success",
                    });

                    close();
                })
                .catch(() => {
                    ElMessage({
                        message:
                            "Er is een fout opgetreden bij het verwijderen van " +
                            importedFile?.value?.filename,
                        type: "error",
                    });
                });
        };

        watch(
            () => props.importedFile,
            (value, oldValue) => {
                if (value !== oldValue) {
                    importedFile.value =
                        value ?? ({ mapping: {} as ImportMapping } as ImportedFile);
                }
            }
        );

        return {
            ...toRefs(data),
            ...toRefs(state),
            close,
            deleteImportedFile,
            dialogClosed,
            importedFile,
            importedFileDeleteFlag,
        };
    },
});
