<template>
    <div class="holiday-message">
        <el-alert type="warning" :closable="false" v-if="holidays.length">
            <template #default>
                <b>Let op:</b>
                {{
                    (holidays.length > 1
                        ? "Vandaag zijn de volgende feestdagen: "
                        : "Vandaag is de feestdag: ") + getValue()
                }}
            </template>
        </el-alert>
    </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs } from "vue";

import HolidayApiService from "@/service/api/settings/holiday/holiday";
import { DataResult } from "@/types/result/dataResult";

export default defineComponent({
    name: "HolidayMessage",
    setup() {
        const holidayApi = new HolidayApiService();

        const data = reactive({
            holidays: [] as Array<string>,
        });

        function init() {
            holidayApi
                .fetchToday()
                .then(
                    (response: DataResult<string[] | null>) => (data.holidays = response.data ?? [])
                );
        }

        function getValue(): string {
            return data.holidays.sort().join(", ");
        }

        onMounted(() => init());

        return {
            ...toRefs(data),
            getValue,
        };
    },
});
</script>

<style>
.holiday-message .el-alert .el-alert__description {
    margin: 0;
}
</style>
