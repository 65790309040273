<template>
    <holiday-message />

    <advertiser-dialog
        class="dialog-advertiser"
        v-if="this.showAdvertiserDialog"
        :advertiser="this.currentAdvertiser"
        @close="this.onAdvertiserDialogClosed"
    />

    <alerts-dialog
        class="dialog-alerts"
        v-if="this.showAlertsDialog"
        :flight="this.currentFlight"
        :disabled="this.alertsDialogDisabled"
        @close="this.onAlertsDialogClosed"
    />

    <sub-order-dialog
        class="dialog-sub-order"
        v-if="this.showLinkSubOrderDialog"
        :flight="this.currentFlight"
        @close="this.onSubOrderDialogClosed"
    />

    <export-dialog
        class="dialog-export"
        v-if="this.showExportDialog"
        :flight="this.currentFlight"
        @close="this.onExportDialogClosed"
    />

    <flight-dialog
        class="dialog-flight"
        v-if="this.showFlightDialog"
        :flight="this.currentFlight"
        @close="this.onFlightDialogClosed"
    />

    <property-dialog
        class="dialog-property"
        v-if="this.showLinkPropertyDialog"
        :flight="this.currentFlight"
        @close="this.onPropertyDialogClosed"
    />

    <q-r-code-dialog
        class="dialog-qr-code"
        v-if="this.showQRCodeDialog"
        :flight="this.currentFlight"
        @close="this.onQRCodeDialogClosed"
    />

    <el-form class="table-actions">
        <el-button class="button-new-advertiser" @click="this.newAdvertiser()"
            >Nieuwe adverteerder</el-button
        >
        <el-button class="button-new-flight" @click="this.newFlight()">Nieuwe flight</el-button>
        <el-button class="button-export" @click="this.newExport()">Exporteren</el-button>
        <el-input
            v-model="this.search"
            class="search-bar"
            clearable="clearable"
            placeholder="Zoeken..."
            suffix-icon="el-icon-search"
            @input="this.onSearchTermChanged"
            @change="this.onSearchTermChanged"
        ></el-input>
        <el-select
            v-model="this.year"
            class="year-select"
            placeholder="Jaartal"
            clearable
            :multiple="false"
            @change="this.onSelectedYearChanged"
        >
            <el-option v-for="year of this.years" :key="year" :value="year" :label="year">{{
                year
            }}</el-option>
        </el-select>
        <el-select
            v-model="this.advertiserIds"
            class="advertiser-select"
            placeholder="Adverteerders"
            :multiple="true"
            @change="this.onSelectedAdvertiserChanged"
        >
            <el-option
                v-for="advertiser of this.advertisers"
                :key="advertiser.id"
                :value="advertiser.id"
                :label="advertiser.name"
                >{{ advertiser.name }}</el-option
            >
        </el-select>
    </el-form>

    <el-table
        v-loading="this.loading"
        class="flights-table"
        :default-sort="{ prop: 'start_date', order: 'descending' }"
        :data="this.flights?.data"
        :row-class-name="this.getRowClassName"
        @sort-change="this.sortingChanged($event)"
        width="100%"
    >
        <el-table-column class-name="column-message has-icon" min-width="25">
            <template #default="scope">
                <template v-if="scope.row.is_importing">
                    <el-tooltip effect="dark" placement="bottom">
                        <template #content><div v-html="scope.row.alert_summary" /></template>
                        <el-icon name="loading" @click="this.showAlerts(scope)" />
                    </el-tooltip>
                </template>
                <template v-else>
                    <el-tooltip
                        v-if="scope.row.has_alerts"
                        effect="dark"
                        placement="bottom"
                        :class="{
                            active: this.flightIsActive(scope),
                            upcoming: this.flightIsUpcoming(scope),
                        }"
                    >
                        <template #content><div v-html="scope.row.alert_summary" /></template>
                        <el-icon
                            :name="
                                scope.row.has_errors &&
                                (this.flightIsActive(scope) ||
                                    this.flightIsUpcoming(scope) ||
                                    this.flightHasImportantErrors(scope))
                                    ? 'circle-close'
                                    : 'warning-outline'
                            "
                            :class="
                                scope.row.has_errors &&
                                (this.flightIsActive(scope) ||
                                    this.flightIsUpcoming(scope) ||
                                    this.flightHasImportantErrors(scope))
                                    ? 'error'
                                    : 'notice'
                            "
                            @click="this.showAlerts(scope)"
                        />
                    </el-tooltip>
                    <el-tooltip
                        v-if="scope.row.has_successes && !scope.row.has_alerts"
                        effect="dark"
                        placement="bottom"
                        disabled
                    >
                        <el-icon
                            name="circle-check"
                            class="success"
                            @click="this.showAlerts(scope)"
                        />
                    </el-tooltip>
                </template>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-advertiser"
            label="Adverteerder"
            min-width="150"
            prop="uzs_advertiser_id"
            sortable="custom"
        >
            <template #default="scope">
                <a
                    href="javascript:void(0)"
                    :title="scope.row.uzs_advertiser?.name"
                    @click="this.editAdvertiser(scope)"
                >
                    {{ scope.row.uzs_advertiser?.name }}
                </a>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-name"
            label="Flight"
            min-width="180"
            prop="name"
            sortable="custom"
        >
            <template #default="scope">
                <a
                    href="javascript:void(0)"
                    :title="scope.row.name"
                    @click="this.editFlight(scope)"
                >
                    {{ scope.row.name }}
                </a>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-flight-is-active"
            min-width="20"
            show-overflow-tooltip="false"
        >
            <template #default="scope">
                <span v-if="this.flightIsActive(scope)" class="flight-is-active" />
                <span v-if="this.flightIsUpcoming(scope)" class="flight-is-upcoming" />
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-date-period"
            label="Looptijd"
            min-width="150"
            prop="start_date"
            sortable="custom"
        >
            <template #default="scope">
                {{ this.getDatePeriod(scope) }}
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-country"
            label="Land"
            min-width="135"
            prop="country"
            sortable="custom"
        >
            <template #default="scope">
                {{ scope.row.country }}
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-goal"
            label="Doel"
            min-width="135"
            prop="goal"
            sortable="custom"
        >
            <template #default="scope">
                {{ scope.row.goal }}
            </template>
        </el-table-column>
        <el-table-column class-name="column-goal" label="Score" min-width="60" prop="goal">
            <template #default="scope">
                <span style="display: block; text-align: center">
                    <OptimizationScore
                        :value="scope.row.uzs_flight_statistics?.optimization_score"
                        :round="true"
                /></span>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-budget"
            label="Budget"
            min-width="100"
            prop="budget"
            sortable="custom"
        >
            <template #default="scope"
                ><span class="block text-align-right">{{ this.getBudget(scope) }}</span></template
            >
        </el-table-column>
        <el-table-column class-name="column-cronjob" label="Crontaak" min-width="100">
            <template #default="scope">
                <center>
                    <el-switch
                        v-model="scope.row.active"
                        @change="this.changeRowActivation(scope)"
                    ></el-switch></center
            ></template>
        </el-table-column>
        <el-table-column class-name="column-link" label="Koppelen" min-width="120">
            <template #default="scope">
                <el-dropdown trigger="click">
                    <el-button
                        class="button-link"
                        icon="el-icon-connection"
                        plain="plain"
                        size="mini"
                        title="Koppel"
                        >Koppel</el-button
                    >
                    <template #dropdown>
                        <el-dropdown-menu class="link-menu">
                            <el-dropdown-item
                                class="menu-item link-suborders"
                                @click="this.linkSubOrders(scope)"
                            >
                                Dataset
                            </el-dropdown-item>
                            <el-dropdown-item
                                class="menu-item link-property"
                                @click="this.linkProperty(scope)"
                            >
                                BigQuery
                            </el-dropdown-item>
                            <el-dropdown-item
                                class="menu-item qr-code"
                                @click="this.editQRCode(scope)"
                            >
                                QR-code
                            </el-dropdown-item>
                        </el-dropdown-menu></template
                    ></el-dropdown
                ></template
            ></el-table-column
        >
        <el-table-column class-name="column-export" label="Exporteren" min-width="150">
            <template #default="scope">
                <el-button
                    icon="el-icon-download"
                    plain="plain"
                    size="mini"
                    title="Exporteer"
                    @click="this.newExport(scope)"
                    >Exporteer</el-button
                ></template
            >
        </el-table-column>
        <el-table-column class-name="column-actions" fixed="right" min-width="120">
            <template #default="scope">
                <el-button
                    icon="el-icon-edit"
                    plain="plain"
                    size="mini"
                    title="Bekijk details"
                    @click="this.editFlight(scope)"
                ></el-button>
                <el-button
                    class="button-delete-flight"
                    icon="el-icon-delete"
                    plain="plain"
                    size="mini"
                    title="Verwijder"
                    type="danger"
                    @click="this.deleteFlight(scope)"
                ></el-button>
            </template>
        </el-table-column>
    </el-table>

    <el-pagination
        :background="false"
        :current-page="this.currentPage"
        :hide-on-single-page="false"
        :page-count="this.pages"
        :page-size="this.perPage"
        :page-sizes="this.pageSizes"
        :total="
            this.totalBudget +
            ' - Pagina: ' +
            this.flights?.pagination?.page +
            ' / ' +
            this.flights?.pagination?.total_items
        "
        layout="sizes, prev, pager, next, jumper, ->, total"
        @current-change="this.goToPage"
        @next-click="this.goToPage"
        @prev-click="this.goToPage"
        @size-change="this.pageSizeChange"
    />
</template>

<script lang="ts">
import { endOfDay, format, parseISO, startOfDay } from "date-fns";
import { nl } from "date-fns/locale";
import { ElMessage, ElMessageBox } from "element-plus";
import { range } from "lodash";
import { defineComponent, inject, onMounted, Ref, ref } from "vue";
import { mapActions, Store } from "vuex";

import OptimizationScore from "@/components/campaigns/OptimizationScore.vue";
import HolidayMessage from "@/components/message/HolidayMessage.vue";
import DateHelper from "@/helper/date-helper";
import AdvertiserApiService from "@/service/api/advertiser";
import config from "@/service/config";
import { FilterRequest } from "@/types/request/filterRequest";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { DataResult } from "@/types/result/dataResult";
import { FlightState } from "@/types/state/flight";
import { Advertiser } from "@/types/uzs/advertiser";
import { Flight } from "@/types/uzs/flight";
import { Message } from "@/types/uzs/message";
import AdvertiserDialog from "@/views/campaigns/dialogs/Advertiser.vue";
import AlertsDialog from "@/views/campaigns/dialogs/Alerts.vue";
import ExportDialog from "@/views/campaigns/dialogs/Export.vue";
import FlightDialog from "@/views/campaigns/dialogs/Flight.vue";
import PropertyDialog from "@/views/campaigns/dialogs/link-data/Property.vue";
import QRCodeDialog from "@/views/campaigns/dialogs/link-data/QRCode.vue";
import SubOrderDialog from "@/views/campaigns/dialogs/link-data/SubOrder.vue";

type CurrentRow = {
    row: Flight;
    rowIndex: number;
};

type SortEvent = {
    column: string;
    order: string;
    prop: string;
};

export default defineComponent({
    name: "List",
    components: {
        HolidayMessage,
        AlertsDialog,
        SubOrderDialog,
        PropertyDialog,
        AdvertiserDialog,
        ExportDialog,
        FlightDialog,
        OptimizationScore,
        QRCodeDialog,
    },
    setup() {
        const store = inject("store") as Store<FlightState>;
        const advertisers = ref(null) as Ref<Advertiser[] | null>;
        const advertiserApi = new AdvertiserApiService();

        onMounted(() => {
            advertiserApi
                .fetchAll({
                    order: {
                        name: "asc",
                    },
                })
                .then((data) => {
                    advertisers.value = data.data;
                });
        });

        return {
            _store: store,
            advertisers,
            advertiserApi,
        };
    },
    computed: {
        page(): number {
            return this.pagination.page;
        },
        pages(): number {
            return this.flights?.pagination?.total_pages as number;
        },
        perPage(): number {
            return this.pagination.per_page;
        },
        totalBudget(): string {
            let total = 0;

            this.flights?.data?.forEach((flight) => {
                total += flight.budget ?? 0.0;
            });

            return (
                "€ " +
                total.toLocaleString("nl", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                })
            );
        },
    },
    data() {
        return {
            filter: {} as FilterRequest,
            order: {
                start_date: "desc",
                country: "asc",
                name: "asc",
            } as OrderRequest,
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            search: undefined as string | undefined,
            searchTimeout: undefined as undefined | unknown,
            flight: undefined as Flight | undefined,
            flights: undefined as DataResult<Flight[]> | undefined,
            advertiserIds: [] as number[],
            alertsDialogDisabled: false,
            showAdvertiserDialog: false,
            showAlertsDialog: false,
            showExportDialog: false,
            showFlightDialog: false,
            showLinkSubOrderDialog: false,
            showLinkPropertyDialog: false,
            showQRCodeDialog: false,
            currentAdvertiser: null as Advertiser | null | undefined,
            currentFlight: null as Flight | null,
            currentFlightId: null as number | null,
            currentPage: undefined as number | undefined,
            loading: true,
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            showFilterModal: false,
            sortingEnabled: false,
            flashMessages: [] as Message[],
            year: null as null | number,
            years: range(
                new Date().getMonth() > 10
                    ? new Date().getFullYear() + 1
                    : new Date().getFullYear(),
                2016
            ),
        };
    },
    mounted() {
        this.order = {
            start_date: "desc",
            country: "asc",
            name: "asc",
        };

        Object.assign(this.pagination, {
            per_page: 25,
        });

        this.update();
    },
    methods: {
        ...mapActions("flight", [
            "_activate",
            "_deactivate",
            "_delete",
            "_details",
            "_list",
            "_store",
        ]),
        getDatePeriod(value: CurrentRow) {
            const startDate = DateHelper.date(value.row.start_date ?? "now");
            const endDate = DateHelper.date(value.row.end_date);

            let startDay = format(startDate, "d");
            const endDay = format(endDate, "d");

            let startMonth =
                " " + format(startDate, "LLL", { locale: nl }).toLowerCase().replace(".", "");

            const endMonth =
                " " + format(endDate, "LLL", { locale: nl }).toLowerCase().replace(".", "");

            let startYear = " " + format(startDate, "yyyy");
            const endYear = " " + format(endDate, "yyyy");

            if (startDay === endDay && startMonth === endMonth && startYear === endYear) {
                startDay = "";
            }

            if (startMonth === endMonth) {
                startMonth = "";
            }

            if (startYear === endYear) {
                startYear = "";
            }

            return (
                startDay +
                startMonth +
                startYear +
                (startDay === "" && startMonth === "" && startYear === "" ? "" : " - ") +
                endDay +
                endMonth +
                endYear
            );
        },
        getBudget(value: CurrentRow): string {
            return (
                "€ " +
                (value.row.budget ?? 0).toLocaleString("nl", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                })
            );
        },
        getRowClassName(value: CurrentRow) {
            let className = "row " + value.row.id;

            if (this.flightIsActive(value)) {
                className += " active";
            }

            if (this.flightIsUpcoming(value)) {
                className += " upcoming";
            }

            return className;
        },
        goToPage(page: number) {
            this.currentPage = page;
            this.pagination.page = page;
            this.update();
        },
        pageSizeChange(pageSize: number) {
            this.pagination.per_page = pageSize;
            this.update();
        },
        routeToDetails($event: Event, row: Flight) {
            $event.stopImmediatePropagation();

            this.$router.push({
                name: "Details",
                params: {
                    id: row.id,
                },
            });
        },
        sortingChanged($event: SortEvent) {
            const property = $event.prop?.length ? $event.prop : null;
            const direction = $event?.order === "descending" ? "desc" : "asc";

            if (!property || !this.sortingEnabled || !this.flights || !this.order) {
                return;
            }

            this.order = {
                [property]: direction,
            };

            this.update();
        },
        newExport(value?: CurrentRow): void {
            this.currentFlight = {} as Flight;

            if (value) {
                this.currentFlight = value.row;
            }

            this.showExportDialog = true;
        },
        onExportDialogClosed(): void {
            this.currentFlight = {} as Flight;
            this.showExportDialog = false;
        },
        onFlightDialogClosed(changed: boolean): void {
            this.currentFlight = {} as Flight;
            this.showFlightDialog = false;

            if (changed) {
                this.update();
            }
        },
        onQRCodeDialogClosed() {
            this.currentFlight = {} as Flight;
            this.showQRCodeDialog = false;
        },
        deleteFlight(value: CurrentRow): void {
            ElMessageBox.confirm(
                "Weet je zeker dat je " + value.row.name + " wilt verwijderen?",
                "Verwijderen?",
                {
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nee",
                    type: "warning",
                }
            ).then(() => {
                this._delete(value.row.id)
                    .then((result) => {
                        this.update();

                        if (!result.success) {
                            throw new Error();
                        }

                        ElMessage({
                            message: value.row.name + " is verwijderd.",
                            type: "success",
                        });
                    })
                    .catch(() => {
                        ElMessage({
                            message: "Er is een onbekende fout opgetreden.",
                            type: "error",
                        });
                    });
            });
        },
        changeRowActivation(value: CurrentRow) {
            if (!value.row.active) {
                this._deactivate(value.row.id);
                return;
            }

            this._activate(value.row.id);
        },
        onAdvertiserDialogClosed(changed: boolean): void {
            this.currentAdvertiser = null;
            this.showAdvertiserDialog = false;

            if (changed) {
                this.update();
            }
        },
        newAdvertiser(): void {
            this.showAdvertiserDialog = true;
        },
        editAdvertiser(value: CurrentRow): void {
            this.currentAdvertiser = value.row.uzs_advertiser;
            this.showAdvertiserDialog = true;
        },
        onAlertsDialogClosed(): void {
            this.currentFlight = {} as Flight;
            this.showAlertsDialog = false;
            this.alertsDialogDisabled = false;
        },
        showAlerts(value: CurrentRow): void {
            this.alertsDialogDisabled = true;
            this.currentFlight = value.row as Flight;
            this.showAlertsDialog = true;
        },
        flightIsActive(value: CurrentRow): boolean {
            const currentDate = new Date();
            const startDate = startOfDay(parseISO(value.row.start_date));
            const endDate = endOfDay(parseISO(value.row.end_date));

            return currentDate >= startDate && currentDate <= endDate;
        },
        flightIsUpcoming(value: CurrentRow): boolean {
            const currentDate = new Date();
            const startDate = startOfDay(parseISO(value.row.start_date));

            return currentDate < startDate;
        },
        flightHasImportantErrors(value: CurrentRow): boolean {
            const important = ["importeer nieuwe uitzenddata"];

            for (const message of important) {
                if (value.row.alert_summary.toLowerCase().indexOf(message) !== -1) {
                    return true;
                }
            }

            return false;
        },
        onSubOrderDialogClosed(changed: boolean): void {
            this.currentFlight = {} as Flight;
            this.showLinkSubOrderDialog = false;

            if (changed) {
                this.update();
            }
        },
        linkSubOrders(value: CurrentRow): void {
            this.currentFlight = value.row;
            this.showLinkSubOrderDialog = true;
        },
        newFlight(): void {
            this.currentFlight = {} as Flight;
            this.showFlightDialog = true;
        },
        editFlight(value: CurrentRow): void {
            this.currentFlight = value.row;
            this.showFlightDialog = true;
        },
        onPropertyDialogClosed(changed: boolean): void {
            this.currentFlight = {} as Flight;
            this.showLinkPropertyDialog = false;

            if (changed) {
                this.update();
            }
        },
        linkProperty(value: CurrentRow): void {
            this.currentFlight = value.row;
            this.showLinkPropertyDialog = true;
        },
        editQRCode(value: CurrentRow): void {
            this.currentFlight = value.row;
            this.showQRCodeDialog = true;
        },
        onSearchTermChanged(): void {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout as number);
            }

            this.searchTimeout = setTimeout(() => this.update(), 500);
        },
        onSelectedAdvertiserChanged(): void {
            Object.assign(this.filter, {
                uzs_advertiser_id: {
                    operator: "IN",
                    value: this.advertiserIds,
                },
            });

            this.update();
        },
        onSelectedYearChanged() {
            if (!this.year) {
                Object.assign(this.filter, {
                    start_date: undefined,
                });

                this.update();
                return;
            }

            Object.assign(this.filter, {
                start_date: {
                    operator: "BETWEEN",
                    value: [this.year + "-01-01", this.year + "-12-31"],
                },
            });

            this.update();
        },
        update() {
            this.loading = true;

            this._list({
                filter: this.filter,
                order: this.order,
                pagination: this.pagination,
                search: this.search,
            }).then((response) => {
                this.loading = false;
                this.sortingEnabled = true;
                this.flights = response;
            });
        },
    },
});
</script>

<style>
td.column-message > div.cell > i {
    cursor: pointer;
    margin: -4px;
    padding: 4px;
}

@media screen and (max-width: 719px) {
    .button-new-advertiser,
    .button-new-flight,
    .button-delete-flight {
        display: none !important;
    }

    .button-export {
        margin-left: 0 !important;
        width: 100%;
    }

    form.table-actions button,
    form.table-actions input {
        float: none !important;
        margin-bottom: 5px !important;
    }

    div.el-table__fixed-right,
    .column-actions {
        width: 65px !important;
    }
}
</style>
