
import { format } from "date-fns";
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs } from "vue";
import { Store, useStore } from "vuex";

import CpaValue from "@/components/campaigns/CpaValue.vue";
import CpmValue from "@/components/campaigns/CpmValue.vue";
import DurationPercentage from "@/components/campaigns/DurationPercentage.vue";
import GrpPercentage from "@/components/campaigns/GrpPercentage.vue";
import GrpPriceValue from "@/components/campaigns/GrpPriceValue.vue";
import OptimizationScore from "@/components/campaigns/OptimizationScore.vue";
import TargetPercentage from "@/components/campaigns/TargetPercentage.vue";
import { AlertClass } from "@/enums/alertClass";
import { AlertMessage } from "@/enums/alertMessage";
import { Goal } from "@/enums/goal";
import DateHelper from "@/helper/date-helper";
import FlightHelper from "@/helper/flight-helper";
import GrpHelper from "@/helper/grp-helper";
import PercentageHelper from "@/helper/percentage-helper";
import AlertApiService from "@/service/api/alert";
import { DataResult } from "@/types/result/dataResult";
import { FlightState } from "@/types/state/flight";
import { RootState } from "@/types/state/root";
import { Alert } from "@/types/uzs/alert";
import { Flight } from "@/types/uzs/flight";
import { FlightStatistics } from "@/types/uzs/flightStatistics";
import AlertChannelClustersTable from "@/views/campaigns/dialogs/alerts/ChannelClustersTable.vue";
import AlertDailyGrpTable from "@/views/campaigns/dialogs/alerts/DailyGrpTable.vue";
import AlertErrorsTable from "@/views/campaigns/dialogs/alerts/ErrorsTable.vue";
import AlertGcfTable from "@/views/campaigns/dialogs/alerts/GcfTable.vue";
import AlertGrpClustersTable from "@/views/campaigns/dialogs/alerts/GrpClustersTable.vue";
import PackageSpreadingTable from "@/views/campaigns/dialogs/alerts/PackageSpreadingTable.vue";
import AlertPeakClustersTable from "@/views/campaigns/dialogs/alerts/PeakClusterTable.vue";
import AlertRequestedGrpsTable from "@/views/campaigns/dialogs/alerts/RequestedGrpsTable.vue";
import AlertSpotsTable from "@/views/campaigns/dialogs/alerts/SpotsTable.vue";
import AlertSpreadingTable from "@/views/campaigns/dialogs/alerts/SpreadingTable.vue";
import AlertSubOrdersTable from "@/views/campaigns/dialogs/alerts/SubOrdersTable.vue";

const props = {
    flight: {
        type: Object as PropType<Flight>,
        required: true,
    },
};

export default defineComponent({
    name: "AlertsDialog",
    computed: {
        Goal() {
            return Goal;
        },
    },
    components: {
        PackageSpreadingTable,
        AlertPeakClustersTable,
        GrpPercentage,
        DurationPercentage,
        GrpPriceValue,
        CpmValue,
        CpaValue,
        OptimizationScore,
        TargetPercentage,
        AlertGcfTable,
        AlertDailyGrpTable,
        AlertSpreadingTable,
        AlertRequestedGrpsTable,
        AlertChannelClustersTable,
        AlertGrpClustersTable,
        AlertSubOrdersTable,
        AlertSpotsTable,
        AlertErrorsTable,
    },
    props,
    setup(props, { emit }) {
        const alertApi = new AlertApiService();
        const alertClass = AlertClass;
        const alertMessage = AlertMessage;
        const flight = ref(props.flight) as Ref<Flight | undefined>;
        const dateHelper = DateHelper;
        const grpHelper = GrpHelper;
        const percentageHelper = PercentageHelper;
        const store = useStore() as Store<RootState>;
        const state = store.state.flight as FlightState;

        const data = reactive({
            alert: null as Alert | null,
            alerts: [] as Alert[],
            flightStatistics: null as FlightStatistics | null,
            gcfAlert: null as Alert | null,
            dailyGrpAlert: null as Alert | null,
            displayGrpAlert: false,
            successes: [] as Alert[],
            isLoadingAlerts: false,
            isModalVisible: true,
        });

        function close(changed = false): void {
            flight.value = undefined;
            data.gcfAlert = null;
            data.dailyGrpAlert = null;
            data.displayGrpAlert = false;
            data.isModalVisible = false;

            emit("close", changed);
        }

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        function init(): void {
            loadAlerts();
        }

        function getTotalGrp(): number {
            if (!data.dailyGrpAlert?.uzs_flight?.uzs_flight_daily_grp) {
                return 0;
            }

            let totalGrp = 0;

            for (const d of data.dailyGrpAlert.uzs_flight.uzs_flight_daily_grp) {
                totalGrp +=
                    d?.holiday?.is_operator_closed &&
                    d?.holiday?.date === format(new Date(), "yyyy-MM-dd")
                        ? d.predicted_grp
                        : d.rating;
            }

            return totalGrp;
        }

        function getTotalRequestedGrp(): number {
            return data.flightStatistics?.total_requested_grp ?? 0;
        }

        function getGrpProgress(): number {
            return data.flightStatistics?.grp_progress ?? 0;
        }

        function getDurationProgress(): number {
            return data.flightStatistics?.duration_progress ?? 0;
        }

        const getTargetProgress = (): number => {
            return data.flightStatistics?.target_progress ?? 0;
        };

        function getCpa(): number {
            return data.flightStatistics?.cpa ?? 0;
        }

        function getCpm(): number {
            return data.flightStatistics?.cpm ?? 0;
        }

        function getGrpPrice(): number {
            return data.flightStatistics?.grp_price ?? 0;
        }

        function getOptimizationScore(): number {
            return data.flightStatistics?.optimization_score ?? 0;
        }

        function getLastUpdated(): string | null {
            if (!flight.value?.most_recent_package_imported_at) {
                return null;
            }

            const date = dateHelper.display(
                flight.value.most_recent_package_imported_at,
                true,
                true
            );

            return (
                "Laatste wijziging " +
                (date.indexOf("gisteren") > -1 || date.indexOf("vandaag") > -1 ? "" : " op ") +
                date
            );
        }

        function loadAlerts(): void {
            if (!flight.value?.id) {
                return;
            }

            let messageShown = false;

            data.isLoadingAlerts = true;

            alertApi
                .fetchAllForFlight({}, flight.value.id)
                .then((result: DataResult<Alert[]>) => {
                    data.alerts = (result.data ?? []).map((alert) => {
                        if (
                            alert.message !==
                                AlertClass.WarningTooMuchGrpToLowPerformingTimeslotsAfternoon &&
                            alert.message !==
                                AlertClass.WarningTooMuchGrpToLowPerformingTimeslotsOutsideDaytime
                        ) {
                            return alert;
                        }

                        alert.message_shown = messageShown;
                        messageShown = true;

                        return alert;
                    });

                    data.alert = data.alerts.length ? data.alerts[0] : null;

                    data.dailyGrpAlert =
                        data.alerts.filter(
                            (alert) => alert?.uzs_flight?.uzs_flight_daily_grp !== null
                        )[0] ?? null;

                    data.flightStatistics = data.alert?.uzs_flight?.uzs_flight_statistics ?? null;

                    data.gcfAlert =
                        data.alerts.filter(
                            (alert) => alert?.uzs_flight?.uzs_flight_gcf !== null
                        )[0] ?? null;

                    data.isLoadingAlerts = false;
                })
                .catch(() => (data.isLoadingAlerts = false));
        }

        function isCampaignFulfilled(): boolean {
            return data.alert ? FlightHelper.isCampaignFulfilled(flight.value) : false;
        }

        function isFutureCampaign(): boolean {
            return data.alert ? FlightHelper.isFutureCampaign(flight.value) : false;
        }

        onMounted(() => {
            if (props.flight) {
                flight.value = props.flight as Flight;
                init();
            }
        });

        return {
            ...toRefs(data),
            ...toRefs(state),
            alertClass,
            alertMessage,
            close,
            dialogClosed,
            flight,
            grpHelper,
            isCampaignFulfilled,
            isFutureCampaign,
            getTotalGrp,
            getGrpProgress,
            getDurationProgress,
            getTargetProgress,
            getCpa,
            getCpm,
            getGrpPrice,
            getOptimizationScore,
            getLastUpdated,
            percentageHelper,
        };
    },
});
