<template>
    <span :class="getClassName()">{{ getValue() }}</span>
</template>
<script lang="ts">
import { defineComponent, PropType, Ref, ref, watch } from "vue";

import PercentageHelper from "@/helper/percentage-helper";

const props = {
    value: {
        type: Number as PropType<number>,
        required: true,
    },
    isFuture: {
        type: Boolean as PropType<boolean>,
        default: false,
    },
    round: {
        type: Boolean as PropType<boolean>,
        default: false,
    },
};

export default defineComponent({
    name: "GrpPercentage",
    props,
    setup(props) {
        const grpProgress = ref(props.value) as Ref<number>;
        const isFuture = ref(props.isFuture ?? false) as Ref<boolean>;
        const round = ref(props.round ?? false) as Ref<boolean>;

        function getClassName(): string {
            if (Math.round(grpProgress.value) <= 0 && isFuture.value) {
                return "is--greyed-out";
            }

            return "";
        }

        function getValue(): string {
            return PercentageHelper.display(grpProgress.value, round.value);
        }

        watch(
            () => props.value,
            (value) => {
                grpProgress.value = value as number;
            }
        );

        watch(
            () => props.isFuture,
            (value) => {
                isFuture.value = value as boolean;
            }
        );

        watch(
            () => props.round,
            (value) => {
                round.value = value as boolean;
            }
        );

        return {
            getClassName,
            getValue,
        };
    },
});
</script>
