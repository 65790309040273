
import { ElMessage } from "element-plus";
import { defineComponent, PropType, Ref, ref, watch } from "vue";

import { Country } from "@/enums/country";
import StripApiService from "@/service/api/settings/strip/strip";
import { Strip } from "@/types/uzs/settings/strip/strip";

const props = {
    value: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
    strip: {
        type: Object as PropType<Strip>,
        required: true,
    },
};

export default defineComponent({
    name: "StripDialog",
    props,
    setup(props, { emit }) {
        const strip = ref(props.strip ?? {}) as Ref<Strip | undefined>;
        const stripService = new StripApiService();
        const countries = Country;

        const actions = {
            strip: {
                store: (strip: Strip) => stripService.store(strip),
            },
        };

        const close = (): void => {
            strip.value = {} as Strip;

            emit("input", props.value);
        };

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        const save = (): void => {
            if (!strip?.value) {
                return;
            }

            actions.strip
                .store(strip.value)
                .then(() => {
                    ElMessage({
                        message: strip?.value?.value + " is opgeslagen.",
                        type: "success",
                    });

                    close();
                })
                .catch(() => {
                    ElMessage({
                        message:
                            "Er is een fout opgetreden bij het opslaan van " + strip?.value?.value,
                        type: "error",
                    });
                });
        };

        const submitOnEnter = (event: KeyboardEvent): void => {
            const input = event.target as HTMLElement | null;

            if (
                event.key.toLowerCase() === "enter" &&
                input?.tagName?.toLowerCase() !== "textarea"
            ) {
                save();
            }
        };

        watch(
            () => props.strip,
            (value, oldValue) => {
                if (value !== oldValue) {
                    strip.value = value ?? ({} as Strip);
                }
            }
        );

        return {
            close,
            countries,
            strip,
            dialogClosed,
            save,
            submitOnEnter,
        };
    },
});
