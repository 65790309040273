<template>
    <el-dialog
        :title="this.flight?.name ? 'QR-code voor flight ' + this.flight?.name : 'QR-code'"
        :before-close="this.dialogClosed"
        :destroy-on-close="true"
        :lock-scroll="false"
        :model-value="isModalVisible"
        width="100%"
        ref="dialog"
    >
        <el-form
            v-if="this.flight"
            :model="this.flight"
            label-position="top"
            @keydown="this.submitOnEnter"
        >
            <el-form-item label="UTM-medium">
                <el-select
                    v-model="this.flight.utm_medium"
                    clearable
                    filterable
                    @change="this.trafficMediumChanged"
                >
                    <el-option
                        v-for="medium in this.trafficMedia"
                        :key="medium"
                        :label="medium"
                        :value="medium"
                        >{{ medium }}</el-option
                    >
                </el-select>
            </el-form-item>
            <el-form-item label="UTM-campagne">
                <el-select v-model="this.flight.utm_campaign" clearable filterable>
                    <el-option
                        v-for="campaign in this.trafficCampaigns"
                        :key="campaign"
                        :label="campaign"
                        :value="campaign"
                        >{{ campaign }}</el-option
                    >
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="this.save()">Opslaan</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script lang="ts">
import { ElMessage } from "element-plus";
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs } from "vue";
import { Store, useStore } from "vuex";

import TrafficSourceApiService from "@/service/api/integration/bigquery/trafficSource";
import { FlightState } from "@/types/state/flight";
import { RootState } from "@/types/state/root";
import { TrafficSource } from "@/types/uzs/big-query-traffic-source/trafficSource";
import { Flight } from "@/types/uzs/flight";

const props = {
    flight: {
        type: Object as PropType<Flight>,
        required: true,
    },
};

export default defineComponent({
    name: "QRCodeDialog",
    props,
    setup(props, { emit }) {
        const flight = ref(props.flight ?? {}) as Ref<Flight | undefined>;
        const store = useStore() as Store<RootState>;
        const state = store.state.flight as FlightState;

        const actions = {
            flight: {
                store: (flight: Flight) => store.dispatch("flight/_store", flight),
            },
        };

        const data = reactive({
            isModalVisible: true,
            trafficSources: null as Array<TrafficSource> | null,
            trafficMedia: [] as Array<string>,
            trafficCampaigns: [] as Array<string>,
        });

        const close = (changed = false): void => {
            flight.value = {} as Flight;

            data.isModalVisible = false;

            emit("close", changed);
        };

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        const save = (): void => {
            if (!flight?.value) {
                return;
            }

            actions.flight
                .store(flight.value)
                .then(() => {
                    ElMessage({
                        message: "QR-code voor " + flight?.value?.name + " is opgeslagen.",
                        type: "success",
                    });

                    close(true);
                })
                .catch(() => {
                    ElMessage({
                        message:
                            "Er is een fout opgetreden bij het opslaan van de QR-code voor " +
                            flight?.value?.name,
                        type: "error",
                    });
                });
        };

        const submitOnEnter = (event: KeyboardEvent): void => {
            const input = event.target as HTMLElement | null;

            if (
                event.key.toLowerCase() === "enter" &&
                input?.tagName?.toLowerCase() !== "textarea"
            ) {
                save();
            }
        };

        function findTrafficSources(): void {
            if (!flight.value?.uzs_advertiser_id) {
                return;
            }

            const trafficSourceApi = new TrafficSourceApiService();

            trafficSourceApi
                .list(flight.value.uzs_advertiser_id)
                .then((response) => (data.trafficSources = response.data as Array<TrafficSource>))
                .then(() => {
                    if (!data.trafficSources) {
                        return;
                    }

                    for (const trafficSource of data.trafficSources) {
                        if (data.trafficMedia.includes(trafficSource.medium.toLowerCase())) {
                            continue;
                        }

                        data.trafficMedia.push(trafficSource.medium.toLowerCase());
                    }
                })
                .then(() => data.trafficMedia.sort());
        }

        function trafficMediumChanged(): void {
            data.trafficCampaigns = [];

            if (flight.value) {
                flight.value.utm_campaign = "";
            }

            if (!data.trafficMedia?.length || !data.trafficSources?.length) {
                return;
            }

            data.trafficCampaigns = data.trafficSources
                .filter((source) => flight.value?.utm_medium === source.medium)
                .map((source) => source.name);
        }

        onMounted(() => {
            if (props.flight) {
                flight.value = props.flight as Flight;
            }

            findTrafficSources();
        });

        return {
            ...toRefs(data),
            ...toRefs(state),
            close,
            dialogClosed,
            flight,
            save,
            submitOnEnter,
            trafficMediumChanged,
        };
    },
});
</script>
