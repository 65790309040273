<template>
    <holiday-message />

    <delete-imported-file-dialog
        v-model="this.showDeleteImportedFileDialog"
        :imported-file="this.currentImportedFile"
        @input="this.closeDeleteImportedFile"
    />

    <imported-file-mapping-dialog
        v-model="this.showImportedFileMappingDialog"
        :imported-file="this.currentImportedFile"
        @input="this.closeImportedFileMapping"
    />

    <div>
        <el-upload
            class="uploader"
            drag
            multiple
            :action="this.backendUrl + '/campaign/dataset/upload-spreadsheet'"
            :headers="{ Authorization: 'Bearer ' + this.accessToken }"
            :show-file-list="false"
            @error="this.onDoneUploading"
            @progress="this.onBusyUploading"
            @success="this.onDoneUploading"
        >
            <el-icon name="upload"></el-icon>
            <div v-if="!this.isUploading" class="el-upload__text">
                Sleep hier bestanden naartoe om ze te uploaden, of klik dit veld aan om een bestand
                te selecteren...
            </div>
            <div v-if="this.isUploading">
                <el-icon name="loading" />
            </div>
        </el-upload>
    </div>

    <el-table
        v-loading="this.loading"
        :default-sort="{ prop: 'created_at', order: 'descending' }"
        :data="this.importedFiles?.data"
        width="100%"
    >
        <el-table-column
            class-name="column-filename"
            label="Bestand"
            min-width="150"
            prop="filename"
        >
            <template #default="scope">
                <span :title="scope.row.filename">{{ scope.row.filename }}</span>
            </template>
        </el-table-column>
        <el-table-column class-name="column-country" label="Land" min-width="100" prop="country">
            <template #default="scope">
                <span :title="scope.row.country">{{ scope.row.country }}</span>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-order"
            label="Order"
            min-width="100"
            prop="order_reference"
        >
            <template #default="scope">
                <span :title="scope.row.order_reference">{{ scope.row.order_reference }}</span>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-number-of-orders"
            label="Aantal orders"
            prop="number_of_orders"
        >
            <template #default="scope">
                <span :title="scope.row.number_of_orders">{{ scope.row.number_of_orders }}</span>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-number-of-sub-orders"
            label="Aantal suborders"
            prop="number_of_sub_orders"
        >
            <template #default="scope">
                <span :title="scope.row.number_of_sub_orders">{{
                    scope.row.number_of_sub_orders
                }}</span>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-number-of-spots"
            label="Aantal spots"
            prop="number_of_spots"
        >
            <template #default="scope">
                <span :title="scope.row.number_of_spots">{{ scope.row.number_of_spots }}</span>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-import-end"
            label="Geïmporteerd"
            min-width="100"
            prop="import_end"
        >
            <template #default="scope">
                <span
                    v-if="scope.row.import_end"
                    :title="this.getFormattedDate(scope.row.import_end)"
                    >{{ this.getFormattedDate(scope.row.import_end) }}</span
                >
                <el-icon v-if="scope.row.import_start && !scope.row.import_end" name="loading" />
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-created-at"
            label="Aangemaakt"
            min-width="100"
            prop="created_at"
        >
            <template #default="scope">
                <span :title="this.getFormattedDate(scope.row.created_at)">{{
                    this.getFormattedDate(scope.row.created_at)
                }}</span>
            </template>
        </el-table-column>
        <el-table-column class-name="column-actions" fixed="right">
            <template #default="scope">
                <el-button
                    icon="el-icon-edit"
                    plain="plain"
                    size="mini"
                    title="Stel mapping in"
                    @click="this.editImportedFileMapping(scope.row)"
                ></el-button>
                <el-button
                    :icon="'el-icon-' + (this.isDeleting === scope.row?.id ? 'loading' : 'delete')"
                    :disabled="this.isDeleting === scope.row?.id"
                    plain="plain"
                    size="mini"
                    title="Verwijder bestand en data"
                    type="danger"
                    @click="this.onDelete(scope.row)"
                ></el-button>
            </template>
        </el-table-column>
    </el-table>

    <el-pagination
        :background="false"
        :current-page="this.currentPage"
        :hide-on-single-page="false"
        :page-count="this.pages"
        :page-size="this.perPage"
        :page-sizes="this.pageSizes"
        :total="this.importedFiles?.pagination?.total_items"
        layout="sizes, prev, pager, next, jumper, ->, total"
        @current-change="this.goToPage"
        @next-click="this.goToPage"
        @prev-click="this.goToPage"
        @size-change="this.pageSizeChange"
    />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, toRefs } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import { Store, useStore } from "vuex";

import HolidayMessage from "@/components/message/HolidayMessage.vue";
import DateHelper from "@/helper/date-helper";
import { Copy } from "@/service/clipboard/copy";
import config from "@/service/config";
import { Authentication } from "@/types/credentials/authentication";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { ImportedFileState } from "@/types/state/importedFile";
import { RootState } from "@/types/state/root";
import { ImportedFile } from "@/types/uzs/importedFile";
import DeleteImportedFileDialog from "@/views/import/dataset/dialogs/DeleteImportedFile.vue";
import ImportedFileMappingDialog from "@/views/import/dataset/dialogs/ImportedFileMapping.vue";

export default defineComponent({
    name: "List",
    components: { HolidayMessage, DeleteImportedFileDialog, ImportedFileMappingDialog },
    setup() {
        const store = useStore() as Store<RootState>;
        const auth = store.state.authentication as Authentication;
        const state = store.state.importedFile as ImportedFileState;
        const textCopier = new Copy();

        const actions = {
            importedFile: {
                list: (params: RequestParameters) => store.dispatch("importedFile/_list", params),
                delete: (id: number) => store.dispatch("importedFile/_delete", id),
            },
        };

        /* eslint-disable  @typescript-eslint/no-explicit-any */
        const data = reactive({
            backendUrl: config.backend,
            currentImportedFile: {} as ImportedFile,
            currentPage: 1,
            isDeleting: -1,
            isUploading: false,
            loading: true,
            filter: {},
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            order: {
                created_at: "desc",
            } as OrderRequest,
            page: computed(() => data.pagination?.page as number | undefined),
            pages: computed(
                () => state.importedFiles?.pagination?.total_pages as number | undefined
            ),
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            perPage: computed(() => state.pagination?.per_page as number | undefined),
            showDeleteImportedFileDialog: false,
            showImportedFileMappingDialog: false,
            updateTimeout: null,
        }) as any;

        const closeDeleteImportedFile = (): void => {
            data.isDeleting = -1;
            data.showDeleteImportedFileDialog = false;
            update();
        };

        const closeImportedFileMapping = (): void => {
            data.showImportedFileMappingDialog = false;
            update();
        };

        const editImportedFileMapping = (file: ImportedFile): void => {
            data.currentImportedFile = file;
            data.showImportedFileMappingDialog = true;
        };

        const getFormattedDate = (date: string | null): string => {
            if (!date) {
                return "";
            }

            return DateHelper.dateTime(date);
        };

        const goToPage = (page: number) => {
            if (!data.pagination) {
                return;
            }

            data.currentPage = page;
            data.pagination.page = page;

            update();
        };

        const pageSizeChange = (pageSize: number) => {
            if (!data.pagination) {
                return;
            }

            data.pagination.per_page = pageSize;

            update();
        };

        const update = (loading = true) => {
            data.loading = loading;
            actions.importedFile
                .list({
                    filter: data.filter,
                    pagination: data.pagination,
                    order: data.order,
                })
                .then(() => {
                    data.loading = false;
                });
        };

        const updateOnInterval = () => {
            if (data.updateTimeout) {
                clearInterval(data.updateTimeout);
            }

            if (!data.loading) {
                update(false);
            }

            data.updateTimeout = setTimeout(() => {
                updateOnInterval();
            }, 3000);
        };

        onBeforeRouteLeave(() => {
            if (data.updateTimeout) {
                clearTimeout(data.updateTimeout);
            }
        });

        const onBusyUploading = () => {
            data.isUploading = true;
        };

        const onDelete = (file: ImportedFile) => {
            data.currentImportedFile = file;
            data.isDeleting = file.id;
            data.showDeleteImportedFileDialog = true;
        };

        const onDoneUploading = () => {
            data.isUploading = false;

            update();
        };

        onMounted(() => {
            state.importedFiles = {} as DataResult<ImportedFile[]>;

            Object.assign(data.pagination, {
                per_page: config.pagination.per_page,
            });

            Object.assign(data.order, {
                created_at: "desc",
            });

            update();
            updateOnInterval();
        });

        onBeforeRouteLeave(() => {
            if (data.updateTimeout) {
                clearInterval(data.updateTimeout);
            }
        });

        return {
            ...toRefs(data),
            ...toRefs(state),
            accessToken: auth.token,
            closeDeleteImportedFile,
            closeImportedFileMapping,
            editImportedFileMapping,
            getFormattedDate,
            goToPage,
            pageSizeChange,
            textCopier,
            update,
            onBusyUploading,
            onDelete,
            onDoneUploading,
        };
    },
});
</script>

<style lang="scss">
div.el-upload,
div.el-upload-dragger {
    width: 100%;
}
</style>
