<template>
    <strip-dialog
        v-if="this.showStripDialog"
        v-model="this.showStripDialog"
        :strip="this.currentStrip"
        @input="this.stripInput"
    />

    <el-form class="table-actions">
        <el-button @click="this.newStrip">Nieuwe waarde toevoegen</el-button>
    </el-form>

    <el-table
        v-loading="this.loading"
        :default-sort="{ prop: 'value', order: 'ascending' }"
        :data="this.strips"
        @sort-change="this.sortingChanged($event)"
        width="100%"
    >
        <el-table-column
            class-name="column-value"
            label="Waarde"
            prop="value"
            min-width="125"
            sortable
        >
            <template #default="scope">
                {{ scope.row.value }}
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-is-full-title"
            label="Volledige titel strippen"
            prop="is_full_title"
            min-width="125"
            sortable
        >
            <template #default="scope">
                {{ scope.row.is_full_title ? "Ja" : "Nee" }}
            </template>
        </el-table-column>
        <el-table-column class-name="column-actions" fixed="right" min-width="120">
            <template #default="scope">
                <el-button
                    icon="el-icon-edit"
                    plain="plain"
                    size="mini"
                    title="Bekijk details"
                    @click="this.editStrip(scope.row)"
                ></el-button>
                <el-button
                    icon="el-icon-delete"
                    plain="plain"
                    size="mini"
                    title="Verwijder"
                    type="danger"
                    @click="this.deleteStrip(scope)"
                ></el-button>
            </template>
        </el-table-column>
    </el-table>

    <el-pagination
        :background="false"
        :current-page="this.currentPage"
        :hide-on-single-page="false"
        :page-count="this.pages"
        :page-size="this.perPage"
        :page-sizes="this.pageSizes"
        :total="this.totalItems"
        layout="sizes, prev, pager, next, jumper, ->, total"
        @current-change="this.goToPage"
        @next-click="this.goToPage"
        @prev-click="this.goToPage"
        @size-change="this.pageSizeChange"
    />
</template>

<script lang="ts">
import { ElMessage, ElMessageBox } from "element-plus";
import { defineComponent, onMounted, reactive, toRefs } from "vue";

import StripApiService from "@/service/api/settings/strip/strip";
import { Copy } from "@/service/clipboard/copy";
import config from "@/service/config";
import { FilterRequest } from "@/types/request/filterRequest";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { Strip } from "@/types/uzs/settings/strip/strip";
import StripDialog from "@/views/settings/strip/dialogs/Strip.vue";

type CurrentRow = {
    row: Strip;
    rowIndex: number;
};

type SortEvent = {
    column: string;
    order: string;
    prop: string;
};

export default defineComponent({
    name: "List",
    components: { StripDialog },
    setup() {
        const stripService = new StripApiService();
        const textCopier = new Copy();

        const actions = {
            strip: {
                delete: (guid: string) => stripService.delete(guid),
                list: (params: RequestParameters) => stripService.fetchAll(params),
            },
        };

        const data = reactive({
            currentStrip: {} as Strip | null,
            currentPage: 1 as number,
            strips: [] as Strip[],
            loading: true,
            filter: {} as FilterRequest,
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            order: {
                value: "asc",
            } as OrderRequest,
            page: 1,
            pages: undefined as number | undefined,
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            perPage: 25,
            totalPages: 1,
            totalItems: 0,
            showStripDialog: false,
            showFilterModal: false,
            sortingEnabled: false,
        });

        function init() {
            data.loading = true;
            data.sortingEnabled = false;

            const params = {
                filter: data.filter,
                pagination: data.pagination,
                sort: data.order,
            };

            actions.strip.list(params).then((response: DataResult<Strip[] | null>) => {
                data.strips = (response.data ?? []) as Strip[];
                data.page = response.pagination?.page ?? 1;
                data.perPage = response.pagination?.per_page ?? 1;
                data.totalPages = response.pagination?.total_pages ?? 1;
                data.totalItems = response.pagination?.total_items ?? 0;

                data.loading = false;
                data.sortingEnabled = true;
            });
        }

        const stripInput = (value: boolean) => {
            data.currentStrip = null;
            data.showStripDialog = value;

            init();
        };

        const deleteStrip = (value: CurrentRow) => {
            ElMessageBox.confirm(
                "Weet je zeker dat je " + value.row.value + " wilt verwijderen?",
                "Verwijderen?",
                {
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nee",
                    type: "warning",
                }
            ).then(() => {
                actions.strip
                    .delete(value.row.guid)
                    .then((result) => {
                        init();

                        if (!result.success) {
                            throw new Error();
                        }

                        ElMessage({
                            message: value.row.value + " is verwijderd.",
                            type: "success",
                        });
                    })
                    .catch(() => {
                        ElMessage({
                            message: "Er is een onbekende fout opgetreden.",
                            type: "error",
                        });
                    });
            });
        };

        const editStrip = (strip?: Strip | null) => {
            data.currentStrip = strip ?? null;
            data.showStripDialog = true;
        };

        const goToPage = (page: number) => {
            if (!data?.pagination) {
                return;
            }

            data.currentPage = page;
            data.pagination.page = page;

            init();
        };

        const newStrip = () => {
            data.currentStrip = {} as Strip;
            data.showStripDialog = true;
        };

        const pageSizeChange = (pageSize: number) => {
            if (!data?.pagination) {
                return;
            }

            data.pagination.per_page = pageSize;

            init();
        };

        const sortingChanged = ($event: SortEvent) => {
            const property = $event.prop?.length ? $event.prop : "value";
            const direction = $event.order === "descending" ? "desc" : "asc";

            if (!data.strips || !data.order || !data.sortingEnabled) {
                return;
            }

            data.order = {
                [property]: direction,
            };

            init();
        };

        onMounted(() => {
            init();
        });

        return {
            ...toRefs(data),
            stripInput,
            deleteStrip,
            editStrip,
            goToPage,
            newStrip,
            pageSizeChange,
            sortingChanged,
            textCopier,
        };
    },
});
</script>
