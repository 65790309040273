export type GrpSpreadingData = {
    average: number;
    date: string;
    date_string: string;
    time_string: string;
    grp: number;
    type: GrpSpreadingType;
    difference: number;
    solution: number;
    spreading: number;
};

export enum GrpSpreadingType {
    LOW_SPREADING = "LOW",
    HIGH_SPREADING = "HIGH",
}
