<template>
    <div v-if="this.subOrders.length">
        <el-table :data="this.subOrders" class="sub-orders">
            <el-table-column prop="operator" label="Exploitant" width="150">
                <template #default="scope">
                    <span class="ellipsis" style="max-width: 150px">{{
                        scope.row.operator === "Rtl" ? "AdAlliance" : scope.row.operator
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="order_identifier" label="Order" width="170">
                <template #default="scope">
                    <span
                        :class="
                            scope.row.order_reference.toLowerCase().indexOf('n/a') !== -1
                                ? 'is--greyed-out'
                                : ''
                        "
                    >
                        {{
                            scope.row.order_reference.toLowerCase().indexOf("n/a") !== -1
                                ? "n/a"
                                : scope.row.order_reference
                                      .substring(scope.row.order_reference.indexOf(":"))
                                      .replace(":", "")
                        }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="package_description" label="Pakket">
                <template #default="scope">
                    <span
                        class="ellipsis"
                        :title="scope.row.description"
                        style="max-width: 150px"
                        >{{ scope.row.description }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column prop="requested_grps" label="Aangevraagd">
                <template #default="scope">
                    <span class="ellipsis" style="max-width: 100px">{{
                        this.grpHelper.display(scope.row.requested_grps)
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="booked_grps" label="Geboekt">
                <template #default="scope">
                    <span class="ellipsis" style="max-width: 100px">{{
                        this.grpHelper.display(scope.row.booked_grps)
                    }}</span>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs, watch } from "vue";

import GrpHelper from "@/helper/grp-helper";
import { Alert } from "@/types/uzs/alert";
import { Package } from "@/types/uzs/alert/package";

const props = {
    alert: {
        type: Object as PropType<Alert>,
        required: true,
    },
};

export default defineComponent({
    name: "AlertSubOrdersTable",
    props,
    setup(props) {
        const alert = ref(props.alert) as Ref<Alert>;
        const grpHelper = GrpHelper;

        const data = reactive({
            subOrders: [] as Array<Package>,
        });

        const init = (): void => {
            data.subOrders = alert.value?.data as Array<Package>;
            data.subOrders.sort();

            console.info(data.subOrders);
        };

        onMounted(() => {
            init();
        });

        watch(
            () => props.alert,
            (value, oldValue) => {
                if (value !== oldValue) {
                    alert.value = value ?? ({} as Alert);
                    init();
                }
            }
        );

        return {
            ...toRefs(data),
            close,
            grpHelper,
        };
    },
});
</script>

<style>
h3 {
    margin-bottom: 5px;
}

p {
    margin-bottom: 15px;
    word-break: break-word;
}

b.red {
    color: red;
}

div.el-table.sub-orders {
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
}
</style>
