
import { ElMessage } from "element-plus";
import { defineComponent, inject } from "vue";
import { mapActions, mapState, Store } from "vuex";

import { User } from "@/types/credentials/user";
import { AuthenticationRequest } from "@/types/google/authenticationRequest";
import { State } from "@/types/state";

export default defineComponent({
    name: "Login",
    setup() {
        const store = inject("store") as Store<State>;

        return {
            _store: store,
        };
    },
    computed: {
        ...mapState(["token"]),
    },
    data() {
        return {
            isLoading: false,
            credentials: {} as User,
        };
    },
    mounted() {
        this.rerouteUser();

        if (this.$route.query?.type === "sso") {
            this.isLoading = true;

            this.loginWithGoogle(this.$route.query as AuthenticationRequest)
                .then(this.rerouteUser)
                .catch(() => {
                    ElMessage({
                        message: "Kan niet inloggen. Controleer de ingevulde gegevens.",
                        type: "error",
                    });

                    this.isLoading = false;
                });
        }
    },
    methods: {
        ...mapActions("authentication", ["checkIfTokenExpired", "login", "loginWithGoogle"]),
        keyPress(event: KeyboardEvent) {
            if (event.key.toLowerCase() === "enter") {
                this.loginUser();
            }
        },
        loginUser() {
            this.isLoading = true;

            this.login(this.credentials)
                .then(this.rerouteUser)
                .catch(() => {
                    ElMessage({
                        message: "Kan niet inloggen. Controleer de ingevulde gegevens.",
                        type: "error",
                    });

                    this.isLoading = false;
                });
        },
        rerouteUser() {
            this.checkIfTokenExpired().then((response: boolean) => {
                if (!response) {
                    this.$router.push({
                        name: "campaigns",
                    });
                }
            });
        },
    },
});
