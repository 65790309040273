export default class GrpHelper {
    static display(value: number | null | undefined, round = false): string {
        if (value === null || value === undefined) {
            return "? GRP";
        }

        if (round) {
            value = Math.round(value);
        }

        let grp = (Math.round(value * 10) / 10).toString().replace(".", ",");

        if (!round && grp.indexOf(",") === -1) {
            grp += ",0";
        }

        return grp + " GRP";
    }
}
