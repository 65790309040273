import axios from "axios";
import { ActionContext } from "vuex";

import config from "@/service/config";
import { Request } from "@/types/request/request";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { SuccessResult } from "@/types/result/successResult";
import { ImportedFileState } from "@/types/state/importedFile";
import { RootState } from "@/types/state/root";
import { ImportedFile } from "@/types/uzs/importedFile";

const initialState: ImportedFileState = {
    columns: undefined,
    filter: {},
    order: {},
    pagination: {
        page: 1,
        per_page: config.pagination.per_page,
    },
    search: undefined,
    importedFile: undefined,
    importedFiles: undefined,
};

const ImportedFileModule = {
    namespaced: true,
    state: initialState,
    getters: {},
    mutations: {},
    actions: {
        _delete(
            context: ActionContext<ImportedFileState, RootState>,
            params: Request | null
        ): Promise<SuccessResult> {
            return axios
                .post(config.backend + "/api/uzs/imported-file/" + params?.id + "/delete", params)
                .then((response) => response?.data)
                .then((response) => response as SuccessResult);
        },
        _list(
            context: ActionContext<ImportedFileState, RootState>,
            params: RequestParameters | null
        ): Promise<DataResult<ImportedFile[]>> {
            return axios
                .get(config.backend + "/api/uzs/imported-files", {
                    params: {
                        columns: params?.columns ?? context.state.columns,
                        filter: params?.filter ?? context.state.filter,
                        order: params?.order ?? context.state.order,
                        pagination: params?.pagination ?? context.state.pagination,
                        search: params?.search ?? context.state.search,
                    },
                })
                .then((response) => response?.data as DataResult<ImportedFile[]>)
                .then((response) => {
                    context.state.importedFiles = response;
                    return response;
                });
        },
        _details(
            context: ActionContext<ImportedFileState, RootState>,
            id: number
        ): Promise<DataResult<ImportedFile>> {
            return axios
                .get(config.backend + "/api/uzs/imported-file/" + id)
                .then((response) => response?.data as DataResult<ImportedFile>)
                .then((response) => {
                    context.state.importedFile = response;
                    return response;
                });
        },
        _store(
            context: ActionContext<ImportedFileState, RootState>,
            importedFile: ImportedFile
        ): Promise<DataResult<ImportedFile>> {
            return axios
                .post(config.backend + "/api/uzs/imported-file", importedFile)
                .then((response) => response?.data as DataResult<ImportedFile>)
                .then((response) => {
                    context.state.importedFile = response;
                    return response;
                });
        },
    },
};

export default ImportedFileModule;
