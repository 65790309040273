<template>
    <el-dialog
        :title="
            this.targetGroup?.name || this.targetGroup?.sf_target_group?.description
                ? 'Doelgroep - ' +
                  (this.targetGroup?.name ?? this.targetGroup?.sf_target_group?.description)
                : 'Nieuwe doelgroep'
        "
        :before-close="this.dialogClosed"
        :destroy-on-close="true"
        :lock-scroll="false"
        :model-value="this.value"
        width="100%"
        ref="dialog"
    >
        <el-form label-position="top" :model="this.targetGroup" @keydown="this.submitOnEnter">
            <el-form-item label="Land">
                <el-select
                    v-model="this.targetGroup.country"
                    filterable
                    @change="this.onCountrySelected"
                >
                    <el-option v-for="item in this.countries" :key="item" :value="item"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Doelgroep uit TIP / Import">
                <el-select
                    v-if="!this.isLoadingTargetGroups"
                    v-model="this.targetGroup.sf_target_group_id"
                    filterable
                >
                    <el-option
                        v-for="item in this.screenForceTargetGroups"
                        :key="item.id"
                        :label="item.description"
                        :value="item.id"
                    ></el-option>
                </el-select>
                <el-icon v-if="this.isLoadingTargetGroups" name="loading" />
            </el-form-item>
            <el-form-item label="Doelgroep">
                <el-input v-model="this.targetGroup.name" />
            </el-form-item>
            <el-form-item label="Grootte">
                <el-input-number
                    v-model="this.targetGroup.size"
                    :model-value="this.targetGroup.size"
                    :min="0"
                    :max="999999999999999"
                    :step="1"
                />
            </el-form-item>
            <el-form-item label="Belangrijkste doelgroep">
                <el-switch v-model="this.targetGroup.is_most_important" />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="this.save()">Opslaan</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script lang="ts">
import { ElMessage } from "element-plus";
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs, watch } from "vue";
import { useStore } from "vuex";

import { Country } from "@/enums/country";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { TargetGroup as ScreenForceTargetGroup } from "@/types/screen-force/targetGroup";
import { TargetGroup } from "@/types/uzs/targetGroup";

const props = {
    value: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
    targetGroup: {
        type: Object as PropType<TargetGroup>,
        required: true,
    },
};

export default defineComponent({
    name: "TargetGroupDialog",
    props,
    setup(props, { emit }) {
        const store = useStore();
        const targetGroup = ref(props.targetGroup) as Ref<TargetGroup | undefined>;

        const actions = {
            targetGroup: {
                list: (params?: RequestParameters) => store.dispatch("targetGroup/_list", params),
                listScreenForce: (params?: RequestParameters) =>
                    store.dispatch("targetGroup/_list_screen_force", params),
                store: (data: TargetGroup) => store.dispatch("targetGroup/_store", data),
            },
        };

        const data = reactive({
            countries: Country,
            allTargetGroups: [] as TargetGroup[],
            usedScreenForceTargetGroups: [] as number[],
            screenForceTargetGroups: [] as ScreenForceTargetGroup[],
            isLoadingTargetGroups: false,
        });

        const init = (): void => {
            if (targetGroup.value && !targetGroup.value?.country) {
                targetGroup.value.country = Country.NETHERLANDS;
            }

            loadTargetGroups();
        };

        const close = (): void => {
            targetGroup.value = {} as TargetGroup;

            emit("input", props.value);
        };

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        const loadTargetGroups = (): void => {
            data.isLoadingTargetGroups = true;

            actions.targetGroup
                .list({
                    pagination: {
                        page: 1,
                        per_page: 1000,
                    },
                })
                .then((response) => {
                    data.allTargetGroups = response.data;

                    data.usedScreenForceTargetGroups = data.allTargetGroups.map((result) => {
                        return result.id !== targetGroup.value?.id ? result.sf_target_group_id : -1;
                    });

                    loadScreenForceTargetGroups();
                });
        };

        const loadScreenForceTargetGroups = (): void => {
            data.isLoadingTargetGroups = true;

            actions.targetGroup
                .listScreenForce({
                    order: {
                        description: "asc",
                    },
                    filter: {
                        country: {
                            operator: "=",
                            value: targetGroup.value?.country ?? Country.NETHERLANDS,
                        },
                    },
                    pagination: {
                        page: 1,
                        per_page: 1000,
                    },
                })
                .then((targetGroups: DataResult<ScreenForceTargetGroup[]>) => {
                    data.screenForceTargetGroups =
                        targetGroups.data?.filter(
                            (targetGroup) =>
                                !data.usedScreenForceTargetGroups.includes(targetGroup.id)
                        ) ?? [];

                    data.isLoadingTargetGroups = false;
                })
                .catch(() => (data.isLoadingTargetGroups = false));
        };

        const save = (): void => {
            if (!targetGroup.value) {
                return;
            }

            actions.targetGroup
                .store(targetGroup.value)
                .then(() => {
                    ElMessage({
                        message:
                            (targetGroup.value?.name ??
                                targetGroup.value?.sf_target_group?.description) +
                            " is opgeslagen.",
                        type: "success",
                    });

                    close();
                })
                .catch(() => {
                    ElMessage({
                        message:
                            "Er is een fout opgetreden bij het opslaan van " +
                            (targetGroup.value?.name ??
                                targetGroup.value?.sf_target_group?.description),
                        type: "error",
                    });
                });
        };

        const onCountrySelected = (): void => {
            loadTargetGroups();
        };

        const submitOnEnter = (event: KeyboardEvent): void => {
            const input = event.target as HTMLElement | null;

            if (
                event.key.toLowerCase() === "enter" &&
                input?.tagName?.toLowerCase() !== "textarea"
            ) {
                save();
            }
        };

        watch(
            () => props.targetGroup,
            (value, oldValue) => {
                if (value !== oldValue) {
                    targetGroup.value = value ?? ({ country: Country.NETHERLANDS } as TargetGroup);
                }

                init();
            }
        );

        onMounted(() => {
            init();
        });

        return {
            ...toRefs(data),
            dialogClosed,
            onCountrySelected,
            save,
            submitOnEnter,
            targetGroup,
        };
    },
});
</script>
