
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import { Store, useStore } from "vuex";

import HolidayMessage from "@/components/message/HolidayMessage.vue";
import DateHelper from "@/helper/date-helper";
import PercentageHelper from "@/helper/percentage-helper";
import ImportedLeadsApiService from "@/service/api/importedLeads";
import config from "@/service/config";
import { Authentication } from "@/types/credentials/authentication";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { RequestParameters } from "@/types/request/requestParameters";
import { RootState } from "@/types/state/root";
import { ImportedLeads } from "@/types/uzs/importedLeads";
import DownloadTemplateDialog from "@/views/import/leads/dialogs/DownloadTemplate.vue";
import ImportedLeadsDialog from "@/views/import/leads/dialogs/ImportedLeads.vue";

export default defineComponent({
    name: "List",
    components: { HolidayMessage, ImportedLeadsDialog, DownloadTemplateDialog },
    setup() {
        const store = useStore() as Store<RootState>;
        const auth = store.state.authentication as Authentication;
        const dateHelper = DateHelper;
        const importedLeadsApi = new ImportedLeadsApiService();

        const actions = {
            importedLeads: {
                list: (params: RequestParameters) => importedLeadsApi.fetchAll(params),
                delete: (id: number) => importedLeadsApi.delete(id),
            },
        };

        /* eslint-disable  @typescript-eslint/no-explicit-any */
        const data = reactive({
            backendUrl: config.backend,
            isDeleting: -1,
            isLoading: false,
            isUploading: false,
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            order: {
                created_at: "desc",
            } as OrderRequest,
            currentPage: 1,
            currentLeads: null as ImportedLeads | null,
            pageCount: 0,
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            itemsPerPage: config.pagination.per_page,
            itemCount: 0,
            importedLeads: [] as ImportedLeads[],
            showTemplateDialog: false,
            showImportedLeadsDialog: false,
            updateTimeout: null as any | null,
        });

        const getMatchedPercentage = (importedLeads: ImportedLeads): string => {
            if (importedLeads.number_of_leads === 0) {
                return "0%";
            }

            return PercentageHelper.display(
                (importedLeads.number_of_allocated_leads / importedLeads.number_of_leads) * 100,
                true
            );
        };

        const goToPage = (page: number) => {
            if (!data.pagination) {
                return;
            }

            data.currentPage = page;
            data.pagination.page = page;

            loadImportedLeads();
        };

        const init = (): void => {
            loadImportedLeads();
            updateOnInterval();
        };

        const loadImportedLeads = (isLoading = true): void => {
            data.isLoading = isLoading;

            actions.importedLeads
                .list({
                    pagination: data.pagination,
                    order: data.order,
                })
                .then((result) => {
                    data.currentPage = result.pagination?.page ?? 1;
                    data.pageCount = result.pagination?.total_pages ?? 0;
                    data.itemsPerPage = result.pagination?.per_page ?? config.pagination.per_page;
                    data.itemCount = result.pagination?.total_items ?? 0;

                    return (result.data ?? []) as ImportedLeads[];
                })
                .then((response) => {
                    data.importedLeads = response;
                    data.isLoading = false;
                });
        };

        const onBusyUploading = () => {
            data.isUploading = true;
        };

        const onDelete = (importedLeads: ImportedLeads): void => {
            data.isDeleting = importedLeads.id;

            actions.importedLeads
                .delete(importedLeads.id)
                .then(() => (data.isDeleting = -1))
                .catch(() => (data.isDeleting = -1));
        };

        const onDoneEditing = (): void => {
            data.currentLeads = null;
            data.showImportedLeadsDialog = false;
        };

        const onDoneUploading = () => {
            data.isUploading = false;

            loadImportedLeads();
        };

        const onEdit = (importedLeads: ImportedLeads): void => {
            data.currentLeads = importedLeads;
            data.showImportedLeadsDialog = true;
        };

        const onPageSizeChanged = (pageSize: number) => {
            if (!data.pagination) {
                return;
            }

            data.pagination.per_page = pageSize;

            loadImportedLeads();
        };

        const updateOnInterval = () => {
            if (data.updateTimeout) {
                clearTimeout(data.updateTimeout);
            }

            if (!data.isLoading) {
                loadImportedLeads(false);
            }

            data.updateTimeout = setTimeout(() => {
                updateOnInterval();
            }, 3000);
        };

        onMounted(() => {
            init();
        });

        onBeforeRouteLeave(() => {
            if (data.updateTimeout) {
                clearTimeout(data.updateTimeout);
            }
        });

        return {
            ...toRefs(data),
            accessToken: auth.token,
            dateHelper,
            getMatchedPercentage,
            goToPage,
            onBusyUploading,
            onDelete,
            onDoneEditing,
            onDoneUploading,
            onEdit,
            onPageSizeChanged,
        };
    },
});
