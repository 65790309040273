
import { format, formatISO, subDays } from "date-fns";
import { nl } from "date-fns/locale";
import { ElMessage } from "element-plus";
import { defineComponent, onMounted, PropType, reactive, toRefs, watch } from "vue";
import { Store, useStore } from "vuex";

import AdvertiserApiService from "@/service/api/advertiser";
import config from "@/service/config";
import { RequestParameters } from "@/types/request/requestParameters";
import { SuccessResult } from "@/types/result/successResult";
import { RootState } from "@/types/state/root";
import { Advertiser } from "@/types/uzs/advertiser";
import { Flight } from "@/types/uzs/flight";
import { GenerateRequest } from "@/types/uzs/request/spreadsheet";

const props = {
    flight: {
        type: Object as PropType<Flight>,
        required: false,
    },
};

export default defineComponent({
    name: "ExportDialog",
    props,
    setup(props, { emit }) {
        const advertiserApi = new AdvertiserApiService();
        const store = useStore() as Store<RootState>;

        const actions = {
            flight: {
                details: (id: number) => store.dispatch("flight/_details", id),
                list: (params?: RequestParameters) => store.dispatch("flight/_list", params),
            },
            spreadsheet: {
                generate: (data: GenerateRequest) => store.dispatch("spreadsheet/_generate", data),
            },
        };

        const data = reactive({
            advertiserId: null as number | null,
            allAdvertisers: [] as Advertiser[],
            allCountries: config.countries,
            flightId: null as number | null,
            allFlights: [] as Flight[],
            selectedFlights: [] as number[],
            startDate: null as Date | null,
            endDate: null as Date | null,
            forClient: false,
            exportGrp: false,
            exportFrequencyChannels: false,
            isModalVisible: true,
        });

        const init = (): void => {
            data.advertiserId = props.flight?.uzs_advertiser_id
                ? props.flight.uzs_advertiser_id
                : null;

            data.flightId = props.flight ? props.flight.id : null;

            if (data.flightId && !data.selectedFlights.length) {
                data.selectedFlights.push(data.flightId);
            }

            loadAdvertisers();
            loadFlights();
            setDates();
        };

        const getDatePeriod = (flight: Flight): string => {
            const startDate = Date.parse(flight.start_date ?? "now");
            const endDate = Date.parse(flight.end_date);

            let startDay = format(startDate, "d");
            const endDay = format(endDate, "d");

            let startMonth =
                " " + format(startDate, "LLL", { locale: nl }).toLowerCase().replace(".", "");

            const endMonth =
                " " + format(endDate, "LLL", { locale: nl }).toLowerCase().replace(".", "");

            let startYear = " " + format(startDate, "yyyy");

            const endYear = " " + format(endDate, "yyyy");

            if (startDay === endDay && startMonth === endMonth && startYear === endYear) {
                startDay = "";
            }

            if (startMonth === endMonth) {
                startMonth = "";
            }

            if (startYear === endYear) {
                startYear = "";
            }

            return (
                startDay +
                startMonth +
                startYear +
                (startDay === "" && startMonth === "" && startYear === "" ? "" : " - ") +
                endDay +
                endMonth +
                endYear
            );
        };

        const loadAdvertisers = (): void => {
            advertiserApi
                .fetchAll({
                    order: {
                        name: "asc",
                    },
                    pagination: {
                        page: 1,
                        per_page: 1000,
                    },
                })
                .then((response) => {
                    data.allAdvertisers = response.data ?? [];
                });
        };

        const close = (changed = false): void => {
            data.isModalVisible = false;
            emit("close", changed);
        };

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        const exportData = (): void => {
            if (!data.selectedFlights.length) {
                return;
            }

            const request = {
                campaign_ids: data.selectedFlights,
                start_date: data.startDate ? formatISO(data.startDate) : null,
                end_date: data.endDate ? formatISO(data.endDate) : null,
                for_client: data.forClient,
                export_grp: data.exportGrp,
                export_frequency_channels: data.exportFrequencyChannels,
            };

            actions.spreadsheet
                .generate(request)
                .then((result: SuccessResult) => {
                    if (result.success) {
                        ElMessage({
                            message: "De export is op de achtergrond gestart.",
                            type: "success",
                        });

                        close(true);

                        return;
                    }

                    throw new Error();
                })
                .catch(() => {
                    ElMessage({
                        message: "De export kon niet gestart worden.",
                        type: "error",
                    });
                });
        };

        const loadFlights = (): void => {
            data.allFlights = [];

            actions.flight
                .list({
                    filter: {
                        uzs_advertiser_id: {
                            operator: "=",
                            value: data.advertiserId,
                        },
                    },
                    order: {
                        start_date: "desc",
                    },
                    pagination: {
                        page: 1,
                        per_page: 1000,
                    },
                })
                .then((response) => {
                    data.allFlights = response.data ?? [];
                });
        };

        function onAdvertiserChanged() {
            data.flightId = null;
            data.selectedFlights = [];
        }

        const setDates = (flights?: Array<Flight>): void => {
            const today = new Date();
            const yesterday = subDays(new Date(), 1);

            if (!flights?.length && props.flight !== undefined) {
                flights = [props.flight];
            }

            if (flights?.length) {
                flights.sort((a, b) => {
                    if (a.start_date === b.start_date) {
                        return 0;
                    }

                    return a.start_date > b.start_date ? 1 : -1;
                });

                console.info(flights);

                data.startDate = new Date(flights[0].start_date ?? yesterday);
                data.endDate = new Date(flights[flights.length - 1].end_date ?? today);
            }
        };

        const submitOnEnter = (event: KeyboardEvent): void => {
            const input = event.target as HTMLElement | null;

            if (
                event.key.toLowerCase() === "enter" &&
                input?.tagName?.toLowerCase() !== "textarea"
            ) {
                exportData();
            }
        };

        watch(
            () => data.advertiserId,
            (value, oldValue) => {
                if (value !== oldValue) {
                    loadFlights();
                }
            }
        );

        watch(
            () => data.selectedFlights,
            (value, oldValue) => {
                if (value !== oldValue) {
                    const selectedFlightsData = data.allFlights.filter((flight) =>
                        data.selectedFlights.includes(flight.id)
                    );

                    setDates(selectedFlightsData);
                }
            }
        );

        onMounted(() => {
            if (props.flight) {
                init();
            }
        });

        return {
            ...toRefs(data),
            close,
            dialogClosed,
            exportData,
            getDatePeriod,
            onAdvertiserChanged,
            submitOnEnter,
        };
    },
});
