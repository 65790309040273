<template>
    <holiday-message />

    <download-template-dialog
        v-model="this.showTemplateDialog"
        @input="this.showTemplateDialog = false"
    />

    <imported-leads-dialog
        v-model="this.showImportedLeadsDialog"
        :imported-leads="this.currentLeads"
        @input="this.onDoneEditing"
    />

    <el-form class="table-actions">
        <el-button type="secondary" @click="this.showTemplateDialog = true"
            >Download template</el-button
        >
    </el-form>

    <div>
        <el-upload
            class="uploader"
            drag
            multiple
            :action="this.backendUrl + '/campaign/lead/upload-spreadsheet'"
            :headers="{ Authorization: 'Bearer ' + this.accessToken }"
            :show-file-list="false"
            @error="this.onDoneUploading"
            @progress="this.onBusyUploading"
            @success="this.onDoneUploading"
        >
            <el-icon name="upload"></el-icon>
            <div v-if="!this.isUploading" class="el-upload__text">
                Sleep hier bestanden naartoe om ze te uploaden, of klik dit veld aan om een bestand
                te selecteren...
            </div>
            <div v-if="this.isUploading">
                <el-icon name="loading" />
            </div>
        </el-upload>
    </div>

    <el-table
        v-loading="this.isLoading"
        :default-sort="{ prop: 'created_at', order: 'descending' }"
        :data="this.importedLeads"
        width="100%"
    >
        <el-table-column
            class-name="column-filename"
            label="Bestand"
            min-width="150"
            prop="filename"
        >
            <template #default="scope">
                <span :title="scope.row.filename">{{ scope.row.filename }}</span>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-number-of-leads"
            label="Aantal leads"
            prop="number_of_leads"
        >
            <template #default="scope">
                <span :title="scope.row.number_of_leads">{{ scope.row.number_of_leads }}</span>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-number-of-allocated-leads"
            label="Geattribueerd"
            prop="number_of_allocated_leads"
        >
            <template #default="scope">
                <span :title="scope.row.number_of_allocated_leads">{{
                    scope.row.number_of_allocated_leads
                }}</span>
            </template>
        </el-table-column>
        <el-table-column class-name="column-matched" label="Matched" prop="matched">
            <template #default="scope">
                <span :title="getMatchedPercentage(scope.row)">{{
                    getMatchedPercentage(scope.row)
                }}</span>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-created-at"
            label="Geïmporteerd"
            min-width="100"
            prop="created_at"
        >
            <template #default="scope">
                <span :title="this.dateHelper.dateTime(scope.row.created_at)">{{
                    this.dateHelper.dateTime(scope.row.created_at)
                }}</span>
            </template>
        </el-table-column>
        <el-table-column
            class-name="column-import-end"
            label="Verwerkt"
            min-width="100"
            prop="import_end"
        >
            <template #default="scope">
                <span
                    v-if="scope.row.import_end"
                    :title="this.dateHelper.dateTime(scope.row.import_end)"
                    >{{ this.dateHelper.dateTime(scope.row.import_end) }}</span
                >
                <el-icon v-if="scope.row.import_start && !scope.row.import_end" name="loading" />
            </template>
        </el-table-column>
        <el-table-column class-name="column-actions" fixed="right">
            <template #default="scope">
                <el-button
                    icon="el-icon-edit"
                    plain="plain"
                    size="mini"
                    title="Stel flight in"
                    @click="this.onEdit(scope.row)"
                ></el-button>
                <el-button
                    :icon="'el-icon-' + (this.isDeleting === scope.row?.id ? 'loading' : 'delete')"
                    :disabled="this.isDeleting === scope.row?.id"
                    plain="plain"
                    size="mini"
                    title="Verwijder bestand en data"
                    type="danger"
                    @click="this.onDelete(scope.row)"
                ></el-button>
            </template>
        </el-table-column>
    </el-table>

    <el-pagination
        :background="false"
        :current-page="this.currentPage"
        :hide-on-single-page="false"
        :page-count="this.pageCount"
        :page-size="this.itemsPerPage"
        :page-sizes="this.pageSizes"
        :total="this.itemCount"
        layout="sizes, prev, pager, next, jumper, ->, total"
        @current-change="this.goToPage"
        @next-click="this.goToPage"
        @prev-click="this.goToPage"
        @size-change="this.onPageSizeChanged"
    />
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import { Store, useStore } from "vuex";

import HolidayMessage from "@/components/message/HolidayMessage.vue";
import DateHelper from "@/helper/date-helper";
import PercentageHelper from "@/helper/percentage-helper";
import ImportedLeadsApiService from "@/service/api/importedLeads";
import config from "@/service/config";
import { Authentication } from "@/types/credentials/authentication";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { RequestParameters } from "@/types/request/requestParameters";
import { RootState } from "@/types/state/root";
import { ImportedLeads } from "@/types/uzs/importedLeads";
import DownloadTemplateDialog from "@/views/import/leads/dialogs/DownloadTemplate.vue";
import ImportedLeadsDialog from "@/views/import/leads/dialogs/ImportedLeads.vue";

export default defineComponent({
    name: "List",
    components: { HolidayMessage, ImportedLeadsDialog, DownloadTemplateDialog },
    setup() {
        const store = useStore() as Store<RootState>;
        const auth = store.state.authentication as Authentication;
        const dateHelper = DateHelper;
        const importedLeadsApi = new ImportedLeadsApiService();

        const actions = {
            importedLeads: {
                list: (params: RequestParameters) => importedLeadsApi.fetchAll(params),
                delete: (id: number) => importedLeadsApi.delete(id),
            },
        };

        /* eslint-disable  @typescript-eslint/no-explicit-any */
        const data = reactive({
            backendUrl: config.backend,
            isDeleting: -1,
            isLoading: false,
            isUploading: false,
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            order: {
                created_at: "desc",
            } as OrderRequest,
            currentPage: 1,
            currentLeads: null as ImportedLeads | null,
            pageCount: 0,
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            itemsPerPage: config.pagination.per_page,
            itemCount: 0,
            importedLeads: [] as ImportedLeads[],
            showTemplateDialog: false,
            showImportedLeadsDialog: false,
            updateTimeout: null as any | null,
        });

        const getMatchedPercentage = (importedLeads: ImportedLeads): string => {
            if (importedLeads.number_of_leads === 0) {
                return "0%";
            }

            return PercentageHelper.display(
                (importedLeads.number_of_allocated_leads / importedLeads.number_of_leads) * 100,
                true
            );
        };

        const goToPage = (page: number) => {
            if (!data.pagination) {
                return;
            }

            data.currentPage = page;
            data.pagination.page = page;

            loadImportedLeads();
        };

        const init = (): void => {
            loadImportedLeads();
            updateOnInterval();
        };

        const loadImportedLeads = (isLoading = true): void => {
            data.isLoading = isLoading;

            actions.importedLeads
                .list({
                    pagination: data.pagination,
                    order: data.order,
                })
                .then((result) => {
                    data.currentPage = result.pagination?.page ?? 1;
                    data.pageCount = result.pagination?.total_pages ?? 0;
                    data.itemsPerPage = result.pagination?.per_page ?? config.pagination.per_page;
                    data.itemCount = result.pagination?.total_items ?? 0;

                    return (result.data ?? []) as ImportedLeads[];
                })
                .then((response) => {
                    data.importedLeads = response;
                    data.isLoading = false;
                });
        };

        const onBusyUploading = () => {
            data.isUploading = true;
        };

        const onDelete = (importedLeads: ImportedLeads): void => {
            data.isDeleting = importedLeads.id;

            actions.importedLeads
                .delete(importedLeads.id)
                .then(() => (data.isDeleting = -1))
                .catch(() => (data.isDeleting = -1));
        };

        const onDoneEditing = (): void => {
            data.currentLeads = null;
            data.showImportedLeadsDialog = false;
        };

        const onDoneUploading = () => {
            data.isUploading = false;

            loadImportedLeads();
        };

        const onEdit = (importedLeads: ImportedLeads): void => {
            data.currentLeads = importedLeads;
            data.showImportedLeadsDialog = true;
        };

        const onPageSizeChanged = (pageSize: number) => {
            if (!data.pagination) {
                return;
            }

            data.pagination.per_page = pageSize;

            loadImportedLeads();
        };

        const updateOnInterval = () => {
            if (data.updateTimeout) {
                clearTimeout(data.updateTimeout);
            }

            if (!data.isLoading) {
                loadImportedLeads(false);
            }

            data.updateTimeout = setTimeout(() => {
                updateOnInterval();
            }, 3000);
        };

        onMounted(() => {
            init();
        });

        onBeforeRouteLeave(() => {
            if (data.updateTimeout) {
                clearTimeout(data.updateTimeout);
            }
        });

        return {
            ...toRefs(data),
            accessToken: auth.token,
            dateHelper,
            getMatchedPercentage,
            goToPage,
            onBusyUploading,
            onDelete,
            onDoneEditing,
            onDoneUploading,
            onEdit,
            onPageSizeChanged,
        };
    },
});
</script>

<style lang="scss"></style>
