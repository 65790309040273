import axios from "axios";

import config from "@/service/config";
import { SuccessResult } from "@/types/result/successResult";
import { UserState } from "@/types/state/user";

const initialState: UserState = {
    columns: undefined,
    filter: {},
    order: {
        id: "asc",
    },
    pagination: {
        page: 1,
        per_page: config.pagination.per_page,
    },
    search: undefined,
    user: undefined,
    users: undefined,
};

const UserModule = {
    namespaced: true,
    state: initialState,
    getters: {},
    mutations: {},
    actions: {
        ping(): Promise<SuccessResult> {
            return axios
                .get(config.backend + "/api/uzs/ping")
                .then((response) => response?.data)
                .then((response) => response as SuccessResult);
        },
    },
};

export default UserModule;
