import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import config from "@/service/config";
import AuthenticationModule from "@/store/modules/authenticationModule";
import ChannelModule from "@/store/modules/channelModule";
import CommercialModule from "@/store/modules/commercialModule";
import DayPartModule from "@/store/modules/dayPartModule";
import EventModule from "@/store/modules/eventModule";
import FlightModule from "@/store/modules/flightModule";
import ImportedFileModule from "@/store/modules/importedFileModule";
import ImportTemplateModule from "@/store/modules/importTemplateModule";
import LeadSpreadsheetModule from "@/store/modules/leadSpreadsheetModule";
import LogModule from "@/store/modules/logModule";
import SpreadsheetModule from "@/store/modules/spreadsheetModule";
import TargetGroupModule from "@/store/modules/targetGroupModule";
import UserModule from "@/store/modules/userModule";
import { Authentication } from "@/types/credentials/authentication";
import { RootState } from "@/types/state/root";

const rootState: RootState = {
    columns: undefined,
    filter: {},
    order: {
        property: "id",
        direction: "asc",
    },
    pagination: {
        page: 1,
        per_page: config.pagination.per_page,
    },
    search: undefined,
    authentication: undefined,
};

const persistedState = createPersistedState({
    paths: ["authentication"],
});

export default createStore({
    state: rootState,
    modules: {
        authentication: AuthenticationModule,
        channel: ChannelModule,
        commercial: CommercialModule,
        dayPart: DayPartModule,
        event: EventModule,
        log: LogModule,
        flight: FlightModule,
        importedFile: ImportedFileModule,
        importTemplate: ImportTemplateModule,
        user: UserModule,
        spreadsheet: SpreadsheetModule,
        leadSpreadsheet: LeadSpreadsheetModule,
        targetGroup: TargetGroupModule,
    },
    mutations: {
        updateAuthentication(state: RootState, authentication: Authentication): void {
            state.authentication = authentication;
        },
    },
    plugins: [persistedState],
});
