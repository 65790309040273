<template>
    <div v-if="this.spreading.length">
        <p v-html="message?.replace('[dates]', this.getDates())" />
        <el-table :data="this.spreading" class="spreading">
            <el-table-column prop="date_string" label="Datum">
                <template #default="scope">
                    <span class="ellipsis" :title="scope.row.date_string" style="max-width: 250px">
                        {{ scope.row.date_string }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column prop="type" label="Probleem" width="170">
                <template #default="scope">
                    <span
                        class="ellipsis"
                        :title="
                            scope.row.type === this.spreadingType.LOW_SPREADING
                                ? 'Te veel GRP'
                                : 'Te weinig GRP'
                        "
                        style="max-width: 150px"
                    >
                        {{
                            scope.row.type === this.spreadingType.LOW_SPREADING
                                ? "Te veel GRP"
                                : "Te weinig GRP"
                        }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column prop="solution" label="Oplossing" width="170">
                <template #default="scope">
                    <span
                        class="ellipsis"
                        :title="
                            scope.row.type === this.spreadingType.LOW_SPREADING
                                ? 'ca. ' + this.grpHelper.display(scope.row.solution) + ' afboeken'
                                : 'ca. ' + this.grpHelper.display(scope.row.solution) + ' bijboeken'
                        "
                        style="max-width: 150px"
                    >
                        {{
                            scope.row.type === this.spreadingType.LOW_SPREADING
                                ? "ca. " + this.grpHelper.display(scope.row.solution) + " afboeken"
                                : "ca. " + this.grpHelper.display(scope.row.solution) + " bijboeken"
                        }}</span
                    >
                </template></el-table-column
            >
            <el-table-column prop="grp" label="GRP" width="170">
                <template #default="scope">
                    <span
                        class="ellipsis"
                        :title="this.grpHelper.display(scope.row.grp)"
                        style="max-width: 150px"
                    >
                        <b class="red">{{ this.grpHelper.display(scope.row.grp) }}</b></span
                    >
                </template>
            </el-table-column>
            <el-table-column prop="average" label="Gemiddeld" width="170">
                <template #default="scope">
                    <span
                        class="ellipsis"
                        :title="this.grpHelper.display(scope.row.average_grp)"
                        style="max-width: 150px"
                    >
                        {{ this.grpHelper.display(scope.row.average_grp) }}/uitzenddag</span
                    >
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs, watch } from "vue";

import GrpHelper from "@/helper/grp-helper";
import { Alert } from "@/types/uzs/alert";
import { GrpSpreadingType } from "@/types/uzs/alert/grp-spreading-data";
import { SpotSpreading } from "@/types/uzs/alert/spot";

const props = {
    alert: {
        type: Object as PropType<Alert>,
        required: true,
    },
    message: {
        type: String as PropType<string>,
    },
};

export default defineComponent({
    name: "AlertSpreadingTable",
    props,
    setup(props) {
        const alert = ref(props.alert) as Ref<Alert>;
        const message = ref(props.message) as Ref<string>;
        const grpHelper = GrpHelper;
        const spreadingType = GrpSpreadingType;

        const data = reactive({
            dates: [] as string[],
            spreading: [] as Array<SpotSpreading>,
        });

        const init = (): void => {
            data.spreading = alert.value.data as SpotSpreading[];
            data.spreading.forEach((spreading) => {
                if (data.dates.includes(spreading.date_string)) {
                    return;
                }

                data.dates.push(spreading.date_string);
            });
        };

        const getDates = (): string => {
            let dates = "";

            for (const date of data.dates) {
                dates +=
                    (data.dates.length > 1 && data.dates.indexOf(date) === data.dates.length - 1
                        ? "</b> en <b>"
                        : data.dates.indexOf(date) > 0
                        ? "</b>, <b>"
                        : "") + date.toLowerCase();
            }

            return dates;
        };

        onMounted(() => {
            init();
        });

        watch(
            () => props.alert,
            (value, oldValue) => {
                if (value !== oldValue) {
                    alert.value = value ?? ({} as Alert);
                    init();
                }
            }
        );

        watch(
            () => props.message,
            (value, oldValue) => {
                if (value !== oldValue) {
                    message.value = value ?? "";
                    init();
                }
            }
        );

        return {
            ...toRefs(data),
            close,
            getDates,
            grpHelper,
            spreadingType,
        };
    },
});
</script>

<style>
h3 {
    color: red;
    font-weight: bold;
    margin-bottom: 15px;
    word-break: break-word;
}

div.el-table.spreading {
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
}
</style>
