
import { defineComponent, PropType, Ref, ref, watch } from "vue";

import PercentageHelper from "@/helper/percentage-helper";

const props = {
    value: {
        type: Number as PropType<number>,
        required: true,
    },
    minimum: {
        type: Number as PropType<number>,
        default: 0,
    },
    maximum: {
        type: Number as PropType<number>,
        default: 100,
    },
    round: {
        type: Boolean as PropType<boolean>,
        default: false,
    },
    showBoundaries: {
        type: Boolean as PropType<boolean>,
        default: false,
    },
};

export default defineComponent({
    name: "Percentage",
    props,
    setup(props) {
        const percentage = ref(props.value) as Ref<number>;
        const minimum = ref(props.minimum ?? 0) as Ref<number>;
        const maximum = ref(props.maximum ?? 100) as Ref<number>;
        const round = ref(props.round ?? false) as Ref<boolean>;
        const showBoundaries = ref(props.showBoundaries ?? false) as Ref<boolean>;

        function getClassName(): string {
            if (percentage.value <= 0) {
                return "is--greyed-out";
            }

            if (showBoundaries.value && hasBoundaries() && !isOverMaximum() && !isUnderMinimum()) {
                return "has--success is--bold";
            }

            if (showBoundaries.value && hasBoundaries() && (isOverMaximum() || isUnderMinimum())) {
                return "has--error is--bold";
            }

            return "";
        }

        function getLabel(): string {
            if (showBoundaries.value && hasMinimum()) {
                return "minimaal " + PercentageHelper.display(minimum.value, true);
            }

            if (showBoundaries.value && hasMaximum()) {
                return "maximaal " + PercentageHelper.display(maximum.value, true);
            }

            return "";
        }

        function getValue(): string {
            return PercentageHelper.display(percentage.value, round.value);
        }

        function hasBoundaries(): boolean {
            return hasMinimum() || hasMaximum();
        }

        function hasMaximum(): boolean {
            return maximum.value < 100;
        }

        function hasMinimum(): boolean {
            return minimum.value > 0;
        }

        function isOverMaximum(): boolean {
            return maximum.value < 100 && percentage.value >= maximum.value;
        }

        function isUnderMinimum(): boolean {
            return minimum.value > 0 && percentage.value < minimum.value;
        }

        watch(
            () => props.value,
            (value) => {
                percentage.value = value as number;
            }
        );

        watch(
            () => props.minimum,
            (value) => {
                minimum.value = value as number;
            }
        );

        watch(
            () => props.maximum,
            (value) => {
                maximum.value = value as number;
            }
        );

        watch(
            () => props.round,
            (value) => {
                round.value = value as boolean;
            }
        );

        watch(
            () => props.showBoundaries,
            (value) => {
                showBoundaries.value = value as boolean;
            }
        );

        return {
            getClassName,
            getLabel,
            getValue,
        };
    },
});
