<template>
    <p
        v-html="
            this.message?.replace(
                '[percentage]',
                this.percentageHelper.display(this.grpClusters?.large?.share)
            )
        "
    ></p>
    <div v-if="this.tableData.length">
        <el-table :data="this.tableData" class="channel-clusters">
            <el-table-column
                prop="label"
                label="Cluster"
                :width="this.flight?.goal === 'LEADS' ? 320 : 450"
            >
                <template #default="scope">
                    <span class="ellipsis" v-if="scope.row.spots?.length">
                        {{ scope.row.label }}
                        <a
                            href="javascript:void(0)"
                            @click="
                                this.createAlert(
                                    scope.row.label,
                                    scope.row.spots,
                                    scope.row.averageLeadsPerGrp,
                                    scope.row.averagePredictedLeadsPerGrp
                                )
                            "
                            ><i
                                class="expand-icon"
                                :class="{
                                    ['el-icon-minus']: this.spotsTableLabels.includes(
                                        scope.row.label
                                    ),
                                    ['el-icon-plus']: !this.spotsTableLabels.includes(
                                        scope.row.label
                                    ),
                                }"
                            ></i></a
                    ></span>
                    <span class="ellipsis" v-else>{{ scope.row.label }}</span>
                </template>
            </el-table-column>
            <el-table-column
                v-if="this.flight?.goal === 'LEADS'"
                prop="leads"
                :label="'Leads/GRP'"
                width="130"
            >
                <template #default="scope">
                    <LeadsPerGrpValue :value="this.getLeadsPerGrp(scope.row)" />
                </template>
            </el-table-column>
            <el-table-column prop="percentage" label="Percentage" width="170">
                <template #default="scope">
                    <span class="ellipsis">
                        <Percentage
                            :value="scope.row.percentageNumeric"
                            :minimum="grpClusters.minimum_share"
                            :maximum="grpClusters.maximum_share"
                            :show-boundaries="showBoundaries(scope.row)"
                        />
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="grp" label="GRP" width="90">
                <template #default="scope">
                    <span class="ellipsis">{{ scope.row.grp }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="channels" label="Zenders" width="248">
                <template #default="scope">
                    <span class="ellipsis" :title="scope.row.channels">{{
                        scope.row.channels
                    }}</span>
                </template>
            </el-table-column>
        </el-table>
        <div v-if="this.spotsTableAlerts.length">
            <h3 style="color: black">
                {{ this.spotsTableLabels.length ? spotsTableLabels.join(", ") : "" }}
            </h3>
            <AlertSpotsTable
                :average-leads-per-grp="this.averageLeadsPerGrp"
                :average-predicted-leads-per-grp="this.averagePredictedLeadsPerGrp"
                :flight="this.flight"
                :multiple-alerts="this.spotsTableAlerts"
                :show-grp="true"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs, watch } from "vue";

import Percentage from "@/components/alerts/Percentage.vue";
import LeadsPerGrpValue from "@/components/campaigns/LeadsPerGrpValue.vue";
import { AlertClass } from "@/enums/alertClass";
import { AlertType } from "@/enums/alertType";
import GrpHelper from "@/helper/grp-helper";
import NumberHelper from "@/helper/number-helper";
import PercentageHelper from "@/helper/percentage-helper";
import { Alert } from "@/types/uzs/alert";
import { BlockClusterData } from "@/types/uzs/alert/blockClusterData";
import { Spot } from "@/types/uzs/alert/spot";
import AlertSpotsTable from "@/views/campaigns/dialogs/alerts/SpotsTable.vue";

const props = {
    alert: {
        type: Object as PropType<Alert>,
        required: true,
    },
    message: {
        type: String as PropType<string>,
    },
    showMax: {
        type: Boolean as PropType<boolean>,
        default: false,
    },
};

export type TableRow = {
    label: string;
    grp: string;
    grpOfAllocatedLeads: number;
    averageLeadsPerGrp: number;
    averagePredictedLeadsPerGrp: number;
    leads: number;
    percentage: string;
    percentageNumeric: number;
    channels: string;
    spots: Spot[] | null;
};

export default defineComponent({
    name: "AlertGrpClustersTable",
    components: { LeadsPerGrpValue, Percentage, AlertSpotsTable },
    props,
    setup(props) {
        const alert = ref(props.alert) as Ref<Alert>;
        const alertType = AlertType;
        const grpHelper = GrpHelper;
        const message = ref(props.message) as Ref<string>;
        const numberHelper = NumberHelper;
        const percentageHelper = PercentageHelper;

        const data = reactive({
            averageLeadsPerGrp: 0.0,
            averagePredictedLeadsPerGrp: 0.0,
            flight: alert.value?.uzs_flight ?? null,
            flightStatistics: alert.value?.uzs_flight?.uzs_flight_statistics ?? null,
            grpClusters: {} as BlockClusterData,
            tableData: [] as Array<TableRow>,
            spotsTableAlerts: [] as Array<Alert>,
            spotsTableLabels: [] as Array<string>,
        });

        const init = (): void => {
            data.grpClusters = alert.value?.data as BlockClusterData;

            loadTableData();
            updateMessage();
        };

        const createAlert = (
            label: string,
            spots: Spot[] | null,
            averageLeadsPerGrp: number,
            averagePredictedLeadsPerGrp: number
        ): void => {
            data.averageLeadsPerGrp = averageLeadsPerGrp;
            data.averagePredictedLeadsPerGrp = averagePredictedLeadsPerGrp;

            if (data.spotsTableLabels.indexOf(label) !== -1) {
                data.spotsTableAlerts = data.spotsTableAlerts.filter(
                    (alert) => alert.message !== label
                );

                data.spotsTableLabels = data.spotsTableLabels.filter((l) => l !== label);

                return;
            }

            if (spots?.length) {
                data.spotsTableAlerts.push({ data: spots, message: label } as Alert);
                data.spotsTableLabels.push(label);
                data.spotsTableAlerts = data.spotsTableAlerts.filter((alert) => alert);
            }

            data.spotsTableLabels.sort();
        };

        const updateMessage = (): void => {
            if (message.value?.length) {
                return;
            }

            message.value =
                data.grpClusters.large.share > data.grpClusters.minimum_share
                    ? "Op dit moment gaat <b class='red'>[percentage]</b> van het budget naar blokken van 4+ GRP, daar waar <b>maximaal" +
                      data.grpClusters.maximum_share +
                      "%</b> is toegestaan bij de gekozen campagnedoelstelling."
                    : "Op dit moment gaat <b class='red'>[percentage]</b> van het budget naar blokken van 4+ GRP, daar waar <b>minimaal " +
                      data.grpClusters.minimum_share +
                      "%</b> nodig is bij de gekozen campagnedoelstelling.";
        };

        function showBoundaries(row: TableRow): boolean {
            if (
                row.label.indexOf("<") !== -1 &&
                (alert.value?.message === AlertClass.SuccessCorrectAirtimeToSmallBlocks ||
                    alert.value?.message === AlertClass.WarningTooMuchAirtimeToSmallBlocks)
            ) {
                return true;
            }

            if (
                row.label.indexOf(">") !== -1 &&
                (alert.value?.message === AlertClass.SuccessCorrectAirtimeToLargeBlocks ||
                    alert.value?.message === AlertClass.WarningTooMuchAirtimeToLargeBlocks)
            ) {
                return true;
            }

            return false;
        }

        const getLeadsPerGrp = (row: TableRow): number => {
            if (row.grpOfAllocatedLeads === 0 || row.leads === 0) {
                return 0;
            }

            return row.grpOfAllocatedLeads > 0.5 ? row.leads / row.grpOfAllocatedLeads : 0;
        };

        const loadTableData = (): void => {
            data.tableData = [
                {
                    label: "Airings > 4 GRP",
                    grp: grpHelper.display(data.grpClusters.large.rating),
                    grpOfAllocatedLeads: data.grpClusters.large.achieved_number_of_leads_grp,
                    averageLeadsPerGrp: data.grpClusters.average_leads_per_grp,
                    averagePredictedLeadsPerGrp: data.grpClusters.average_predicted_leads_per_grp,
                    leads: data.grpClusters.large.achieved_number_of_leads,
                    percentage: percentageHelper.display(data.grpClusters.large.share),
                    percentageNumeric: data.grpClusters.large.share,
                    channels: data.grpClusters.large.channels,
                    spots: data.grpClusters.large.spots,
                },
                {
                    label: "Airings 2 - 4 GRP",
                    grp: grpHelper.display(data.grpClusters.medium.rating),
                    grpOfAllocatedLeads: data.grpClusters.medium.achieved_number_of_leads_grp,
                    averageLeadsPerGrp: data.grpClusters.average_leads_per_grp,
                    averagePredictedLeadsPerGrp: data.grpClusters.average_predicted_leads_per_grp,
                    leads: data.grpClusters.medium.achieved_number_of_leads,
                    percentage: percentageHelper.display(data.grpClusters.medium.share),
                    percentageNumeric: data.grpClusters.medium.share,
                    channels: data.grpClusters.medium.channels,
                    spots: data.grpClusters.medium.spots,
                },
                {
                    label: "Airings < 2 GRP",
                    grp: grpHelper.display(data.grpClusters.small.rating),
                    grpOfAllocatedLeads: data.grpClusters.small.achieved_number_of_leads_grp,
                    averageLeadsPerGrp: data.grpClusters.average_leads_per_grp,
                    averagePredictedLeadsPerGrp: data.grpClusters.average_predicted_leads_per_grp,
                    leads: data.grpClusters.small.achieved_number_of_leads,
                    percentage: percentageHelper.display(data.grpClusters.small.share),
                    percentageNumeric: data.grpClusters.small.share,
                    channels: data.grpClusters.small.channels,
                    spots: data.grpClusters.small.spots,
                },
            ];
        };

        onMounted(() => {
            init();
        });

        watch(
            () => props.alert,
            (value, oldValue) => {
                if (value !== oldValue) {
                    alert.value = value ?? ({} as Alert);
                    init();
                }
            }
        );

        watch(
            () => props.message,
            (value, oldValue) => {
                if (value !== oldValue) {
                    message.value = value ?? "";
                    init();
                }
            }
        );

        return {
            ...toRefs(data),
            alertType,
            close,
            createAlert,
            showBoundaries,
            getLeadsPerGrp,
            grpHelper,
            numberHelper,
            percentageHelper,
        };
    },
});
</script>

<style>
h3 {
    margin-bottom: 5px;
}

p {
    margin-bottom: 15px;
    word-break: break-word;
}

.greyed-out {
    color: #aaa;
}

div.el-table.channel-clusters {
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
}

i.expand-icon {
    float: right;
    margin-top: 4px;
}
</style>
