import { createRouter, createWebHistory } from "vue-router";

import { RouteRecord } from "@/types/router/route-record";
import Debugger from "@/views/campaigns/Debug.vue";
import CampaignsList from "@/views/campaigns/List.vue";
import CreativesList from "@/views/creatives/List.vue";
import EventsList from "@/views/events/List.vue";
import ExportBlocksList from "@/views/export/blocks/List.vue";
import Export from "@/views/export/Export.vue";
import ExportList from "@/views/export/spreadsheet/List.vue";
import ImportDatasetList from "@/views/import/dataset/List.vue";
import Import from "@/views/import/Import.vue";
import ImportLeadsList from "@/views/import/leads/List.vue";
import Login from "@/views/Login.vue";
import SettingsGoogleBigQueryDataSetsList from "@/views/settings/big-query/dataSet/List.vue";
import SettingsGoogleBigQueryServiceAccountsList from "@/views/settings/big-query/serviceAccount/List.vue";
import SettingsChannelsList from "@/views/settings/channels/List.vue";
import SettingsDayPartsList from "@/views/settings/day-parts/List.vue";
import SettingsHolidaysList from "@/views/settings/holidays/List.vue";
import SettingsImportTemplatesList from "@/views/settings/import-templates/List.vue";
import Settings from "@/views/settings/Settings.vue";
import SettingsStripList from "@/views/settings/strip/List.vue";
import SettingsTargetGroupsList from "@/views/settings/target-groups/List.vue";

export const routes: Array<RouteRecord> = [
    {
        path: "/events",
        name: "events",
        component: EventsList,
        displayName: "Event log",
    },
    {
        path: "/debugger",
        name: "debugger",
        component: Debugger,
        showInNavigation: false,
        displayName: "Debugger",
    },
    {
        path: "/campaigns",
        name: "campaigns",
        component: CampaignsList,
        showInNavigation: true,
        displayName: "Campaigns",
    },
    {
        path: "/campaigns/statistics",
        name: "campaigns.statistics",
        redirect: {
            name: "debugger",
        },
        component: Debugger,
        showInNavigation: false,
        displayName: "Debugger",
    },
    {
        path: "/creatives",
        name: "creatives",
        component: CreativesList,
        showInNavigation: true,
        displayName: "Creatives",
    },
    {
        path: "/import",
        name: "import",
        component: Import,
        redirect: {
            name: "import.dataset",
        },
        showInNavigation: true,
        displayName: "Import",
        children: [
            {
                path: "/import/dataset",
                name: "import.dataset",
                component: ImportDatasetList,
                showInNavigation: true,
                displayName: "Dataset",
            },
            {
                path: "/import/leads",
                name: "import.leads",
                component: ImportLeadsList,
                showInNavigation: true,
                displayName: "Leads",
            },
        ],
    },
    {
        path: "/export",
        name: "export",
        component: Export,
        redirect: {
            name: "export.dataset",
        },
        showInNavigation: true,
        displayName: "Export",
        children: [
            {
                path: "/export/dataset",
                name: "export.dataset",
                component: ExportList,
                showInNavigation: true,
                displayName: "Dataset",
            },
            {
                path: "/export/blocks",
                name: "export.blocks",
                component: ExportBlocksList,
                showInNavigation: true,
                displayName: "Blockselector",
            },
        ],
    },
    {
        path: "/settings",
        name: "settings",
        component: Settings,
        redirect: {
            name: "settings.target-groups",
        },
        showInNavigation: true,
        displayName: "Settings",
        children: [
            {
                path: "/settings/target-groups",
                name: "settings.target-groups",
                component: SettingsTargetGroupsList,
                showInNavigation: true,
                displayName: "Doelgroepen",
            },
            {
                path: "/settings/channels",
                name: "settings.channels",
                component: SettingsChannelsList,
                showInNavigation: true,
                displayName: "Zenders",
            },
            {
                path: "/settings/day-parts",
                name: "settings.day-parts",
                component: SettingsDayPartsList,
                showInNavigation: true,
                displayName: "Dagdelen",
            },
            {
                path: "/settings/holidays",
                name: "settings.holidays",
                component: SettingsHolidaysList,
                showInNavigation: true,
                displayName: "Feestdagen",
            },
            {
                path: "/settings/strip",
                name: "settings.strip",
                component: SettingsStripList,
                showInNavigation: true,
                displayName: "Strippen",
            },
            {
                path: "/settings/google-big-query/service-accounts",
                name: "settings.google-big-query.service-accounts",
                component: SettingsGoogleBigQueryServiceAccountsList,
                showInNavigation: false,
            },
            {
                path: "/settings/google-big-query/datasets",
                name: "settings.google-big-query.datasets",
                component: SettingsGoogleBigQueryDataSetsList,
                showInNavigation: true,
                displayName: "Google BigQuery",
            },
            {
                path: "/settings/import-templates",
                name: "settings.import-templates",
                component: SettingsImportTemplatesList,
                showInNavigation: true,
                displayName: "Import templates",
            },
        ],
    },
    {
        path: "/:pathMatch(.*)*",
        name: "login",
        component: Login,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
