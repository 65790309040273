
import { AxiosError } from "axios";
import { ElMessage } from "element-plus";
import { defineComponent, PropType, reactive, Ref, ref, toRefs, watch } from "vue";

import { Country } from "@/enums/country";
import DataSetApiService from "@/service/api/settings/bigquery/dataSet";
import config from "@/service/config";
import { ErrorResult } from "@/types/result/errorResult";
import { DataSet } from "@/types/uzs/settings/bigquery/dataSet";

const props = {
    value: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
    dataSet: {
        type: Object as PropType<DataSet>,
        required: true,
    },
};

export default defineComponent({
    name: "DataSetDialog",
    props,
    setup(props, { emit }) {
        const dataSet = ref(props.dataSet ?? {}) as Ref<DataSet | undefined>;
        const dataSetApi = new DataSetApiService();
        const countries = Country;

        const data = reactive({
            countries: config.countries,
        });

        const close = (): void => {
            dataSet.value = {} as DataSet;

            emit("input", props.value);
        };

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        const save = (): void => {
            if (!dataSet?.value) {
                return;
            }

            dataSetApi
                .update(dataSet.value)
                .then(() => {
                    ElMessage({
                        message: dataSet?.value?.dataset_reference + " is opgeslagen.",
                        type: "success",
                    });

                    close();
                })
                .catch((error: AxiosError) => {
                    const response = error.response?.data as ErrorResult;

                    if (!response.error) {
                        return;
                    }

                    ElMessage({
                        message: response.message,
                        type: "error",
                    });
                });
        };

        const submitOnEnter = (event: KeyboardEvent): void => {
            const input = event.target as HTMLElement | null;

            if (
                event.key.toLowerCase() === "enter" &&
                input?.tagName?.toLowerCase() !== "textarea"
            ) {
                save();
            }
        };

        watch(
            () => props.dataSet,
            (value, oldValue) => {
                if (value !== oldValue) {
                    dataSet.value = value ?? ({} as DataSet);
                }
            }
        );

        return {
            ...toRefs(data),
            close,
            countries,
            dataSet,
            dialogClosed,
            save,
            submitOnEnter,
        };
    },
});
