
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs, watch } from "vue";

import DateHelper from "@/helper/date-helper";
import GrpHelper from "@/helper/grp-helper";
import NumberHelper from "@/helper/number-helper";
import PercentageHelper from "@/helper/percentage-helper";
import PriceHelper from "@/helper/price-helper";
import CampaignInsightApiService from "@/service/api/campaignInsight";
import { OperatorData, SpreadingData } from "@/types/uzs/campaign-insight/campaignPackageSpreading";

const props = {
    campaignId: {
        type: Number as PropType<number>,
        required: true,
    },
};

export default defineComponent({
    name: "PackageSpreadingTable",
    props,
    setup(props) {
        const campaignId = ref(props.campaignId) as Ref<number>;
        const insightApiService = new CampaignInsightApiService();
        const dateHelper = DateHelper;
        const grpHelper = GrpHelper;
        const numberHelper = NumberHelper;
        const percentageHelper = PercentageHelper;
        const priceHelper = PriceHelper;

        const data = reactive({
            operators: [] as OperatorData[],
            selectedOperators: [] as string[],
            spreading: [] as SpreadingData[],
            totalRequestedGrps: 0,
            totalBookedGrps: 0,
            totalBudget: 0,
        });

        function init(): void {
            data.spreading = [];

            insightApiService.fetchPackageSpreading(campaignId.value).then((response) => {
                data.operators =
                    response.data?.operators.filter((o) => o.requested_grps >= 0.0) ?? [];

                data.spreading =
                    response.data?.spreading.filter((s) => s.requested_grps >= 0.0) ?? [];
            });
        }

        function getLastUpdateForOperator(operator: string): string {
            const summary = getSummaryForOperator(operator);

            if (!summary?.last_update) {
                return "";
            }

            return "Laatste wijziging " + DateHelper.display(summary.last_update, true, true, true);
        }

        function getTargetClassName(target: number): string {
            if (target === 0.0) {
                return "is--greyed-out";
            }

            if (target < 95.0) {
                return "is--bold has--error";
            }

            if (target > 105) {
                return "is--bold has--warning";
            }

            return "";
        }

        function getSummaryForOperator(operator: string) {
            const foundOperators = data.operators.filter((s) => s.name === operator);
            return foundOperators[0] ?? null;
        }

        function getSpreadingForOperator(operator: string) {
            const summary = getSummaryForOperator(operator);
            let spreading = data.spreading.filter(
                (s) => s.operator === operator && s.requested_grps >= 0
            );

            if (summary.requested_grps > 0) {
                spreading = spreading.filter((s) => s.requested_grps > 0);
            }

            if (spreading.length === 1 && spreading[0].budget === 0.0) {
                spreading[0].budget = summary.budget;
            }

            return spreading;
        }

        function onSelectOperator(operator: string) {
            if (data.selectedOperators.includes(operator)) {
                data.selectedOperators = data.selectedOperators.filter((o) => o !== operator);
                return;
            }

            data.selectedOperators.push(operator);
        }

        onMounted(() => {
            init();
        });

        watch(
            () => props.campaignId,
            (value, oldValue) => {
                if (value !== oldValue) {
                    campaignId.value = value as number;
                    init();
                }
            }
        );

        return {
            ...toRefs(data),
            close,
            dateHelper,
            getLastUpdateForOperator,
            getTargetClassName,
            getSpreadingForOperator,
            getSummaryForOperator,
            grpHelper,
            numberHelper,
            percentageHelper,
            priceHelper,
            onSelectOperator,
        };
    },
});
