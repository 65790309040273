import axios from "axios";

import config from "@/service/config";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { SubOrder } from "@/types/screen-force/subOrder";

export default class SubOrderApiService {
    public fetchOperators(): Promise<DataResult<string[]>> {
        return axios
            .get(config.backend + "/api/screen-force/sub-orders/operators")
            .then((response) => response?.data as DataResult<string[]>);
    }

    public fetchAll(params: RequestParameters): Promise<DataResult<SubOrder[]>> {
        return axios
            .get(config.backend + "/api/screen-force/sub-orders", {
                params,
            })
            .then((response) => response.data as DataResult<SubOrder[]>);
    }
}
