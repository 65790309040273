import axios from "axios";

import config from "@/service/config";
import { DataResult } from "@/types/result/dataResult";
import { Notification } from "@/types/uzs/notification";

export default class NotificationApiService {
    public list(): Promise<DataResult<Notification>> {
        return axios
            .get(config.backend + "/api/system/notifications")
            .then((response) => response?.data as DataResult<Notification>);
    }
}
