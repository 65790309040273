<template>
    <el-dialog
        :title="'Template - ' + (this.importTemplate?.name ?? '')"
        :before-close="this.dialogClosed"
        :destroy-on-close="true"
        :lock-scroll="false"
        :model-value="this.value"
        width="100%"
        ref="dialog"
    >
        <el-form
            :model="this.importTemplate"
            label-position="left"
            label-width="150px"
            @keydown="this.submitOnEnter"
        >
            <el-form-item label="Land">
                <el-select v-model="this.importTemplate.country" filterable allow-create>
                    <el-option v-for="item in this.countries" :key="item" :value="item"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Naam">
                <el-input clearable="clearable" v-model="this.importTemplate.name" />
            </el-form-item>
            <br />
            <el-divider><b>Exploitant</b></el-divider>
            <el-form-item label="Exploitant">
                <el-input clearable="clearable" v-model="this.importTemplate.operator" />
            </el-form-item>
            <el-form-item label="E-mailadres(sen)">
                <el-input clearable="clearable" v-model="this.importTemplate.operator_email" />
            </el-form-item>
            <el-form-item label="Verwerken">
                <span>
                    <el-switch
                        clearable="clearable"
                        v-model="this.importTemplate.auto_import"
                        active-text="automatisch"
                /></span>
            </el-form-item>
            <br />
            <el-divider><b>Doelgroep</b></el-divider>
            <el-form-item label="Doelgroep">
                <el-input
                    clearable="clearable"
                    v-model="this.importTemplate.mapping.targetAudience"
                />
            </el-form-item>
            <br />
            <el-divider><b>Order</b></el-divider>
            <el-form-item label="Unieke waarde">
                <el-input
                    clearable="clearable"
                    v-model="this.importTemplate.mapping.orderUniqueIdentifier"
                />
            </el-form-item>
            <el-form-item label="Beschrijving">
                <el-input
                    clearable="clearable"
                    v-model="this.importTemplate.mapping.orderDescription"
                />
            </el-form-item>
            <br />
            <el-divider><b>Suborder/Pakket</b></el-divider>
            <el-form-item label="Unieke waarde">
                <el-input
                    clearable="clearable"
                    v-model="this.importTemplate.mapping.subOrderUniqueIdentifier"
                />
            </el-form-item>
            <el-form-item label="Beschrijving">
                <el-input
                    clearable="clearable"
                    v-model="this.importTemplate.mapping.subOrderDescription"
                />
            </el-form-item>
            <br />
            <el-divider><b>Spot</b></el-divider>
            <el-form-item label="Unieke waarde">
                <el-input
                    clearable="clearable"
                    v-model="this.importTemplate.mapping.spotUniqueIdentifier"
                />
            </el-form-item>
            <el-form-item label="Beschrijving">
                <el-input
                    clearable="clearable"
                    v-model="this.importTemplate.mapping.spotDescription"
                />
            </el-form-item>
            <el-form-item label="Datum/tijd">
                <el-input
                    clearable="clearable"
                    v-model="this.importTemplate.mapping.spotDateTime"
                />
            </el-form-item>
            <el-form-item label="Datum/tijd formaat">
                <el-input
                    clearable="clearable"
                    v-model="this.importTemplate.mapping.spotDateTimeFormat"
                />
            </el-form-item>
            <el-form-item label="GRP">
                <el-input clearable="clearable" v-model="this.importTemplate.mapping.spotGrp" />
            </el-form-item>
            <el-form-item label='Basis prijs 30"'>
                <el-input
                    clearable="clearable"
                    v-model="this.importTemplate.mapping.spotBasePrice"
                />
            </el-form-item>
            <el-form-item label="Bruto prijs">
                <el-input
                    clearable="clearable"
                    v-model="this.importTemplate.mapping.spotGrossPrice"
                />
            </el-form-item>
            <el-form-item label="Netto prijs">
                <el-input
                    clearable="clearable"
                    v-model="this.importTemplate.mapping.spotNetPrice"
                />
            </el-form-item>
            <el-form-item label="Lengte">
                <el-input clearable="clearable" v-model="this.importTemplate.mapping.spotLength" />
            </el-form-item>
            <el-form-item label="Break ID">
                <el-input clearable="clearable" v-model="this.importTemplate.mapping.spotBreakId" />
            </el-form-item>
            <el-form-item label="Programma voor">
                <el-input
                    clearable="clearable"
                    v-model="this.importTemplate.mapping.spotProgramBefore"
                />
            </el-form-item>
            <el-form-item label="Programma na">
                <el-input
                    clearable="clearable"
                    v-model="this.importTemplate.mapping.spotProgramAfter"
                />
            </el-form-item>
            <el-form-item label="Zender">
                <el-input clearable="clearable" v-model="this.importTemplate.mapping.spotChannel" />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="this.save()">Opslaan</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script lang="ts">
import { ElMessage } from "element-plus";
import { defineComponent, PropType, reactive, Ref, ref, toRefs, watch } from "vue";
import { Store, useStore } from "vuex";

import { Country } from "@/enums/country";
import { ImportTemplateState } from "@/types/state/importTemplate";
import { RootState } from "@/types/state/root";
import { ImportMapping } from "@/types/uzs/importMapping";
import { ImportTemplate } from "@/types/uzs/importTemplate";

const props = {
    value: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
    importTemplate: {
        type: Object as PropType<ImportTemplate>,
        required: true,
    },
};

export default defineComponent({
    name: "ImportTemplateDialog",
    props,
    setup(props, { emit }) {
        const importTemplate = ref(props.importTemplate ?? {}) as Ref<ImportTemplate | undefined>;
        const store = useStore() as Store<RootState>;
        const state = store.state.importTemplate as ImportTemplateState;
        const countries = Country;

        const actions = {
            importTemplate: {
                store: (importTemplate: ImportTemplate) =>
                    store.dispatch("importTemplate/_store", importTemplate),
            },
        };

        const data = reactive({
            selectedTemplateId: null as number | null,
            allTemplates: [] as ImportTemplate[],
        });

        const close = (): void => {
            importTemplate.value = {} as ImportTemplate;

            emit("input", props.value);
        };

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        const save = (): void => {
            if (!importTemplate?.value) {
                return;
            }

            actions.importTemplate
                .store(importTemplate.value)
                .then(() => {
                    ElMessage({
                        message: importTemplate?.value?.name + " is opgeslagen.",
                        type: "success",
                    });

                    close();
                })
                .catch(() => {
                    ElMessage({
                        message:
                            "Er is een fout opgetreden bij het opslaan van " +
                            importTemplate?.value?.name,
                        type: "error",
                    });
                });
        };

        const submitOnEnter = (event: KeyboardEvent): void => {
            const input = event.target as HTMLElement | null;

            if (
                event.key.toLowerCase() === "enter" &&
                input?.tagName?.toLowerCase() !== "textarea"
            ) {
                save();
            }
        };

        watch(
            () => props.importTemplate,
            (value, oldValue) => {
                if (value !== oldValue) {
                    importTemplate.value =
                        value ?? ({ mapping: {} as ImportMapping } as ImportTemplate);

                    if (!importTemplate.value?.mapping) {
                        importTemplate.value.mapping = {} as ImportMapping;
                    }
                }
            }
        );

        return {
            ...toRefs(data),
            ...toRefs(state),
            close,
            countries,
            importTemplate,
            dialogClosed,
            save,
            submitOnEnter,
        };
    },
});
</script>
