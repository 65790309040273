export default class PriceHelper {
    static display(value: number | null | undefined, digits = 2, locale = "nl"): string {
        return (
            "€ " +
            (value ?? 0).toLocaleString(locale, {
                minimumFractionDigits: digits,
                maximumFractionDigits: digits,
            })
        );
    }
}
