
import { format } from "date-fns";
import { ElMessage } from "element-plus";
import { defineComponent, PropType, reactive, Ref, ref, toRefs, watch } from "vue";

import { Country } from "@/enums/country";
import DateHelper from "@/helper/date-helper";
import HolidayApiService from "@/service/api/settings/holiday/holiday";
import { Holiday } from "@/types/uzs/settings/holiday/holiday";

const props = {
    value: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
    holiday: {
        type: Object as PropType<Holiday>,
        required: true,
    },
};

export default defineComponent({
    name: "HolidayDialog",
    props,
    setup(props, { emit }) {
        const dateHelper = DateHelper;
        const holiday = ref(props.holiday ?? {}) as Ref<Holiday | undefined>;
        const holidayService = new HolidayApiService();
        const countries = Country;

        const data = reactive({
            date: holiday.value?.date ? DateHelper.parse(holiday.value.date) : new Date(),
        });

        const actions = {
            holiday: {
                store: (holiday: Holiday) => holidayService.store(holiday),
            },
        };

        const close = (): void => {
            holiday.value = {} as Holiday;

            emit("input", props.value);
        };

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        const save = (): void => {
            if (!holiday?.value) {
                return;
            }

            holiday.value.date = format(data.date, "yyyy-MM-dd");

            actions.holiday
                .store(holiday.value)
                .then(() => {
                    ElMessage({
                        message: holiday?.value?.name + " is opgeslagen.",
                        type: "success",
                    });

                    close();
                })
                .catch(() => {
                    ElMessage({
                        message:
                            "Er is een fout opgetreden bij het opslaan van " + holiday?.value?.name,
                        type: "error",
                    });
                });
        };

        const submitOnEnter = (event: KeyboardEvent): void => {
            const input = event.target as HTMLElement | null;

            if (
                event.key.toLowerCase() === "enter" &&
                input?.tagName?.toLowerCase() !== "textarea"
            ) {
                save();
            }
        };

        watch(
            () => props.holiday,
            (value, oldValue) => {
                if (value !== oldValue) {
                    holiday.value = value ?? ({} as Holiday);
                }
            }
        );

        return {
            ...toRefs(data),
            close,
            countries,
            dateHelper,
            holiday,
            dialogClosed,
            save,
            submitOnEnter,
        };
    },
});
