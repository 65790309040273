
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs, watch } from "vue";

import GrpValue from "@/components/campaigns/GrpValue.vue";
import LeadsPerGrpValue from "@/components/campaigns/LeadsPerGrpValue.vue";
import LeadsValue from "@/components/campaigns/LeadsValue.vue";
import PriceValue from "@/components/campaigns/PriceValue.vue";
import { Goal } from "@/enums/goal";
import FlightHelper from "@/helper/flight-helper";
import GrpHelper from "@/helper/grp-helper";
import PriceHelper from "@/helper/price-helper";
import StringHelper from "@/helper/string-helper";
import { Alert } from "@/types/uzs/alert";
import { FlightGcf } from "@/types/uzs/flightGcf";

const props = {
    alert: {
        type: Object as PropType<Alert>,
        required: true,
    },
};

export default defineComponent({
    name: "AlertGcfTable",
    components: { PriceValue, GrpValue, LeadsValue, LeadsPerGrpValue },
    props,
    setup(props) {
        const alert = ref(props.alert) as Ref<Alert>;
        const flightHelper = FlightHelper;
        const grpHelper = GrpHelper;
        const priceHelper = PriceHelper;
        const stringHelper = StringHelper;
        const goal = Goal;

        const data = reactive({
            flight: alert.value?.uzs_flight ?? null,
            flightStatistics: alert.value?.uzs_flight?.uzs_flight_statistics ?? null,
            items: [] as FlightGcf[],
            isCampaignFulfilled: false,
        });

        const init = (): void => {
            data.isCampaignFulfilled = flightHelper.isCampaignFulfilled(alert.value?.uzs_flight);

            collectItems();
        };

        const collectItems = (): void => {
            if (!alert.value?.uzs_flight?.uzs_flight_gcf) {
                return;
            }

            const items: FlightGcf[] = [];

            for (const gcf of alert.value.uzs_flight.uzs_flight_gcf) {
                if (!items.includes(gcf)) {
                    items.push(gcf);
                }
            }

            data.items = items;
        };

        function getCpa(item: FlightGcf): number {
            return item.grp > 0.5 ? item.cpa : 0;
        }

        function getCpaClassName(item: FlightGcf): string {
            const cpa = data.flightStatistics?.cpa ?? 0;

            if (cpa > 0 && item.cpa / 3 > cpa) {
                return "has--error is--bold";
            }

            return "";
        }

        function getFrequencyClassName(item: FlightGcf): string[] {
            if (item.channel_market_share < 0.2) {
                return ["is--greyed-out"];
            }

            if (item.frequency.toLowerCase() === "high") {
                return ["has--warning", "is--bold"];
            }

            if (item.frequency.toLowerCase() === "low") {
                return ["has--error", "is--bold"];
            }

            return [];
        }

        const getFrequencyLabel = (item: FlightGcf): string => {
            if (item.channel_market_share < 0.2) {
                return "n/a";
            }

            if (item.frequency === "HIGH") {
                return "GCF te hoog";
            }

            if (item.frequency === "LOW") {
                return "GCF te laag";
            }

            return "GCF is gunstig";
        };

        const getLeadsPerGrp = (item: FlightGcf): number => {
            return item.allocated_leads_grp > 0.5 ? item.leads / item.allocated_leads_grp : 0;
        };

        const getOptimizationLabel = (item: FlightGcf): string => {
            if (item.optimization > 0.0) {
                return "ca. " + grpHelper.display(item.optimization) + " erbij";
            }

            if (item.optimization < 0.0) {
                return "ca. " + grpHelper.display(Math.abs(item.optimization)) + " eraf";
            }

            return "";
        };

        onMounted(() => {
            init();
        });

        watch(
            () => props.alert,
            (value, oldValue) => {
                if (value !== oldValue) {
                    alert.value = value ?? ({} as Alert);
                    init();
                }
            }
        );

        return {
            ...toRefs(data),
            alert,
            getCpa,
            getCpaClassName,
            getFrequencyClassName,
            getFrequencyLabel,
            getLeadsPerGrp,
            getOptimizationLabel,
            goal,
            grpHelper,
            priceHelper,
            stringHelper,
        };
    },
});
