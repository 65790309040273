import axios from "axios";
import { ActionContext } from "vuex";

import config from "@/service/config";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { LogState } from "@/types/state/log";
import { RootState } from "@/types/state/root";
import { Log } from "@/types/uzs/log";

const initialState: LogState = {
    columns: undefined,
    filter: {},
    order: {
        start_date: "desc",
    },
    pagination: {
        page: 1,
        per_page: config.pagination.per_page,
    },
    search: undefined,
    logs: undefined,
};

const LogModule = {
    namespaced: true,
    state: initialState,
    getters: {},
    mutations: {},
    actions: {
        _list(
            context: ActionContext<LogState, RootState>,
            params: RequestParameters | null
        ): Promise<DataResult<Log[]>> {
            return axios
                .get(config.backend + "/api/uzs/logs", {
                    params: {
                        columns: params?.columns ?? context.state.columns,
                        filter: params?.filter ?? context.state.filter,
                        order: params?.order ?? context.state.order,
                        pagination: params?.pagination ?? context.state.pagination,
                        search: params?.search ?? context.state.search,
                    },
                })
                .then((response) => response?.data as DataResult<Log[]>)
                .then((response) => {
                    context.state.logs = response;
                    return response;
                });
        },
    },
};

export default LogModule;
