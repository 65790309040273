<template>
    <el-dialog
        :title="'Blocks - ' + (this.importedBlocks?.filename ?? '')"
        :before-close="this.dialogClosed"
        :destroy-on-close="true"
        :lock-scroll="false"
        :model-value="this.value"
        width="100%"
        ref="dialog"
    >
        <el-form
            :model="this.importedBlocks"
            v-if="this.importedBlocks"
            label-position="left"
            label-width="150px"
            @keydown="this.submitOnEnter"
        >
            <el-form-item label="Adverteerder">
                <i v-if="!this.advertisers.length" class="el-icon-loading"></i>
                <el-select
                    v-if="this.advertisers.length"
                    v-model="importedBlocks.uzs_advertiser_id"
                    filterable
                >
                    <el-option
                        v-for="advertiser in this.advertisers"
                        :key="advertiser.id"
                        :label="advertiser.name"
                        :value="advertiser.id"
                        >{{ advertiser.name }}</el-option
                    >
                </el-select>
            </el-form-item>
            <el-form-item label="Doelstelling">
                <el-select v-model="importedBlocks.target" filterable>
                    <el-option v-for="goal in this.goals" :key="goal" :label="goal" :value="goal">{{
                        goal
                    }}</el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Land">
                <el-select v-model="importedBlocks.country" filterable>
                    <el-option
                        v-for="country in this.countries"
                        :key="country"
                        :label="country"
                        :value="country"
                        >{{ country }}</el-option
                    >
                </el-select>
            </el-form-item>
            <el-form-item label="GRP">
                <div>
                    <el-slider
                        max="50"
                        step="1"
                        v-model="importedBlocks.required_grps"
                        :model-value="importedBlocks.required_grps"
                        :format-tooltip="(val) => getFormattedGrp(val)"
                        @change="onGrpSliderChanged"
                        @input="onGrpSliderChanged"
                    />
                    <el-input
                        min="0"
                        max="50"
                        type="number"
                        v-model="requiredGrps"
                        @change="onGrpValueChanged"
                        @input="onGrpValueChanged"
                    >
                        <template #append>GRP</template>
                    </el-input>
                </div>
            </el-form-item>
            <el-form-item label="Datumformaat">
                <el-input
                    type="text"
                    v-model="importedBlocks.date_format"
                    :model-value="
                        importedBlocks.date_format !== ''
                            ? importedBlocks.date_format
                            : 'j M. Y G:i'
                    "
                />
            </el-form-item>
            <el-form-item label="Dagdelen">
                <div v-for="dayPart of dayParts" :key="dayPart">
                    <el-switch
                        :active-text="dayPart"
                        :model-value="selectedDayParts.includes(dayPart)"
                        @change="onDayPartSelectChanged(dayPart)"
                    />
                </div>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="this.save()">Opslaan</el-button>
                <el-button type="secondary" @click="this.save(true)"
                    >Opslaan en opnieuw inladen</el-button
                >
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script lang="ts">
import { ElMessage } from "element-plus";
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs, watch } from "vue";
import { Store, useStore } from "vuex";

import { Country } from "@/enums/country";
import { Goal } from "@/enums/goal";
import DateHelper from "@/helper/date-helper";
import GrpHelper from "@/helper/grp-helper";
import AdvertiserApiService from "@/service/api/advertiser";
import ImportedBlocksApiService from "@/service/api/importedBlocks";
import { RequestParameters } from "@/types/request/requestParameters";
import { DataResult } from "@/types/result/dataResult";
import { RootState } from "@/types/state/root";
import { Advertiser } from "@/types/uzs/advertiser";
import { DayPart } from "@/types/uzs/dayPart";
import { ImportedBlocks } from "@/types/uzs/importedBlocks";

const props = {
    value: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
    importedBlocks: {
        type: Object as PropType<ImportedBlocks>,
        required: true,
    },
};

export default defineComponent({
    name: "ImportedBlocksDialog",
    props,
    setup(props, { emit }) {
        const importedBlocks = ref(props.importedBlocks ?? {}) as Ref<ImportedBlocks | undefined>;
        const advertiserApi = new AdvertiserApiService();
        const importedBlocksApi = new ImportedBlocksApiService();
        const store = useStore() as Store<RootState>;
        const dateHelper = DateHelper;
        const goals = Goal;
        const countries = Country;

        const actions = {
            dayPart: {
                list: (params: RequestParameters) => store.dispatch("dayPart/_list", params),
            },
            flight: {
                list: (params: RequestParameters) => store.dispatch("flight/_list", params),
            },
            importedBlocks: {
                store: (importedBlocks: ImportedBlocks) => importedBlocksApi.store(importedBlocks),
            },
        };

        const data = reactive({
            advertisers: [] as Array<Advertiser>,
            dayParts: [] as Array<string>,
            selectedDayParts: (importedBlocks.value?.day_parts ?? []) as Array<string>,
            requiredGrps: 0 as number | string,
        });

        const close = (): void => {
            importedBlocks.value = {} as ImportedBlocks;

            emit("input", props.value);
        };

        function onGrpSliderChanged() {
            data.requiredGrps = importedBlocks.value?.required_grps ?? 0;
        }

        function onGrpValueChanged() {
            Object.assign(importedBlocks.value, {
                ...importedBlocks.value,
                required_grps: parseInt(data.requiredGrps.toString()),
            });
        }

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        function getFormattedGrp(value: number | undefined): string {
            if (!value) {
                return "0 GRP";
            }

            return GrpHelper.display(value);
        }

        const init = (): void => {
            data.requiredGrps = importedBlocks.value?.required_grps ?? 0;
            data.selectedDayParts = importedBlocks.value?.day_parts ?? [];

            loadAdvertisers();
            loadDayParts();
        };

        function loadAdvertisers(): void {
            advertiserApi
                .fetchAll({
                    pagination: {
                        page: 1,
                        per_page: 1000,
                    },
                    order: {
                        name: "asc",
                    },
                })
                .then((response) => {
                    data.advertisers = response.data as Advertiser[];
                });
        }

        function loadDayParts(): void {
            actions.dayPart
                .list({})
                .then(
                    (response: DataResult<Array<DayPart>>) =>
                        (data.dayParts = (response.data ?? []).map((dayPart) => dayPart.name))
                )
                .then(
                    () =>
                        (data.dayParts = data.dayParts
                            .filter((dayPart, index) => data.dayParts.indexOf(dayPart) === index)
                            .sort())
                );
        }

        function onDayPartSelectChanged(dayPart: string): void {
            if (!data.selectedDayParts.includes(dayPart)) {
                data.selectedDayParts.push(dayPart);
            } else {
                data.selectedDayParts = data.selectedDayParts.filter(
                    (part: string) => part !== dayPart
                );
            }
        }

        const save = (reset = false): void => {
            if (!importedBlocks?.value) {
                return;
            }

            importedBlocks.value.day_parts = data.selectedDayParts;

            if (reset) {
                importedBlocks.value.import_end = null;
            }

            actions.importedBlocks
                .store(importedBlocks.value)
                .then(() => {
                    ElMessage({
                        message: importedBlocks?.value?.filename + " is opgeslagen.",
                        type: "success",
                    });

                    close();
                })
                .catch(() => {
                    ElMessage({
                        message:
                            "Er is een fout opgetreden bij het opslaan van " +
                            importedBlocks?.value?.filename,
                        type: "error",
                    });
                });
        };

        const submitOnEnter = (event: KeyboardEvent): void => {
            const input = event.target as HTMLElement | null;

            if (
                event.key.toLowerCase() === "enter" &&
                input?.tagName?.toLowerCase() !== "textarea"
            ) {
                save();
            }
        };

        onMounted(() => {
            init();
        });

        watch(
            () => props.importedBlocks,
            (value, oldValue) => {
                if (value !== oldValue) {
                    importedBlocks.value = value ?? ({} as ImportedBlocks);
                    init();
                }
            }
        );

        return {
            ...toRefs(data),
            close,
            dateHelper,
            dialogClosed,
            getFormattedGrp,
            countries,
            goals,
            importedBlocks,
            onGrpSliderChanged,
            onGrpValueChanged,
            onDayPartSelectChanged,
            save,
            submitOnEnter,
        };
    },
});
</script>
