import axios from "axios";

import config from "@/service/config";
import { DataResult } from "@/types/result/dataResult";
import { TrafficSource } from "@/types/uzs/big-query-traffic-source/trafficSource";

export default class TrafficSourceApiService {
    public async list(
        advertiserId: number,
        medium: string | null = null
    ): Promise<DataResult<Array<TrafficSource>>> {
        const response = await axios.post(
            config.backend + "/api/integration/bigquery-traffic-sources/" + advertiserId,
            {
                medium,
            }
        );

        return response?.data as DataResult<Array<TrafficSource>>;
    }
}
