
import { mean } from "lodash";
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs, watch } from "vue";

import GrpValue from "@/components/campaigns/GrpValue.vue";
import LeadsPerGrpValue from "@/components/campaigns/LeadsPerGrpValue.vue";
import { Goal } from "@/enums/goal";
import DateHelper from "@/helper/date-helper";
import GrpHelper from "@/helper/grp-helper";
import { Alert } from "@/types/uzs/alert";
import { Spot } from "@/types/uzs/alert/spot";
import { Flight } from "@/types/uzs/flight";

const props = {
    alert: {
        type: Object as PropType<Alert>,
        required: false,
    },
    averageLeadsPerGrp: {
        type: Number as PropType<number>,
        default: true,
    },
    averagePredictedLeadsPerGrp: {
        type: Number as PropType<number>,
        default: true,
    },
    flight: {
        type: Object as PropType<Flight>,
        required: false,
    },
    multipleAlerts: {
        type: Array as PropType<Array<Alert>>,
        required: false,
    },
    message: {
        type: String as PropType<string>,
    },
    showGrp: {
        type: Boolean as PropType<boolean>,
    },
    showGrpErrors: {
        type: Boolean as PropType<boolean>,
        default: true,
    },
};

const daysOfWeek = [
    {
        number: 1,
        label: "maandag",
    },
    {
        number: 2,
        label: "dinsdag",
    },
    {
        number: 3,
        label: "woensdag",
    },
    {
        number: 4,
        label: "donderdag",
    },
    {
        number: 5,
        label: "vrijdag",
    },
    {
        number: 6,
        label: "zaterdag",
    },
    {
        number: 7,
        label: "zondag",
    },
];

type CurrentRow = {
    row: Spot;
    rowIndex: number;
};

export default defineComponent({
    name: "AlertSpotsTable",
    components: { LeadsPerGrpValue, GrpValue },
    props,
    setup(props) {
        const alert = ref(props.alert ?? {}) as Ref<Alert>;
        const averageLeadsPerGrp = ref(props.averageLeadsPerGrp ?? 0.0) as Ref<number>;
        const averagePredictedLeadsPerGrp = ref(props.averageLeadsPerGrp ?? 0.0) as Ref<number>;
        const flight = ref(props.flight ?? {}) as Ref<Flight>;
        const alerts = ref(props.multipleAlerts ?? []) as Ref<Array<Alert>>;
        const goal = Goal;
        const dateHelper = DateHelper;
        const grpHelper = GrpHelper;
        const message = ref(props.message) as Ref<string>;
        const showGrpErrors = ref(props.showGrpErrors ?? true) as Ref<boolean>;

        if (alert?.value?.data) {
            alerts?.value.push(alert.value);
        }

        const data = reactive({
            days: [] as Array<number | null>,
            daysString: "",
            spots: [] as Array<Spot>,
            leadsPerGrp: [] as number[],
            predictedLeadsPerGrp: [] as number[],
            predictedAverageLeadsPerGrp: 0.0,
        });

        const init = (): void => {
            data.spots = [];
            data.leadsPerGrp = [];
            data.predictedLeadsPerGrp = [];

            for (const item of alerts?.value) {
                const alertSpots = item.data as Array<Spot>;

                for (const alertSpot of alertSpots) {
                    data.spots.push(alertSpot);

                    const leadsPerGrp =
                        alertSpot.achieved_number_of_leads > -1 && alertSpot.rating !== 0.0
                            ? alertSpot.achieved_number_of_leads / alertSpot.rating
                            : null;

                    if (alertSpot.predicted_leads_per_grp) {
                        data.predictedLeadsPerGrp.push(alertSpot.predicted_leads_per_grp);
                    }
                }

                data.days = data.spots
                    .map((spot) => spot.day_of_week)
                    .filter((value, index, self) => self.indexOf(value) === index)
                    .sort();

                data.days.sort();
                data.daysString = data.days
                    .map((day) => daysOfWeek.find((d) => d.number === day)?.label)
                    .join(", ");
            }

            data.spots.sort(sortSpots);
            data.predictedAverageLeadsPerGrp = mean(data.predictedLeadsPerGrp);
        };

        const getDayOfWeek = (day: number): string => {
            return daysOfWeek.find((d) => d.number === day)?.label ?? "";
        };

        const roundNumber = (value: number): string => {
            const numberString = (Math.round(value * 10) / 10).toString().replace(".", ",");

            return numberString.indexOf(",") === -1 ? numberString + ",0" : numberString;
        };

        const sortSpots = (a: Spot, b: Spot) => {
            if (a.timestamp === b.timestamp) {
                return 0;
            }

            return (a?.timestamp ?? 0) > (b?.timestamp ?? 0) ? 1 : -1;
        };

        function getGoogleSearchUrl(scope: CurrentRow): string {
            return (
                "https://google.com/search?q=" +
                scope.row.channel.replaceAll(" ", "%20").replaceAll("&", "%26") +
                "%20" +
                scope.row.program_before.toLowerCase().replaceAll(" ", "%20").replaceAll("&", "%26")
            );
        }

        function getLeadsPerGrp(scope: CurrentRow): number | null {
            const leadsPerGrp =
                scope.row.achieved_number_of_leads && scope.row.rating > 0
                    ? scope.row.achieved_number_of_leads / scope.row.rating
                    : null;

            if (
                isTodayOrLater(scope.row.scheduled_date_time) &&
                leadsPerGrp === null &&
                scope.row.predicted_leads_per_grp > 0.0
            ) {
                return scope.row.predicted_leads_per_grp;
            }

            return leadsPerGrp;
        }

        function getLeadsPerGrpClasses(scope: CurrentRow): string[] {
            const leadsPerGrp = getLeadsPerGrp(scope)
                ? Math.round((getLeadsPerGrp(scope) ?? 0) * 10) / 10
                : null;

            const averageTarget =
                Math.round(
                    (averageLeadsPerGrp.value > 0
                        ? averageLeadsPerGrp.value
                        : averagePredictedLeadsPerGrp.value) *
                        0.8 *
                        10
                ) / 10;

            if (
                leadsPerGrp !== null &&
                leadsPerGrp < averageTarget &&
                !isTodayOrLater(scope.row.scheduled_date_time)
            ) {
                return ["is--bold", "has--warning"];
            }

            if (
                leadsPerGrp !== null &&
                leadsPerGrp < averageTarget &&
                isTodayOrLater(scope.row.scheduled_date_time)
            ) {
                return ["is--bold", "has--error"];
            }

            return leadsPerGrp === null || isTodayOrLater(scope.row.scheduled_date_time)
                ? ["is--greyed-out"]
                : [];
        }

        function getPackage(spot: Spot): string {
            if (spot.package_description.trim() !== "") {
                return spot.package_description
                    .substring(spot.package_description?.indexOf(":") + 1)
                    .replace("-", " ")
                    .trim();
            }

            return spot.channel;
        }

        function getPosition(spot: Spot): string | null {
            if (isTodayOrLater(spot.scheduled_date_time) && spot.position?.length === 2) {
                return spot.position;
            }

            if (spot.preferred_position?.length === 2) {
                return spot.preferred_position;
            }

            return "";
        }

        function hasGrpError(grp: number): boolean {
            return showGrpErrors.value && grp > 12 && flight.value?.goal !== Goal.BRAND_UPLIFT;
        }

        function isLeadsCampaign(): boolean {
            return flight.value?.goal === Goal.LEADS;
        }

        function isTodayOrLater(date: string | null): boolean {
            if (date === null) {
                return false;
            }

            return DateHelper.isDateTodayOrLater(date);
        }

        onMounted(() => {
            init();
        });

        watch(
            () => props.alert,
            (value, oldValue) => {
                if (value !== oldValue) {
                    alert.value = value ?? ({} as Alert);
                    init();
                }
            }
        );

        watch(
            () => props.averageLeadsPerGrp,
            (value, oldValue) => {
                if (value !== oldValue) {
                    averageLeadsPerGrp.value = value ?? 0.0;
                    init();
                }
            }
        );

        watch(
            () => props.flight,
            (value, oldValue) => {
                if (value !== oldValue) {
                    flight.value = value ?? ({} as Flight);
                    init();
                }
            }
        );

        watch(
            () => props.multipleAlerts,
            (value, oldValue) => {
                if (value !== oldValue) {
                    alerts.value = value ?? [];
                    init();
                }
            }
        );

        watch(
            () => props.message,
            (value, oldValue) => {
                if (value !== oldValue) {
                    message.value = value ?? "";
                    init();
                }
            }
        );

        return {
            ...toRefs(data),
            close,
            dateHelper,
            getDayOfWeek,
            goal,
            getGoogleSearchUrl,
            grpHelper,
            getLeadsPerGrp,
            getLeadsPerGrpClasses,
            getPackage,
            getPosition,
            hasGrpError,
            isLeadsCampaign,
            isTodayOrLater,
            roundNumber,
        };
    },
});
