
import { ElMessage, ElMessageBox } from "element-plus";
import { defineComponent, inject, Ref, ref } from "vue";
import { mapActions, mapState, Store } from "vuex";

import { Copy } from "@/service/clipboard/copy";
import config from "@/service/config";
import { FilterRequest } from "@/types/request/filterRequest";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { TargetGroupState } from "@/types/state/targetGroup";
import { TargetGroup } from "@/types/uzs/targetGroup";
import TargetGroupDialog from "@/views/settings/target-groups/dialogs/TargetGroup.vue";

type CurrentRow = {
    row: TargetGroup;
    rowIndex: number;
};

type SortEvent = {
    column: string;
    order: string;
    prop: string;
};

export default defineComponent({
    name: "List",
    components: { TargetGroupDialog },
    setup() {
        const currentTargetGroup = ref(null) as Ref<TargetGroup | null>;
        const store = inject("store") as Store<TargetGroupState>;
        const textCopier = new Copy();

        return {
            currentTargetGroup,
            _store: store,
            textCopier,
        };
    },
    computed: {
        ...mapState("targetGroup", ["targetGroup", "targetGroups"]),
        page(): number {
            return this.pagination.page as number;
        },
        pages(): number {
            return this.targetGroups?.pagination?.total_pages;
        },
        perPage(): number {
            return this.pagination.per_page as number;
        },
    },
    data() {
        return {
            filter: {} as FilterRequest,
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            order: {
                name: "asc",
            } as OrderRequest,
            currentPage: undefined as number | undefined,
            loading: true,
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            showFilterModal: false,
            showTargetGroupDialog: false,
            sortingEnabled: false,
        };
    },
    mounted() {
        this.update();
    },
    methods: {
        ...mapActions("targetGroup", ["_delete", "_details", "_list"]),
        delete(value: CurrentRow) {
            ElMessageBox.confirm(
                "Weet je zeker dat je " + value.row.name + " wilt verwijderen?",
                "Verwijderen?",
                {
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nee",
                    type: "warning",
                }
            ).then(() => {
                this._delete(value.row.id)
                    .then((result) => {
                        this.update();

                        if (!result.success) {
                            throw new Error();
                        }

                        ElMessage({
                            message: value.row.name + " is verwijderd.",
                            type: "success",
                        });
                    })
                    .catch(() => {
                        ElMessage({
                            message: "Er is een onbekende fout opgetreden.",
                            type: "error",
                        });
                    });
            });
        },
        getRowClassName(value: CurrentRow) {
            return "row " + value.row.id;
        },
        goToPage(page: number) {
            this.currentPage = page;
            this.pagination.page = page;

            this.update();
        },
        newTargetGroup() {
            this.currentTargetGroup = {} as TargetGroup;
            this.showTargetGroupDialog = true;
        },
        pageSizeChange(pageSize: number) {
            this.pagination.per_page = pageSize;

            this.update();
        },
        routeToDetails($event: Event, row: TargetGroup) {
            $event.stopImmediatePropagation();

            this.$router.push({
                name: "target-group-detail",
                params: {
                    id: row.id,
                },
            });
        },
        sortingChanged($event: SortEvent) {
            const property = $event.prop?.length ? $event.prop : null;
            const direction = $event.order === "descending" ? "desc" : "asc";

            if (!property || !this.targetGroups || !this.sortingEnabled) {
                return;
            }

            this.order = {
                [property]: direction,
            };

            this.update();
        },
        openTargetGroupDialog(targetGroup?: TargetGroup | null) {
            this.currentTargetGroup = targetGroup ?? null;
            this.showTargetGroupDialog = true;
        },
        targetGroupDialogInput(value: boolean) {
            this.showTargetGroupDialog = value;
            this.update();
        },
        update() {
            this.loading = true;

            this._list({
                filter: this.filter,
                pagination: this.pagination,
                order: this.order,
            }).then(() => {
                this.loading = false;
                this.sortingEnabled = true;
            });
        },
    },
});
