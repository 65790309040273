<template>
    <el-dialog
        :title="'Import - ' + (this.importedFile?.filename ?? '')"
        :before-close="this.dialogClosed"
        :destroy-on-close="true"
        :lock-scroll="false"
        :model-value="this.value"
        width="100%"
        ref="dialog"
    >
        <el-form
            :model="this.importedFile"
            v-if="this.importedFile?.mapping"
            label-position="left"
            label-width="150px"
            @keydown="this.submitOnEnter"
        >
            <div>
                <el-form-item label="Overnemen van">
                    <el-select
                        v-model="this.selectedTemplateId"
                        filterable
                        clearable
                        @change="this.onSelectedTemplateChanged"
                    >
                        <el-option
                            v-for="template in this.allTemplates"
                            :key="template.id"
                            :label="template.name"
                            :value="template.id"
                            >{{ template.name }}</el-option
                        >
                    </el-select>
                </el-form-item>
                <el-switch v-model="customizeTemplate" active-text="Aanpassen"></el-switch>
            </div>
            <div v-if="customizeTemplate">
                <br />
                <el-divider><b>Exploitant</b></el-divider>
                <el-form-item label="Exploitant">
                    <el-input v-model="this.importedFile.operator" />
                </el-form-item>
                <br />
                <el-divider><b>Doelgroep</b></el-divider>
                <el-form-item label="Doelgroep">
                    <el-input
                        clearable="clearable"
                        v-model="this.importedFile.mapping.targetAudience"
                    />
                </el-form-item>
                <br />
                <el-divider><b>Order</b></el-divider>
                <el-form-item label="Unieke waarde">
                    <el-input
                        clearable="clearable"
                        v-model="this.importedFile.mapping.orderUniqueIdentifier"
                    />
                </el-form-item>
                <el-form-item label="Beschrijving">
                    <el-input
                        clearable="clearable"
                        v-model="this.importedFile.mapping.orderDescription"
                    />
                </el-form-item>
                <br />
                <el-divider><b>Suborder/Pakket</b></el-divider>
                <el-form-item label="Unieke waarde">
                    <el-input
                        clearable="clearable"
                        v-model="this.importedFile.mapping.subOrderUniqueIdentifier"
                    />
                </el-form-item>
                <el-form-item label="Beschrijving">
                    <el-input
                        clearable="clearable"
                        v-model="this.importedFile.mapping.subOrderDescription"
                    />
                </el-form-item>
                <br />
                <el-divider><b>Spot</b></el-divider>
                <el-form-item label="Unieke waarde">
                    <el-input
                        clearable="clearable"
                        v-model="this.importedFile.mapping.spotUniqueIdentifier"
                    />
                </el-form-item>
                <el-form-item label="Beschrijving">
                    <el-input
                        clearable="clearable"
                        v-model="this.importedFile.mapping.spotDescription"
                    />
                </el-form-item>
                <el-form-item label="Datum/tijd">
                    <el-input
                        clearable="clearable"
                        v-model="this.importedFile.mapping.spotDateTime"
                    />
                </el-form-item>
                <el-form-item label="Datum/tijd formaat">
                    <el-input
                        clearable="clearable"
                        v-model="this.importedFile.mapping.spotDateTimeFormat"
                    />
                </el-form-item>
                <el-form-item label="GRP">
                    <el-input clearable="clearable" v-model="this.importedFile.mapping.spotGrp" />
                </el-form-item>
                <el-form-item label='Basis prijs 30"'>
                    <el-input
                        clearable="clearable"
                        v-model="this.importedFile.mapping.spotBasePrice"
                    />
                </el-form-item>
                <el-form-item label="Bruto prijs">
                    <el-input
                        clearable="clearable"
                        v-model="this.importedFile.mapping.spotGrossPrice"
                    />
                </el-form-item>
                <el-form-item label="Netto prijs">
                    <el-input
                        clearable="clearable"
                        v-model="this.importedFile.mapping.spotNetPrice"
                    />
                </el-form-item>
                <el-form-item label="Lengte">
                    <el-input
                        clearable="clearable"
                        v-model="this.importedFile.mapping.spotLength"
                    />
                </el-form-item>
                <el-form-item label="Break ID">
                    <el-input
                        clearable="clearable"
                        v-model="this.importedFile.mapping.spotBreakId"
                    />
                </el-form-item>
                <el-form-item label="Programma voor">
                    <el-input
                        clearable="clearable"
                        v-model="this.importedFile.mapping.spotProgramBefore"
                    />
                </el-form-item>
                <el-form-item label="Programma na">
                    <el-input
                        clearable="clearable"
                        v-model="this.importedFile.mapping.spotProgramAfter"
                    />
                </el-form-item>
                <el-form-item label="Zender">
                    <el-input
                        clearable="clearable"
                        v-model="this.importedFile.mapping.spotChannel"
                    />
                </el-form-item>
            </div>
            <el-form-item>
                <el-button type="primary" @click="this.save()">Opslaan</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script lang="ts">
import { ElMessage } from "element-plus";
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs, watch } from "vue";
import { Store, useStore } from "vuex";

import { DataResult } from "@/types/result/dataResult";
import { ImportedFileState } from "@/types/state/importedFile";
import { RootState } from "@/types/state/root";
import { ImportedFile } from "@/types/uzs/importedFile";
import { ImportMapping } from "@/types/uzs/importMapping";
import { ImportTemplate } from "@/types/uzs/importTemplate";

const props = {
    value: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
    importedFile: {
        type: Object as PropType<ImportedFile>,
        required: true,
    },
};

export default defineComponent({
    name: "ImportedFileMappingDialog",
    props,
    setup(props, { emit }) {
        const importedFile = ref(props.importedFile ?? {}) as Ref<ImportedFile | undefined>;
        const store = useStore() as Store<RootState>;
        const state = store.state.importedFile as ImportedFileState;

        const actions = {
            importTemplate: {
                all: () =>
                    store.dispatch("importTemplate/_list", {
                        pagination: {
                            per_page: 1000,
                        },
                        order: {
                            name: "asc",
                        },
                    }),
            },
            importedFile: {
                store: (importedFile: ImportedFile) =>
                    store.dispatch("importedFile/_store", importedFile),
            },
        };

        const data = reactive({
            selectedTemplateId: null as number | null,
            allTemplates: [] as ImportTemplate[],
            customizeTemplate: false,
        });

        const close = (): void => {
            importedFile.value = {} as ImportedFile;
            data.selectedTemplateId = null;

            emit("input", props.value);
        };

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        const loadAllTemplates = (): void => {
            actions.importTemplate.all().then((result: DataResult<ImportTemplate[]>) => {
                data.allTemplates = result.data ?? [];
            });
        };

        const onSelectedTemplateChanged = () => {
            const selectedTemplate = data.allTemplates.find(
                (template: ImportTemplate) => template.id === data.selectedTemplateId
            );

            if (!importedFile.value || !selectedTemplate) {
                return;
            }

            importedFile.value.country = selectedTemplate.country;
            importedFile.value.operator = selectedTemplate.operator;
            importedFile.value.mapping = selectedTemplate.mapping;
        };

        const save = (): void => {
            if (!importedFile?.value) {
                return;
            }

            actions.importedFile
                .store(importedFile.value)
                .then(() => {
                    ElMessage({
                        message: importedFile?.value?.filename + " is opgeslagen.",
                        type: "success",
                    });

                    close();
                })
                .catch(() => {
                    ElMessage({
                        message:
                            "Er is een fout opgetreden bij het opslaan van " +
                            importedFile?.value?.filename,
                        type: "error",
                    });
                });
        };

        const submitOnEnter = (event: KeyboardEvent): void => {
            const input = event.target as HTMLElement | null;

            if (
                event.key.toLowerCase() === "enter" &&
                input?.tagName?.toLowerCase() !== "textarea"
            ) {
                save();
            }
        };

        onMounted(() => {
            loadAllTemplates();
        });

        watch(
            () => props.importedFile,
            (value, oldValue) => {
                if (value !== oldValue) {
                    importedFile.value =
                        value ?? ({ mapping: {} as ImportMapping } as ImportedFile);

                    if (!importedFile.value?.mapping) {
                        importedFile.value.mapping = {} as ImportMapping;
                    }

                    loadAllTemplates();
                }
            }
        );

        return {
            ...toRefs(data),
            ...toRefs(state),
            close,
            importedFile,
            dialogClosed,
            onSelectedTemplateChanged,
            save,
            submitOnEnter,
        };
    },
});
</script>
