
import { format } from "date-fns";
import { nl } from "date-fns/locale";
import { defineComponent, onMounted, Ref, ref } from "vue";
import { mapActions } from "vuex";

import CpaValue from "@/components/campaigns/CpaValue.vue";
import CpmValue from "@/components/campaigns/CpmValue.vue";
import DurationPercentage from "@/components/campaigns/DurationPercentage.vue";
import GrpPercentage from "@/components/campaigns/GrpPercentage.vue";
import GrpPriceValue from "@/components/campaigns/GrpPriceValue.vue";
import GrpValue from "@/components/campaigns/GrpValue.vue";
import OptimizationScore from "@/components/campaigns/OptimizationScore.vue";
import TargetPercentage from "@/components/campaigns/TargetPercentage.vue";
import { Country } from "@/enums/country";
import GrpHelper from "@/helper/grp-helper";
import PercentageHelper from "@/helper/percentage-helper";
import PriceHelper from "@/helper/price-helper";
import AdvertiserApiService from "@/service/api/advertiser";
import config from "@/service/config";
import { FilterRequest } from "@/types/request/filterRequest";
import { OrderRequest } from "@/types/request/orderRequest";
import { PaginationRequest } from "@/types/request/paginationRequest";
import { DataResult } from "@/types/result/dataResult";
import { Advertiser } from "@/types/uzs/advertiser";
import { Flight } from "@/types/uzs/flight";
import { FlightStatistics } from "@/types/uzs/flightStatistics";

type SortEvent = {
    column: string;
    order: string;
    prop: string;
};

export default defineComponent({
    name: "Debug",
    components: {
        GrpValue,
        DurationPercentage,
        CpmValue,
        GrpPriceValue,
        GrpPercentage,
        CpaValue,
        TargetPercentage,
        OptimizationScore,
    },
    setup() {
        const advertisers = ref(null) as Ref<Advertiser[] | null>;
        const advertiserApi = new AdvertiserApiService();
        const countries = Country;
        const grpHelper = GrpHelper;
        const percentageHelper = PercentageHelper;
        const priceHelper = PriceHelper;

        onMounted(() => {
            advertiserApi
                .fetchAll({
                    order: {
                        start_date: "desc",
                        country: "asc",
                        name: "asc",
                    },
                })
                .then((data) => {
                    advertisers.value = data.data;
                });
        });

        return {
            advertisers,
            advertiserApi,
            countries,
            grpHelper,
            percentageHelper,
            priceHelper,
        };
    },
    computed: {
        page(): number {
            return this.pagination.page;
        },
        pages(): number {
            return this.statistics?.pagination?.total_pages as number;
        },
        perPage(): number {
            return this.pagination.per_page;
        },
    },
    data() {
        return {
            filter: {} as FilterRequest,
            order: {} as OrderRequest,
            pagination: {
                page: 1,
                per_page: config.pagination.per_page,
            } as PaginationRequest,
            search: undefined as null | number | string | undefined,
            selectedCountries: [] as string[],
            advertiserIds: [] as number[],
            statistics: undefined as DataResult<FlightStatistics[]> | undefined,
            currentPage: undefined as number | undefined,
            loading: true,
            pageSizes: [10, 25, 50, 100, 250, 500] as number[],
            sortingEnabled: false,
            totalItems: 0,
        };
    },
    mounted() {
        this.order = {
            start_date: "desc",
            country: "asc",
            name: "asc",
        };

        Object.assign(this.pagination, {
            per_page: 25,
        });

        this.update();
    },
    methods: {
        ...mapActions("flight", ["_statistics"]),
        getYear(date: string) {
            return format(Date.parse(date), "yyyy");
        },
        getDatePeriod(value: Flight | null) {
            if (!value) {
                return "";
            }

            const startDate = Date.parse(value.start_date ?? "now");
            const endDate = Date.parse(value.end_date);

            let startDay = format(startDate, "d");
            const endDay = format(endDate, "d");
            let startMonth =
                " " + format(startDate, "LLL", { locale: nl }).toLowerCase().replace(".", "");
            const endMonth =
                " " + format(endDate, "LLL", { locale: nl }).toLowerCase().replace(".", "");
            let startYear = " " + format(startDate, "yyyy");
            const endYear = " " + format(endDate, "yyyy");

            if (startDay === endDay && startMonth === endMonth && startYear === endYear) {
                startDay = "";
            }

            if (startMonth === endMonth) {
                startMonth = "";
            }

            if (startYear === endYear) {
                startYear = "";
            }

            return (
                startDay +
                startMonth +
                startYear +
                (startDay === "" && startMonth === "" && startYear === "" ? "" : " - ") +
                endDay +
                endMonth +
                endYear
            );
        },
        getBudget(value: Flight | null): string {
            if (!value) {
                return "";
            }

            return (
                "€ " +
                (value.budget ?? 0).toLocaleString("nl", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                })
            );
        },
        goToPage(page: number) {
            this.currentPage = page;
            this.pagination.page = page;
            this.update();
        },
        pageSizeChange(pageSize: number) {
            this.pagination.per_page = pageSize;
            this.update();
        },
        sortingChanged($event: SortEvent) {
            const property = $event.prop.length ? $event.prop : null;
            const direction = $event?.order === "descending" ? "desc" : "asc";

            if (!property || !this.sortingEnabled || !this.advertisers || !this.order) {
                return;
            }

            this.order = {
                [property]: direction,
            };

            this.update();
        },
        onSelectedAdvertiserChanged(): void {
            Object.assign(this.filter, {
                "uzs_flight_statistics.uzs_advertiser_id": {
                    operator: "IN",
                    value: this.advertiserIds,
                },
            });

            this.update();
        },
        onSelectedCountryChanged(): void {
            Object.assign(this.filter, {
                "uzs_flight_statistics.country": {
                    operator: "IN",
                    value: this.selectedCountries,
                },
            });

            this.update();
        },
        update() {
            this.loading = true;

            this._statistics({
                filter: this.filter,
                order: this.order,
                pagination: this.pagination,
                search: this.search,
            }).then((response) => {
                this.loading = false;
                this.sortingEnabled = true;
                this.statistics = response;
                this.totalItems = response.pagination?.total_items ?? 0;
            });
        },
    },
});
