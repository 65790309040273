
import { defineComponent, onMounted, PropType, reactive, Ref, ref, toRefs, watch } from "vue";

import Percentage from "@/components/alerts/Percentage.vue";
import LeadsPerGrpValue from "@/components/campaigns/LeadsPerGrpValue.vue";
import { AlertType } from "@/enums/alertType";
import { ChannelCluster } from "@/enums/channelCluster";
import GrpHelper from "@/helper/grp-helper";
import NumberHelper from "@/helper/number-helper";
import PercentageHelper from "@/helper/percentage-helper";
import { Alert } from "@/types/uzs/alert";
import { ChannelClusterData } from "@/types/uzs/alert/channelClusterData";

const props = {
    alert: {
        type: Object as PropType<Alert>,
        required: true,
    },
    message: {
        type: String as PropType<string>,
    },
    showMax: {
        type: Boolean as PropType<boolean>,
        default: false,
    },
};

export type TableRow = {
    cluster: string;
    grp: string;
    grpOfAllocatedLeads: number;
    leads: number;
    percentage: string;
    percentageNumeric: number;
    channels: string;
};

export default defineComponent({
    name: "AlertChannelClustersTable",
    components: { LeadsPerGrpValue, Percentage },
    props,
    setup(props) {
        const alert = ref(props.alert) as Ref<Alert>;
        const grpHelper = GrpHelper;
        const numberHelper = NumberHelper;
        const percentageHelper = PercentageHelper;
        const message = ref(props.message) as Ref<string>;
        const alertType = AlertType;

        const data = reactive({
            channelClusters: {} as ChannelClusterData,
            flight: alert.value?.uzs_flight ?? null,
            flightStatistics: alert.value?.uzs_flight?.uzs_flight_statistics ?? null,
            tableData: [] as Array<TableRow>,
        });

        const init = (): void => {
            data.channelClusters = alert.value?.data as ChannelClusterData;

            loadTableData();
            updateMessage();
        };

        const getLabelClassName = (row: TableRow, column: string | null = null): string => {
            if (row.percentageNumeric === 0 || row.grp === "0,0 GRP") {
                return "greyed-out";
            }

            let className = alert.value?.type === alertType.Success ? "green" : "red";

            return row.cluster.indexOf("Awareness") !== -1 &&
                column !== "GRP" &&
                (data.channelClusters?.maximum_share > 0 ||
                    data.channelClusters?.maximum_share < 100)
                ? className
                : "";
        };

        const getLeadsPerGrp = (row: TableRow): number => {
            if (row.grpOfAllocatedLeads === 0 || row.leads === 0) {
                return 0;
            }

            return row.grpOfAllocatedLeads > 0.5 ? row.leads / row.grpOfAllocatedLeads : 0;
        };

        const updateMessage = (): void => {
            if (message.value?.length) {
                return;
            }

            message.value =
                data.channelClusters.awareness.share > data.channelClusters.minimum_share
                    ? "Op dit moment gaat <b class='" + alert.value.type === AlertType.Success
                        ? "green"
                        : "red" +
                          "'>[percentage]</b> van het budget naar Awareness Channels, daar waar <b>maximaal " +
                          data.channelClusters.maximum_share +
                          "%</b> is toegestaan bij de gekozen campagnedoelstelling."
                    : "Op dit moment gaat <b class='" + alert.value.type === AlertType.Success
                    ? "green"
                    : "red" +
                      "'>[percentage]</b> van het budget naar Awareness Channels, daar waar <b>minimaal " +
                      data.channelClusters.minimum_share +
                      "%</b> nodig is bij de gekozen campagnedoelstelling.";
        };

        const loadTableData = (): void => {
            let frequencyPercentage = data.channelClusters.frequency.share;

            if (alert.value.total === 0) {
                frequencyPercentage = 100;
            }

            data.tableData = [
                {
                    cluster: ChannelCluster.AWARENESS + "s",
                    grp: grpHelper.display(data.channelClusters.awareness.rating),
                    grpOfAllocatedLeads:
                        data.channelClusters.awareness.achieved_number_of_leads_grp,
                    leads: Math.round(data.channelClusters.awareness.achieved_number_of_leads),
                    percentage: percentageHelper.display(data.channelClusters.awareness.share),
                    percentageNumeric: data.channelClusters.awareness.share,
                    channels: data.channelClusters.awareness.channels,
                },
                {
                    cluster: ChannelCluster.VOLUME + "s",
                    grp: grpHelper.display(data.channelClusters.volume.rating),
                    grpOfAllocatedLeads: data.channelClusters.volume.achieved_number_of_leads_grp,
                    leads: Math.round(data.channelClusters.volume.achieved_number_of_leads),
                    percentage: percentageHelper.display(data.channelClusters.volume.share),
                    percentageNumeric: data.channelClusters.volume.share,
                    channels: data.channelClusters.volume.channels,
                },
                {
                    cluster: ChannelCluster.FREQUENCY + "s",
                    grp: grpHelper.display(data.channelClusters.frequency.rating),
                    grpOfAllocatedLeads:
                        data.channelClusters.frequency.achieved_number_of_leads_grp,
                    leads: Math.round(data.channelClusters.frequency.achieved_number_of_leads),
                    percentage: percentageHelper.display(frequencyPercentage),
                    percentageNumeric: frequencyPercentage,
                    channels: data.channelClusters.frequency.channels,
                },
            ];
        };

        function showBoundaries(row: TableRow): boolean {
            return row.cluster.indexOf("Awareness") !== -1;
        }

        onMounted(() => {
            init();
        });

        watch(
            () => props.alert,
            (value, oldValue) => {
                if (value !== oldValue) {
                    alert.value = value ?? ({} as Alert);
                    init();
                }
            }
        );

        watch(
            () => props.message,
            (value, oldValue) => {
                if (value !== oldValue) {
                    message.value = value ?? "";
                    init();
                }
            }
        );

        return {
            ...toRefs(data),
            alertType,
            close,
            getLabelClassName,
            getLeadsPerGrp,
            grpHelper,
            numberHelper,
            percentageHelper,
            showBoundaries,
        };
    },
});
