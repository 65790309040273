
import { ElMessage } from "element-plus";
import { defineComponent, PropType, reactive, Ref, ref, toRefs, watch } from "vue";
import { Store, useStore } from "vuex";

import { Country } from "@/enums/country";
import { ImportTemplateState } from "@/types/state/importTemplate";
import { RootState } from "@/types/state/root";
import { ImportMapping } from "@/types/uzs/importMapping";
import { ImportTemplate } from "@/types/uzs/importTemplate";

const props = {
    value: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
    importTemplate: {
        type: Object as PropType<ImportTemplate>,
        required: true,
    },
};

export default defineComponent({
    name: "ImportTemplateDialog",
    props,
    setup(props, { emit }) {
        const importTemplate = ref(props.importTemplate ?? {}) as Ref<ImportTemplate | undefined>;
        const store = useStore() as Store<RootState>;
        const state = store.state.importTemplate as ImportTemplateState;
        const countries = Country;

        const actions = {
            importTemplate: {
                store: (importTemplate: ImportTemplate) =>
                    store.dispatch("importTemplate/_store", importTemplate),
            },
        };

        const data = reactive({
            selectedTemplateId: null as number | null,
            allTemplates: [] as ImportTemplate[],
        });

        const close = (): void => {
            importTemplate.value = {} as ImportTemplate;

            emit("input", props.value);
        };

        const dialogClosed = (done: CallableFunction): void => {
            close();
            done();
        };

        const save = (): void => {
            if (!importTemplate?.value) {
                return;
            }

            actions.importTemplate
                .store(importTemplate.value)
                .then(() => {
                    ElMessage({
                        message: importTemplate?.value?.name + " is opgeslagen.",
                        type: "success",
                    });

                    close();
                })
                .catch(() => {
                    ElMessage({
                        message:
                            "Er is een fout opgetreden bij het opslaan van " +
                            importTemplate?.value?.name,
                        type: "error",
                    });
                });
        };

        const submitOnEnter = (event: KeyboardEvent): void => {
            const input = event.target as HTMLElement | null;

            if (
                event.key.toLowerCase() === "enter" &&
                input?.tagName?.toLowerCase() !== "textarea"
            ) {
                save();
            }
        };

        watch(
            () => props.importTemplate,
            (value, oldValue) => {
                if (value !== oldValue) {
                    importTemplate.value =
                        value ?? ({ mapping: {} as ImportMapping } as ImportTemplate);

                    if (!importTemplate.value?.mapping) {
                        importTemplate.value.mapping = {} as ImportMapping;
                    }
                }
            }
        );

        return {
            ...toRefs(data),
            ...toRefs(state),
            close,
            countries,
            importTemplate,
            dialogClosed,
            save,
            submitOnEnter,
        };
    },
});
